import React, { useState, useContext, useEffect, memo, createRef } from "react";
import { makeStyles } from "@mui/styles";
import {
  Modal,
  Box,
  Button,
  Divider,
  Typography,
  FormControlLabel,
  Switch,
  TextField,
  MenuItem,
  FormControl,
  InputAdornment,
  FormLabel,
  RadioGroup,
  Radio,
  Tooltip,
  Paper,
  TableContainer,
  Table,
  TablePagination,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Grid,
  Avatar,
  LinearProgress,
} from "@mui/material";

import RowAgregarPagoAdicional from "./PagoAdicional/RowAgregarPagoAdicional";
import RowPagoAdicional from "./PagoAdicional/RowPagoAdicional";
import Draggable from "react-draggable";
import {
  AddCircle,
  Delete,
  CloudUpload,
  Map,
  DeleteForever,
  Save,
  Restore,
  Close,
  Search,
} from "@mui/icons-material";
import DialogMap from "../../../../components/Extra/DialogMap";
import { Autocomplete, IconButton } from "@mui/material";
// eslint-disable-next-line
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import AuthContext from "../../../../Context/Auth/AuthContext";
import clienteAxios from "../../../../config/axios";
import {
  validarDocumento,
  validarEmail,
} from "../../../../config/validaciones";
import { trimPropsItem } from "../../../../config/const";
import { Download } from "@mui/icons-material";
import MuiPhoneNumber from "material-ui-phone-number";

import ToolBarTable from "./ToolBarTable";
import TablaCabecera from "./TablaCabecera";
import { usePermiso } from "../../../../hooks/usePermiso";
// Importar la librería
import { isValidNumber } from "libphonenumber-js";
import { agregarTransparencia } from "../../../../helpers";
import { useTheme } from "@emotion/react";
import CampoDoc from "../../../components/Campos/CampoDoc";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "70%",
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[5],
    padding: 0,
    borderRadius: "15px",
    marginTop: "5vh",
    [theme.breakpoints.down("sm")]: { width: "90%", marginTop: "2vh" },
  },
  margin: {
    margin: theme.spacing(1),
    width: "45%",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  ocultar: { display: "none" },
  inputPadding: {
    padding: theme.spacing(0.5),
    margin: theme.spacing(0),
    fontSize: "1rem",
  },
  formD: {
    flexGrow: 1,
    width: "43%",
    padding: "0",
    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "4.%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  formAllW: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "95%",
    [theme.breakpoints.down("md")]: { padding: "0", margin: "2px" },
    [theme.breakpoints.down("sm")]: { padding: "0", margin: "2px" },
  },

  form30PC: {
    flexGrow: 1,
    width: "30%",
    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "30%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },

  large: {
    width: theme.spacing(13),
    height: theme.spacing(13),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    "&:hover": { opacity: ".7" },
  },
  center: { justifyContent: "center", alignItems: "center" },
  root: { width: "100%", marginTop: theme.spacing(1) },
  correo: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "45%",
    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  error: { color: "red", fontWeight: "bold" },
  container: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "flex-end",
    marginTop: theme.spacing(1),
    overflowY: "scroll",
    overflowX: "hidden",
    maxHeight: "65vh",
    [theme.breakpoints.down("md")]: {
      maxHeight: "45vh",
    },
    [theme.breakpoints.down("sm")]: {
      maxHeight: "40vh",
    },
  },
}));

const ModalAsistencia = (props) => {
  const { tienePermiso, alertaPermiso } = usePermiso("Personas_Veterinaria");

  const [clientesData, setclientesData] = useState([]);
  // eslint-disable-next-line
  const { mostrarAlerta } = useContext(AlertaContext);
  // eslint-disable-next-line
  const { usuario } = useContext(AuthContext);
  const tema = useTheme();
  //props
  const {
    open,
    setopen,
    editar,
    seteditar,
    estadoRequest,
    ObjectoActivo,
    changeObjetoActivoState,
    socket,
  } = props;
  const [item, setitem] = useState(ObjectoActivo);
  const [cargando, setcargando] = useState(false);
  const [paises, setpaises] = useState(null);
  const [docSearch, setdocSearch] = useState("");
  const [cargandoDoc, setcargandoDoc] = useState(false);
  const [recomendacionesData, setrecomendacionesData] = useState(null);
  const [provincias, setprovincias] = useState([]);
  const [cantones, setcantones] = useState([]);
  const [openMap, setopenMap] = useState(false);

  const [PagoAdicionalsData, setPagoAdicionalsData] = useState([]);
  const reducer = (previousValue, currentValue) =>
    Number(previousValue) + Number(currentValue);
  // TABLA
  const [agregandoPagoAdicional, setagregandoPagoAdicional] = useState(false);
  const [pagePagoAdicional, setPagePagoAdicional] = React.useState(0);
  const [
    rowsPerPagePagoAdicional,
    setRowsPerPagePagoAdicional,
  ] = React.useState(10);
  const handleChangePagePagoAdicional = async (event, newPage) => {
    setPagePagoAdicional(newPage);
  };

  const handleChangeRowsPerPagePagoAdicional = (event) => {
    setRowsPerPagePagoAdicional(+event.target.value);
    setPagePagoAdicional(0);
  };

  //documento
  const [documentoperDocumento, setdocumentoperDocumento] = useState("");
  const refArchivoinvPermisoPdf = createRef();
  const focusInputinvPermisoPdf = () => {
    refArchivoinvPermisoPdf.current.click();
  };

  const onChangeArchivoinvPermisoPdf = async (e) => {
    if (!e.target.files[0]) {
      return;
    }
    if (
      !["pdf"].some((item) => item === e.target.files[0].type.split("/")[1])
    ) {
      setdocumentoperDocumento("");
      return mostrarAlerta("El archivo debe ser un PDF", "error");
    }

    try {
      setcargando(true);
      const formDataLogos = new FormData();

      if (perDocumento === "") {
        await formDataLogos.append("tipo", "documentos");
        await formDataLogos.append("archivo", e.target.files[0]);
      } else {
        await clienteAxios.post("/upload_delete_multiple_files/delete", {
          urls: [perDocumento],
        });
        await formDataLogos.append("tipo", "documentos");
        await formDataLogos.append("archivo", e.target.files[0]);
      }

      const resDocumentos = await clienteAxios.post(
        "/upload_delete_multiple_files/upload",
        formDataLogos
      );

      // const documentosUrls = {};
      // resDocumentos.data.urls.forEach((dataUrl) => {

      //   if (dataUrl.nombre === "fotoperfil" || dataUrl.nombre === "logo") {
      //     return;
      //   }
      //   documentosUrls[dataUrl.nombre] = dataUrl.url;
      // });

      setitem({
        ...item,
        perDocumento: resDocumentos.data.nombre,
      });

      setdocumentoperDocumento(e.target.files[0]);
      setcargando(false);
    } catch (error) {
      setcargando(false);
      setdocumentoperDocumento(e.target.files[0]);

      return mostrarAlerta("Hubo un error", "error");
    }
    setdocumentoperDocumento(e.target.files[0]);
  };

  // DOCUMENTO CONTRATO

  useEffect(() => {
    if (!recomendacionesData) {
      const getDatarecomendacionesData = async () => {
        try {
          const resrecomendacionesData = await clienteAxios.get(
            `/recomendaciones/filtro/0`
          );

          setrecomendacionesData(resrecomendacionesData.data.data);
        } catch (error) {
          setrecomendacionesData([]);
        }
      };
      getDatarecomendacionesData();
    }

    if (!paises) {
      const getDataPaises = async () => {
        try {
          const resPaises = await clienteAxios.get(`/paises/0`);
          setpaises(resPaises.data.data);
        } catch (error) {
          setpaises([]);
        }
      };
      getDataPaises();
    }

    // eslint-disable-next-line
  }, []);

  // eslint-disable-next-line
  const {
    // perNombreComercial,
    perDocTipo,
    perDocNumero,
    // perPerfil,
    perNombre,
    perDireccion,
    perObligado,
    perCredito,
    perTelefono,
    perCelular,
    perEstado,
    perObsevacion,
    perEmail,
    perPais,
    perProvincia,
    perCanton,
    perTipoProveedor,
    perTiempoCredito,
    // perOtros,
    // perFecContrato,
    // perFecInstalacion,
    // perDiaCobro,
    // perTipoPlan,
    // perNombrePlan,
    // perValorPlan,
    // perServicioAdicional,
    // perDescuentos,
    // perFacturaAutomatica,
    // perVendedor1,
    // perVendedor2,
    // perObservacionIsp,
    // perTipoDocumento,
    // perTecnico1,
    // perTecnico2,
    // perMegas,
    perUbicacion,
    perDocumento,
    // perNickname,
    // errorAutorizacion,perISPContratos
    perGenero,
    perFecNacimiento,
    perEspecialidad,
    perTitulo,
    perSenescyt,
    perPersonal,
    perRecomendacion,
  } = item;
  // eslint-disable-next-line

  const [error, seterror] = useState({
    perDocTipo: false,
    perDocNumero: false,
    perNombre: false,
    perDireccion: false,
    perTelefono: false,
    perPais: false,
    perProvincia: false,
    perCanton: false,
    perTipoProveedor: false,
    perObligado: false,
    perTiempoCredito: false,
    perCredito: false,
    perEmail: false,
  });
  useEffect(() => {
    if (editar && open) {
      const getDataState = async () => {
        const res = await clienteAxios.get(
          `/pagosadicionales/${ObjectoActivo.perId}`
        );

        setPagoAdicionalsData(res.data.data);
      };

      getDataState();
    }
    // eslint-disable-next-line
  }, [open, ObjectoActivo]);

  useEffect(() => {
    if (editar) {
      setdocSearch(ObjectoActivo.perDocNumero);
      setitem({
        ...ObjectoActivo,
      });

      try {
        const getDataState = async () => {
          const paisF = paises.find((itemFind) => {
            return itemFind.paisNombre === ObjectoActivo.perPais;
          });

          const provinciasRes = await clienteAxios.get(
            `/provincias/filtro/${paisF.paisId}`
          );
          const provinciaF = provinciasRes.data.data.find(
            (itemProvincia) =>
              itemProvincia.provNombre === ObjectoActivo.perProvincia
          );
          const cantonesRes = await clienteAxios.get(
            `/cantones/filtro/${provinciaF.provId}`
          );
          setprovincias(provinciasRes.data.data);
          setcantones(cantonesRes.data.data);
        };
        if (paises && ObjectoActivo.perPais !== "") {
          getDataState();
        }
      } catch (error) {}
    } else {
      setitem({
        ...ObjectoActivo,
        perDocTipo:
          estadoRequest.trim() === "VETERINARIO" ||
          estadoRequest.trim() === "CLIENTE"
            ? "CEDULA"
            : "RUC",
        perOtros: ObjectoActivo.perOtros ? ObjectoActivo.perOtros : [],
      });
    }
    // eslint-disable-next-line
  }, [open]);

  const defaultActive = () => {
    setdocSearch("");
    setPagoAdicionalsData([]);
    changeObjetoActivoState({
      perISPContratos: [],
      perNombreComercial: "",
      perEmpresa: [],
      perPais: "",
      perProvincia: "",
      perCanton: "",
      perTipoProveedor: "BIENES",
      perTiempoCredito: "0",
      perDocTipo: "",
      perDocNumero: "",
      perPerfil: [],
      perNombre: "",
      perDireccion: "",
      perObligado: "NO",
      perCredito: "NO",
      perTelefono: "0",
      perCelular: ["+593"],
      perEstado: "ACTIVO",
      perObsevacion: "",
      perEmail: ["sin@sincorreo.com"],
      perOtros: ["ZZZ9999"],
      perNickname: "",
      perUser: "",
      perFecReg: "",
      perFoto: "",
      perUbicacion: {
        longitud: "",
        latitud: "",
      },
      perDocumento: "",
      perRecomendacion: "",
      perGenero: "M",
      perFecNacimiento: "",
      perEspecialidad: "",
      perTitulo: "",
      perSenescyt: "",
      perPersonal: "INTERNO",
    });
    setopen(false);
    seteditar(false);
    seterror({
      perDocTipo: false,
      perDocNumero: false,
      perNombre: false,
      perDireccion: false,
      perTelefono: false,
      perPais: false,
      perProvincia: false,
      perCanton: false,
      perTipoProveedor: false,
      perObligado: false,
      perTiempoCredito: false,
      perCredito: false,
      perEmail: false,
    });
    setseccionActive({
      datosPersonalesSeccion: true,
      datosContablesSeccion: false,
      datosISP: false,
    });
    setcargando(false);
    setdocumentoperDocumento("");
    setarchivo("");
    setimagenMostrada(null);
  };
  // eslint-disable-next-line
  const handleChange = (e) => {
    setitem({
      ...item,
      [e.target.name]: e.target.value,
    });
  };

  //hook de estilos
  const clases = useStyles();
  //definiendo los estilos del modal

  const [seccionActive, setseccionActive] = useState({
    datosPersonalesSeccion: true,
    datosContablesSeccion: false,
    datosISP: false,
    datosPagosAdicionales: false,
  });
  const {
    datosPersonalesSeccion,
    datosContablesSeccion,
    datosISP,
    datosPagosAdicionales,
  } = seccionActive;
  const [archivo, setarchivo] = useState("");
  const [imagenMostrada, setimagenMostrada] = useState(null);
  const onChangeFile = (e) => {
    if (!e.target.files[0]) {
      setimagenMostrada(null);
      return setarchivo("");
    }

    setarchivo(e.target.files[0]);
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = (e) => {
      e.preventDefault();
      setimagenMostrada(e.target.result);
    };
  };
  const validacion = () => {
    if (estadoRequest === "CLIENTE") {
      if (
        item.perDocTipo.trim() === "" ||
        item.perDocNumero.trim() === "" ||
        item.perNombre.trim() === "" ||
        item.perDireccion.trim() === "" ||
        item.perPais.trim() === "" ||
        item.perProvincia.trim() === "" ||
        item.perCanton.trim() === "" ||
        item.perEmail.length === 0 ||
        item.perRecomendacion.trim() === "" ||
        item.perEmail.some((email) => {
          return validarEmail(email);
        }) ||
        perRecomendacion.trim() === "" ||
        perCelular.some((celular) => !isValidNumber(celular)) ||
        perCelular.length === 0
      ) {
        seterror({
          perDocTipo: item.perDocTipo.trim() === "" ? true : false,
          perDocNumero: item.perDocNumero.trim() === "" ? true : false,
          perNombre: item.perNombre.trim() === "" ? true : false,
          perDireccion: item.perDireccion.trim() === "" ? true : false,
          perRecomendacion: item.perRecomendacion.trim() === "" ? true : false,
          perPais: item.perPais.trim() === "" ? true : false,
          perProvincia: item.perProvincia.trim() === "" ? true : false,
          perCanton: item.perCanton.trim() === "" ? true : false,
          perCelular:
            perCelular.some((celular) => !isValidNumber(celular)) ||
            perCelular.length === 0,
          perEmail:
            item.perEmail.length === 0 ||
            item.perEmail.some((email) => {
              return validarEmail(email);
            })
              ? true
              : false,
        });
        return true;
      }
    }

    if (
      item.perDocTipo.trim() === "" ||
      item.perDocNumero.trim() === "" ||
      item.perNombre.trim() === "" ||
      item.perDireccion.trim() === "" ||
      item.perPais.trim() === "" ||
      item.perProvincia.trim() === "" ||
      item.perCanton.trim() === "" ||
      item.perTipoProveedor.trim() === "" ||
      item.perObligado.trim() === "" ||
      item.perTiempoCredito.trim() === "" ||
      item.perCredito.trim() === "" ||
      item.perEmail.length === 0 ||
      item.perEmail.some((email) => {
        return validarEmail(email);
      })
    ) {
      seterror({
        perDocTipo: item.perDocTipo.trim() === "" ? true : false,
        perDocNumero: item.perDocNumero.trim() === "" ? true : false,
        perNombre: item.perNombre.trim() === "" ? true : false,
        perDireccion: item.perDireccion.trim() === "" ? true : false,
        perPais: item.perPais.trim() === "" ? true : false,
        perProvincia: item.perProvincia.trim() === "" ? true : false,
        perCanton: item.perCanton.trim() === "" ? true : false,
        perTipoProveedor: item.perTipoProveedor.trim() === "" ? true : false,
        perObligado: item.perObligado.trim() === "" ? true : false,
        perTiempoCredito: item.perTiempoCredito.trim() === "" ? true : false,
        perCredito: item.perCredito.trim() === "" ? true : false,
        perEmail:
          item.perEmail.length === 0 ||
          item.perEmail.some((email) => {
            return validarEmail(email);
          })
            ? true
            : false,
      });
      return true;
    }
    return false;
  };
  const handleCrear = async () => {
    if (validarDocumento(perDocTipo, perDocNumero, mostrarAlerta)) {
      return;
    }
    if (validacion()) {
      return mostrarAlerta("Faltan campos obligatorios", "error");
    }
    try {
      setcargando(true);
      let resFoto = null;
      if (archivo !== "") {
        const formDataLogos = new FormData();
        formDataLogos.append("tipo", "fotoperfil");
        formDataLogos.append("rucempresa", usuario.rucempresa);
        await clienteAxios.post("/upload_delete_multiple_files/delete", {
          urls: [item.perFoto],
        });
        formDataLogos.append("archivo", archivo);
        resFoto = await clienteAxios.post(
          "/upload_delete_multiple_files/upload",
          formDataLogos
        );
      }
      if (item.perId) {
        changeObjetoActivoState({
          ...ObjectoActivo,
          changeObjetoActivoState: true,
        });

        socket.current.emit(
          "client:actualizarData",
          trimPropsItem({
            ...item,
            perFoto: resFoto ? resFoto.data.nombre : item.perFoto,
            existeEnLaTabla: item.perPerfil.some((item) => {
              if (estadoRequest === "CLIENTE") {
                return item === "CLIENTE";
              } else {
                return item === "PROVEEDOR";
              }
            }),
            perEmail: perEmail.filter((itemEmail) => itemEmail.trim() !== ""),
            tabla: "proveedor",
            rucempresa: usuario.rucempresa,
            rol: usuario.rol,
            perUser: `${item.perUser.split(" ** ")[0]} ** ${usuario.usuario}`,
            perEmpresa: [...new Set([...item.perEmpresa, usuario.rucempresa])],
            perPerfil:
              estadoRequest === "CLIENTE"
                ? [...new Set([...item.perPerfil, "CLIENTE"])]
                : estadoRequest === "PROVEEDOR"
                ? [...new Set([...item.perPerfil, "PROVEEDOR"])]
                : estadoRequest === "ADMINISTRACION"
                ? [...new Set([...item.perPerfil, "ADMINISTRACION"])]
                : estadoRequest === "USUARIO"
                ? [...new Set([...item.perPerfil, "USUARIO"])]
                : estadoRequest === "TECNICO"
                ? [...new Set([...item.perPerfil, "TECNICO"])]
                : estadoRequest === "VETERINARIO"
                ? [...new Set([...item.perPerfil, "VETERINARIO"])]
                : [...new Set([...item.perPerfil, "VENDEDOR"])],
          })
        );
      } else {
        changeObjetoActivoState({
          ...ObjectoActivo,
          changeObjetoActivoState: true,
        });
        socket.current.emit(
          "client:guardarData",
          trimPropsItem({
            ...item,
            perFoto: resFoto ? resFoto.data.nombre : item.perFoto,
            perEmail: perEmail.filter((itemEmail) => itemEmail.trim() !== ""),
            tabla: "proveedor",
            rucempresa: usuario.rucempresa,
            rol: usuario.rol,
            perUser: usuario.usuario,
            perEmpresa: [...new Set([...item.perEmpresa, usuario.rucempresa])],
            perPerfil: [...new Set([...item.perPerfil, estadoRequest])],
          })
        );
      }

      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);
      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  const handleEditar = async () => {
    if (validarDocumento(perDocNumero, mostrarAlerta)) {
      return;
    }
    if (validacion()) {
      return mostrarAlerta("Faltan campos obligatorios", "error");
    }
    try {
      // setcargando(true);
      let resFoto = null;
      if (archivo !== "") {
        const formDataLogos = new FormData();
        formDataLogos.append("tipo", "fotoperfil");
        formDataLogos.append("rucempresa", usuario.rucempresa);
        await clienteAxios.post("/upload_delete_multiple_files/delete", {
          urls: [item.perFoto],
        });
        formDataLogos.append("archivo", archivo);
        resFoto = await clienteAxios.post(
          "/upload_delete_multiple_files/upload",
          formDataLogos
        );
      }
      console.log(
        trimPropsItem({
          ...item,
          perFoto: resFoto ? resFoto.data.nombre : item.perFoto,
          perEmail: perEmail.filter((itemEmail) => itemEmail.trim() !== ""),
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          perUser: `${item.perUser.split(" ** ")[0]} ** ${usuario.usuario}`,
          perEmpresa: [...new Set([...item.perEmpresa, usuario.rucempresa])],
          tabla: "proveedor",
        })
      );
      socket.current.emit(
        "client:actualizarData",
        trimPropsItem({
          ...item,
          perFoto: resFoto ? resFoto.data.nombre : item.perFoto,
          perEmail: perEmail.filter((itemEmail) => itemEmail.trim() !== ""),
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          perUser: `${item.perUser.split(" ** ")[0]} ** ${usuario.usuario}`,
          perEmpresa: [...new Set([...item.perEmpresa, usuario.rucempresa])],
          tabla: "proveedor",
        })
      );
      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);
      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  //filas por pagina

  // eslint-disable-next-line

  // 1 + 2 + 3 + 4

  if (!open) {
    return null;
  }
  if (!paises) {
    return null;
  }

  if (!recomendacionesData) {
    return null;
  }
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Draggable handle="#idPersona">
          <Box display="flex" justifyContent="center">
            <div
              style={{
                borderRadius: "15px",
                position: "absolute",
              }}
              className={clases.paper}
            >
              <Grid container sx={{ width: "100%" }}>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  sx={{
                    background: agregarTransparencia(
                      tema.palette.secondary.light,
                      0.1
                    ),
                    borderRight: `1px solid ${agregarTransparencia(
                      tema.palette.secondary.light,
                      0.25
                    )}`,
                  }}
                  p={2}
                  display={"flex"}
                  alignItems={"center"}
                  flexDirection={"column"}
                >
                  <Box
                    display="flex"
                    justifyContent="center"
                    width="100%"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <input
                      id="contained-button-file"
                      className={clases.ocultar}
                      type="file"
                      name="myImage"
                      onChange={onChangeFile}
                    />

                    {/* //si un archivo exoste  */}
                    {imagenMostrada ? (
                      <Box
                        display="flex"
                        flexDirection="column"
                        className={clases.center}
                      >
                        <label htmlFor="contained-button-file">
                          <Avatar
                            htmlFor="contained-button-file"
                            alt="Remy Sharp"
                            src={imagenMostrada}
                            className={clases.large}
                          />
                        </label>
                        <Typography
                          variant="body1"
                          color="initial"
                          align="center"
                        ></Typography>
                      </Box>
                    ) : (
                      <Box
                        display="flex"
                        flexDirection="column"
                        className={clases.center}
                      >
                        <label htmlFor="contained-button-file">
                          <Avatar
                            htmlFor="contained-button-file"
                            alt="Logo"
                            src={item.perFoto}
                            className={clases.large}
                          />
                        </label>
                        <Typography
                          variant="body1"
                          color="initial"
                          align="center"
                        >
                          Seleccione una foto
                        </Typography>
                      </Box>
                    )}
                  </Box>
                  <Box mt={3}>
                    <Button
                      fullWidth
                      variant={datosPersonalesSeccion ? "contained" : "text"}
                      disableElevation
                      style={{ padding: "8px" }}
                      color="secondary"
                      onClick={() =>
                        setseccionActive({ datosPersonalesSeccion: true })
                      }
                    >
                      Datos Personales
                    </Button>
                    {usuario.empCategoria === "ISP" &&
                    estadoRequest === "CLIENTE" ? (
                      <Button
                        fullWidth
                        variant={datosISP ? "contained" : "text"}
                        disableElevation
                        style={{ padding: "8px" }}
                        color="secondary"
                        onClick={() => setseccionActive({ datosISP: true })}
                      >
                        ISP
                      </Button>
                    ) : null}
                    {usuario.empCategoria === "ISP" &&
                    estadoRequest === "CLIENTE" ? (
                      <Button
                        fullWidth
                        variant={datosPagosAdicionales ? "contained" : "text"}
                        disableElevation
                        style={{ padding: "8px" }}
                        color="secondary"
                        onClick={() =>
                          setseccionActive({ datosPagosAdicionales: true })
                        }
                      >
                        Adicionales
                      </Button>
                    ) : null}
                    <Button
                      fullWidth
                      variant={datosContablesSeccion ? "contained" : "text"}
                      disableElevation
                      style={{ padding: "8px" }}
                      color="secondary"
                      onClick={() =>
                        setseccionActive({ datosContablesSeccion: true })
                      }
                    >
                      Otros datos
                    </Button>
                  </Box>

                  <FormControlLabel
                    control={
                      <Switch
                        checked={perEstado === "ACTIVO" ? true : false}
                        onChange={(e) => {
                          setitem({
                            ...item,
                            perEstado: e.target.checked ? "ACTIVO" : "INACTIVO",
                          });
                        }}
                        name="antoine"
                        color="secondary"
                      />
                    }
                    label="Estado"
                  />
                </Grid>
                <Grid item xs={12} sm={9} p={2} pt={1}>
                  <>
                    <Box
                      display="flex"
                      width="100%"
                      justifyContent="space-between"
                      alignItems="center"
                      flexWrap={"wrap"}
                    >
                      <Typography
                        variant="subtitle1"
                        color="primary"
                        sx={{ cursor: "grab" }}
                        id="idPersona"
                      >
                        {editar ? "Editar" : "Crear"}
                        {" - "}
                        <Typography
                          variant="subtitle2"
                          color="secondary"
                          component={"span"}
                        >
                          {seccionActive.datosPersonalesSeccion &&
                            "Datos Personales"}
                          {seccionActive.datosContablesSeccion && "Otros Datos"}
                          {seccionActive.datosISP && "Datos ISP"}
                          {seccionActive.datosPagosAdicionales &&
                            "Pagos Adicionales"}
                        </Typography>
                      </Typography>

                      <Box>
                        <IconButton
                          color={item.restaurado ? "success" : "secondary"}
                          disabled={cargando}
                          onClick={() => {
                            if (editar) {
                              if (!tienePermiso("editar")) {
                                return alertaPermiso("editar");
                              }
                              handleEditar();
                            } else {
                              if (!tienePermiso("agregar")) {
                                return alertaPermiso("agregar");
                              }
                              handleCrear();
                            }
                          }}
                        >
                          {item.restaurado ? <Restore /> : <Save />}
                        </IconButton>{" "}
                        <IconButton
                          color={"error"}
                          disabled={cargando}
                          onClick={() => {
                            defaultActive();
                          }}
                        >
                          <Close />
                        </IconButton>
                      </Box>
                    </Box>
                    <Divider />
                    <Box
                      height={"90%"}
                      display={"flex"}
                      flexDirection={"column"}
                      justifyContent={"space-between"}
                    >
                      <Box className={clases.container}>
                        <>
                          {datosPersonalesSeccion ? (
                            <>
                              <TextField
                                inputProps={{ className: clases.inputPadding }}
                                className={clases.formD}
                                id="perDocTipo"
                                label="Tipo Doc."
                                helperText={`Seleccione tipo de documento`}
                                value={perDocTipo}
                                name="perDocTipo"
                                error={error.perDocTipo}
                                onChange={(e) => handleChange(e)}
                                select
                                InputLabelProps={{ shrink: true }}
                                SelectProps={{ displayEmpty: true }}
                              >
                                <MenuItem value="">
                                  Seleccione un tipo de Doc.
                                </MenuItem>
                                {["RUC", "CEDULA", "PASAPORTE"].map((item) => {
                                  return (
                                    <MenuItem value={item}>{item} </MenuItem>
                                  );
                                })}
                              </TextField>
                              <Box
                                className={clases.formD}
                                display={"flex"}
                                flexDirection={"column"}
                              >
                                <TextField
                                  fullWidth
                                  size="small"
                                  required
                                  label={
                                    estadoRequest === "USUARIO"
                                      ? "Usuario"
                                      : "Número Doc."
                                  }
                                  error={error.perDocNumero}
                                  value={docSearch}
                                  onChange={async (e) => {
                                    setdocSearch(e.target.value);
                                    setitem({
                                      ...item,
                                      perDocNumero: e.target.value,
                                    });
                                  }}
                                  helperText={`Presione "Enter" para buscar`}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment>
                                        <IconButton
                                          aria-label=""
                                          onClick={async (e) => {
                                            if (docSearch === "") {
                                              return mostrarAlerta(
                                                "Ingrese un documento",
                                                "error"
                                              );
                                            }
                                            try {
                                              setcargandoDoc(true);
                                              const res = await clienteAxios.get(
                                                `proveedores/searchByCedulaOfRuc/0?search=${docSearch}&rol=${estadoRequest}`
                                              );

                                              if (res?.data) {
                                                setitem({
                                                  ...res.data,
                                                  perOtros: res.data.perOtros
                                                    ? res.data.perOtros
                                                    : [],
                                                });
                                              }
                                              setcargandoDoc(false);
                                            } catch (error) {
                                              setcargandoDoc(false);
                                              console.log({ error });
                                              mostrarAlerta(
                                                error?.response?.data?.msg,
                                                "error"
                                              );
                                              setitem({
                                                ...ObjectoActivo,
                                                perDocNumero: docSearch,
                                              });
                                            }
                                          }}
                                        >
                                          <Search color="secondary" />
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  }}
                                  onKeyDown={async (e) => {
                                    if (e.key !== "Enter") {
                                      return;
                                    }
                                    console.log(e.target.value);
                                    if (e.target.value === "") {
                                      return mostrarAlerta(
                                        "Ingrese un documento",
                                        "error"
                                      );
                                    }
                                    try {
                                      setcargandoDoc(true);
                                      setitem({
                                        ...item,
                                        perDocNumero: e.target.value,
                                      });
                                      const res = await clienteAxios.get(
                                        `proveedores/searchByCedulaOfRuc/0?search=${e.target.value}&rol=${estadoRequest}`
                                      );
                                      if (res?.data) {
                                        setitem({
                                          ...res.data,
                                          perOtros: res.data.perOtros
                                            ? res.data.perOtros
                                            : [],
                                        });
                                      }
                                      setcargandoDoc(false);
                                    } catch (error) {
                                      console.log({ error });
                                      setcargandoDoc(false);
                                      mostrarAlerta(
                                        error?.response?.data?.msg,
                                        "error"
                                      );
                                      setitem({
                                        ...ObjectoActivo,
                                        perDocNumero: docSearch,
                                      });
                                    }
                                  }}
                                />
                                {cargandoDoc && (
                                  <LinearProgress
                                    color="primary"
                                    fourColor
                                    variant="query"
                                  />
                                )}
                              </Box>
                              <TextField
                                inputProps={{ className: clases.inputPadding }}
                                className={clases.formD}
                                id="perNombre"
                                label={
                                  estadoRequest.trim() === "VETERINARIO" ||
                                  estadoRequest.trim() === "CLIENTE" ||
                                  estadoRequest.trim() === "USUARIO"
                                    ? "Apellidos y Nombres"
                                    : "Razón Social"
                                }
                                value={perNombre}
                                name="perNombre"
                                error={error.perNombre}
                                onChange={(e) => {
                                  setitem({
                                    ...item,
                                    [e.target.name]: [e.target.value]
                                      .toString()
                                      .toUpperCase(),
                                  });
                                }}
                              />
                              <TextField
                                inputProps={{ className: clases.inputPadding }}
                                className={clases.formD}
                                id="perDireccion"
                                label="Dirección"
                                value={perDireccion}
                                name="perDireccion"
                                error={error.perDireccion}
                                onChange={(e) => handleChange(e)}
                              />
                              <TextField
                                inputProps={{ className: clases.inputPadding }}
                                className={clases.formD}
                                id="perTelefono"
                                label="Teléfono"
                                value={perTelefono}
                                name="perTelefono"
                                error={error.perTelefono}
                                onChange={(e) => handleChange(e)}
                              />

                              {estadoRequest === "CLIENTE" ? (
                                <>
                                  <FormControl
                                    fullWidth
                                    className={clases.formD}
                                  >
                                    <Autocomplete
                                      options={recomendacionesData.map(
                                        (item) => item.recoNombre
                                      )}
                                      value={perRecomendacion}
                                      onChange={(e, newValue) => {
                                        if (newValue) {
                                          setitem({
                                            ...item,
                                            perRecomendacion: newValue,
                                          });
                                        }
                                      }}
                                      paperprops
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          size="small"
                                          label="Recomendación"
                                          error={error.perRecomendacion}
                                          autoComplete={false}
                                          InputProps={{
                                            ...params.InputProps,
                                          }}
                                        />
                                      )}
                                    />
                                  </FormControl>
                                  <FormControl
                                    component="fieldset"
                                    inputProps={{
                                      className: clases.inputPadding,
                                    }}
                                    className={clases.formD}
                                  >
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      justifyContent="space-between"
                                    >
                                      <FormLabel
                                        component="span"
                                        color="primary"
                                        style={{
                                          marginRight: ".5rem",
                                          fontWeight: "bold",
                                        }}
                                        className={
                                          error.perGenero ? clases.error : ""
                                        }
                                      >
                                        Genero
                                      </FormLabel>
                                      <RadioGroup
                                        onChange={(e) => handleChange(e)}
                                        row
                                        aria-label="gender"
                                        value={perGenero}
                                        name="perGenero"
                                      >
                                        <FormControlLabel
                                          value="M"
                                          control={<Radio />}
                                          label="M"
                                        />
                                        <FormControlLabel
                                          value="F"
                                          control={<Radio />}
                                          label="F"
                                        />
                                      </RadioGroup>
                                    </Box>
                                  </FormControl>
                                </>
                              ) : null}
                              {/*  */}
                              <FormControl className={clases.formAllW}>
                                <Box display="flex" alignItems="center">
                                  <Typography
                                    variant="subtitle1"
                                    color={
                                      error.perCelular ? "error" : "primary"
                                    }
                                  >
                                    Celulares:
                                  </Typography>
                                  <IconButton
                                    aria-label=""
                                    size="small"
                                    onClick={async () => {
                                      setitem({
                                        ...item,
                                        perCelular: [
                                          ...item.perCelular,
                                          "+593",
                                        ],
                                      });
                                    }}
                                  >
                                    <AddCircle
                                      fontSize="small"
                                      color="secondary"
                                    />
                                  </IconButton>
                                </Box>
                              </FormControl>
                              {perCelular.map((celular, index) => {
                                return (
                                  <MuiPhoneNumber
                                    key={index}
                                    inputClass={clases.formD}
                                    defaultCountry={"ec"}
                                    regions={"america"}
                                    value={celular}
                                    name="perCelular"
                                    error={error.perCelular}
                                    onChange={(e) => {
                                      setitem({
                                        ...item,
                                        perCelular: perCelular.map(
                                          (item, indexItem) => {
                                            if (indexItem === index) {
                                              return e;
                                            } else {
                                              return item;
                                            }
                                          }
                                        ),
                                      });
                                    }}
                                    label={`Celular #${index + 1}`}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment>
                                          <IconButton
                                            aria-label=""
                                            onClick={() => {
                                              setitem({
                                                ...item,
                                                perCelular: perCelular.filter(
                                                  (item, indexItem) => {
                                                    return indexItem !== index;
                                                  }
                                                ),
                                              });
                                            }}
                                          >
                                            <Delete color="error" />
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                );
                              })}
                              <FormControl className={clases.formAllW}>
                                <Box display="flex" alignItems="center">
                                  <Typography
                                    variant="subtitle1"
                                    color={error.perEmail ? "error" : "primary"}
                                  >
                                    Correos:
                                  </Typography>
                                  <IconButton
                                    aria-label=""
                                    size="small"
                                    onClick={async () => {
                                      setitem({
                                        ...item,
                                        perEmail: [...item.perEmail, ""],
                                      });
                                    }}
                                  >
                                    <AddCircle
                                      fontSize="small"
                                      color="secondary"
                                    />
                                  </IconButton>
                                </Box>
                              </FormControl>
                              {perEmail.map((precio, index) => {
                                return (
                                  <TextField
                                    key={index}
                                    className={clases.formD}
                                    id=""
                                    value={precio}
                                    onChange={(e) => {
                                      setitem({
                                        ...item,
                                        perEmail: perEmail.map(
                                          (item, indexItem) => {
                                            if (indexItem === index) {
                                              return e.target.value;
                                            } else {
                                              return item;
                                            }
                                          }
                                        ),
                                      });
                                    }}
                                    label={`Correo #${index + 1}`}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment>
                                          <IconButton
                                            aria-label=""
                                            onClick={() => {
                                              setitem({
                                                ...item,
                                                perEmail: perEmail.filter(
                                                  (item, indexItem) => {
                                                    return indexItem !== index;
                                                  }
                                                ),
                                              });
                                            }}
                                          >
                                            <Delete color="error" />
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                );
                              })}
                              <FormControl fullWidth className={clases.formD}>
                                <Autocomplete
                                  options={paises.map(
                                    (item) =>
                                      item.paisNombre + "/+/" + item.paisId
                                  )}
                                  getOptionLabel={(option) => {
                                    return option.split("/+/")[0];
                                  }}
                                  id="controlled-demo"
                                  value={perPais}
                                  paperprops
                                  onChange={async (event, newValue) => {
                                    if (newValue) {
                                      try {
                                        const options = newValue.split("/+/");
                                        const provinciasRes = await clienteAxios.get(
                                          `/provincias/filtro/${options[1]}`
                                        );

                                        setprovincias(provinciasRes.data.data);
                                        setitem({
                                          ...item,
                                          perPais: options[0],
                                          perProvincia: "",
                                          perCanton: "",
                                        });
                                      } catch (error) {
                                        const options = newValue.split("/+/");
                                        setitem({
                                          ...item,
                                          perPais: options[0],
                                          perProvincia: "",
                                          perCanton: "",
                                        });
                                        mostrarAlerta(
                                          error.response.data.msg,
                                          "error"
                                        );
                                        setprovincias([]);
                                        setcantones([]);
                                      }
                                    } else {
                                      // setrucString("");
                                      // setitem({
                                      //   ...item,
                                      //   rucProveedor: "",
                                      //   nomProveedor: "",
                                      // });
                                    }
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      size="small"
                                      required
                                      label="País"
                                      error={error.perPais}
                                      autoComplete={false}
                                      fullWidth
                                      onChange={async (e) => {}}
                                      InputProps={{
                                        ...params.InputProps,
                                      }}
                                    />
                                  )}
                                />
                              </FormControl>
                              <FormControl fullWidth className={clases.formD}>
                                <Autocomplete
                                  autoComplete="false"
                                  options={provincias.map(
                                    (item) =>
                                      item.provNombre + "/+/" + item.provId
                                  )}
                                  getOptionLabel={(option) => {
                                    return option.split("/+/")[0];
                                  }}
                                  id="controlled-demo"
                                  value={perProvincia}
                                  paperprops
                                  onChange={async (event, newValue) => {
                                    if (newValue) {
                                      try {
                                        const options = newValue.split("/+/");
                                        const cantonesRes = await clienteAxios.get(
                                          `/cantones/filtro/${options[1]}`
                                        );

                                        setcantones(cantonesRes.data.data);
                                        setitem({
                                          ...item,
                                          perProvincia: options[0],
                                          perCanton: "",
                                        });
                                      } catch (error) {
                                        const options = newValue.split("/+/");
                                        setitem({
                                          ...item,
                                          perProvincia: options[0],
                                          perCanton: "",
                                        });
                                        mostrarAlerta(
                                          error.response.data.msg,
                                          "error"
                                        );
                                        setcantones([]);
                                      }
                                    } else {
                                      // setrucString("");
                                      // setitem({
                                      //   ...item,
                                      //   rucProveedor: "",
                                      //   nomProveedor: "",
                                      // });
                                    }
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      size="small"
                                      required
                                      autoComplete={false}
                                      label="Provincia"
                                      error={error.perProvincia}
                                      fullWidth
                                      onChange={async (e) => {}}
                                      InputProps={{
                                        ...params.InputProps,
                                      }}
                                    />
                                  )}
                                />
                              </FormControl>
                              <FormControl fullWidth className={clases.formD}>
                                <Autocomplete
                                  options={cantones.map(
                                    (item) => item.canNombre
                                  )}
                                  getOptionLabel={(option) => {
                                    return option;
                                  }}
                                  id="controlled-demo"
                                  value={perCanton}
                                  paperprops
                                  onChange={async (event, newValue) => {
                                    if (newValue) {
                                      try {
                                        setitem({
                                          ...item,
                                          perCanton: newValue,
                                        });
                                      } catch (error) {}
                                    } else {
                                      // setrucString("");
                                      // setitem({
                                      //   ...item,
                                      //   rucProveedor: "",
                                      //   nomProveedor: "",
                                      // });
                                    }
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      size="small"
                                      required
                                      label="Cantón"
                                      error={error.perCanton}
                                      fullWidth
                                      onChange={async (e) => {}}
                                      InputProps={{
                                        ...params.InputProps,
                                      }}
                                    />
                                  )}
                                />
                              </FormControl>
                              {/* NUEVOS DATOS VET */}
                              {estadoRequest === "VETERINARIO" ? (
                                <>
                                  <TextField
                                    type="date"
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{
                                      className: clases.inputPadding,
                                    }}
                                    className={clases.formD}
                                    label="F. Nacimiento"
                                    value={perFecNacimiento}
                                    name="perFecNacimiento"
                                    error={error.perFecNacimiento}
                                    onChange={(e) => handleChange(e)}
                                  />
                                  <TextField
                                    inputProps={{
                                      className: clases.inputPadding,
                                    }}
                                    className={clases.formD}
                                    label="Especialidad"
                                    value={perEspecialidad}
                                    name="perEspecialidad"
                                    error={error.perEspecialidad}
                                    onChange={(e) => handleChange(e)}
                                  />
                                  <TextField
                                    inputProps={{
                                      className: clases.inputPadding,
                                    }}
                                    className={clases.formD}
                                    label="Titulo"
                                    value={perTitulo}
                                    name="perTitulo"
                                    error={error.perTitulo}
                                    onChange={(e) => handleChange(e)}
                                  />
                                  <TextField
                                    inputProps={{
                                      className: clases.inputPadding,
                                    }}
                                    className={clases.formD}
                                    label="Senescyt"
                                    value={perSenescyt}
                                    name="perSenescyt"
                                    error={error.perSenescyt}
                                    onChange={(e) => handleChange(e)}
                                  />
                                  <FormControl
                                    component="fieldset"
                                    inputProps={{
                                      className: clases.inputPadding,
                                    }}
                                    className={clases.formD}
                                  >
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      justifyContent="space-between"
                                    >
                                      <FormLabel
                                        component="span"
                                        color="primary"
                                        style={{
                                          marginRight: ".5rem",
                                          fontWeight: "bold",
                                        }}
                                        className={
                                          error.perGenero ? clases.error : ""
                                        }
                                      >
                                        Genero
                                      </FormLabel>
                                      <RadioGroup
                                        onChange={(e) => handleChange(e)}
                                        row
                                        aria-label="gender"
                                        value={perGenero}
                                        name="perGenero"
                                      >
                                        <FormControlLabel
                                          value="M"
                                          control={<Radio />}
                                          label="M"
                                        />
                                        <FormControlLabel
                                          value="F"
                                          control={<Radio />}
                                          label="F"
                                        />
                                      </RadioGroup>
                                    </Box>
                                  </FormControl>
                                  <FormControl
                                    component="fieldset"
                                    inputProps={{
                                      className: clases.inputPadding,
                                    }}
                                    className={clases.formD}
                                  >
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      justifyContent="space-between"
                                    >
                                      <FormLabel
                                        component="span"
                                        color="primary"
                                        style={{
                                          marginRight: ".5rem",
                                          fontWeight: "bold",
                                        }}
                                        className={
                                          error.perPersonal ? clases.error : ""
                                        }
                                      >
                                        Tipo
                                      </FormLabel>
                                      <RadioGroup
                                        onChange={(e) => handleChange(e)}
                                        row
                                        aria-label="gender"
                                        value={perPersonal}
                                        name="perPersonal"
                                      >
                                        <FormControlLabel
                                          value="INTERNO"
                                          control={<Radio />}
                                          label="INTERNO"
                                        />
                                        <FormControlLabel
                                          value="EXTERNO"
                                          control={<Radio />}
                                          label="EXTERNO"
                                        />
                                      </RadioGroup>
                                    </Box>
                                  </FormControl>
                                </>
                              ) : null}
                            </>
                          ) : null}
                          {datosPagosAdicionales ? (
                            <>
                              <Paper className={clases.root}>
                                <ToolBarTable
                                  titulo={"Pago Adicional"}
                                  soloTitulo={true}
                                  botonAdd={true}
                                  deshabilitarbotonAdd={false}
                                  funcionAdd={() => {
                                    setagregandoPagoAdicional(true);
                                  }}
                                />
                                <TableContainer className={clases.container}>
                                  <Table
                                    stickyHeader
                                    aria-label="sticky table"
                                    id="tabla"
                                    size="small"
                                  >
                                    <caption
                                      style={{
                                        padding: "0px",
                                        paddingRight: "1rem",
                                        paddingLeft: "1rem",
                                      }}
                                    >
                                      <Box
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                      >
                                        <TablePagination
                                          labelRowsPerPage="Filas por pagina"
                                          rowsPerPageOptions={[
                                            10,
                                            20,
                                            50,
                                            100,
                                            500,
                                            1000,
                                          ]}
                                          component="div"
                                          count={PagoAdicionalsData.length}
                                          rowsPerPage={rowsPerPagePagoAdicional}
                                          page={pagePagoAdicional}
                                          onPageChange={
                                            handleChangePagePagoAdicional
                                          }
                                          onRowsPerPageChange={
                                            handleChangeRowsPerPagePagoAdicional
                                          }
                                        />
                                      </Box>
                                    </caption>

                                    <TableHead>
                                      <TablaCabecera
                                        columns={[
                                          {
                                            id: "padiContrato",
                                            label: "Contrato",
                                            minWidth: 100,
                                          },
                                          {
                                            id: "padiDetalle",
                                            label: "Detalle",
                                            minWidth: 150,
                                          },
                                          {
                                            id: "padiValor",
                                            label: "Valor",
                                            minWidth: 50,
                                          },
                                          {
                                            id: "padiIva",
                                            label: "Iva",
                                            minWidth: 50,
                                          },
                                          {
                                            id: "padiFecCobro",
                                            label: "Fec. Cobro",
                                            minWidth: 100,
                                          },
                                        ]}
                                        habilitarOrdenar={false}
                                      />
                                    </TableHead>

                                    <TableBody>
                                      {agregandoPagoAdicional ? (
                                        <RowAgregarPagoAdicional
                                          agregar={agregandoPagoAdicional}
                                          setagregar={setagregandoPagoAdicional}
                                          setPagoAdicionalsData={
                                            setPagoAdicionalsData
                                          }
                                          PagoAdicionalsData={
                                            PagoAdicionalsData
                                          }
                                          setitem={setitem}
                                          item={item}
                                        />
                                      ) : null}
                                      {PagoAdicionalsData.slice(
                                        pagePagoAdicional *
                                          rowsPerPagePagoAdicional,
                                        pagePagoAdicional *
                                          rowsPerPagePagoAdicional +
                                          rowsPerPagePagoAdicional
                                      ).map((row, index) => {
                                        return (
                                          <RowPagoAdicional
                                            setPagoAdicionalsData={
                                              setPagoAdicionalsData
                                            }
                                            PagoAdicionalsData={
                                              PagoAdicionalsData
                                            }
                                            item={item}
                                            setitem={setitem}
                                            row={row}
                                            index={index}
                                            key={row.padiId}
                                          />
                                        );
                                      })}
                                      <TableRow>
                                        <TableCell
                                          size="small"
                                          align="center"
                                        ></TableCell>
                                        <TableCell
                                          size="small"
                                          align="center"
                                        ></TableCell>
                                        <TableCell
                                          size="small"
                                          align="center"
                                        ></TableCell>
                                        <TableCell size="small" align="center">
                                          <Typography
                                            variant="body2"
                                            color="primary"
                                            style={{ fontWeight: "bold" }}
                                          >
                                            $
                                            {PagoAdicionalsData.length === 0
                                              ? null
                                              : PagoAdicionalsData.map(
                                                  (row) => {
                                                    return Number(
                                                      row.padiValor
                                                    );
                                                  }
                                                )
                                                  .reduce(reducer)
                                                  .toFixed(2)}
                                          </Typography>
                                        </TableCell>
                                        <TableCell
                                          size="small"
                                          align="center"
                                        ></TableCell>
                                        <TableCell
                                          size="small"
                                          align="center"
                                        ></TableCell>
                                      </TableRow>
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </Paper>
                            </>
                          ) : null}
                          {datosContablesSeccion ? (
                            <>
                              {estadoRequest === "CLIENTE" ||
                              estadoRequest === "PROVEEDOR" ? (
                                <FormControl
                                  component="fieldset"
                                  inputProps={{
                                    className: clases.inputPadding,
                                  }}
                                  className={clases.formD}
                                >
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                  >
                                    <FormLabel
                                      component="span"
                                      color="primary"
                                      style={{
                                        marginRight: ".5rem",
                                        fontWeight: "bold",
                                      }}
                                      className={
                                        error.perObligado ? clases.error : ""
                                      }
                                    >
                                      Obligado
                                    </FormLabel>
                                    <RadioGroup
                                      onChange={(e) => handleChange(e)}
                                      row
                                      aria-label="gender"
                                      value={perObligado}
                                      name="perObligado"
                                    >
                                      <FormControlLabel
                                        value="SI"
                                        control={<Radio />}
                                        label="SI"
                                      />
                                      <FormControlLabel
                                        value="NO"
                                        control={<Radio />}
                                        label="NO"
                                      />
                                    </RadioGroup>
                                  </Box>
                                </FormControl>
                              ) : null}
                              {estadoRequest === "CLIENTE" ||
                              estadoRequest === "USUARIO" ||
                              estadoRequest === "VETERINARIO" ? null : (
                                <>
                                  <TextField
                                    inputProps={{
                                      className: clases.inputPadding,
                                    }}
                                    className={clases.formD}
                                    id="perTipoProveedor"
                                    label="Tipo proveedor"
                                    value={perTipoProveedor}
                                    name="perTipoProveedor"
                                    error={error.perTipoProveedor}
                                    onChange={(e) => handleChange(e)}
                                    select
                                    InputLabelProps={{ shrink: true }}
                                    SelectProps={{ displayEmpty: true }}
                                  >
                                    <MenuItem value="">Seleccione.</MenuItem>
                                    {[
                                      "BIENES",
                                      "SERVICIOS",
                                      "BIENES / SERVICIOS",
                                    ].map((item) => {
                                      return (
                                        <MenuItem value={item}>
                                          {item}{" "}
                                        </MenuItem>
                                      );
                                    })}
                                  </TextField>
                                  <TextField
                                    type="number"
                                    inputProps={{
                                      className: clases.inputPadding,
                                    }}
                                    className={clases.formD}
                                    id="perTiempoCredito"
                                    label="Tiempo Crédito(Días)."
                                    value={perTiempoCredito}
                                    name="perTiempoCredito"
                                    error={error.perTiempoCredito}
                                    onChange={(e) => handleChange(e)}
                                  />
                                  <FormControl
                                    component="fieldset"
                                    inputProps={{
                                      className: clases.inputPadding,
                                    }}
                                    className={clases.formD}
                                  >
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      justifyContent="space-between"
                                    >
                                      <FormLabel
                                        component="span"
                                        color="primary"
                                        style={{
                                          marginRight: ".5rem",
                                          fontWeight: "bold",
                                        }}
                                        className={
                                          error.perCredito ? clases.error : ""
                                        }
                                      >
                                        Crédito
                                      </FormLabel>
                                      <RadioGroup
                                        onChange={(e) => handleChange(e)}
                                        row
                                        aria-label="gender"
                                        value={perCredito}
                                        name="perCredito"
                                      >
                                        <FormControlLabel
                                          value="SI"
                                          control={<Radio />}
                                          label="SI"
                                        />
                                        <FormControlLabel
                                          value="NO"
                                          control={<Radio />}
                                          label="NO"
                                        />
                                      </RadioGroup>
                                    </Box>
                                  </FormControl>
                                </>
                              )}
                              <>
                                <CampoDoc
                                  disabled={cargando}
                                  disabledE={cargando}
                                  setcargando={setcargando}
                                  label={"Documento"}
                                  propiedad={"perDocumento"}
                                  item={item}
                                  setitem={setitem}
                                  celda={false}
                                />
                              </>
                              <TextField
                                inputProps={{ className: clases.inputPadding }}
                                className={clases.formD}
                                size="small"
                                id="latitud"
                                label="Ubicación GPS"
                                value={
                                  perUbicacion.latitud +
                                  ", " +
                                  perUbicacion.longitud
                                }
                                name="latitud"
                                error={error.latitud}
                                InputProps={{
                                  endAdornment: (
                                    <Box
                                      htmlFor="contained-button-documento"
                                      fullWidth
                                    >
                                      <InputAdornment>
                                        <Tooltip title="Mostrar Mapa" arrow>
                                          <Box>
                                            <IconButton
                                              aria-label=""
                                              onClick={() => setopenMap(true)}
                                            >
                                              <Map color="secondary" />
                                            </IconButton>
                                          </Box>
                                        </Tooltip>
                                      </InputAdornment>
                                    </Box>
                                  ),
                                }}
                              />
                              <DialogMap
                                open={openMap}
                                setopen={setopenMap}
                                campo={"perUbicacion"}
                                setitem={setitem}
                                item={item}
                                editar={editar}
                              />
                              <TextField
                                inputProps={{ className: clases.inputPadding }}
                                className={clases.formAllW}
                                id="perObsevacion"
                                label="Observación"
                                value={perObsevacion}
                                multiline
                                rows={3}
                                variant={"outlined"}
                                name="perObsevacion"
                                error={error.perObsevacion}
                                onChange={(e) => handleChange(e)}
                              />
                            </>
                          ) : null}
                        </>
                      </Box>
                      <Box display={"flex"} justifyContent={"space-between"}>
                        <Typography
                          className={clases.formD}
                          color="secondary"
                          fontSize={".9rem"}
                          fontWeight="bold"
                        >
                          Crea:{" "}
                          <Typography
                            className={clases.formD}
                            color="primary"
                            fontSize={".9rem"}
                            component="span"
                          >
                            {item.perUser.split(" ** ")[0]}
                          </Typography>
                        </Typography>
                        <Typography
                          className={clases.formD}
                          color="secondary"
                          fontSize={".9rem"}
                          fontWeight="bold"
                        >
                          Act:{" "}
                          <Typography
                            className={clases.formD}
                            color="primary"
                            fontSize={".9rem"}
                            component="span"
                          >
                            {item.perUser.split(" ** ")[1]}
                          </Typography>
                        </Typography>
                      </Box>
                    </Box>
                  </>
                </Grid>
              </Grid>
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default memo(ModalAsistencia);
