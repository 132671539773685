import React from "react";
// MATERIAL
import { makeStyles } from "@mui/styles";
import {
  Box,
  IconButton,
  Dialog,
  Slide,
  Typography,
  Button,
} from "@mui/material";
import { Cancel } from "@mui/icons-material";
const useStyles = makeStyles((theme) => ({
  success: {
    backgroundColor: "#00a000",
    color: "white",
  },
  warning: {
    backgroundColor: "#f44336",
    color: "white",
  },
  error: {
    backgroundColor: "#ff1c06",
    color: "white",
  },
  font: { fontSize: "1.1rem", fontWeight: "900" },
}));

export default function ConfirmacionDialog(props) {
  const clases = useStyles();
  const {
    open,
    setopen,
    titulo,
    funcion1,
    funcion2,
    funcion3,
    tripleOpcion,
    categoria,
    funcion,
    NotaCredito,
    funcion4,
    texto1,
    texto2,
    texto3,
    texto4,
    deshabilitarBoton1,
    deshabilitarBoton2,
    deshabilitarBoton3,
    funcionNo,
  } = props;
  const handleClose = () => {
    setopen(false);
  };

  if (!open) {
    return null;
  }
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  return (
    <Dialog
      maxWidth="xl"
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <Box className={categoria ? clases[categoria] : clases.warning}>
        <Box
          display="flex"
          justifyContent="space-between"
          p={1.5}
          alignItems={"center"}
        >
          <Typography
            variant="subtitle1"
            color=""
            component={"span"}
            className={clases.font}
          >
            {titulo}
          </Typography>
          <Box mr={1}></Box>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            {tripleOpcion ? (
              <>
                {NotaCredito ? (
                  <Box ml={1} mr={1}>
                    <Button
                      size="small"
                      color="secondary"
                      variant="contained"
                      className={clases.font}
                      onClick={() => {
                        funcion4();
                        handleClose();
                      }}
                    >
                      {texto4}
                    </Button>
                  </Box>
                ) : (
                  <>
                    {deshabilitarBoton1 ? null : (
                      <Button
                        size="small"
                        color="primary"
                        variant="contained"
                        className={clases.font}
                        onClick={() => {
                          funcion1();

                          handleClose();
                        }}
                      >
                        {texto1}
                      </Button>
                    )}

                    <Box ml={1} mr={1}>
                      {deshabilitarBoton2 ? null : (
                        <Button
                          size="small"
                          color="secondary"
                          variant="contained"
                          className={clases.font}
                          onClick={() => {
                            funcion2();
                            handleClose();
                          }}
                        >
                          {texto2}
                        </Button>
                      )}
                    </Box>
                    {deshabilitarBoton3 ? null : (
                      <Button
                        size="small"
                        color={deshabilitarBoton2 ? "secondary" : "primary"}
                        variant="contained"
                        className={clases.font}
                        onClick={() => {
                          funcion3();
                          handleClose();
                        }}
                      >
                        {texto3}
                      </Button>
                    )}
                  </>
                )}
                <IconButton
                  aria-label=""
                  onClick={handleClose}
                  color="secondary"
                >
                  <Cancel style={{ color: "white" }} />
                </IconButton>
              </>
            ) : (
              <>
                <Button
                  size="small"
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    funcion();
                    handleClose();
                  }}
                >
                  SI
                </Button>
                <Box ml={1} mr={1}>
                  <Button
                    size="small"
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                      if (funcionNo) {
                        funcionNo();
                      }
                      handleClose();
                    }}
                  >
                    NO
                  </Button>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
}
