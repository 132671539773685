import React, { useContext, useEffect, useRef, useState } from "react";
import {
  IconButton,
  Box,
  Badge,
  MenuItem,
  MenuList,
  Paper,
  ClickAwayListener,
  Avatar,
  Button,
  Popper,
  Fade,
} from "@mui/material";
import Typography from "@mui/material/Typography";
// @mui/icons-material
import { Notifications } from "@mui/icons-material";
// eslint-disable-next-line
import AuthContext from "../../../Context/Auth/AuthContext.js";
// import sigeopLogo from "../../../assets/img/sigeoplogo.png";
import clienteAxios from "../../../config/axios.js";
import { NavLink, withRouter } from "react-router-dom";
import Perfil from "./Perfil.js";
import MenuContext from "../../../Context/Menu/MenuContext.js";
import io from "socket.io-client";
import { socketUrl, trimPropsItem } from "../../../config/const.js";
const NotificactionsButtons = (props) => {
  const { usuario, cerrarSesion } = useContext(AuthContext);
  const { clear: clearMenu } = useContext(MenuContext);
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };
  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? "transition-popper" : undefined;

  // NOTIFICACION 2
  const [openNotificacion2, setOpenNotificacion2] = React.useState(false);
  const [anchorElNotificacion2, setAnchorElNotificacion2] = React.useState(
    null
  );
  const handleClickNotificacion2 = (event) => {
    setAnchorElNotificacion2(event.currentTarget);
    setOpenNotificacion2((previousOpen) => !previousOpen);
  };
  const canBeOpenNotificacion2 =
    openNotificacion2 && Boolean(anchorElNotificacion2);
  const idNotificacion2 = canBeOpenNotificacion2
    ? "transition-popperNotificacion2 "
    : undefined;

  const [openPerfil, setopenPerfil] = useState(false);

  const handleEditar = (notificacion) => {
    socket.current.emit(
      "client:actualizarData",
      trimPropsItem({
        ...notificacion,
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        notUser: usuario.usuario,
        notEmpresa: usuario.rucempresa,
        tabla: "notificacion-leido",
      })
    );
  };
  const [notificacionesData, setnotificacionesData] = useState({
    notificaciones: [],
    estado: false,
  });
  const [itemEdit, setitemEdit] = useState(false);
  const [nuevoItem, setnuevoItem] = useState(false);
  useEffect(() => {
    const getDataState = async () => {
      if (usuario.rucempresa !== "ULTRA2022") {
        return;
      }
      const resNotificaciones = await clienteAxios.get(
        "/notificaciones/listado"
      );

      setnotificacionesData({
        estado: true,
        notificaciones: resNotificaciones.data.notificaciones,
      });
    };
    if (!notificacionesData.estado) {
      getDataState();
    }
    // eslint-disable-next-line
  }, []);
  // const notificacionesDataRef = React.useRef(notificacionesData);
  // useEffect(() => {
  //   // eslint-disable-next-line
  // }, [notificacionesDataRef, socket]);
  const socket = useRef();
  useEffect(() => {
    // Creates a WebSocket connection
    const token = localStorage.getItem("token");
    // Creates a WebSocket connection
    socket.current = io(socketUrl, {
      autoConnect: true,
      forceNew: true,
      transports: ["websocket"],
      query: {
        "x-token": token,
      },
    });
    socket.current.on("server:actualizadoExitoso", async (data) => {
      // if (usuario.usuario !== data.notNotificacionPertenece) {
      //   return;
      // }
      setitemEdit(data);
      // data;
    });
    socket.current.on("server:nuevanotificacion", async (data) => {
      // if (usuario.usuario !== data.notNotificacionPertenece) {
      // return;
      // }
      setnuevoItem(data);
    });

    return () => {
      socket.current.disconnect();
    };
    // Destroys the socket reference
    // when the connection is closed
    // eslint-disable-next-line
  }, []);
  //  eslint-disable-next-line
  useEffect(() => {
    if (itemEdit) {
      setnotificacionesData({
        ...notificacionesData,
        notificaciones: notificacionesData.notificaciones.map((notMap) =>
          notMap.notId === itemEdit.notId ? itemEdit : notMap
        ),
      });
    }
    if (nuevoItem) {
      setnotificacionesData({
        ...notificacionesData,
        notificaciones: [nuevoItem, ...notificacionesData.notificaciones],
      });

      //refresh the local state
      setnuevoItem(false);
    }
    // eslint-disable-next-line
  }, [itemEdit, nuevoItem]);
  return (
    <Box display="flex" alignItems={"center"}>
      <Perfil open={openPerfil} setopen={setopenPerfil} />
      {/* NOTIFICACIONES 1 */}
      {/* <Box display="flex" alignItems="center">
        <IconButton
          aria-describedby={idNotificacion1}
          type="button"
          onClick={handleClickNotificacion1}
        >
          <Badge
            badgeContent={
              notificacionesData.notificacion1.filter(
                (itemNotificacion) => itemNotificacion.notVisto === "NO"
              ).length
            }
            color="error"
          >
            <LocalPolice color="primary" />
          </Badge>
        </IconButton>
      </Box> */}

      {/* NOTIFICACIONES 2 */}
      <Box mr={2}>
        {usuario.rucempresa === "ULTRA2022" && (
          <IconButton
            aria-describedby={idNotificacion2}
            type="button"
            onClick={handleClickNotificacion2}
            sx={{ color: "white" }}
          >
            <Badge
              badgeContent={
                notificacionesData.notificaciones.filter(
                  (itemNotificacion) =>
                    itemNotificacion.notVisto === "NO" &&
                    itemNotificacion.notIdPerPertenece === usuario.id
                ).length
              }
              color="error"
            >
              <Notifications />
            </Badge>
          </IconButton>
        )}
      </Box>

      <Popper
        sx={{ zIndex: "3500" }}
        id={idNotificacion2}
        open={openNotificacion2}
        anchorEl={anchorElNotificacion2}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <ClickAwayListener
                onClickAway={() => setOpenNotificacion2(false)}
              >
                <MenuList
                  style={{
                    minWidth: "30rem",
                    maxHeight: "20rem",
                    overflow: "scroll",
                  }}
                  role="menu"
                  notEmpresa
                  color="primary"
                >
                  {[...notificacionesData.notificaciones].map((item, key) => {
                    return (
                      <NavLink to={"/Contable/Monitoreo"} key={key}>
                        <MenuItem
                          style={{
                            backgroundColor:
                              item.notVisto === "NO" ? "#096d9c" : "white",
                            borderBottom: "1px solid #e1e1e1",
                          }}
                          onClick={() => {
                            if (item.notVisto === "SI") {
                              return;
                            }
                            handleEditar(item);
                          }}
                        >
                          <Box
                            width={"100%"}
                            display="flex"
                            justifyContent={"space-between"}
                            alignItems="center"
                          >
                            <Box>
                              <Typography
                                variant="body1"
                                color={
                                  item.notVisto === "NO" ? "white" : "black"
                                }
                              >
                                {item.notTitulo}
                              </Typography>

                              <Typography
                                variant="body2"
                                color={
                                  item.notVisto === "NO" ? "white" : "black"
                                }
                              >
                                {item.perDocNumero} - {item.perNombre}
                              </Typography>
                            </Box>

                            <Box>
                              <Avatar src={item.notFoto} />
                            </Box>
                          </Box>
                        </MenuItem>
                      </NavLink>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>

      {/* SUBMENU */}
      <Button
        color="inherit"
        aria-describedby={id}
        type="button"
        onClick={handleClick}
      >
        <Avatar
          alt={usuario.nombre}
          style={{ marginRight: ".3rem", marginLeft: ".3rem" }}
          src={usuario.foto}
        />

        {usuario.nombre}
      </Button>
      <Popper
        sx={{ zIndex: "3500" }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <ClickAwayListener onClickAway={() => setOpen(false)}>
                <MenuList role="menu" color="primary">
                  <MenuItem
                    onClick={() => {
                      props.history.push("/Contable/Dashboard");
                    }}
                  >
                    Inicio
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setopenPerfil(true);
                    }}
                  >
                    Mis Datos
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      cerrarSesion();
                      clearMenu();

                      props.history.push("/login");
                    }}
                  >
                    Cerrar Sesión
                  </MenuItem>{" "}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </Box>
  );
};

export default withRouter(NotificactionsButtons);
