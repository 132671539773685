import { useEffect, useState } from "react";
import clienteAxios from "../config/axios";

export const useDynamicForm = ({ tabla, propiedad }) => {
  const [tabsDinamicos, setcamposDinamicos] = useState(null);
  const getCamposDinamicos = async () => {
    const res = await clienteAxios.get(`camposDinamicos/filtro?tabla=${tabla}`);
    const data = res.data?.data;
    const { campDatos } = !!data && data[0];

    setcamposDinamicos(campDatos);
  };
  const tienePropiedadVerdadera = (objeto) => {
    return Object.values(objeto).some((valor) => valor === true);
  };
  const onValidacionCamposDinamicos = (item) => {
    const error = {};
    const errorTab = {};
    const allCampos = tabsDinamicos.reduce((acc, obj) => {
      return [
        ...acc,
        ...obj.campos.map((campo) => ({
          ...campo,
          idTab: obj.id,
        })),
      ];
    }, []);

    allCampos.forEach((campo) => {
      if (campo.required === 'SI') {
        if (!item[propiedad]) {
          errorTab[campo.idTab] = true;
          return (error[campo.name] = true);
        }
        if (!item[propiedad][campo.name]) {
          errorTab[campo.idTab] = true;
          return (error[campo.name] = true);
        }
        if (
          campo.tipo === "TEXTO" &&
          item[propiedad][campo.name].trim() === ""
        ) {
          errorTab[campo.idTab] = true;
          return (error[campo.name] = true);
        }

        if (
          campo.tipo === "NUMERICO" &&
          item[propiedad][campo.name].trim() === ""
        ) {
          errorTab[campo.idTab] = true;
          return (error[campo.name] = true);
        }

        if (
          campo.tipo === "MULTISELECT" &&
          item[propiedad][campo.name].length === 0
        ) {
          errorTab[campo.idTab] = true;
          return (error[campo.name] = true);
        }

        if (
          campo.tipo === "SELECT" &&
          item[propiedad][campo.name].trim() === ""
        ) {
          errorTab[campo.idTab] = true;
          return (error[campo.name] = true);
        }

        if (
          campo.tipo === "IMAGEN" &&
          item[propiedad][campo.name].trim() === ""
        ) {
          errorTab[campo.idTab] = true;
          return (error[campo.name] = true);
        }

        if (
          campo.tipo === "ARCHIVO" &&
          item[propiedad][campo.name].trim() === ""
        ) {
          errorTab[campo.idTab] = true;
          return (error[campo.name] = true);
        }
      }
    });
    return {
      error,
      errorTab,
      tienePropiedadVerdadera: tienePropiedadVerdadera(error),
    };
  };
  useEffect(() => {
    getCamposDinamicos();
     // eslint-disable-next-line
  }, []);

  return {
    tabsDinamicos,
    onValidacionCamposDinamicos,
  };
};
