import { Box, Button } from "@mui/material";
import React, { useContext } from "react";
import { useModalStyle } from "../../../styles/styleModal";
import AuthContext from "../../../Context/Auth/AuthContext";
import { useMemo } from "react";

export const TabsRolEliminado = ({
  filtrarEliminar,
  filtrarRol,
  eliminadoEstado,
  rolEstado,
  cargando,
  setarrayExport,
}) => {
  const { usuario } = useContext(AuthContext);
  const clases = useModalStyle();

  const mostarEliminar = useMemo(
    () =>
      usuario.rol.some(
        (rol) => rol === "SUPERADMIN" || rol === "ADMIN" || rol === "USUARIO"
      ), // eslint-disable-next-line
    []
  );
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems=" stretch"
      width="100%"
    >
      <Box>
        {usuario.rol.length === 1
          ? null
          : usuario.rol.map((itemRol) => {
              return (
                <Button
                  disabled={cargando}
                  size="small"
                  key={itemRol}
                  variant={itemRol === rolEstado ? "contained" : "outlined"}
                  onClick={async () => {
                    setarrayExport([]);
                    filtrarRol(itemRol);
                  }}
                  disableElevation
                  className={clases.buttonTab}
                  color="primary"
                >
                  {itemRol}
                </Button>
              );
            })}
      </Box>
      <Box>
        {filtrarEliminar && mostarEliminar && (
          <Button
            disabled={cargando}
            size="small"
            variant={eliminadoEstado ? "contained" : "outlined"}
            onClick={async () => {
              setarrayExport([]);
              filtrarEliminar(!eliminadoEstado);
            }}
            disableElevation
            className={clases.buttonTab}
            color="error"
          >
            Eliminados
          </Button>
        )}
      </Box>
    </Box>
  );
};
