import React, { useState, useContext, useEffect, memo } from "react";

import Draggable from "react-draggable";
import AlertaContext from "../../../Context/Alerta/AlertaContext";
import AuthContext from "../../../Context/Auth/AuthContext";
import { replaceCaracteres, trimPropsItem } from "../../../config/const";
import {
  Modal,
  Box,
  Divider,
  Typography,
  IconButton,
  Button,
  TextField,
  MenuItem,
} from "@mui/material";
import { Cancel } from "@mui/icons-material";
import { objectDefault } from "../data";
import { useModalStyle } from "../../../styles/styleModal";
import { PlantillaTab } from "../../Configuración/Empresas/Componentes/PlantillaTab";
import clienteAxios from "../../../config/axios";
import ListPersonas from "./ListPersonas";

const Modalmensajeria_noficacions = (props) => {
  const { mostrarAlerta } = useContext(AlertaContext);

  const { usuario } = useContext(AuthContext);
  //props
  const {
    editar,
    seteditar,
    setopen,
    open,
    ObjectoActivo,
    changeObjetoActivoState,
    socket,
  } = props;

  const [item, setitem] = useState(ObjectoActivo);
  const [cargando, setcargando] = useState(false);
  const [left, setLeft] = useState([]);

  const [right, setRight] = useState([]);

  const {
    mensNombre,
    mensYearMonth,
    // mensIncluyePropiedadSaldo
  } = item;

  const [error, seterror] = useState({});

  // paginacion
  const [estadoRequest, setestadoRequest] = useState("CLIENTE");
  const [page, setPage] = useState(0);
  const [paginas, setpaginas] = useState(0);
  const [totalItems, settotalItems] = useState(0);
  const getPersonasRight = async (ids) => {
    try {
      const res = await clienteAxios.get(
        `/proveedores/byIds/0?ids=${ids ? ids : ""}`
      );
      setRight(res.data.data);
      onPaginate({ perfil: estadoRequest, page }, res.data.data);
    } catch (error) {
      console.log({ error });
    }
  };
  function calcularPaginas(paginacion, resta) {
    // Restamos el valor de 'resta' a 'numRows'
    paginacion.numRows -= resta;

    // Calculamos el número total de páginas
    let totalPaginas = Math.ceil(paginacion.numRows / paginacion.perPage);

    setpaginas(totalPaginas || 0);
    settotalItems(paginacion.numRows);
  }
  const [isActive, setisActive] = useState(1);
  const [search, setSearch] = useState("");
  // (isActive = "1"), // | 0 esto es para buscar personas activas o inactivas
  // (propiedadSaldo = "1"), // | 0 para la verificar si ha seleccionado la propiedad saldo
  // (yearMonth = "2024-01"); // en caso que haya seleccionado saldo enviarme el year-month
  const onPaginate = async (
    { perfil, page },
    dataRight,
    isActiveArg = isActive,
    searchArg = search
  ) => {
    const mensIncluyePropiedadSaldo = item.mensPlantilla.variables.some(
      (variable) => variable.campo === "perSaldo"
    );
    try {
      const res = await clienteAxios.get(
        `/proveedores/list/byToMessage?cantidad=${100}&page=${page}&search=${searchArg}&input=${"perNombre"}&orden=${true}&datos=${""}&rucempresa=${
          usuario.rucempresa
        }&perfil=${perfil}&allData=${true}&propiedadSaldo=${
          mensIncluyePropiedadSaldo && mensYearMonth !== "" ? 1 : 0
        }&yearMonth=${mensYearMonth}&isActive=${isActiveArg}`
      );

      calcularPaginas(res.data.data.pagination, dataRight.length);

      const resPersonas = res.data.data.results.filter(
        (itemD) => !dataRight.some((itemData) => itemData.perId === itemD.perId)
      );
      setLeft(resPersonas);
    } catch (error) {
      mostrarAlerta("Hubo un error", "error");
    }
  };

  useEffect(() => {
    if (ObjectoActivo.mensPerIds.length > 0 && open) {
      getPersonasRight(ObjectoActivo.mensPerIds);
    } else if (open) {
      onPaginate({ perfil: estadoRequest, page }, []);
    }
    setitem(ObjectoActivo);
    // eslint-disable-next-line
  }, [open]);

  const defaultActive = () => {
    changeObjetoActivoState(objectDefault);
    setopen(false);
    seteditar(false);
    seterror({});
    setLeft([]);
    setRight([]);
    setpaginas(0);
  };

  const handleChange = (e) => {
    const res = replaceCaracteres(e.target.value);
    setitem({
      ...item,
      [e.target.name]: res,
    });
  };

  //hook de estilos
  const clases = useModalStyle();
  function getMonths() {
    let currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() - 6);
    let months = [
      currentDate.getFullYear() +
        "-" +
        (currentDate.getMonth() + 1).toString().padStart(2, "0"),
    ];
    for (let i = 0; i < 6; i++) {
      currentDate.setMonth(currentDate.getMonth() + 1);
      months.push(
        currentDate.getFullYear() +
          "-" +
          (currentDate.getMonth() + 1).toString().padStart(2, "0")
      );
    }
    return months.reverse();
  }
  const validacion = () => {
    if (
      mensNombre.trim() === "" ||
      right.length === 0 ||
      mensYearMonth === ""
    ) {
      seterror({
        mensNombre: mensNombre.trim() === "",
        right: right.length === 0,
        mensYearMonth: mensYearMonth === "",
      });
      return true;
    } else {
      return false;
    }
  };

  const handleCrear = async () => {
    if (validacion()) {
      return mostrarAlerta("Faltan datos obligatorios por llenar", "error");
    }
    try {
      setcargando(true);
      const mensIncluyePropiedadSaldo = item.mensPlantilla.variables.some(
        (variable) => variable.campo === "perSaldo"
      );

      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...item,
          mensPerIds: right.map((item) => item.perId),
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          mensUser: usuario.usuario,
          mensEmpresa: usuario.rucempresa,
          tabla: "mensajeria_noficacion",
          mensIncluyePropiedadSaldo: mensIncluyePropiedadSaldo ? 1 : 0,
        })
      );
      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);

      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  const handleEditar = async () => {
    if (validacion()) {
      return mostrarAlerta("Faltan datos obligatorios por llenar", "error");
    }
    try {
      setcargando(true);
      const mensIncluyePropiedadSaldo = item.mensPlantilla.variables.some(
        (variable) => variable.campo === "perSaldo"
      );

      socket.current.emit(
        "client:actualizarData",
        trimPropsItem({
          ...item,
          //params
          mensPerIds: right.map((item) => item.perId),
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          mensUser: usuario.usuario,
          mensEmpresa: usuario.rucempresa,
          tabla: "mensajeria_noficacion",
          mensIncluyePropiedadSaldo: mensIncluyePropiedadSaldo ? 1 : 0,
        })
      );
      setcargando(false);
      return defaultActive();
    } catch (error) {
      console.log({ error });
      setcargando(false);

      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };

  if (!open) {
    return null;
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Draggable handle="#handle">
          <Box display="flex" justifyContent="center">
            <div
              style={{
                borderRadius: "15px",
                position: "absolute",
                overflow: "scroll",
                maxHeight: "95vh",
              }}
              className={clases.paper}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography
                  id="handle"
                  style={{ cursor: "-webkit-grab" }}
                  variant="button"
                  color="initial"
                  align="center"
                >
                  {item.ver ? "Visualizando " : editar ? "Editar " : "Agregar "}
                  Mensaje.
                </Typography>
                <Typography
                  variant="h5"
                  color="initial"
                  align="center"
                ></Typography>
                <Box display="flex" alignItems="center">
                  <IconButton
                    aria-label=""
                    style={{ margin: "0px" }}
                    onClick={() => {
                      defaultActive();
                    }}
                  >
                    <Cancel color="secondary" />
                  </IconButton>
                </Box>
              </Box>

              <Divider />
              <Box className={clases.container}>
                <TextField
                  className={clases.formD}
                  label="Nombre del mensaje"
                  value={mensNombre}
                  name="mensNombre"
                  error={error.mensNombre}
                  onChange={(e) => handleChange(e)}
                />
                <TextField
                  className={clases.formD}
                  label="Año Y Mes"
                  value={mensYearMonth}
                  name="mensYearMonth"
                  error={error.mensYearMonth}
                  onChange={(e) => handleChange(e)}
                  select
                  InputLabelProps={{ shrink: true }}
                  SelectProps={{ displayEmpty: true }}
                >
                  <MenuItem value="">Seleccione </MenuItem>
                  {getMonths().map((item) => {
                    return <MenuItem value={item}>{item} </MenuItem>;
                  })}
                </TextField>{" "}
                {/* <TextField
                  className={clases.formD}
                  label="Nombre del mensaje"
                  value={mensNombre}
                  name="mensNombre"
                  error={error.mensNombre}
                  onChange={(e) => handleChange(e)}
                /> */}
                <PlantillaTab
                  propiedadPlantilla="mensPlantilla"
                  empresa={item}
                  setempresa={setitem}
                  clases={clases}
                  arregloPropiedades={[
                    {
                      propiedad: "perDocNumero",
                      etiqueta: "#Identificación",
                    },
                    { propiedad: "perNombre", etiqueta: "Nombres" },
                    { propiedad: "perCelular", etiqueta: "Celular" },
                    { propiedad: "perEmail", etiqueta: "Email" },
                    { propiedad: "perSaldo", etiqueta: "Saldo" },
                  ]}
                />
                <ListPersonas
                  error={error.right}
                  left={left}
                  setLeft={setLeft}
                  right={right}
                  setRight={setRight}
                  setcargando={setcargando}
                  cargando={cargando}
                  // PAGINACION
                  estadoRequest={estadoRequest}
                  setestadoRequest={setestadoRequest}
                  page={page}
                  setPage={setPage}
                  paginas={paginas}
                  totalItems={totalItems}
                  onPaginate={onPaginate}
                  isActive={isActive}
                  setisActive={setisActive}
                  setSearch={setSearch}
                  search={search}
                />
              </Box>
              {/* <Divider /> */}
              <Box width="100%" display="flex" justifyContent="flex-end" mt={1}>
                {!item.ver && (
                  <Button
                    style={{ width: "30%" }}
                    variant="contained"
                    color="primary"
                    disabled={cargando}
                    onClick={() => {
                      if (editar) {
                        handleEditar();
                      } else {
                        handleCrear();
                      }
                    }}
                  >
                    Guardar
                  </Button>
                )}
              </Box>
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default memo(Modalmensajeria_noficacions);
