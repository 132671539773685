import React, { memo, useContext, useEffect } from "react";
import { useState } from "react";

// import ConfirmacionDialog from "../../Dialog/ConfirmacionDialog";

import ConfirmacionDialog from "../../../../components/Extra/ConfirmacionDialog";

import { withRouter } from "react-router";
import ButtonsAction from "../../../../components/Extra/ButtonsAction";
import { FormControl } from "@mui/material";
import clienteAxios from "../../../../config/axios";
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
// MATERIAL
import { makeStyles } from "@mui/styles";
import {
  MenuItem,
  TableCell,
  TableRow,
  TextField,
  Autocomplete,
} from "@mui/material";
const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  celda: {
    fontSize: ".7rem !important",
    "&:hover": {
      opacity: ".7",
      cursor: "pointer",
    },
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));

const RowRetencion = (props) => {
  const { row, index, campoExport, item, setitem, deshabilitarbotones } = props;
  const [itemRow, setitemRow] = useState(row);
  const [data, setdata] = useState([]);
  const { mostrarAlerta } = useContext(AlertaContext);
  useEffect(() => {
    setitemRow(row);
    // eslint-disable-next-line
  }, [item]);
  const classes = useRowStyles();
  const handleEliminar = async (_id) => {
    setitem({
      ...item,
      retenciones: item.retenciones.filter((item, itemIndex) => {
        return index !== itemIndex;
      }),
    });
  };
  const funcionEdit = () => {
    seteditar(true);
  };
  const [editar, seteditar] = useState(false);
  const funcCancel = () => {
    setitemRow(row);
    seteditar(false);
  };
  const botonDisabled = () => {
    if (
      itemRow.detTipo === "" ||
      itemRow.detCodigo === "" ||
      itemRow.detBasImponible === "" ||
      itemRow.detImpuesto === "" ||
      itemRow.detPorcentaje === "" ||
      itemRow.detValor === ""
    ) {
      return true;
    } else {
      return false;
    }
  };
  const funcSubmit = () => {
    setitem({
      ...item,
      retenciones: item.retenciones.map((item, itemIndex) => {
        if (index === itemIndex) {
          return itemRow;
        } else {
          return item;
        }
      }),
    });
    seteditar(false);
  };
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        titulo="¿Esta seguro que quiere eliminar esta Retención?"
        contenido={`La Retención no puede ser recuperada.`}
        _id={itemRow._id}
        funcion={handleEliminar}
      />

      <TableRow className={classes.root} selected={index % 2 === 0}>
        {/* boton */}
        <ButtonsAction
          itemRow={itemRow}
          setopenConfirmDialog={setopenConfirmDialog}
          funcionEdit={funcionEdit}
          ocultarEditar={false}
          ocultarDelete={false}
          mostrarEstado={false}
          campoExport={campoExport}
          campoEstado="empEstado"
          funcCancel={funcCancel}
          funcSubmit={funcSubmit}
          editar={editar}
          deshabilitarbotones={deshabilitarbotones}
          botonDisabled={botonDisabled}
        />{" "}
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {editar ? (
            <TextField
              id=""
              value={itemRow.detTipo}
              onChange={(e) =>
                setitemRow({
                  ...itemRow,
                  detTipo: e.target.value,
                })
              }
              size="small"
              fullWidth
              helperText="Editando"
              select
              InputLabelProps={{ shrink: true }}
              SelectProps={{ displayEmpty: true }}
            >
              <MenuItem value="">Seleccione</MenuItem>
              {["FACTURA", "NOTA DEBITO", "LIQ. COMPRA"].map((item) => {
                return <MenuItem value={item}>{item} </MenuItem>;
              })}
            </TextField>
          ) : (
            `${itemRow.detTipo}`
          )}
        </TableCell>{" "}
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {editar ? (
            <TextField
              id=""
              value={itemRow.detImpuesto}
              onChange={(e) => {
                setitemRow({
                  ...itemRow,
                  detImpuesto: e.target.value,
                  detCodigo: "",
                  detPorcentaje: "",
                  detValor: "",
                });
                setdata([]);
              }}
              size="small"
              fullWidth
              helperText="Editando"
              select
              InputLabelProps={{ shrink: true }}
              SelectProps={{ displayEmpty: true }}
            >
              <MenuItem value="">Seleccione</MenuItem>
              {["RENTA", "IVA", "ISD"].map((item) => {
                return <MenuItem value={item}>{item} </MenuItem>;
              })}
            </TextField>
          ) : (
            `${itemRow.detImpuesto}`
          )}
        </TableCell>{" "}
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {editar ? (
            <FormControl fullWidth style={{ minWidth: 500 }}>
              <Autocomplete
                options={data.map((item) => {
                  return (
                    item.porCodigo +
                    "/+/" +
                    item.porPorcentaje +
                    "/+/" +
                    item.porConcepto
                  );
                })}
                getOptionLabel={(option) => {
                  const options = option.split("/+/");
                  if (!option[2]) {
                    return "";
                  } else {
                    return `${options[0]} - ${options[1]}% - ${options[2]}`;
                  }
                }}
                id="controlled-demo"
                value={
                  itemRow.detCodigo === "" ||
                  itemRow.detPorcentaje === "" ||
                  itemRow.detValor === ""
                    ? ""
                    : itemRow.detCodigo +
                      "/+/" +
                      itemRow.detPorcentaje +
                      "/+/" +
                      itemRow.detValor
                }
                paperprops
                onChange={async (event, newValue) => {
                  if (newValue) {
                    const options = newValue.split("/+/");

                    const renta = options[1] === "" ? 0 : Number(options[1]);
                    const imponible =
                      itemRow.detBasImponible === ""
                        ? 0
                        : Number(itemRow.detBasImponible);

                    const resultado = (imponible / 100) * renta;

                    setitemRow({
                      ...itemRow,
                      detCodigo: options[0],
                      detPorcentaje: options[1],
                      detValor: resultado.toFixed(2),
                    });
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    required
                    fullWidth
                    onChange={async (e) => {
                      try {
                        const res = await clienteAxios.get(
                          `/porcentajesRetenciones/filtro/0?impuesto=${itemRow.detImpuesto}&codigo=${e.target.value}`
                        );
                        if (res.data.msg === "No hay registro") {
                          return;
                        }

                        setdata(res.data.data);
                      } catch (error) {
                        mostrarAlerta(error.response.data.msg, "error");
                      }
                    }}
                    helperText="Agregando"
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />
            </FormControl>
          ) : (
            `${itemRow.detCodigo}`
          )}
        </TableCell>{" "}
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {editar ? (
            <TextField
              id=""
              type="number"
              value={itemRow.detPorcentaje}
              onChange={(e) => {
                const renta =
                  e.target.value === "" ? 0 : Number(e.target.value);
                const imponible =
                  itemRow.detBasImponible === ""
                    ? 0
                    : Number(itemRow.detBasImponible);

                const resultado = (imponible / 100) * renta;

                setitemRow({
                  ...itemRow,
                  detPorcentaje: e.target.value,
                  detValor: resultado.toFixed(2),
                });
              }}
              size="small"
              fullWidth
              helperText="Editando"
            />
          ) : (
            `${itemRow.detPorcentaje}`
          )}
        </TableCell>{" "}
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {editar ? (
            <TextField
              type="number"
              id=""
              value={itemRow.detBasImponible}
              onChange={(e) => {
                const renta =
                  itemRow.detPorcentaje === ""
                    ? 0
                    : Number(itemRow.detPorcentaje);
                const imponible =
                  e.target.value === "" ? 0 : Number(e.target.value);

                const resultado = (imponible / 100) * renta;

                setitemRow({
                  ...itemRow,
                  detBasImponible: e.target.value,
                  detValor: resultado.toFixed(2),
                });
              }}
              size="small"
              fullWidth
              helperText="Editando"
            />
          ) : (
            `$${itemRow.detBasImponible}`
          )}
        </TableCell>{" "}
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {editar ? (
            <TextField
              id=""
              value={itemRow.detValor}
              onChange={(e) =>
                setitemRow({
                  ...itemRow,
                  detValor: e.target.value,
                })
              }
              size="small"
              fullWidth
              helperText="Editando"
              disabled
            />
          ) : (
            `$${itemRow.detValor}`
          )}
        </TableCell>{" "}
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(RowRetencion));
