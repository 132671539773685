import { Box, Checkbox, FormControlLabel, TableCell } from "@mui/material";

import React, { memo } from "react";
import { withRouter } from "react-router";
import Botones from "../Botones";
import { useRowStyles } from "../../../styles/stylesRows";

const ButtonsAction = (props) => {
  const {
    arrayExport,
    setarrayExport,
    campoExport,
    row,
    botones,
    claseCelda,
    agregarCelda,
    agrupado,
    rowPadre,
  } = props;
  const classes = useRowStyles();
  return (
    <>
      {campoExport ? (
        agrupado ? (
          <TableCell
            size="small"
            padding="checkbox"
            style={{
              borderRight: "2px solid #ccc",
              maxWidth: "1rem",
            }}
            className={claseCelda ? claseCelda : classes.celda}
          >
            <Box display="flex" alignItems="center" justifyContent="center">
              <FormControlLabel
                sx={{
                  margin: {
                    margin: "0px",
                    padding: "2px",
                  },
                }}
                control={
                  <Checkbox
                    size="small"
                    sx={{
                      margin: {
                        margin: "0px",
                        padding: "2px",
                      },
                    }}
                    checked={
                      arrayExport[rowPadre[campoExport]]?.some((item) => {
                        return item === row[campoExport];
                      })
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      if (e.target.checked) {
                        if (arrayExport[rowPadre[campoExport]]) {
                          setarrayExport({
                            ...arrayExport,
                            [rowPadre[campoExport]]: [
                              ...arrayExport[rowPadre[campoExport]],
                              row[campoExport],
                            ],
                          });
                        } else {
                          setarrayExport({
                            ...arrayExport,
                            [rowPadre[campoExport]]: [row[campoExport]],
                          });
                        }
                      } else {
                        setarrayExport({
                          ...arrayExport,
                          [rowPadre[campoExport]]: arrayExport[
                            rowPadre[campoExport]
                          ].filter((item) => item !== row[campoExport]),
                        });
                      }
                    }}
                    color="secondary"
                  />
                }
              />
            </Box>
          </TableCell>
        ) : (
          <TableCell
            size="small"
            padding="checkbox"
            style={{
              borderRight: "2px solid #ccc",
              maxWidth: "1rem",
            }}
            className={claseCelda ? claseCelda : classes.celda}
          >
            <Box display="flex" alignItems="center" justifyContent="center">
              <FormControlLabel
                sx={{
                  margin: {
                    margin: "0px",
                    padding: "2px",
                  },
                }}
                control={
                  <Checkbox
                    size="small"
                    sx={{
                      margin: {
                        margin: "0px",
                        padding: "2px",
                      },
                    }}
                    checked={arrayExport.some((item) => {
                      return item === row[campoExport];
                    })}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setarrayExport([...arrayExport, row[campoExport]]);
                      } else {
                        setarrayExport(
                          arrayExport.filter(
                            (item) => item !== row[campoExport]
                          )
                        );
                      }
                    }}
                    color="secondary"
                  />
                }
              />
            </Box>
          </TableCell>
        )
      ) : null}
      {agregarCelda ? (
        <TableCell
          className={claseCelda ? claseCelda : classes.celda}
        ></TableCell>
      ) : null}
      <TableCell
        className={claseCelda ? claseCelda : classes.celda}
        style={{ borderRight: "1px solid #ccc" }}
      >
        <Box display="flex" alignItems="center">
          <Botones botones={botones} />
        </Box>
      </TableCell>
    </>
  );
};

export default withRouter(memo(ButtonsAction));
