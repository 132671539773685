import React, { useEffect, useState } from "react";
import { Line } from "@reactchartjs/react-chart.js";
import { Typography } from "@mui/material";
import Cargando from "../components/Cargando/Cargando";
const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};
const reducer = (previousValue, currentValue) =>
  Number(previousValue) + Number(currentValue);
const GraficoLineaHis = ({ titulo, dataGrafico }) => {
  const [data, setdata] = useState(null);

  const [total] = useState(
    Object.values(dataGrafico.hisclinicas).length === 0
      ? 0
      : Object.values(dataGrafico.hisclinicas)
          .map((numero) => {
            return Number(numero.total);
          })
          .reduce(reducer)
          .toFixed(0)
  );
  useEffect(() => {
    const { hisclinicas } = dataGrafico;

    const labels = hisclinicas.map((item) => {
      return `${item.mes}`;
    });
    const data = hisclinicas.map((item) => item.total);

    setdata({
      labels: labels.reverse(),
      datasets: [
        {
          label: "Historia Clinica",
          data: data.reverse(),
          fill: true,
          borderColor: "rgb(75, 192, 192)",
          tension: 0.1,
        },
      ],
    });
    // eslint-disable-next-line
  }, []);
  if (!data) {
    return <Cargando titulo="Cargando Multas" />;
  }
  return (
    <>
      <Typography variant="h5" align="center" color="secondary">
        {titulo}:{" "}
        <Typography variant="h5" color="primary" component={"span"}>
          {total}
        </Typography>
      </Typography>

      <Line data={data} options={options} height={60} />
    </>
  );
};

export default GraficoLineaHis;
