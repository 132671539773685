import React, { memo } from "react";
// MATERIAL
import {
  TableCell,
  TableRow,
  TextField,
  MenuItem,
  Typography,
  Box,
  IconButton,
  Divider,
  Collapse,
  Badge,
  InputAdornment,
} from "@mui/material";
import {
  butonIconTransition,
  useRowStyles,
} from "../../../../../styles/stylesRows";
import ButtonsAction from "../../../../components/Tablas/ButtonsAction";
import {
  Cancel,
  Create,
  Delete,
  DeleteForever,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import { useState } from "react";
import { v4 } from "uuid";
import { useContext } from "react";
import AlertaContext from "../../../../../Context/Alerta/AlertaContext";
import { useRef } from "react";
const RowAgregar = (props) => {
  const { mostrarAlerta } = useContext(AlertaContext);
  const classes = useRowStyles();
  const {
    tabActual,
    settabActual,
    handleDragStart,
    handleDragEnter,
    handleDragEnd,
    seteditado,
    index,
    itemHijo,
  } = props;
  const inputRef = useRef();
  const claseCelda = `${
    index % 2 === 0 ? classes.celda : classes.celdaSecundario
  }`;
  const [editar, seteditar] = useState(false);
  const [expandir, setexpandir] = useState(false);
  const [newOption, setnewOption] = useState({
    id: "",
    value: "",
  });
  const onNewOptionSubmit = (e) => {
    e.preventDefault();
    if (newOption.value.length <= 1)
      return mostrarAlerta(
        "El nombre del nuevo tab debe de tener mas de un caracter",
        "error"
      );
    const existe = itemHijo.opciones.some(
      (opcion) => opcion.value === newOption.value
    );
    if (existe)
      return mostrarAlerta("Ya existe un tab con este nombre", "error");

    if (editar) {
      settabActual({
        ...tabActual,
        campos: tabActual.campos.map((campo) => {
          if (campo.id === itemHijo.id) {
            return {
              ...itemHijo,
              opciones: itemHijo.opciones.map((opcionF) => {
                if (opcionF.id === newOption.id) {
                  return newOption;
                }
                return opcionF;
              }),
            };
          }
          return campo;
        }),
      });
    } else {
      settabActual({
        ...tabActual,
        campos: tabActual.campos.map((campo) => {
          if (campo.id === itemHijo.id) {
            return {
              ...itemHijo,
              opciones: [{ ...newOption, id: v4() }, ...itemHijo.opciones],
            };
          }
          return campo;
        }),
      });
    }
    seteditado(true);
    seteditar(false);
    setnewOption({ id: "", value: "" });
  };
  const handleChange = ({ target }) => {
    seteditado(true);
    settabActual({
      ...tabActual,
      campos: tabActual.campos.map((campo) => {
        if (campo.id === itemHijo.id) {
          if (target.name === "label") {
            return {
              ...itemHijo,
              [target.name]: target.value,
              name: target.value.replace(/ /g, "_"),
            };
          }
          return { ...itemHijo, [target.name]: target.value };
        }
        return campo;
      }),
    });
  };
  return (
    <React.Fragment>
      <TableRow
        className={classes.root}
        onDragStart={() => handleDragStart(index)}
        onDragEnter={() => handleDragEnter(index)}
        onDragEnd={(e) => {
          seteditado(true);
          handleDragEnd("campos", tabActual.campos, itemHijo, (e) => {
            settabActual({
              ...tabActual,
              campos: e.campos,
            });
          });
        }}
        onDragOver={(e) => e.preventDefault()}
        draggable
      >
        <ButtonsAction
          claseCelda={claseCelda}
          botones={[
            {
              tooltip: "Eliminar Campo",
              texto: "",
              funcion: (e) => {
                seteditado(true);
                settabActual({
                  ...tabActual,
                  campos: tabActual.campos.filter(
                    (campo) => campo.id !== itemHijo.id
                  ),
                });
              },
              disabled: false,
              Icon: DeleteForever,
              color: "error",
              id: 6,
              ocultar: false,
              tipo: "icono",
              estado: false,
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
          ]}
        />
        {/* las demas columnas */}
        <>
          <TableCell fontSize="small" align="center" className={claseCelda}>
            <TextField
              value={itemHijo.label}
              name="label"
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
            />
          </TableCell>
          <TableCell fontSize="small" align="center" className={claseCelda}>
            <TextField
              type="time"
              value={itemHijo.tipo}
              name="tipo"
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
              select
              InputLabelProps={{ shrink: true }}
              SelectProps={{ displayEmpty: true }}
            >
              <MenuItem value="">Seleccione.</MenuItem>
              {[
                "TEXTO",
                "NUMERICO",
                "SELECT",
                "MULTISELECT",
                "ARCHIVO",
                "IMAGEN",
              ].map((item) => {
                return (
                  <MenuItem key={item} value={item}>
                    {item}{" "}
                  </MenuItem>
                );
              })}
            </TextField>
          </TableCell>
          <TableCell fontSize="small" align="center" className={claseCelda}>
            <TextField
              type="time"
              value={itemHijo.required}
              name="required"
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
              select
              InputLabelProps={{ shrink: true }}
              SelectProps={{ displayEmpty: true }}
            >
              <MenuItem value="">Seleccione.</MenuItem>
              {["SI", "NO"].map((item) => {
                return <MenuItem value={item}>{item} </MenuItem>;
              })}
            </TextField>
          </TableCell>
          <TableCell fontSize="small" align="center" className={claseCelda}>
            {(itemHijo.tipo === "SELECT" ||
              itemHijo.tipo === "MULTISELECT") && (
              <>
                <form onSubmit={onNewOptionSubmit}>
                  <TextField
                    inputRef={inputRef}
                    autoComplete="false"
                    value={newOption.value}
                    placeholder="Ingrese una nueva opcion aqui"
                    onChange={(e) =>
                      setnewOption({ id: newOption.id, value: e.target.value })
                    }
                    size="small"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          {editar && (
                            <IconButton
                              onClick={(e) => {
                                seteditar(false);
                                setnewOption({
                                  id: "",
                                  value: "",
                                });
                              }}
                            >
                              <Cancel color="error" />
                            </IconButton>
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </form>
                {itemHijo.opciones.length > 0 && (
                  <Badge
                    badgeContent={itemHijo.opciones.length}
                    color={"secondary"}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <IconButton onClick={() => setexpandir(!expandir)}>
                      {expandir ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                  </Badge>
                )}

                {itemHijo.opciones.map((opcion) => (
                  <Collapse
                    key={opcion.id}
                    in={expandir}
                    timeout="auto"
                    unmountOnExit
                  >
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Typography
                        key={opcion.id}
                        variant="body1"
                        color={
                          newOption.id === opcion.id
                            ? "ButtonText"
                            : "secondary"
                        }
                      >
                        {opcion.value}
                      </Typography>
                      <Box>
                        <IconButton
                          onClick={() => {
                            inputRef.current.select();
                            seteditar(true);
                            setnewOption(opcion);
                          }}
                        >
                          <Create color="secondary" fontSize="small" />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            seteditado(true);
                            settabActual({
                              ...tabActual,
                              campos: tabActual.campos.map((campo) => {
                                if (campo.id === itemHijo.id) {
                                  return {
                                    ...itemHijo,
                                    opciones: itemHijo.opciones.filter(
                                      (opcionF) => opcionF.id !== opcion.id
                                    ),
                                  };
                                }
                                return campo;
                              }),
                            });
                          }}
                        >
                          <Delete color="error" fontSize="small" />
                        </IconButton>
                      </Box>
                    </Box>
                    <Divider />
                  </Collapse>
                ))}
              </>
            )}
          </TableCell>
        </>
      </TableRow>
    </React.Fragment>
  );
};

export default memo(RowAgregar);
