import React, { memo, useEffect } from "react";
import { useState } from "react";
import { withRouter } from "react-router";
import ConfirmacionDialog from "../../../../../components/Extra/ConfirmacionDialog";
import ButtonsAction from "./ButtonsAction";

// import clienteAxios from "../../../../../config/axios";

// MATERIAL
import { makeStyles } from "@mui/styles";
import {
  Autocomplete,
  FormControl,
  Popper,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import clienteAxios from "../../../../../config/axios";
import AlertaContext from "../../../../../Context/Alerta/AlertaContext";
import { useContext } from "react";
import { trimPropsItem } from "../../../../../config/const";

const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  celda: {
    fontSize: ".7rem !important",
    "&:hover": {
      opacity: ".7",
      cursor: "pointer",
    },
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));

const RowArma = (props) => {
  const {
    row,
    index,
    campoExport,
    item,
    setitem,
    deshabilitarbotones,
    handleDragStart,
    handleDragEnter,
    handleDragEnd,
  } = props;
  const [itemRow, setItemRow] = useState(row);
  const { mostrarAlerta } = useContext(AlertaContext);
  const [medicinasData, setmedicinasData] = useState([]);
  const PopperMy = function(props) {
    return (
      <Popper {...props} style={useRowStyles.popper} placement="bottom-start" />
    );
  };
  useEffect(() => {
    if (row.nuevo) {
      seteditar(true);
    }
    // eslint-disable-next-line
  }, [row]);
  const classes = useRowStyles();
  const handleChange = (e) => {
    setItemRow({ ...itemRow, [e.target.name]: e.target.value });
  };
  const handleEliminar = async (_id) => {
    setitem({
      ...item,
      recMedicamentos: {
        arrayMedicamentos: item.recMedicamentos.arrayMedicamentos.filter(
          (item, itemIndex) => {
            return item.id !== itemRow.id;
          }
        ),
      },
    });
  };
  const funcionEdit = () => {
    const recMedicamentos = {
      arrayMedicamentos: item.recMedicamentos.arrayMedicamentos.map(
        (item, itemIndex) => {
          const { nuevo, ...rest } = row;
          if (item.id === row.id) {
            return trimPropsItem({ ...rest, order: row.order, editar: true });
          } else {
            return item;
          }
        }
      ),
    };
    setItemRow(row);
    setitem({
      ...item,
      recMedicamentos,
    });
    seteditar(true);
  };
  const [editar, seteditar] = useState(false);
  const funcCancel = () => {
    const recMedicamentos = {
      arrayMedicamentos: item.recMedicamentos.arrayMedicamentos.map(
        (item, itemIndex) => {
          const { nuevo, ...rest } = itemRow;
          if (item.id === itemRow.id) {
            return trimPropsItem({ ...rest, order: row.order, editar: false });
          } else {
            return item;
          }
        }
      ),
    };
    setitem({
      ...item,
      recMedicamentos,
    });
    setItemRow(row);
    seteditar(false);
  };
  const botonDisabled = () => {
    if (
      itemRow.medicina.trim() === "" ||
      itemRow.cantidad.trim() === "" ||
      itemRow.tratamiento.trim() === ""
    ) {
      return true;
    } else {
      return false;
    }
  };
  const funcSubmit = () => {
    const recMedicamentos = {
      arrayMedicamentos: item.recMedicamentos.arrayMedicamentos.map(
        (item, itemIndex) => {
          const { nuevo, ...rest } = itemRow;
          if (item.id === itemRow.id) {
            return trimPropsItem({ ...rest, order: row.order, editar: false });
          } else {
            return item;
          }
        }
      ),
    };
    setitem({
      ...item,
      recMedicamentos,
    });
    seteditar(false);
  };
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);

  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        titulo="¿Esta seguro que quiere eliminar este Registro?"
        _id={itemRow._id}
        funcion={handleEliminar}
      />
      <TableRow
        className={classes.root}
        selected={index % 2 === 0}
        component={"form"}
        onDragStart={() => handleDragStart(index)}
        onDragEnter={() => handleDragEnter(index)}
        onDragEnd={(e) => handleDragEnd(e, index)}
        onDragOver={(e) => e.preventDefault()}
        draggable
      >
        {/* boton */}
        <ButtonsAction
          setitem={setitem}
          index={index}
          itemRow={itemRow}
          row={row}
          item={item}
          setopenConfirmDialog={setopenConfirmDialog}
          funcionEdit={funcionEdit}
          ocultarEditar={false}
          ocultarDelete={false}
          mostrarEstado={false}
          campoExport={campoExport}
          campoEstado="estado"
          funcCancel={funcCancel}
          funcSubmit={funcSubmit}
          editar={editar}
          deshabilitarbotones={deshabilitarbotones}
          botonDisabled={botonDisabled}
          handleEliminar={handleEliminar}
        />
        {editar ? (
          <>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              <FormControl fullWidth>
                <Autocomplete
                  filterOptions={(x) => x}
                  PopperComponent={PopperMy}
                  options={medicinasData.map((item) => {
                    return item.invNombre + "/+/" + item.invId;
                  })}
                  getOptionLabel={(option) => {
                    return option.split("/+/")[0];
                  }}
                  id="controlled-demo"
                  value={itemRow.medicina}
                  paperprops
                  onChange={async (event, newValue) => {
                    if (newValue) {
                      const options = newValue.split("/+/");
                      const itemF = medicinasData.find((itemPersona) => {
                        return itemPersona.invId === Number(options[1]);
                      });

                      setItemRow({
                        ...itemRow,
                        serie: itemF.invSerie,
                        medicina: itemF.invNombre,
                      });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      // error={error.recMascNombre}

                      fullWidth
                      error={row.error}
                      onChange={async (e) => {
                        if (e.target.value === "") {
                          return;
                        }
                        try {
                          setItemRow({
                            ...itemRow,
                            medicina: e.target.value,
                          });
                          const res = await clienteAxios.get(
                            `/inventario/filtro/0?search=${e.target.value}&tipo=`
                          );

                          setmedicinasData(res.data.data);
                        } catch (error) {
                          mostrarAlerta("Hubo un error", "error");
                        }
                      }}
                      // InputProps={{
                      //   ...params.InputProps,
                      //   endAdornment: (
                      //     <React.Fragment>
                      //       {!editar ? (
                      //         <IconButton
                      //           size="small"
                      //           aria-label=""
                      //           onClick={async () => {
                      //             seteditarProveedor(false);
                      //             setopenProveedor(true);
                      //           }}
                      //         >
                      //           <AddCircleOutlineTwoTone color="primary" />
                      //         </IconButton>
                      //       ) : null}
                      //       {params.InputProps.endAdornment}
                      //     </React.Fragment>
                      //   ),
                      // }}
                    />
                  )}
                />
              </FormControl>
              {/* <TextField
              name="medicina"
              value={itemRow.medicina}
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
            /> */}
              {itemRow.editar && "editar"}
            </TableCell>
            <TableCell
              padding="checkbox"
              size="small"
              align="center"
              className={classes.celda}
            >
              <TextField
                type={"number"}
                name="cantidad"
                value={itemRow.cantidad}
                error={row.error}
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
              />
            </TableCell>{" "}
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              <TextField
                name="tratamiento"
                value={itemRow.tratamiento}
                error={row.error}
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
              />
            </TableCell>
          </>
        ) : (
          <>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              {row.medicina}
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              {row.cantidad}
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              {row.tratamiento}
            </TableCell>
          </>
        )}
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(RowArma));
