import React from "react";

// COMPONENTES
// import guardiaPng from "../../../assets/img/guardiaPng.png";
// import sigeopLogo from "../../../assets/img/sigeoplogo.png";

import { IconButton, Toolbar, Box } from "@mui/material";

import { Menu } from "@mui/icons-material";
import NotificactionsButtons from "./NotificactionsButtons";
import themeF from "../../../config/themeUser";
import AuthContext from "../../../Context/Auth/AuthContext";
import { useContext } from "react";
const NavbarDesktop = (props) => {
  const { handleDrawerToggle } = props;
  const { usuario } = useContext(AuthContext);
  const theme = themeF(usuario);
  return (
    <Toolbar
      sx={{
        background: `linear-gradient(to left, ${theme.palette.primary.main} 0%, ${theme.palette.primary.light} 20%, ${theme.palette.secondary.light} 40%,  ${theme.palette.secondary.main} 75%, ${theme.palette.secondary.dark} 100%)`,
      }}
    >
      <IconButton
        color="primary"
        aria-label="open drawer"
        edge="start"
        onClick={() => handleDrawerToggle()}
        sx={{ mr: 2, color: "white" }}
      >
        <Menu sx={{ color: "white" }} />
      </IconButton>

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Box display="flex" justifyContent={"center"} alignItems="center"></Box>
        <Box display="flex" justifyContent={"center"} alignItems="center">
          <NotificactionsButtons />
        </Box>
      </Box>
    </Toolbar>
  );
};

export default NavbarDesktop;
