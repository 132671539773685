import React, { memo, useContext } from "react";

import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

import { useState } from "react";

import { withRouter } from "react-router";

import AuthContext from "../../../../Context/Auth/AuthContext";
import {
  AddCircle,
  Cancel,
  Check,
  Create,
  DeleteForever,
  ExpandLess,
  ExpandMore,
  Restore,
} from "@mui/icons-material";

import RowAgregarItem from "../Componentes/RowItems/RowAgregarItem";
import RowItem from "../Componentes/RowItems/RowItem";
import ConfirmacionDialog from "../../../components/Dialogs/ConfirmacionDialog";
import ButtonsAction from "../../../components/Tablas/ButtonsAction";
import TablaCabecera from "../../../components/Tablas/TablaCabecera";
import { trimPropsItem } from "../../../../config/const";
import { usePermiso } from "../../../../hooks/usePermiso";
import {
  useRowStyles,
  butonIconTransition,
} from "../../../../styles/stylesRows";
const Row = (props) => {
  // CLASES
  const classes = useRowStyles();

  // PROPS
  const {
    arrayExport,
    setarrayExport,
    row,
    index,
    campoExport,
    socket,
  } = props;
  const claseCelda = `${
    index % 2 === 0 ? classes.celda : classes.celdaSecundario
  } ${
    row.editado === true
      ? classes.celdaEditado
      : row.nuevo === true
      ? classes.celdaNuevo
      : row.eliminado === true
      ? classes.celdaEliminado
      : ""
  }`;
  // EDITAR
  const [editar, seteditar] = useState(false);
  // HOOK DE PERMISO
  const { tienePermiso, alertaPermiso } = usePermiso("Servicios_Visita");
  // CONTEXT
  const { usuario } = useContext(AuthContext);
  // ABRE EL DIALOG DE CONFIRMACION
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  // SI SE AGREGA UN ITEM A LA TABLA HIJA
  const [agregarItemLista, setagregarItemLista] = useState(false);

  // EXPANDIR
  const [expandir, setexpandir] = useState(false);
  // COPIA DEL REGISTRO PARA EDITAR
  const [item, setitem] = useState({ ...row });
  // COLUMNAS DE LA TABLA HIJA
  const columnasHijo = [
    {
      id: "nombre",
      align: "left",
      minWidth: 150,
      tipo: "string",
      label: "nombre",
    },
  ];
  //onChange
  const handleChange = (e) => {
    setitem({ ...item, [e.target.name]: e.target.value });
  };
  // DESHABILITAR BOTON
  const botonDisabled = () => {
    if (item.nombre.trim() === "") {
      return true;
    }
  };
  // ELIMINAR EL REGISTRO
  const handleEliminar = async (_id) => {
    try {
      socket.current.emit("client:eliminarData", {
        tabla: "servicio_visita",
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        usuario: usuario.usuario,
        empresa: usuario.rucempresa,
        [campoExport]: row[campoExport],
      });
      setopenConfirmDialog(false);
    } catch (error) {}
  };
  const funcSubmit = (agregando) => {
    try {
      if (agregando) {
        socket.current.emit(
          "client:guardarData",
          trimPropsItem({
            ...row,

            tabla: "servicio_visita",
            rucempresa: usuario.rucempresa,
            rol: usuario.rol,
            usuario: usuario.usuario + "/+/" + row[campoExport],
            empresa: usuario.rucempresa,
            [campoExport]: row[campoExport],
          })
        );
      } else {
        socket.current.emit(
          "client:actualizarData",
          trimPropsItem({
            ...item,
            tabla: "servicio_visita",
            rucempresa: usuario.rucempresa,
            rol: usuario.rol,
            usuario: usuario.usuario,
            empresa: usuario.rucempresa,
          })
        );
      }

      seteditar(false);
    } catch (error) {}
  };

  // FUNCION PARA EDITAR, ABRE EL MODAL COLOCA EN EDITAR EL MODAL Y CAMBIA EL OBJETO ACTIVO
  const funcionEdit = () => {
    setitem({ ...row });
    seteditar(true);
  };

  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        categoria="warning"
        titulo={`¿Esta seguro de eliminar este registro?`}
        botones={[
          {
            tooltip: "Se eliminaran los registros",
            texto: "SI",
            funcion: () => {
              handleEliminar();
            },
            disabled: false,
            Icon: DeleteForever,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenConfirmDialog(false);
            },
            disabled: false,
            Icon: Cancel,
            color: "secondary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <TableRow className={classes.root} component={"form"}>
        {/* boton */}
        <ButtonsAction
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          campoExport={campoExport}
          row={row}
          claseCelda={claseCelda}
          botones={[
            {
              tooltip: "Recuperar",
              texto: "",
              funcion: () => {
                if (!tienePermiso("agregar")) {
                  return alertaPermiso("agregar");
                }
                funcSubmit(true);
              },
              disabled: usuario.usuario !== row.usuario.split("/+/")[0],
              Icon: Restore,
              color: "success",
              id: 3,
              ocultar: !row.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Eliminar Registro",
              texto: "",
              funcion: () => {
                if (!tienePermiso("eliminar")) {
                  return alertaPermiso("eliminar");
                }
                setopenConfirmDialog(true);
              },
              disabled: false,
              Icon: DeleteForever,
              color: "error",
              id: 1,
              ocultar: editar || row.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              // css
              sx: butonIconTransition,
            },
            {
              tooltip: "Editar Registro",
              texto: "",
              funcion: () => {
                if (!tienePermiso("editar")) {
                  return alertaPermiso("editar");
                }
                funcionEdit();
              },
              disabled: false,
              Icon: Create,
              color: "secondary",
              id: 2,
              ocultar: editar || row.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Cancelar",
              texto: "",
              funcion: () => {
                seteditar(false);
                setitem({ ...row });
                // setItemEdit(itemHijo);
              },
              disabled: false,
              Icon: Cancel,
              color: "error",
              id: 1,
              ocultar: !editar,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Guardar Edición",
              texto: "",
              funcion: () => {
                funcSubmit();
              },
              disabled: botonDisabled(),
              Icon: Check,
              color: "success",
              id: 2,
              ocultar: !editar,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
              submit: true,
            },
          ]}
        />

        {editar ? (
          <>
            <TableCell size="small" align="center" className={claseCelda}>
              <TextField
                value={item.nombre}
                name="nombre"
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
              />
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              {item.usuario}
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              {new Date(item.fecReg).toLocaleString("es-ES", {
                weekday: "short",
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour: "2-digit",
              })}
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              {new Date(item.fecUpd).toLocaleString("es-ES", {
                weekday: "short",
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour: "2-digit",
              })}
            </TableCell>
          </>
        ) : (
          <>
            <TableCell size="small" align="center" className={claseCelda}>
              {row.nombre}
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              {row.usuario}
            </TableCell>{" "}
            <TableCell size="small" align="center" className={claseCelda}>
              {new Date(row.fecReg).toLocaleString("es-ES", {
                weekday: "short",
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour: "2-digit",
              })}
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              {new Date(row.fecUpd).toLocaleString("es-ES", {
                weekday: "short",
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour: "2-digit",
              })}
            </TableCell>
          </>
        )}
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
