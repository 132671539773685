import React, { memo, useContext } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  IconButton,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";

import { Cancel, Check } from "@mui/icons-material";

import { useState } from "react";

import AuthContext from "../../../../Context/Auth/AuthContext";
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import { trimPropsItem } from "../../../../config/const";
import { useTheme } from "@emotion/react";
const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  margin: {
    margin: "0px",
    padding: "2px",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
  celda: {
    fontSize: ".7rem !important",
  },
}));

const RowAgregar = (props) => {
  const { setagregar, socket } = props;
  const { mostrarAlerta } = useContext(AlertaContext);
  const [item, setitem] = useState({
    tiporesNombre: "",
    tiporesHoraDesde: "",
    tiporesHoraHasta: "",
    tiporesDias: [],
    tiporesUser: "",
    tiporesEmpresa: "",
    tiporesFecReg: "",
    Todos: "",
  });
  const classes = useRowStyles();
  //gql eliminar user

  const { usuario } = useContext(AuthContext);
  const handleAgregar = async () => {
    try {
      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...item,
          tabla: "tiporeserva",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          tiporesUser: usuario.usuario,
          tiporesEmpresa: usuario.rucempresa,
        })
      );
    } catch (error) {
      mostrarAlerta("Hubo un error");
       
    }
  };
  const handleChange = (e) => {
    setitem({
      ...item,
      [e.target.name]: e.target.value,
    });
  };

  const botonDisabled = () => {
    if (
      item.tiporesNombre.trim() === "" ||
      item.tiporesHoraDesde.trim() === "" ||
      item.tiporesHoraHasta.trim() === "" ||
      item.tiporesDias.length === 0
    ) {
      return true;
    } else {
      return false;
    }
  };
  const theme = useTheme();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  function getStylesMenuItems(name, permisos, theme) {
    return {
      fontWeight:
        permisos.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
       backgroundColor: permisos.indexOf(name) === -1 ? "white" : theme.palette.primary.main,
    };
  }
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell></TableCell> {/* boton */}
        <TableCell padding="none" style={{ borderRight: "1px solid #ccc" }}>
          <Box display="flex" alignItems="center">
            <IconButton
              className={classes.margin}
              onClick={() => {
                setagregar(false);
                setitem({
                  tiporesNombre: "",
                  tiporesHoraDesde: "",
                  tiporesHoraHasta: "",
                  tiporesDias: [
                    // multiselect
                  ],
                  tiporesUser: "",
                  tiporesEmpresa: "",
                  tiporesFecReg: "",
                  Todos: "",
                });
              }}
            >
              <Cancel fontSize="small" color="secondary" />
            </IconButton>
            <IconButton
              color={
                botonDisabled
                  ? botonDisabled()
                    ? "inherit"
                    : "secondary"
                  : "secondary"
              }
              disabled={botonDisabled()}
              className={classes.margin}
              onClick={() => {
                handleAgregar();

                setagregar(false);
                setitem({
                  tiporesNombre: "",
                  tiporesHoraDesde: "",
                  tiporesHoraHasta: "",
                  tiporesDias: [
                    // multiselect
                  ],
                  tiporesUser: "",
                  tiporesEmpresa: "",
                  tiporesFecReg: "",
                  Todos: "",
                });
              }}
            >
              <Check fontSize="small" />
            </IconButton>
          </Box>
        </TableCell>
        {/* las demas columnas */}
        <>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            <TextField
              value={item.tiporesNombre}
              name="tiporesNombre"
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
            />
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            <TextField
              type="time"
              value={item.tiporesHoraDesde}
              name="tiporesHoraDesde"
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
            />
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            <TextField
              type="time"
              value={item.tiporesHoraHasta}
              name="tiporesHoraHasta"
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
            />
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            <TextField
              type="time"
              value={item.tiporesDias}
              name="tiporesDias"
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
              select
              MenuProps={MenuProps}
              SelectProps={{
                displayEmpty: true,
                multiple: true,
                renderValue: (selected) => {
                  return selected
                    .map((value, index) => {
                      return value;
                    })
                    .join(", ");
                },
              }}
            >
              <MenuItem disabled value="">
                Seleccione.
              </MenuItem>
              {[
                "LUNES",
                "MARTES",
                "MIERCOLES",
                "JUEVES",
                "VIERNES",
                "SABADO",
                "DOMINGO",
              ].map((dia) => {
                return (
                  <MenuItem
                    style={getStylesMenuItems(dia, item.tiporesDias, theme)}
                    value={dia}
                  >
                    {dia}
                  </MenuItem>
                );
              })}
            </TextField>
          </TableCell>
        </>
      </TableRow>
    </React.Fragment>
  );
};

export default memo(RowAgregar);
