import { makeStyles } from "@mui/styles";
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  TableCell,
} from "@mui/material";
import {
  Cancel,
  Check,
  Create,
  Delete,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import React, { memo, useContext } from "react";
import { withRouter } from "react-router";
import AlertaContext from "../../../Context/Alerta/AlertaContext";
import { tienePermisoRuta } from "../../../config/validaciones";
import AuthContext from "../../../Context/Auth/AuthContext";
import MenuContext from "../../../Context/Menu/MenuContext";
import { permisoDenagado } from "../../../config/const";
const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  margin: {
    margin: "0px",
    padding: "2px",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));

const ButtonsAction = (props) => {
  const {
    arrayExport,
    setarrayExport,
    campoExport,
    row,
    setopenConfirmDialog,
    funcionEdit,
    ocultarEditar,
    ocultarDelete,
    mostrarEstado,
    campoEstado,
    editar,
    funcCancel,
    funcSubmit,
    botonDisabled,
    expandir,
    setexpandir,
    puedeExportar,
    desdeOtroLugar,
  } = props;
  const classes = useRowStyles();
  const { usuario } = useContext(AuthContext);
  const { rows } = useContext(MenuContext);
  const { mostrarAlerta } = useContext(AlertaContext);

  return (
    <>
      {arrayExport ? (
        <TableCell
          size="small"
          padding="checkbox"
          style={{
            borderRight: "1px solid #ccc",
            maxWidth: "1rem",
          }}
        >
          <Box display="flex" alignItems="center" justifyContent="center">
            <FormControlLabel
              className={classes.margin}
              control={
                <Checkbox
                  size="small"
                  disabled={!puedeExportar && desdeOtroLugar}
                  className={classes.margin}
                  checked={arrayExport.some((item) => {
                    return item[campoExport] === row[campoExport];
                  })}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setarrayExport([
                        ...arrayExport,
                        { [campoExport]: row[campoExport] },
                      ]);
                    } else {
                      setarrayExport(
                        arrayExport.filter(
                          (item) => item[campoExport] !== row[campoExport]
                        )
                      );
                    }
                  }}
                  color="secondary"
                />
              }
            />
          </Box>
        </TableCell>
      ) : null}
      <TableCell padding="none" style={{ borderRight: "1px solid #ccc" }}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-around"
          ml={1}
          mr={1}
        >
          <>
            {editar ? (
              <>
                <IconButton
                  className={classes.margin}
                  onClick={() => {
                    funcCancel();
                  }}
                >
                  <Cancel fontSize="small" color="secondary" />
                </IconButton>
                <IconButton
                  className={classes.margin}
                  disabled={botonDisabled ? botonDisabled() : false}
                  onClick={() => {
                    funcSubmit();
                  }}
                >
                  <Check
                    fontSize="small"
                    color={
                      botonDisabled
                        ? botonDisabled()
                          ? "inherit"
                          : "secondary"
                        : "secondary"
                    }
                  />
                </IconButton>
              </>
            ) : (
              <>
                {ocultarDelete ? null : (
                  <IconButton
                    className={classes.margin}
                    onClick={() => {
                      if (
                        !tienePermisoRuta(
                          rows,
                          props.location.pathname,
                          usuario.rol,
                          "eliminar"
                        )
                      ) {
                        return mostrarAlerta(permisoDenagado, "error");
                      }
                      setopenConfirmDialog(true);
                    }}
                  >
                    <Delete fontSize="small" color="error" />
                  </IconButton>
                )}
                {ocultarEditar ? null : (
                  <IconButton
                    className={classes.margin}
                    onClick={() => {
                      if (
                        !tienePermisoRuta(
                          rows,
                          props.location.pathname,
                          usuario.rol,
                          "editar"
                        )
                      ) {
                        return mostrarAlerta(permisoDenagado, "error");
                      }
                      funcionEdit();
                    }}
                  >
                    <Create color="secondary" />
                  </IconButton>
                )}
              </>
            )}
          </>
          {mostrarEstado ? (
            <FormControlLabel
              disabled
              className={classes.margin}
              control={
                <Checkbox
                  value="ACTIVO"
                  className={classes.margin}
                  checked={
                    row[campoEstado] === "ACTIVA" ||
                    row[campoEstado] === "ACTIVO"
                      ? true
                      : false
                  }
                  color="primary"
                />
              }
            />
          ) : null}
          {expandir ? (
            <IconButton
              className={classes.margin}
              onClick={() => {
                setexpandir(false);
              }}
            >
              <ExpandLess color="secondary" />
            </IconButton>
          ) : (
            <IconButton
              className={classes.margin}
              onClick={() => {
                setexpandir(true);
              }}
            >
              <ExpandMore color="secondary" />
            </IconButton>
          )}
        </Box>
      </TableCell>
    </>
  );
};

export default withRouter(memo(ButtonsAction));
