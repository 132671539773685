import React, { memo, useContext, useEffect, createRef } from "react";
import { makeStyles } from "@mui/styles";
import {
  TableCell,
  TableRow,
  TextField,
  FormControl,
  Popper,
  IconButton,
  MenuItem,
  Autocomplete,
  Typography,
} from "@mui/material";

import { useState } from "react";

// import ConfirmacionDialog from "../../Dialog/ConfirmacionDialog";

import { withRouter } from "react-router";
import ButtonsAction from "./ButtonsAction";

import AlertaContext from "../../../../../Context/Alerta/AlertaContext";
import AuthContext from "../../../../../Context/Auth/AuthContext";
import clienteAxios from "../../../../../config/axios";
import { AddCircleOutlineTwoTone, Create } from "@mui/icons-material";
import { trimPropsItem } from "../../../../../config/const";
import ConfirmacionDialog from "../../../../../components/Extra/ConfirmacionDialog";
import { calcularProducto } from "../helpers";
import { calcularTotales } from "../helpers/calcularTotales";

const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  celda: {
    fontSize: ".7rem !important",
    "&:hover": {
      opacity: ".7",
      cursor: "pointer",
    },
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));

const RowBodega = (props) => {
  const { mostrarAlerta } = useContext(AlertaContext);
  const { usuario } = useContext(AuthContext);
  const {
    row,
    index,
    campoExport,
    item,
    setitem,
    deshabilitarbotones,
    setopenInventario,
    desdePeluqueria,
    subcategoriasData,
    seteditarInventario,
    changeObjetoActivoStateInventario,
    socket,
    handleDragStart,
    handleDragEnter,
    handleDragEnd,
    venProductos,
    formPorcentaje = 0,
  } = props;
  const [itemRow, setitemRow] = useState(row);
  const [productosData, setproductosData] = useState([]);
  const productoDefault = {
    invSerie: "0",
    invTipo: "",
    invNombre: "",
    invDescripcion: "",
    invCosto1: "",
    invCosto2: "",
    invCosto3: "",
    invStock: "",
    invIva: "SI",
    invIncluyeIva: "SI",
    invEstado: "ACTIVA",
    invBodegas: [],
    invprecios: [],
    invProveedor: "",
    invCategoria: "",
    invSubCategoria: "",
    invUnidad: "",
    invMarca: "",
    invSubsidio: "0",
    invPlanCuenta: "",
    invEmpresa: "",
    invUser: "admin",
    invFecReg: "",
    Todos: "",
  };
  const [productoSelect, setproductoSelect] = useState(productoDefault);
  useEffect(() => {
    socket.current.on("server:actualizadoExitoso", (data) => {
      if (data.tabla === "inventario" && data.invUser === usuario.usuario) {
        setproductoSelect(data);
        setproductosData((prev) =>
          prev.map((itemPrev) => {
            if (itemPrev.envId === data.envId) {
              return data;
            } else {
              return itemPrev;
            }
          })
        );
      }
    });

    // eslint-disable-next-line
  }, []);
  const inputEl = createRef();

  const focusInput = () => {
    inputEl.current.focus();
  };
  const [producto, setproducto] = useState({
    invSerie: "",
    invTipo: "",
    invNombre: "",
    invDescripcion: "",
    invCosto1: "",
    invCosto2: "",
    invCosto3: "",
    invStock: "",
    invIva: "",
    invIncluyeIva: "",
    invEstado: "",
    invBodegas: [],
    invprecios: [],
    invProveedor: "",
    invCategoria: "",
    invSubCategoria: "",
    invUnidad: "",
    invMarca: "",
    invSubsidio: "",
    invPlanCuenta: "",
    invEmpresa: "",
    invUser: "",
    invFecReg: "",
  });
  const PopperMy = function(props) {
    return (
      <Popper {...props} style={useRowStyles.popper} placement="bottom-start" />
    );
  };

  useEffect(() => {
    setitemRow(row);
    // eslint-disable-next-line
  }, [item]);
  const classes = useRowStyles();
  const handleEliminar = async (_id) => {
    const venProductos = item.venProductos.filter((item, itemIndex) => {
      return index !== itemIndex;
    });
    const {
      venSubTotal12,
      venSubtotal0,
      venDescuento,
      venSubTotal,
      venTotalIva,
      venTotal,
      venCostoProduccion,
    } = calcularTotales(venProductos);
    setitem({
      ...item,
      venProductos,
      venSubTotal12,
      venSubtotal0,
      venDescuento,
      venSubTotal,
      venTotalIva,
      venTotal,
      venCostoProduccion,
    });
  };
  const funcionEdit = () => {
    seteditar(true);
  };
  const [editar, seteditar] = useState(false);

  // useEffect(() => {
  //   socket.current.on("server:guardadoExitoso", (data) => {
  //     if (objetoGuardadoInventario && editar) {

  //       changeGuardadoInventario(false);

  //       setitemRow({
  //         ...itemRow,
  //         descripcion: data.invNombre,
  //         codigo: data.invSerie,
  //         incluyeIva: data.invIncluyeIva,
  //         llevaIva: data.invIva,
  //         valorUnitario: data.invprecios[0],
  //         valUnitarioInterno: data.invprecios[0],
  //       });
  //     }
  //   });

  //   // eslint-disable-next-line
  // }, [editar, objetoGuardadoInventario]);

  const funcCancel = () => {
    setitemRow(row);
    seteditar(false);
  };
  const botonDisabled = () => {
    if (
      itemRow.descripcion === "" ||
      itemRow.cantidad === "" ||
      itemRow.valUnitarioInterno === "" ||
      itemRow.descPorcentaje === ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  const funcSubmit = () => {
    try {
      const venProductos = item.venProductos
        .map((item, itemIndex) => {
          if (index === itemIndex) {
            return trimPropsItem(itemRow);
          } else {
            return item;
          }
        })
        .map((productoMap) => {
          return calcularProducto({
            producto: productoMap,
            formPorcentaje,
            iva: usuario.iva,
          });
        });
      const {
        venSubTotal12,
        venSubtotal0,
        venDescuento,
        venSubTotal,
        venTotalIva,
        venTotal,
        venCostoProduccion,
      } = calcularTotales(venProductos);
      setitem({
        ...item,
        venProductos,
        venSubTotal12,
        venSubtotal0,
        venDescuento,
        venSubTotal,
        venTotalIva,
        venTotal,
        venCostoProduccion,
      });
    } catch (error) {}
    seteditar(false);
  };
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);

  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        titulo="¿Esta seguro que quiere eliminar este Producto?"
        funcion={handleEliminar}
      />

      <TableRow
        className={classes.root}
        selected={index % 2 === 0}
        component={"form"}
        onDragStart={() => handleDragStart(index)}
        onDragEnter={() => handleDragEnter(index)}
        onDragEnd={(e) =>
          handleDragEnd("venProductos", venProductos, item, setitem)
        }
        onDragOver={(e) => e.preventDefault()}
        draggable
      >
        {/* boton */}
        <ButtonsAction
          itemRow={itemRow}
          setopenConfirmDialog={setopenConfirmDialog}
          funcionEdit={funcionEdit}
          ocultarEditar={usuario.rucempresa === "TE2021"}
          ocultarDelete={false}
          mostrarEstado={false}
          campoExport={campoExport}
          campoEstado="empEstado"
          funcCancel={funcCancel}
          funcSubmit={funcSubmit}
          editar={editar}
          deshabilitarbotones={deshabilitarbotones}
          botonDisabled={botonDisabled}
        />{" "}
        {desdePeluqueria || item.venObservacion.includes("DESDEPELUQUERIA") ? (
          <>
            <TableCell
              padding="checkbox"
              size="small"
              align="center"
              className={classes.celda}
            >
              {editar ? (
                <TableCell
                  padding="checkbox"
                  size="small"
                  align="center"
                  className={classes.celda}
                >
                  <FormControl fullWidth>
                    <Autocomplete
                      PopperComponent={PopperMy}
                      options={subcategoriasData.map((item) => {
                        return item.subNombre;
                      })}
                      props
                      getOptionLabel={(option) => {
                        return option;
                      }}
                      id="controlled-demo"
                      disableClearable={true}
                      value={itemRow.subcategoria}
                      onChange={async (event, newValue) => {
                        if (newValue) {
                          setitemRow({
                            ...itemRow,
                            subcategoria: newValue,
                            limiteStock: false,
                            stock: 0,
                            cantidad: "1",
                            codigo: "",
                            descripcion: "",
                            valUnitarioInterno: "",
                            valorUnitario: "",
                            llevaIva: "SI",
                            incluyeIva: "NO",
                          });
                          setproductosData([]);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          SelectProps={{
                            MenuProps: {
                              PaperProps: {
                                style: {
                                  fontSize: "1px",
                                  maxHeight: 48 * 4.5 + 8,
                                  width: 5000,
                                },
                              },
                            },
                          }}
                          style={{ fontSize: "2px" }}
                          required
                          fullWidth
                          InputProps={{
                            ...params.InputProps,
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </TableCell>
              ) : (
                itemRow.subcategoria
              )}
            </TableCell>
          </>
        ) : null}
        <TableCell
          padding="checkbox"
          size="small"
          align="center"
          className={classes.celda}
        >
          {editar ? (
            <FormControl fullWidth>
              <Autocomplete
                PopperComponent={PopperMy}
                options={productosData.map((item) => {
                  return (
                    item.invNombre +
                    "/+/" +
                    item.invSerie +
                    "/+/" +
                    item.invStock +
                    "/+/" +
                    item.invIncluyeIva +
                    "/+/" +
                    item.invTipo +
                    "/+/" +
                    item.invIva
                  );
                })}
                props
                getOptionLabel={(option) => {
                  return option
                    .replace("/+/", " ** ")
                    .replace("/+/", " ** ")
                    .split("/+/")[0];
                }}
                id="controlled-demo"
                disableClearable={true}
                value={itemRow.descripcion}
                onChange={async (event, newValue) => {
                  if (newValue) {
                    const options = newValue.split("/+/");
                    const productoF = productosData.find(
                      (productoItem) => productoItem.invSerie === options[1]
                    );
                    setproductoSelect(productoF);
                    if (
                      productoF.invTipo !== "SERVICIO" &&
                      Number(productoF.invStock) === 0
                    ) {
                      return mostrarAlerta(
                        `No hay stock de ${productoF.invNombre}`,
                        "error"
                      );
                    }
                    setproducto(productoF);
                    setitemRow({
                      ...itemRow,
                      limiteStock: productoF.invTipo !== "SERVICIO",
                      cantidad:
                        productoF.invTipo === "SERVICIO"
                          ? "1"
                          : Number(itemRow.cantidad) > Number(options[2])
                          ? options[2]
                          : itemRow.cantidad,
                      stock:
                        productoF.invTipo === "SERVICIO"
                          ? 1
                          : Number(options[2]),
                      descripcion: options[0],
                      codigo: options[1],
                      incluyeIva: options[3],
                      llevaIva: options[5],
                      valorUnitario: productoF.invprecios[0],
                      valUnitarioInterno: productoF.invprecios[0],
                    });
                    focusInput();
                  } else {
                    setitemRow({
                      ...itemRow,
                      descripcion: "",
                      codigo: "",
                      incluyeIva: "NO",
                      llevaIva: "",
                      valorUnitario: "",
                      valUnitarioInterno: "",
                    });
                    setproducto({
                      invSerie: "",
                      invTipo: "",
                      invNombre: "",
                      invDescripcion: "",
                      invCosto1: "",
                      invCosto2: "",
                      invCosto3: "",
                      invStock: "",
                      invIva: "",
                      invIncluyeIva: "",
                      invEstado: "",
                      invBodegas: [],
                      invprecios: [],
                      invProveedor: "",
                      invCategoria: "",
                      invSubCategoria: "",
                      invUnidad: "",
                      invMarca: "",
                      invSubsidio: "",
                      invPlanCuenta: "",
                      invEmpresa: "",
                      invUser: "",
                      invFecReg: "",
                    });
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    SelectProps={{
                      MenuProps: {
                        PaperProps: {
                          style: {
                            fontSize: "1px",
                            maxHeight: 48 * 4.5 + 8,
                            width: 5000,
                          },
                        },
                      },
                    }}
                    style={{ fontSize: "2px" }}
                    required
                    fullWidth
                    onChange={async (e) => {
                      if (e.target.value.length >= 301) {
                        setitemRow({
                          ...itemRow,
                          descripcion: e.target.value.slice(0, 300),
                        });
                      } else {
                        setitemRow({
                          ...itemRow,
                          descripcion: e.target.value,
                        });
                      }
                      try {
                        if (e.target.value.length < 3) {
                          return;
                        }
                        const res = await clienteAxios.get(
                          `/inventario/filtro/0?empresa=${
                            usuario.rucempresa
                          }&search=${e.target.value}&tipo=${
                            desdePeluqueria ||
                            item.venObservacion.includes("DESDEPELUQUERIA")
                              ? "PELUQUERIA"
                              : ""
                          }&subCategoria=${
                            desdePeluqueria ||
                            item.venObservacion.includes("DESDEPELUQUERIA")
                              ? itemRow.subcategoria
                              : ""
                          }`
                        );

                        // invDescripcion;

                        if (!res.data.data) {
                          return mostrarAlerta(res.data.msg, "error");
                        }

                        setproductosData(res.data.data);
                      } catch (error) {}
                    }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {" "}
                          <Typography
                            color={
                              itemRow.descripcion.length === 300
                                ? "error"
                                : "secondary"
                            }
                          >
                            {itemRow.descripcion.length}/300
                          </Typography>
                          <IconButton
                            size="small"
                            aria-label=""
                            onClick={async () => {
                              // seteditarProveedor(false);
                              setopenInventario(true);
                            }}
                          >
                            <AddCircleOutlineTwoTone color="primary" />
                          </IconButton>{" "}
                          {productoSelect.invSerie === "0" ? null : (
                            <IconButton
                              size="small"
                              aria-label=""
                              onClick={async () => {
                                changeObjetoActivoStateInventario(
                                  productoSelect
                                );
                                seteditarInventario(true);
                                setopenInventario(true);
                              }}
                            >
                              <Create color="primary" />
                            </IconButton>
                          )}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
          ) : (
            itemRow.descripcion
          )}
        </TableCell>{" "}
        <TableCell
          padding="checkbox"
          size="small"
          align="center"
          className={classes.celda}
        >
          {editar ? (
            <TextField
              id=""
              value={itemRow.codigo}
              inputProps={{ className: classes.input }}
              onChange={(e) =>
                setitemRow({
                  ...itemRow,
                  codigo: e.target.value,
                })
              }
              size="small"
              fullWidth
            />
          ) : (
            itemRow.codigo
          )}
        </TableCell>
        <TableCell
          padding="checkbox"
          size="small"
          align="center"
          className={classes.celda}
        >
          {editar ? (
            <TextField
              id=""
              inputProps={{ className: classes.input }}
              value={`${itemRow.incluyeIva}`}
              onChange={(e) =>
                setitemRow({
                  ...itemRow,
                  incluyeIva: e.target.value,
                })
              }
              size="small"
              fullWidth
              select
              InputLabelProps={{ shrink: true }}
              SelectProps={{ displayEmpty: true }}
            >
              <MenuItem value="">Seleccione</MenuItem>
              {["SI", "NO"].map((item) => {
                return (
                  <MenuItem key={item} value={item}>
                    {item}{" "}
                  </MenuItem>
                );
              })}
            </TextField>
          ) : (
            itemRow.incluyeIva
          )}
        </TableCell>
        <TableCell
          padding="checkbox"
          size="small"
          align="center"
          className={classes.celda}
        >
          {editar ? (
            <TextField
              id=""
              inputProps={{ className: classes.input }}
              value={`${itemRow.llevaIva}`}
              onChange={(e) =>
                setitemRow({
                  ...itemRow,
                  llevaIva: e.target.value,
                })
              }
              size="small"
              fullWidth
              select
              InputLabelProps={{ shrink: true }}
              SelectProps={{ displayEmpty: true }}
            >
              <MenuItem value="">Seleccione</MenuItem>
              {["SI", "NO"].map((item) => {
                return <MenuItem value={item}>{item} </MenuItem>;
              })}
            </TextField>
          ) : (
            itemRow.llevaIva
          )}
        </TableCell>{" "}
        <TableCell
          padding="checkbox"
          size="small"
          align="center"
          className={classes.celda}
        >
          {editar ? (
            <TextField
              id=""
              inputRef={inputEl}
              type="number"
              inputProps={{ className: classes.input }}
              value={itemRow.cantidad}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  if (botonDisabled()) {
                    return;
                  }
                  funcSubmit();
                }
              }}
              onChange={(e) =>
                setitemRow({
                  ...itemRow,
                  cantidad: !itemRow.limiteStock
                    ? e.target.value
                    : e.target.value > itemRow.stock
                    ? String(itemRow.stock)
                    : e.target.value,
                })
              }
              size="small"
              fullWidth
            />
          ) : (
            itemRow.cantidad
          )}
        </TableCell>{" "}
        <TableCell
          padding="checkbox"
          size="small"
          align="center"
          className={classes.celda}
        >
          {" "}
          {editar ? (
            <FormControl fullWidth>
              <Autocomplete
                PopperComponent={PopperMy}
                options={producto.invprecios.map((item) => {
                  return item;
                })}
                props
                getOptionLabel={(option) => {
                  return option;
                }}
                id="controlled-demo"
                disableClearable={true}
                value={itemRow.valorUnitario}
                onChange={async (event, newValue) => {
                  if (newValue) {
                    setitemRow({
                      ...itemRow,
                      valorUnitario: newValue,
                      valUnitarioInterno: newValue,
                    });
                  } else {
                    setitemRow({
                      ...itemRow,
                      valorUnitario: "",
                      valUnitarioInterno: "",
                    });
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    SelectProps={{
                      MenuProps: {
                        PaperProps: {
                          style: {
                            fontSize: "1px",
                            maxHeight: 48 * 4.5 + 8,
                            width: 5000,
                          },
                        },
                      },
                    }}
                    style={{ fontSize: "2px" }}
                    required
                    type="number"
                    fullWidth
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        if (botonDisabled()) {
                          return;
                        }
                        funcSubmit();
                      }
                    }}
                    onChange={async (e) => {
                      if (e.target.value === "") {
                        return mostrarAlerta("Ingrese un documento", "error");
                      }
                      try {
                        setitemRow({
                          ...itemRow,
                          valorUnitario: e.target.value,
                          valUnitarioInterno: e.target.value,
                        });
                      } catch (error) {}
                    }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {/* <IconButton
                      size="small"
                      aria-label=""
                      onClick={async () => {
                        // seteditarProveedor(false);
                        // setopenProveedor(true);
                      }}
                    >
                      <AddCircleOutlineTwoTone color="primary" />
                    </IconButton> */}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
          ) : (
            itemRow.valorUnitario
          )}
        </TableCell>
        <TableCell
          padding="checkbox"
          size="small"
          align="center"
          className={classes.celda}
        >
          {editar ? (
            <TextField
              id=""
              type="number"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  if (botonDisabled()) {
                    return;
                  }
                  funcSubmit();
                }
              }}
              inputProps={{ className: classes.input }}
              value={itemRow.descPorcentaje}
              onChange={(e) =>
                setitemRow({
                  ...itemRow,
                  descPorcentaje: e.target.value,
                })
              }
              size="small"
              fullWidth
            />
          ) : (
            itemRow.descPorcentaje
          )}
        </TableCell>{" "}
        <TableCell
          padding="checkbox"
          size="small"
          align="center"
          className={classes.celda}
        >
          {editar ? (
            <TextField
              id=""
              disabled
              type="number"
              inputProps={{ className: classes.input }}
              value={itemRow.precioSubTotalProducto}
              onChange={(e) =>
                setitemRow({
                  ...itemRow,
                  precioSubTotalProducto: e.target.value,
                })
              }
              size="small"
              fullWidth
            />
          ) : (
            <>
              {`${itemRow.precioSubTotalProducto}`}
            </>
          )}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(RowBodega));
