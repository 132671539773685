import { blueGrey, blue, red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";
const theme = createTheme({
  palette: {
    secondary: {
      main: blueGrey[900],
    },
    primary: {
      main: blue[500],
      dark: blue[800],
    },
    error: {
      main: red[400],
    },
  },
  typography: {
    fontFamily: ["Prompt"],
  },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: "standard",
      },
    },
  },
});

export default theme;
