import React, { memo, useContext, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { TableCell, TableRow, TextField } from "@mui/material";

import { useState } from "react";

// import ConfirmacionDialog from "../../Dialog/ConfirmacionDialog";

import { withRouter } from "react-router";
import ButtonsAction from "./ButtonsAction";

import ConfirmacionDialog from "../../../../components/Extra/ConfirmacionDialog";
import AlertaContext from "../../../../Context/Alerta/AlertaContext";

const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  celda: {
    fontSize: ".7rem !important",
    "&:hover": {
      opacity: ".7",
      cursor: "pointer",
    },
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));

const RowBodega = (props) => {
  const { row, index, campoExport, item, setitem, deshabilitarbotones } = props;
  const [itemRow, setitemRow] = useState(row);
  const { mostrarAlerta } = useContext(AlertaContext);

  useEffect(() => {
    setitemRow(row);
    // eslint-disable-next-line
  }, [item]);
  const classes = useRowStyles();
  const handleEliminar = async (_id) => {
    setitem({
      ...item,
      asitecEquiposInstalados: item.asitecEquiposInstalados.filter(
        (item, itemIndex) => {
          return index !== itemIndex;
        }
      ),
    });
  };
  const funcionEdit = () => {
    seteditar(true);
  };
  const [editar, seteditar] = useState(false);
  const funcCancel = () => {
    setitemRow(row);
    seteditar(false);
  };
  const botonDisabled = () => {
    if (
      itemRow.nomBodega === "" ||
      itemRow.stock === "" ||
      itemRow.maximo === "" ||
      itemRow.minimo === ""
    ) {
      return true;
    } else {
      return false;
    }
  };
  const funcSubmit = () => {
    setitem({
      ...item,
      asitecEquiposInstalados: item.asitecEquiposInstalados.map(
        (item, itemIndex) => {
          if (index === itemIndex) {
            return itemRow;
          } else {
            return item;
          }
        }
      ),
    });
    seteditar(false);
  };
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);

  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        titulo="¿Esta seguro que quiere eliminar este item?"
        _id={itemRow._id}
        funcion={handleEliminar}
      />

      <TableRow className={classes.root} selected={index % 2 === 0}>
        {/* boton */}
        <ButtonsAction
          setitem={() => {
            const res = item.asitecEquiposInstalados.find(
              (itemBodega, itemIndex) => {
                return itemBodega.estado === "ACTIVA" && index !== itemIndex;
              }
            );

            if (res) {
              return mostrarAlerta("Ya hay una bodega ACTIVA", "error");
            }
            setitem({
              ...item,
              asitecEquiposInstalados: item.asitecEquiposInstalados.map(
                (item, itemIndex) => {
                  if (index === itemIndex) {
                    return {
                      ...itemRow,
                      estado:
                        itemRow.estado === "INACTIVA" ? "ACTIVA" : "INACTIVA",
                    };
                  } else {
                    return item;
                  }
                }
              ),
            });
          }}
          item={itemRow}
          setopenConfirmDialog={setopenConfirmDialog}
          funcionEdit={funcionEdit}
          ocultarEditar={false}
          ocultarDelete={false}
          mostrarEstado={false}
          campoExport={campoExport}
          campoEstado=""
          funcCancel={funcCancel}
          funcSubmit={funcSubmit}
          editar={editar}
          deshabilitarbotones={deshabilitarbotones}
          botonDisabled={botonDisabled}
        />

        {editar ? (
          <>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              <TextField
                value={itemRow.item}
                onChange={(e) =>
                  setitemRow({
                    ...itemRow,
                    item: e.target.value,
                  })
                }
                size="small"
                fullWidth
                helperText="Agregando"
              />
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              <TextField
                value={itemRow.costo}
                onChange={(e) =>
                  setitemRow({
                    ...itemRow,
                    costo: e.target.value,
                  })
                }
                size="small"
                fullWidth
                helperText="Agregando"
              />
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              <TextField
                value={itemRow.cantidad}
                onChange={(e) =>
                  setitemRow({
                    ...itemRow,
                    cantidad: e.target.value,
                  })
                }
                size="small"
                fullWidth
                helperText="Agregando"
              />
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              <TextField
                value={itemRow.valor}
                onChange={(e) =>
                  setitemRow({
                    ...itemRow,
                    valor: e.target.value,
                  })
                }
                size="small"
                fullWidth
                helperText="Agregando"
              />
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              <TextField
                value={itemRow.descripcion}
                onChange={(e) =>
                  setitemRow({
                    ...itemRow,
                    descripcion: e.target.value,
                  })
                }
                size="small"
                fullWidth
                helperText="Agregando"
              />
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              <TextField
                value={itemRow.marca}
                onChange={(e) =>
                  setitemRow({
                    ...itemRow,
                    marca: e.target.value,
                  })
                }
                size="small"
                fullWidth
                helperText="Agregando"
              />
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              <TextField
                value={itemRow.observacion}
                onChange={(e) =>
                  setitemRow({
                    ...itemRow,
                    observacion: e.target.value,
                  })
                }
                size="small"
                fullWidth
                helperText="Agregando"
              />
            </TableCell>
          </>
        ) : (
          <>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              {itemRow.item}
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              {itemRow.costo}
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              {itemRow.cantidad}
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              {itemRow.valor}
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              {itemRow.descripcion}
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              {itemRow.marca}
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              {itemRow.observacion}
            </TableCell>
          </>
        )}
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(RowBodega));
