import { useState } from "react";
import clienteAxios from "../config/axios";

const useMultimediasUpload = (tipo) => {
  const [imagenes, setimagenes] = useState([]);
  const [modificoImagenes, setmodificoImagenes] = useState(false);
  const subirImagenes = async (imagenesUpload, fotosSubidas) => {
    const formDataImagenes = new FormData();
    console.log(fotosSubidas);
    let resFotografias = {
      data: {
        pathUrls: fotosSubidas,
      },
    };
    formDataImagenes.append(`tipo`, tipo);
    await imagenesUpload.forEach(async (item, index) => {
      return await formDataImagenes.append(`imagen${index + 1}`, item);
    });
    if (imagenesUpload.length > 0 || modificoImagenes) {
      formDataImagenes.append(`eliminar`, JSON.stringify(fotosSubidas));
      resFotografias = await clienteAxios.post(
        "/upload_delete_multiple_files/uploads",
        formDataImagenes
      );
    }
    setmodificoImagenes(false);
    return resFotografias.data.pathUrls;
  };

  return {
    setmodificoImagenes,
    subirImagenes,
    imagenes,
    setimagenes,
  };
};

export default useMultimediasUpload;
