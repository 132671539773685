import React, { memo, useState, useContext, useEffect, useRef } from "react";
import { Box } from "@mui/material";
import Tabla from "./Componentes/Tabla";
import ModalB from "./Componentes/ModalB";
import BarraTablas from "../../../components/BarraTablas/BarraTablas";
import AuthContext from "../../../Context/Auth/AuthContext";
import clienteAxios from "../../../config/axios";
import AlertaContext from "../../../Context/Alerta/AlertaContext";

import io from "socket.io-client";
import { socketUrl } from "../../../config/const";
import { usePermiso } from "../../../hooks/usePermiso";

const CuentasPorCobrar = (props) => {
  const { tienePermiso } = usePermiso("CuentasPorCobrar");
  // const { llamadoDeOtroLugar, setopenMT } = props;
  const {
    documento,
    settab,
    setarrayExportHijos,
    desdeOtroLugarPersona,
    // itemRelacionadoPadre,

    desdeOtroLugarCompra,
  } = props;
  // DATA DEL STATE
  useEffect(() => {
    if (desdeOtroLugarPersona || desdeOtroLugarCompra) {
      settab("");
      setarrayExportHijos(arrayExport.map((item) => item[campoExport]));
    }
    // eslint-disable-next-line
  }, [arrayExport]);
  const [rows, setrows] = useState(null);
  const columns = [
    {
      id: "cpRucProveedor",
      label: "Ruc Proveedor",
      minWidth: 75,
      tipo: "string",

      propiedad: "cpRucProveedor",
    },
    {
      id: "cpFactura",
      label: "Factura",
      minWidth: 75,
      tipo: "string",

      propiedad: "cpFactura",
    },

    {
      id: "cpFechaFactura",
      label: "Fecha",
      minWidth: 75,
      tipo: "string",

      propiedad: "cpFechaFactura",
    },
    {
      id: "cpEstado",
      label: "Estado",
      minWidth: 75,
      tipo: "string",

      propiedad: "cpEstado",
    },

    {
      id: "cpFechaAbono",
      label: "F.Abono",
      minWidth: 75,
      tipo: "string",

      propiedad: "cpFechaAbono",
    },
    {
      id: "cpValorFactura",
      label: "V.Fact.",
      minWidth: 75,
      tipo: "string",

      propiedad: "cpValorFactura",
    },
    {
      id: "cpValorRetencion",
      label: "V.Ret.",
      minWidth: 75,
      tipo: "string",

      propiedad: "cpValorRetencion",
    },
    {
      id: "cpValorAPagar",
      label: "V.Pagar",
      minWidth: 75,
      tipo: "string",

      propiedad: "cpValorAPagar",
    },

    {
      id: "cpAbono",
      label: "Abono",
      minWidth: 75,
      tipo: "string",

      propiedad: "cpAbono",
    },
    {
      id: "cpSaldo",
      label: "Saldo",
      minWidth: 75,
      tipo: "string",

      propiedad: "cpSaldo",
    },
    {
      id: "cpFecReg",
      label: "Fec Reg.",
      minWidth: 75,
      tipo: "string",

      propiedad: "cpFecReg",
    },
  ];

  const [ObjectoActivo, setObjetoActivo] = useState({
    //   cpId: 1,
    //   compraId: 1,
    cpRucProveedor: "",
    cpNomProveedor: "",
    cpFactura: "",
    cpFechaFactura: "",
    cpValorFactura: "",
    cpValorAPagar: "",
    cpValorRetencion: "",
    cpFechaAbono: "",
    cpEstado: "",
    cpAbono: "",
    cpSaldo: "",
    cpEmpresa: "",
    cpUser: "",
    cpPagos: [
      // {
      //   cpTipo: "CHEQUE",
      //   cpBanco: "Banco Internacional",
      //   cpNumero: "0",
      //   cpDeposito: "NO",
      //   cpValor: "100.00",
      //   cpFechaAbono: "2021-12-21T00:00:00.000Z",
      //   cpDetalle: "Registrado desde socket",
      //   cpProcedencia: "CXP",
      //   cpEstado: "ACTIVO",
      // },
    ],
    Todos: "",
    cpFecReg: "",
  });
  const [pagination, setpagination] = useState(null);
  // FUNCIONES STATE
  const getDataState = async (
    cantidad,
    page,
    search,
    input,
    orden,
    datos,
    rucempresa
  ) => {
    const res = await clienteAxios.get(
      `/cuentasporpagar/?cantidad=${cantidad}&page=${page}&search=${
        search ? search : ""
      }&input=${input ? input : ""}&orden=${orden ? orden : ""}&datos=${
        datos ? datos : ""
      }&rucempresa=${rucempresa ? rucempresa : ""}&documento=${
        documento ? documento : ""
      }&allData=${tienePermiso("allData")}`
    );

    return res;
  };

  const obtenerState = async (
    cantidad = 10,
    page = 0,
    search,
    input,
    orden,
    datos,
    rucempresa
  ) => {
    const res = await getDataState(
      cantidad,
      page,
      search,
      input,
      orden,
      datos,
      rucempresa
    );
    setrows(res.data.data.results);
    setpagination(res.data.data.pagination);
  };
  const addRowsState = async (items) => {
    setrows([...rows, ...items]);
  };
  const remplazarRowsState = async (
    cantidad,
    page,
    search,
    input,
    orden,
    datos,
    rucempresa
  ) => {
    const res = await getDataState(
      cantidad,
      page,
      search,
      input,
      orden,
      datos,
      rucempresa
    );
    setrows(res.data.data.results);
    setpagination(res.data.data.pagination);
    // dispatch({
    //   type: OBTENER,
    //   payload: {
    //     results: res.data.data.results,
    //     pagination: res.data.data.pagination,
    //   },
    // });
  };
  const agregarState = async (item) => {
    try {
      setrows([item, ...rows]);

      setpagination({
        ...pagination,
        numRows: pagination.numRows + 1,
      });
    } catch (error) {}
  };

  const editarState = async (item) => {
    try {
      const resRows = rows.map((itemR) => {
        if (itemR.cpId === item.cpId) {
          return item;
        } else {
          return itemR;
        }
      });

      setrows(resRows);
    } catch (error) {}
  };

  const eliminarState = async (_id) => {
    try {
      const resRows = rows.filter((row) => {
        return row.cpId !== _id;
      });
      setrows(resRows);
      setpagination({
        ...pagination,
        numRows: pagination.numRows - 1,
      });
    } catch (error) {}
  };
  const eliminarSeleccionState = async (_ids) => {
    const resRows = rows.filter((row) => {
      return !_ids.some((item) => {
        return item === row.cpId;
      });
    });
    setpagination({
      ...pagination,
      numRows: pagination.numRows - _ids.length,
    });
    setrows(resRows);
  };

  const changeObjetoActivoState = (item) => {
    setObjetoActivo(item);
    // dispatch({
    //   type: CHANGE_ACTIVO,
    //   payload: item,
    // });
  };

  const rowsImportState = (rows) => {
    // dispatch({
    //   type: IMPORTAR_ROWS,
    //   payload: rows,
    // });
    setrows(rows);
  };
  // FIN FUNCIONES STATE
  // FIN DATA STATE

  // FUNCIONES SOCKET
  const [tabla] = useState("cuentasporpagar");
  const [itemSocket, setitemSocket] = useState({
    tipo: "",
    item: {},
  });
  const socket = useRef();
  useEffect(() => {
    // Creates a WebSocket connection
    socket.current = io(socketUrl);
    socket.current.on("server:guardadoExitoso", (data) => {
      setitemSocket({
        tipo: "agregar",
        item: data,
      });
    });
    socket.current.on("server:actualizadoExitoso", (data) => {
      setitemSocket({
        tipo: "editar",
        item: data,
      });
    });
    socket.current.on("server:eliminadoExitoso", (data) => {
      setitemSocket({
        tipo: "eliminar",
        item: data,
      });
    });
    socket.current.on("server:eliminadoExitosoSeleccionado", (data) => {
      setitemSocket({
        tipo: "eliminarSeleccion",
        item: data,
      });
    });

    socket.current.on("server:error", (data) => {
      mostrarAlerta(
        data.msg ? data.msg : "Hubo un error",
        data.alert ? data.alert : "error"
      );
    });
    return () => {
      socket.current.disconnect();
    };
    // Destroys the socket reference
    // when the connection is closed
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    socket.current.on("server:guardadoExitoso", (data) => {
      setitemSocket({
        tipo: "agregar",
        item: data,
      });
    });
    socket.current.on("server:actualizadoExitoso", (data) => {
      setitemSocket({
        tipo: "editar",
        item: data,
      });
    });
    socket.current.on("server:eliminadoExitoso", (data) => {
      setitemSocket({
        tipo: "eliminar",
        item: data,
      });
    });
    socket.current.on("server:eliminadoExitosoSeleccionado", (data) => {
      setitemSocket({
        tipo: "eliminarSeleccion",
        item: data,
      });
    });

    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    const { item, tipo } = itemSocket;

    if (tipo === "") {
      return;
    }
    if (!rows) {
      return;
    }
    if (usuario.rucempresa !== item.rucempresa) {
      return;
    }
    if (item.tabla !== tabla) {
      return;
    }

    if (
      (desdeOtroLugarPersona || desdeOtroLugarCompra) &&
      item.cpRucProveedor !== documento
    ) {
      return;
    }
    const funcionAalerta = () => {
      if (item.msg === "") {
        return null;
      }
      if (!item.msg) {
        return null;
      }
      mostrarAlerta(item.msg, item.alert ? item.alert : "success");
    };

    if (tipo === "agregar") {
      agregarState(item);
    }
    if (tipo === "editar") {
      editarState(item);
    }
    if (tipo === "eliminar") {
      eliminarState(item.cpId);
    }
    if (tipo === "eliminarSeleccion") {
      eliminarSeleccionState(item.lista);
    }
    funcionAalerta();
    setitemSocket({ tipo: "", item: {} });
    // eslint-disable-next-line
  }, [itemSocket]);
  const {
    usuario,
    usuario: { rucempresa },
  } = useContext(AuthContext);
  const { mostrarAlerta } = useContext(AlertaContext);
  const [arrayExport, setarrayExport] = useState([]);
  const [campoExport] = useState("cpId");
  const [buscando, setbuscando] = useState(false);
  const [search, setsearch] = useState("");
  const [cargando, setcargando] = useState(false);
  const [agregar, setagregar] = useState(false);
  const [input, setinput] = useState({
    id: "cpId",
    label: "ID",
    minWidth: 150,
    tipo: "string",

    propiedad: "cpId",
  });
  const [orden, setorden] = useState(false);
  const [openMB, setopenMB] = useState(false);
  const [fullBuscador, setfullBuscador] = useState({
    cpFecReg1: "",
    cpFecReg2: "",
  });

  //pagina actual
  const [page, setPage] = React.useState(0);
  //filas por pagina
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  // input
  //
  const handleChange = (e) => {
    setfullBuscador({ ...fullBuscador, [e.target.name]: e.target.value });
  };
  const funcionB = async () => {
    setcargando(true);
    await remplazarRowsState(
      rowsPerPage,
      0,
      search,
      input.id,
      orden,
      JSON.stringify(fullBuscador),
      rucempresa
    );
    setPage(0);
    setbuscando(true);
    setopenMB(false);
    setcargando(false);
  };

  const handleSearch = async () => {
    setcargando(true);
    setPage(0);
    await remplazarRowsState(
      rowsPerPage,
      0,
      search,
      input.id,
      orden,
      JSON.stringify(fullBuscador),
      rucempresa
    );
    setcargando(false);
  };
  const funcionDefault = async () => {
    setcargando(true);
    setfullBuscador({
      cpFecReg1: "",
      cpFecReg2: "",
    });
    await remplazarRowsState(
      rowsPerPage,
      0,
      "",
      input.id,
      orden,
      JSON.stringify({
        cpFecReg1: "",
        cpFecReg2: "",
      }),
      rucempresa
    );
    setPage(0);
    setsearch("");
    setbuscando(false);
    setcargando(false);
    setopenMB(false);
  };

  return (
    <Box>
      <BarraTablas
        setopen={setagregar}
        ocultarAgregar={true}
        search={search}
        setsearch={setsearch}
        funcion={handleSearch}
        buscando={buscando}
        setbuscando={setbuscando}
        disabled={false}
        setopenMB={setopenMB}
        LabelBuscador={"Buscar"}
        ocultarBuscar={false}
        funcionDefault={funcionDefault}
        ocultarMigasDePan={desdeOtroLugarPersona || desdeOtroLugarCompra}
      />
      <ModalB
        openMB={openMB}
        setopenMB={setopenMB}
        titulo={"Busqueda avanzada (Cuentas Por Pagar)"}
        columns={columns}
        fullBuscador={fullBuscador}
        setfullBuscador={setfullBuscador}
        handleChange={handleChange}
        funcionB={funcionB}
        buscando={buscando}
        setbuscando={setbuscando}
        funcionDefault={funcionDefault}
      />
      <Tabla
        agregar={agregar}
        setagregar={setagregar}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        rows={rows}
        search={search}
        input={input}
        orden={orden}
        setinput={setinput}
        setorden={setorden}
        cargando={cargando}
        setcargando={setcargando}
        fullBuscador={fullBuscador}
        // STATE
        addRowsState={addRowsState}
        getDataState={getDataState}
        remplazarRowsState={remplazarRowsState}
        obtenerState={obtenerState}
        pagination={pagination}
        columns={columns}
        ObjectoActivo={ObjectoActivo}
        changeObjetoActivoState={changeObjetoActivoState}
        rowsImportState={rowsImportState}
        // export
        arrayExport={arrayExport}
        setarrayExport={setarrayExport}
        campoExport={campoExport}
        socket={socket}
      />
    </Box>
  );
};

export default memo(CuentasPorCobrar);
