import React, { useState, useContext, memo, useMemo } from "react";
import {
  Modal,
  Box,
  Divider,
  Typography,
  IconButton,
  Tooltip,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  MenuItem,
  InputAdornment,
} from "@mui/material";

import Draggable from "react-draggable";

import { Cancel, Save } from "@mui/icons-material";
// eslint-disable-next-line
import AlertaContext from "../../../../Context/Alerta/AlertaContext";

import AuthContext from "../../../../Context/Auth/AuthContext";

import { replaceCaracteres, trimPropsItem } from "../../../../config/const";
import { usePermiso } from "../../../../hooks/usePermiso";
import { useModalStyle } from "../../../../styles/styleModal";
import Row from "./Row";
import TablaCabecera from "../../../../components/Extra/TablaCabecera";
import { v4 } from "uuid";
import clienteAxios from "../../../../config/axios";
import CampoDoc from "../../../components/Campos/CampoDoc";
function getModalStyle() {
  return {
    borderRadius: "15px",
    position: "absolute",
    overflow: "scroll",
    maxHeight: "90vh",
  };
}

const ModalAsistencia = ({
  open,
  setopen,
  socket,
  arrayExport,
  campoExport,
  columns,
  bancos,
  funcionDefault,
  setcargando,
  cargando,
}) => {
  const { alertaPermiso, tienePermiso } = usePermiso("CuentasXCobrar");
  const reducer = (previousValue, currentValue) =>
    Number(previousValue) + Number(currentValue);
  // eslint-disable-next-line
  const { mostrarAlerta } = useContext(AlertaContext);

  const { usuario } = useContext(AuthContext);
  const [valorAlt, setvalorAlt] = useState("0");
  const itemDefault = {
    ccComprobante: "",
    ccTipo: "EFECTIVO",
    ccBanco: "",
    ccNumero: "0",
    ccDeposito: "NO",
    ccValor: "0",
    ccFechaAbono: `${new Date().getFullYear()}-${(
      "0" +
      (new Date().getMonth() + 1)
    ).slice(-2)}-${("0" + new Date().getDate()).slice(-2)}`,
    ccDetalle: "",
    ccProcedencia: "",
    ccEstado: "ACTIVO",
    estado: "ACTIVO",
    imprimir: false,
    nuevo: true,
    multiple: true,
    ccUsuario: usuario.usuario,
  };
  const [itemNuevo, setitemNuevo] = useState(itemDefault);
  const arrayExportMemo = useMemo(() => {
    let valor = itemNuevo.ccValor;

    return arrayExport
      .filter((item) => item.ccEstado === "PENDIENTE")
      .map((item) => {
        const resto = Number(Math.max(0, item.ccSaldo - valor).toFixed(2));
        let res = Number((resto - item.ccSaldo).toFixed(2) * -1);
        valor = Number(Math.max(0, valor - item.ccSaldo).toFixed(2));

        return {
          ...item,
          ccSaldo: resto,
          res,
        };
      });
  }, [arrayExport, itemNuevo.ccValor]);
  const max = useMemo(() => {
    return arrayExport.filter((item) => item.ccEstado === "PENDIENTE")
      .length === 0
      ? 0
      : arrayExport
          .filter((item) => item.ccEstado === "PENDIENTE")
          .map((row) => {
            const existe = arrayExport.some(
              (itemE) => itemE[campoExport] === row[campoExport]
            );

            if (existe) {
              return row.ccEstado === "ANULADA" ? 0 : Number(row.ccSaldo);
            }
            return 0;
          })
          .reduce(reducer)
          .toFixed(2);
  }, [arrayExport]);
  // eslint-disable-next-line

  const [error, seterror] = useState({});

  const defaultActive = () => {
    // 1 nuevo
    // 2 copia
    // 3 editar
    // 4 nota de credito
    // changeObjetoActivoState({});
    if (usuario.rucempresa !== "TE2021") {
      setopen(false);
    }
  };

  const handleChange = (e) => {
    const res = replaceCaracteres(e.target.value);
    setitemNuevo({
      ...itemNuevo,
      [e.target.name]: res,
    });
  };
  //hook de estilos
  const clases = useModalStyle();
  //definiendo los estilos del modal
  const [modalStyle] = useState(getModalStyle);
  const validacion = () => {
    if (false) {
      seterror({});
      return true;
    } else {
      return false;
    }
  };
  const handleCrear = async () => {
    if (!tienePermiso("agregar")) {
      return alertaPermiso("agregar");
    }
    if (validacion()) {
      return mostrarAlerta("Faltan datos obligatorios", "error");
    }

    try {
      setcargando(true);
      console.log(
        trimPropsItem({
          ...itemNuevo,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          venUser: usuario.usuario,
          venEmpresa: usuario.rucempresa,
          tabla: "liquidacion",
        })
      );
      console.log({ arrayExport, arrayExportMemo });

      const cxc = arrayExport
        .filter((item) => item.ccEstado === "PENDIENTE")
        .map((item, index) => {
          const memoItem = arrayExportMemo[index];
          const pagos = [
            { ...itemNuevo, ccValor: memoItem.res, uuid: v4() },
            ...memoItem.ccPagos,
          ];
          return {
            ...memoItem,
            ccSaldo: item.ccSaldo,
            ccPagos: pagos,
            valorTotal: itemNuevo.ccValor,
          };
        });

      console.log({ cxc });
      const res = await clienteAxios.put("/cuentasporcobrar/multiplesPagos", {
        cxc,
      });
      setitemNuevo(itemDefault);
      console.log({ res });
      funcionDefault();
      // socket.current.emit(
      //   "client:guardarData",
      //   trimPropsItem({
      //     ...itemNuevo,
      //     rucempresa: usuario.rucempresa,
      //     rol: usuario.rol,
      //     venUser: usuario.usuario,
      //     venEmpresa: usuario.rucempresa,
      //     tabla: "liquidacion",
      //   })
      // );
      setcargando(false);
      return defaultActive();
    } catch (error) {
      console.log({ error });

      setcargando(false);
      return mostrarAlerta("Hubo un error", "error");
    }
  };

  //filas por pagina

  // eslint-disable-next-line

  if (!open) {
    return null;
  }
  return (
    <>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box>
          <Draggable handle="#handle">
            <Box display="flex" justifyContent="center">
              <div style={modalStyle} className={clases.paper}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                  style={{ cursor: "-webkit-grab" }}
                >
                  <Typography
                    id="handle"
                    variant="button"
                    color="initial"
                    align="center"
                  >
                    Nuevas cuentas por cobrar
                  </Typography>
                  <Box display="flex" alignItems="center">
                    <Tooltip title="Presione F8">
                      <IconButton
                        aria-label=""
                        color="primary"
                        onClick={() => {
                          handleCrear();
                        }}
                      >
                        <Save />
                      </IconButton>
                    </Tooltip>
                    <IconButton
                      aria-label=""
                      style={{ margin: "0px" }}
                      onClick={() => {
                        defaultActive();
                        setopen(false);
                      }}
                    >
                      <Cancel color="secondary" />
                    </IconButton>
                  </Box>
                </Box>
                <Divider />
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems=" stretch"
                  width="100%"
                >
                  <Box></Box>
                </Box>

                <Box
                  display="flex"
                  flexWrap="wrap"
                  alignItems="flex-end"
                  borderRadius="0px 50px 50px 0px"
                  p={1}
                >
                  <CampoDoc
                    disabled={cargando}
                    disabledE={cargando}
                    setcargando={setcargando}
                    label={"Documento"}
                    propiedad={"ccComprobante"}
                    item={itemNuevo}
                    setitem={setitemNuevo}
                    // celda={true}
                    // claseCelda={classes.celda}
                    extension={"all"}
                  />
                  <TextField
                    label="Forma de pago"
                    className={clases.formD}
                    value={itemNuevo.ccTipo}
                    name="ccTipo"
                    onChange={(e) => handleChange(e)}
                    size="small"
                    select
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ displayEmpty: true }}
                    fullWidth
                  >
                    <MenuItem value="">Seleccione Tipo</MenuItem>
                    {[
                      "EFECTIVO",
                      "RETENCION",
                      "CHEQUE",
                      "TRANSFERENCIA",
                      "DEPOSITO",
                      "TARJETA",
                      "CRUCE COMPRA",
                      "DONACION",
                    ]
                      .sort()
                      .map((itemTipo, index) => {
                        return (
                          <MenuItem key={index} value={itemTipo}>
                            {itemTipo}{" "}
                          </MenuItem>
                        );
                      })}
                  </TextField>{" "}
                  <TextField
                    label="Número"
                    className={clases.formD}
                    disabled={itemNuevo.ccTipo === "EFECTIVO"}
                    value={itemNuevo.ccNumero}
                    name="ccNumero"
                    onChange={(e) => handleChange(e)}
                    size="small"
                    fullWidth
                  />
                  <TextField
                    label="Banco"
                    className={clases.formD}
                    disabled={itemNuevo.ccTipo === "EFECTIVO"}
                    value={itemNuevo.ccBanco}
                    name="ccBanco"
                    onChange={(e) => handleChange(e)}
                    size="small"
                    select
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ displayEmpty: true }}
                    fullWidth
                  >
                    <MenuItem value="">Seleccione Banco</MenuItem>
                    {bancos.map((ccPago) => {
                      return (
                        <MenuItem key={ccPago.banId} value={ccPago.banNombre}>
                          {ccPago.banNombre}{" "}
                        </MenuItem>
                      );
                    })}
                  </TextField>{" "}
                  <TextField
                    label="Deposito"
                    className={clases.formD}
                    disabled={itemNuevo.ccTipo === "EFECTIVO"}
                    value={itemNuevo.ccDeposito}
                    name="ccDeposito"
                    onChange={(e) => handleChange(e)}
                    size="small"
                    select
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ displayEmpty: true }}
                    fullWidth
                  >
                    <MenuItem value="">Seleccione Tipo</MenuItem>
                    {["SI", "NO", "NINGUNO"].map((itemccDeposito, index) => {
                      return (
                        <MenuItem key={index} value={itemccDeposito}>
                          {itemccDeposito}{" "}
                        </MenuItem>
                      );
                    })}
                  </TextField>{" "}
                  <TextField
                    label="Fecha"
                    className={clases.formD}
                    type="date"
                    value={itemNuevo.ccFechaAbono}
                    name="ccFechaAbono"
                    onChange={(e) => handleChange(e)}
                    size="small"
                    fullWidth
                  />
                  <TextField
                    label="Valor"
                    className={clases.formD}
                    type="number"
                    value={valorAlt}
                    name="ccValor"
                    onChange={(e) => setvalorAlt(Math.min(e.target.value, max))}
                    size="small"
                    onBlur={() => {
                      setitemNuevo({ ...itemNuevo, ccValor: valorAlt });
                    }}
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Typography
                            variant="body1"
                            color="primary"
                            style={{ fontWeight: "bold" }}
                          >
                            ${max}
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    label="Detalle"
                    className={clases.formD}
                    value={itemNuevo.ccDetalle}
                    name="ccDetalle"
                    onChange={(e) => handleChange(e)}
                    size="small"
                    fullWidth
                  />
                  <TableContainer>
                    <Table
                      stickyHeader
                      aria-label="sticky table"
                      id="tabla"
                      size="small"
                    >
                      {/* <ModalCarnet setopen={setopen} onChangeCarnetActivo={onChangeCarnetActivo}/> */}
                      <TableHead>
                        <TablaCabecera
                          columns={columns}
                          habilitarOrdenar={false}
                          //={}nue//={}vo
                          funcionOrdenar={() => {}}
                        />
                      </TableHead>
                      <TableBody>
                        {arrayExportMemo.map((row, index) => {
                          return (
                            <Row
                              fromModal
                              setarrayExport={() => {}}
                              row={row}
                              index={index}
                              key={row.ccId}
                              bancos={[]}
                              alertaPermiso={() => {}}
                              tienePermiso={() => {}}
                            />
                          );
                        })}
                        <TableRow>
                          <TableCell
                            padding="none"
                            size="small"
                            align="center"
                          ></TableCell>
                          <TableCell
                            padding="none"
                            size="small"
                            align="center"
                          ></TableCell>
                          {usuario.empCategoria === "ISP" && (
                            <>
                              <TableCell
                                padding="none"
                                size="small"
                                align="center"
                              ></TableCell>
                              <TableCell
                                padding="none"
                                size="small"
                                align="center"
                              ></TableCell>
                            </>
                          )}
                          <TableCell
                            padding="none"
                            size="small"
                            align="center"
                          ></TableCell>
                          <TableCell
                            padding="none"
                            size="small"
                            align="center"
                          ></TableCell>
                          <TableCell
                            padding="none"
                            size="small"
                            align="center"
                          ></TableCell>
                          <TableCell
                            padding="none"
                            size="small"
                            align="center"
                          ></TableCell>
                          <TableCell
                            padding="none"
                            size="small"
                            align="center"
                          ></TableCell>
                          <TableCell padding="none" size="small" align="center">
                            <Typography
                              variant="body2"
                              color="secondary"
                              style={{ fontWeight: "bold", padding: ".3rem" }}
                              component={"span"}
                            >
                              $
                              {arrayExportMemo.length === 0
                                ? null
                                : arrayExportMemo
                                    .map((row) => {
                                      const existe = arrayExportMemo.some(
                                        (itemE) =>
                                          itemE[campoExport] ===
                                          row[campoExport]
                                      );

                                      if (existe) {
                                        return row.ccEstado === "ANULADA"
                                          ? 0
                                          : Number(row.ccValorFactura);
                                      }
                                      return 0;
                                    })
                                    .reduce(reducer)
                                    .toFixed(2)}
                            </Typography>
                            /
                            <Typography
                              variant="body2"
                              color="primary"
                              style={{ fontWeight: "bold", padding: ".3rem" }}
                              component={"span"}
                            >
                              $
                              {arrayExportMemo.length === 0
                                ? null
                                : arrayExportMemo
                                    .map((row) => {
                                      return row.ccEstado === "ANULADA"
                                        ? 0
                                        : Number(row.ccValorFactura);
                                    })
                                    .reduce(reducer)
                                    .toFixed(2)}
                            </Typography>
                          </TableCell>
                          <TableCell padding="none" size="small" align="center">
                            <Typography
                              variant="body2"
                              color="primary"
                              style={{ fontWeight: "bold", padding: ".3rem" }}
                            >
                              <Typography
                                variant="body2"
                                color="secondary"
                                style={{
                                  fontWeight: "bold",
                                  padding: ".3rem",
                                }}
                                component={"span"}
                              >
                                $
                                {arrayExportMemo.length === 0
                                  ? null
                                  : arrayExportMemo
                                      .map((row) => {
                                        const existe = arrayExportMemo.some(
                                          (itemE) =>
                                            itemE[campoExport] ===
                                            row[campoExport]
                                        );

                                        if (existe) {
                                          return row.ccEstado === "ANULADA"
                                            ? 0
                                            : Number(row.ccValorRetencion);
                                        }
                                        return 0;
                                      })
                                      .reduce(reducer)
                                      .toFixed(2)}
                              </Typography>
                              /
                              <Typography
                                variant="body2"
                                color="primary"
                                style={{
                                  fontWeight: "bold",
                                  padding: ".3rem",
                                }}
                                component={"span"}
                              >
                                $
                                {arrayExportMemo.length === 0
                                  ? null
                                  : arrayExportMemo
                                      .map((row) => {
                                        return row.ccEstado === "ANULADA"
                                          ? 0
                                          : Number(row.ccValorRetencion);
                                      })
                                      .reduce(reducer)
                                      .toFixed(2)}
                              </Typography>
                            </Typography>
                          </TableCell>
                          <TableCell padding="none" size="small" align="center">
                            <Typography
                              variant="body2"
                              color="secondary"
                              style={{ fontWeight: "bold", padding: ".3rem" }}
                              component={"span"}
                            >
                              $
                              {arrayExportMemo.length === 0
                                ? null
                                : arrayExportMemo
                                    .map((row) => {
                                      const existe = arrayExportMemo.some(
                                        (itemE) =>
                                          itemE[campoExport] ===
                                          row[campoExport]
                                      );

                                      if (existe) {
                                        return row.ccEstado === "ANULADA"
                                          ? 0
                                          : Number(row.ccValorAPagar);
                                      }
                                      return 0;
                                    })
                                    .reduce(reducer)
                                    .toFixed(2)}
                            </Typography>
                            /
                            <Typography
                              variant="body2"
                              color="primary"
                              style={{ fontWeight: "bold", padding: ".3rem" }}
                              component={"span"}
                            >
                              $
                              {arrayExportMemo.length === 0
                                ? null
                                : arrayExportMemo
                                    .map((row) => {
                                      return row.ccEstado === "ANULADA"
                                        ? 0
                                        : Number(row.ccValorAPagar);
                                    })
                                    .reduce(reducer)
                                    .toFixed(2)}
                            </Typography>
                          </TableCell>
                          <TableCell padding="none" size="small" align="center">
                            <Typography
                              variant="body2"
                              color="secondary"
                              style={{ fontWeight: "bold", padding: ".3rem" }}
                              component={"span"}
                            >
                              $
                              {arrayExportMemo.length === 0
                                ? null
                                : arrayExportMemo
                                    .map((row) => {
                                      const existe = arrayExportMemo.some(
                                        (itemE) =>
                                          itemE[campoExport] ===
                                          row[campoExport]
                                      );

                                      if (existe) {
                                        return row.ccEstado === "ANULADA"
                                          ? 0
                                          : Number(row.ccAbono);
                                      }
                                      return 0;
                                    })
                                    .reduce(reducer)
                                    .toFixed(2)}
                            </Typography>
                            /
                            <Typography
                              variant="body2"
                              color="primary"
                              style={{ fontWeight: "bold", padding: ".3rem" }}
                              component={"span"}
                            >
                              $
                              {arrayExportMemo.length === 0
                                ? null
                                : arrayExportMemo
                                    .map((row) => {
                                      return row.ccEstado === "ANULADA"
                                        ? 0
                                        : Number(row.ccAbono);
                                    })
                                    .reduce(reducer)
                                    .toFixed(2)}
                            </Typography>
                          </TableCell>
                          <TableCell padding="none" size="small" align="center">
                            <Typography
                              variant="body2"
                              color="secondary"
                              style={{ fontWeight: "bold", padding: ".3rem" }}
                              component={"span"}
                            >
                              $
                              {arrayExportMemo.length === 0
                                ? null
                                : arrayExportMemo
                                    .map((row) => {
                                      const existe = arrayExportMemo.some(
                                        (itemE) =>
                                          itemE[campoExport] ===
                                          row[campoExport]
                                      );

                                      if (existe) {
                                        return row.ccEstado === "ANULADA"
                                          ? 0
                                          : Number(row.ccSaldo);
                                      }
                                      return 0;
                                    })
                                    .reduce(reducer)
                                    .toFixed(2)}
                            </Typography>
                            /
                            <Typography
                              variant="body2"
                              color="primary"
                              style={{ fontWeight: "bold", padding: ".3rem" }}
                              component={"span"}
                            >
                              $
                              {arrayExportMemo.length === 0
                                ? null
                                : arrayExportMemo
                                    .map((row) => {
                                      return row.ccEstado === "ANULADA"
                                        ? 0
                                        : Number(row.ccSaldo);
                                    })
                                    .reduce(reducer)
                                    .toFixed(2)}
                            </Typography>
                          </TableCell>{" "}
                          <TableCell
                            padding="none"
                            size="small"
                            align="center"
                          ></TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
                {/* <Divider /> */}
                <Box
                  display="flex"
                  width="100%"
                  justifyContent="space-between"
                  alignItems="center"
                ></Box>
              </div>
            </Box>
          </Draggable>
        </Box>
      </Modal>
    </>
  );
};

export default memo(ModalAsistencia);
