import {
  Check,
  Close,
  CloudUpload,
  DeleteForever,
  Download,
} from "@mui/icons-material";
import { IconButton, InputAdornment, TextField, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useContext } from "react";
import { useState, createRef } from "react";
import clienteAxios from "../../../config/axios";
import AlertaContext from "../../../Context/Alerta/AlertaContext";
import { useModalStyle } from "../../../styles/styleModal";
import ConfirmacionDialog from "../Dialogs/ConfirmacionDialog";

const CampoDoc = (props) => {
  const clases = useModalStyle();
  const { mostrarAlerta } = useContext(AlertaContext);
  const {
    setcargando,
    propiedad,
    item,
    setitem,
    celda,
    claseCelda,
    disabled,
    disabledE,
    extension,
    label = "Documento",
    error = {},
    ocultarDelete,
    noMandarEliminar,
  } = props;
  //documento
  const [documentoState, setdocumentoState] = useState("");
  const refArchivoDocumentoState = createRef();
  const focusInputDocumentoState = () => {
    refArchivoDocumentoState.current.click();
  };
  const onChangeArchivoDocumentoState = async (e) => {
    const formDataLogos = new FormData();
    if (!e.target.files[0]) {
      return setdocumentoState("");
    }

    if (extension === "all") {
      if (
        !["png", "jpg", "jpeg", "pdf"].some(
          (item) => item === e.target.files[0].type.split("/")[1]
        )
      ) {
        return mostrarAlerta("Solo archivos png, jpg o jpeg o pdf", "error");
      }
    } else {
      if (extension === "IMAGEN") {
        if (
          !["png", "jpg", "jpeg"].some(
            (item) => item === e.target.files[0].type.split("/")[1]
          )
        ) {
          return mostrarAlerta("Solo archivos png, jpg o jpeg", "error");
        }
      } else {
        if (
          !["pdf"].some((item) => item === e.target.files[0].type.split("/")[1])
        ) {
          return mostrarAlerta("El archivo debe ser PDF", "error");
        }
      }
    }

    try {
      setcargando(true);

      if (item[propiedad] === "") {
        await formDataLogos.append("tipo", propiedad);
        await formDataLogos.append("archivo", e.target.files[0]);
      } else {
        if (!noMandarEliminar) {
          await clienteAxios.post("/upload_delete_multiple_files/delete", {
            urls: [item[propiedad]],
          });
        }
        await formDataLogos.append("tipo", propiedad);
        await formDataLogos.append("archivo", e.target.files[0]);
      }
      console.log(item[propiedad], propiedad);

      const resDocumentos = await clienteAxios.post(
        "/upload_delete_multiple_files/upload",
        formDataLogos
      );
      console.log(resDocumentos);

      setitem({
        ...item,
        [propiedad]: resDocumentos.data.nombre,
      });

      setdocumentoState(e.target.files[0]);
      setcargando(false);
      setopenDialog2(false);
    } catch (error) {
      console.log({ error });

      setcargando(false);
      setdocumentoState(e.target.files[0]);
      setopenDialog2(false);
      return mostrarAlerta("Hubo un error", "error");
    }
    setdocumentoState(e.target.files[0]);
  };

  const [openDialog1, setopenDialog1] = useState(false);
  const [openDialog2, setopenDialog2] = useState(false);
  return (
    <>
      <input
        className={clases.ocultar}
        type="file"
        name="myImage"
        onChange={(e) => onChangeArchivoDocumentoState(e)}
        ref={refArchivoDocumentoState}
      />{" "}
      <ConfirmacionDialog
        open={openDialog1}
        setopen={() => setopenDialog1(false)}
        titulo={
          "Si elimina el archivo y no guarda los cambios es posible que deje de existir en los documentos."
        }
        categoria="warning"
        botones={[
          {
            tooltip: "Eliminar Archivo",
            texto: "Eliminar",
            funcion: async () => {
              try {
                setcargando(false);
                setitem({
                  ...item,
                  [propiedad]: "",
                });
                setdocumentoState("");

                console.log({ urls: [item[propiedad]] });

                await clienteAxios.post(
                  "/upload_delete_multiple_files/delete",
                  { urls: [item[propiedad]] }
                );
                setcargando(false);
                setopenDialog1(false);
              } catch (error) {
                setopenDialog1(false);
                mostrarAlerta("Hubo un error al eliminar el archivo");
              }
            },
            disabled: disabled,
            Icon: DeleteForever,
            color: "secondary",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "Cancelar",
            funcion: () => {
              setopenDialog1(false);
            },
            disabled: disabled,
            Icon: Close,
            color: "error",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <ConfirmacionDialog
        open={openDialog2}
        setopen={() => setopenDialog2(false)}
        titulo={
          "¿Estás seguro de que deseas reemplazar el archivo actual? Si es así, presiona 'Guardar'."
        }
        categoria="warning"
        botones={[
          {
            tooltip: "Reemplazar archivo",
            texto: "Reemplazar",
            funcion: async () => {
              focusInputDocumentoState();
            },
            disabled: disabled,
            Icon: CloudUpload,
            color: "secondary",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "Cancelar",
            funcion: () => {
              setopenDialog2(false);
            },
            disabled: disabled,
            Icon: Close,
            color: "error",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <TextField
        size="small"
        error={error[propiedad]}
        disabled={disabled}
        autoComplete="false"
        className={celda ? (claseCelda ? claseCelda : {}) : clases.formD}
        InputProps={{
          autoComplete: false,
          endAdornment: (
            <Box htmlFor="contained-button-documento" fullWidth>
              <InputAdornment>
                <Tooltip title="Subir Archivo: Documento" arrow>
                  <Box>
                    <IconButton
                      disabled={disabled}
                      onClick={() => {
                        if (item[propiedad] === "" || !item[propiedad]) {
                          focusInputDocumentoState();
                        } else {
                          setopenDialog2(true);
                        }
                      }}
                    >
                      <CloudUpload color={disabled ? "inherit" : "secondary"} />
                    </IconButton>
                  </Box>
                </Tooltip>
                {item[propiedad] === "" || !item[propiedad] ? null : (
                  <Tooltip title="Descargar Archivo: Documento" arrow>
                    <Box>
                      <IconButton
                        color="primary"
                        onClick={async () => {
                          window.open(item[propiedad]);
                        }}
                      >
                        <Download />
                      </IconButton>
                    </Box>
                  </Tooltip>
                )}{" "}
                {item[propiedad] === "" || !item[propiedad] ? null : (
                  <Tooltip title="Descargar Archivo: Documento" arrow>
                    <Box>
                      <IconButton
                        color={disabled ? "inherit" : "error"}
                        onClick={async () => {
                          setopenDialog1(true);
                        }}
                        disabled={disabledE}
                      >
                        <DeleteForever />
                      </IconButton>
                    </Box>
                  </Tooltip>
                )}
              </InputAdornment>
            </Box>
          ),
        }}
        fullWidth
        label={celda ? "" : label}
        value={
          item[propiedad] && !documentoState.name
            ? item[propiedad].split("/")[item[propiedad].split("/").length - 1]
            : documentoState.name
            ? documentoState.name
            : ""
        }
        name={propiedad}
      />
    </>
  );
};

export default CampoDoc;
