import React, { memo, useContext } from "react";
import { makeStyles } from "@mui/styles";
import { TableCell, TableRow } from "@mui/material";

import { useState } from "react";
import ConfirmacionDialog from "../../../../components/Extra/ConfirmacionDialog";
import { withRouter } from "react-router";
import ButtonsAction from "./ButtonsAction";
import AuthContext from "../../../../Context/Auth/AuthContext";
import ModalEmail from "../../../../components/Extra/ModalEmail";
import CallNombrePersona from "../../../components/Tablas/CallNombrePersona";
const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  hover: {
    "&:hover": {
      opacity: ".7",
      cursor: "pointer",
    },
  },
  celda: {
    fontSize: ".7rem !important",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));

const Row = (props) => {
  const {
    arrayExport,
    setarrayExport,
    row,
    index,
    campoExport,
    puedeExportar,
    desdeOtroLugar,
    seteditar,
    setopen,
    changeObjetoActivoState,
    socket,
    tienePermiso,
    alertaPermiso,
    changeObjetoActivoStatePropietario,
    setopenPropietarioModal,
  } = props;
  const { usuario } = useContext(AuthContext);
  const classes = useRowStyles();

  const handleEliminar = async (_id) => {
    if (!tienePermiso("eliminar")) {
      return alertaPermiso("eliminar");
    }
    try {
      socket.current.emit("client:eliminarData", {
        nombre: usuario.nombre,
        tabla: "reserva",
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        resUser: usuario.usuario,
        resEmpresa: usuario.rucempresa,
        resId: row.resId,
      });
    } catch (error) {}
  };
  const funcionEdit = () => {
    setopen(true);
    seteditar(true);
    changeObjetoActivoState(row);
  };
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);

  const [expandir, setexpandir] = useState(false);
  const [openModalEmail, setopenModalEmail] = useState(false);
  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        titulo={`¿Esta seguro de eliminar este registro?`}
        _id={row._id}
        funcion={handleEliminar}
      />
      <ModalEmail
        open={openModalEmail}
        setopen={setopenModalEmail}
        id={row.resId}
        documento={"Reserva"}
        emailDefecto={row.resPerEmail}
        campos={[
          {
            label: "Documento",
            propiedad: row.resPerDocumento,
          },
          {
            label: "Nombre",
            propiedad: row.resPerNombre,
          },
        ]}
      />
      <TableRow className={classes.root} selected={index % 2 === 0}>
        {/* boton */}
        <ButtonsAction
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          row={row}
          setopenConfirmDialog={setopenConfirmDialog}
          funcionEdit={funcionEdit}
          ocultarEditar={false}
          ocultarDelete={false}
          mostrarEstado={false}
          campoExport={campoExport}
          campoEstado=""
          puedeExportar={puedeExportar}
          desdeOtroLugar={desdeOtroLugar}
          expandir={expandir}
          setexpandir={setexpandir}
          changeObjetoActivoStatePropietario={
            changeObjetoActivoStatePropietario
          }
          setopenPropietarioModal={setopenPropietarioModal}
          setopenModalEmail={setopenModalEmail}
        />
        <>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            {row.resId}
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            {row.resPerNombre}
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            {row.resMascNombre}
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            {row.resMascRaza}
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            {row.resTipoReserva}
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            {row.resFecha.replace("T", " ")}
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            {row.resPerNomVeterinario}
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            {row.resObservacion}
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            {new Date(row.resFecUpd).toLocaleString("es-ES", {
              weekday: "short",
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour: "2-digit",
            })}
          </TableCell>
          <CallNombrePersona user={row.resUser} />
          <TableCell
            padding="none"
            size="small"
            align="center"
            style={{
              color:
                row.resEnvioCorreo === "PENDIENTE"
                  ? //rojo tomate
                    "#ffa726"
                  : row.resEnvioCorreo === "ENVIADO"
                  ? //verde
                    "green"
                  : row.resEnvioCorreo === "ERROR"
                  ? "#f44336"
                  : "#ffa726",
              fontWeight: "bold",
            }}
            className={classes.celda}
          >
            {row.resEnvioCorreo}
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            style={{
              color:
                row.resEnvioSms === "PENDIENTE"
                  ? //rojo tomate
                    "#ffa726"
                  : row.resEnvioSms === "ENVIADO"
                  ? //verde
                    "green"
                  : row.resEnvioSms === "ERROR"
                  ? "#f44336"
                  : "#ffa726",
              fontWeight: "bold",
            }}
            className={classes.celda}
          >
            {row.resEnvioSms}
          </TableCell>
        </>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
