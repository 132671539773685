import React, {
  memo,
  useState,
  useContext,
  useEffect,
  useRef,
  useMemo,
} from "react";

import { Box, Button, Divider } from "@mui/material";

import Tabla from "./Componentes/Tabla";
import Modal from "./Componentes/Modal";
import ModalB from "../../../components/Extra/ModalB";
import BarraTablas from "../../../components/BarraTablas/BarraTablas";
import clienteAxios from "../../../config/axios";
import AlertaContext from "../../../Context/Alerta/AlertaContext";
import AuthContext from "../../../Context/Auth/AuthContext";
import io from "socket.io-client";
import { socketUrl } from "../../../config/const";
import { usePermiso } from "../../../hooks/usePermiso";

const Proveedores = (props) => {
  const { tienePermiso } = usePermiso("Personas_Veterinaria");
  const {
    desdeOtroLugarMascota,
    desdeOtroLugarMenu,
    rolesSeleccionados,
    setpersonasSeleccionadas,
  } = props;
  // const editorRef = useRef();
  // DATA DEL STATE
  const [rows, setrows] = useState(null);
  const [estadoRequest, setestadoRequest] = useState("CLIENTE");
  const columns = useMemo(
    () =>
      desdeOtroLugarMenu
        ? [
            {
              id: "perDocNumero",
              label: "Documento",
              minWidth: 50,
              tipo: "string",
              propiedad: "perDocNumero",
            },
            {
              id: "perNombre",
              label: "Nombres",
              minWidth: 50,
              tipo: "string",
              propiedad: "perNombre",
            },
            {
              id: "perPermisos",
              label: "Permisos Personalizados",
              minWidth: 250,
              tipo: "",
              propiedad: "perPermisos",
            },
            {
              id: "perPerfil",
              label: "Roles",
              minWidth: 50,
              tipo: "",
              propiedad: "perPerfil",
            },
            {
              id: "perUser",
              label: "User",
              minWidth: 50,
              tipo: "string",
              propiedad: "perUser",
            },
            {
              id: "perFecReg",
              label: "Fec Reg",
              minWidth: 50,
              tipo: "string",
              propiedad: "perFecReg",
            },
          ]
        : estadoRequest === "CLIENTE"
        ? [
            {
              id: "perDocTipo",
              label: "Tipo Doc",
              minWidth: 50,
              tipo: "string",
              propiedad: "perDocTipo",
            },
            {
              id: "perDocNumero",
              label: "Documento",
              minWidth: 50,
              tipo: "string",
              propiedad: "perDocNumero",
            },
            {
              id: "perObsevacion",
              label: "Observación",
              minWidth: 50,
              tipo: "string",
              propiedad: "perObsevacion",
            },
            {
              id: "perRecomendacion",
              label: "Tipo",
              minWidth: 50,
              tipo: "string",
              propiedad: "perRecomendacion",
            },
            {
              id: "perNombre",
              label: "Nombres",
              minWidth: 50,
              tipo: "string",
              propiedad: "perNombre",
            },
            {
              id: "perCelular",
              label: "Celular",
              minWidth: 50,
              tipo: "string",
              propiedad: "perCelular",
            },
            {
              id: "perEmail",
              label: "Email",
              minWidth: 50,
              tipo: "string",
              propiedad: "perEmail",
            },
            {
              id: "perFecUpd",
              label: "Fec Upd",
              minWidth: 50,
              tipo: "string",
              propiedad: "perFecUpd",
            },
            {
              id: "perUser",
              label: "User",
              minWidth: 50,
              tipo: "string",
              propiedad: "perUser",
            },
          ]
        : [
            {
              id: "perDocTipo",
              label: "Tipo Doc",
              minWidth: 50,
              tipo: "string",
              propiedad: "perDocTipo",
            },
            {
              id: "perDocNumero",
              label: "Documento",
              minWidth: 50,
              tipo: "string",
              propiedad: "perDocNumero",
            },
            {
              id: "perObsevacion",
              label: "Observación",
              minWidth: 50,
              tipo: "string",
              propiedad: "perObsevacion",
            },
            {
              id: "perEspecialidad",
              label: "Especialidad",
              minWidth: 50,
              tipo: "string",
              propiedad: "perEspecialidad",
            },
            {
              id: "perNombre",
              label: "Nombres",
              minWidth: 50,
              tipo: "string",
              propiedad: "perNombre",
            },
            {
              id: "perCelular",
              label: "Celular",
              minWidth: 50,
              tipo: "string",
              propiedad: "perCelular",
            },
            {
              id: "perEmail",
              label: "Email",
              minWidth: 50,
              tipo: "string",
              propiedad: "perEmail",
            },
            {
              id: "perFecUpd",
              label: "Fec Upd",
              minWidth: 50,
              tipo: "string",
              propiedad: "perFecUpd",
            },
            {
              id: "perUser",
              label: "User",
              minWidth: 50,
              tipo: "string",
              propiedad: "perUser",
            },
          ],
    [desdeOtroLugarMenu, estadoRequest]
  );
  const defaultFullBuscador = () => {
    const json = {};
    columns.forEach((itemPropiedad) => {
      Object.entries({ itemPropiedad }).forEach((item) => {
        if (itemPropiedad.tipo === "string") {
          json[`${item[1].propiedad}`] = "";
        } else if (itemPropiedad.tipo === "numero") {
          json[`${item[1].propiedad}1`] = "";
          json[`${item[1].propiedad}2`] = "";
        } else if (itemPropiedad.tipo === "fecha") {
          json[`${item[1].propiedad}1`] = "";
          json[`${item[1].propiedad}2`] = "";
        }
      });
    });

    return json;
  };
  const [ObjectoActivo, setObjetoActivo] = useState({
    perISPContratos: [],
    perNombreComercial: "",
    perEmpresa: [],
    perPais: "",
    perProvincia: "",
    perCanton: "",
    perTipoProveedor: "BIENES",
    perTiempoCredito: "0",
    perDocTipo: "",
    perDocNumero: "",
    perPerfil: [],
    perNombre: "",
    perDireccion: "",
    perObligado: "NO",
    perCredito: "NO",
    perTelefono: "0",
    perCelular: ["+593"],
    perEstado: "ACTIVO",
    perObsevacion: "",
    perEmail: ["sin@sincorreo.com"],
    perOtros: ["ZZZ9999"],
    perNickname: "",
    perUser: "",
    perFecReg: "",
    perFoto: "",
    perUbicacion: {
      longitud: "",
      latitud: "",
    },
    perDocumento: "",
    perGenero: "M",
    perRecomendacion: "",
    perFecNacimiento: "",
    perEspecialidad: "",
    perTitulo: "",
    perSenescyt: "",
    perPersonal: "INTERNO",
  });
  const [pagination, setpagination] = useState(null);
  const [cumple, setcumple] = useState(false);
  // FUNCIONES STATE
  const getDataState = async (
    cantidad,
    page,
    search,
    input,
    orden,
    datos,
    rucempresa,
    perfil,
    cumple
  ) => {
    const res = await clienteAxios.get(
      `/proveedores/?cantidad=${cantidad}&page=${page}&search=${
        search ? search : ""
      }&input=${input ? input : ""}&orden=${orden ? orden : ""}&datos=${
        datos ? datos : ""
      }&rucempresa=${rucempresa ? rucempresa : ""}&perfil=${
        perfil ? perfil : ""
      }&allData=${tienePermiso(
        "allData"
      )}&fromPermisos=${desdeOtroLugarMenu}&cumple=${cumple}`
    );

    return res;
  };

  const obtenerState = async (
    cantidad = 10,
    page = 0,
    search,
    input,
    orden,
    datos,
    rucempresa,
    estado,
    cumple
  ) => {
    const res = await getDataState(
      cantidad,
      page,
      search,
      input,
      orden,
      datos,
      rucempresa,
      estado,
      cumple
    );
    setrows(res.data.data.results);
    setpagination(res.data.data.pagination);
  };
  const addRowsState = async (items) => {
    setrows([...rows, ...items]);
  };
  const remplazarRowsState = async (
    cantidad,
    page,
    search,
    input,
    orden,
    datos,
    rucempresa,
    estado,
    cumple
  ) => {
    const res = await getDataState(
      cantidad,
      page,
      search,
      input,
      orden,
      datos,
      rucempresa,
      estado,
      cumple
    );
    setrows(res.data.data.results);
    setpagination(res.data.data.pagination);
    // dispatch({
    //   type: OBTENER,
    //   payload: {
    //     results: res.data.data.results,
    //     pagination: res.data.data.pagination,
    //   },
    // });
  };
  const agregarState = async (item, funcionAalerta) => {
    try {
      setrows([item, ...rows]);

      setpagination({
        ...pagination,
        numRows: pagination.numRows + 1,
      });
      funcionAalerta();
    } catch (error) {}
  };

  const editarState = async (item, funcionAalerta) => {
    try {
      const resRows = rows.map((itemR) => {
        if (itemR.perId === item.perId) {
          return item;
        } else {
          return itemR;
        }
      });

      setrows(resRows);
      funcionAalerta();
    } catch (error) {}
  };

  const eliminarState = async (_id, funcionAalerta) => {
    try {
      const resRows = rows.filter((row) => {
        return row.perId !== _id;
      });
      setrows(resRows);
      setpagination({
        ...pagination,
        numRows: pagination.numRows - 1,
      });
      funcionAalerta();
    } catch (error) {}
  };
  const eliminarSeleccionState = async (_ids, funcionAalerta) => {
    const resRows = rows.filter((row) => {
      return !_ids.some((item) => {
        return item === row.perId;
      });
    });
    setpagination({
      ...pagination,
      numRows: pagination.numRows - _ids.length,
    });
    setrows(resRows);
    funcionAalerta();
  };

  const changeObjetoActivoState = (item) => {
    setObjetoActivo(item);
    // dispatch({
    //   type: CHANGE_ACTIVO,
    //   payload: item,
    // });
  };

  const rowsImportState = (rows) => {
    // dispatch({
    //   type: IMPORTAR_ROWS,
    //   payload: rows,
    // });
    setrows(rows);
  };
  // FIN FUNCIONES STATE
  // FIN DATA STATE

  // FUNCIONES SOCKET
  const [tabla] = useState("proveedor");
  const [itemSocket, setitemSocket] = useState({
    tipo: "",
    item: {},
  });
  const socket = useRef();
  useEffect(() => {
    // Creates a WebSocket connection
    socket.current = io(socketUrl);
    socket.current.on("server:guardadoExitoso", (data) => {
      if (data.tabla !== tabla) {
        return;
      }

      setitemSocket({
        tipo: "agregar",
        item: data,
      });
    });
    socket.current.on("server:actualizadoExitoso", (data) => {
      if (data.tabla !== tabla) {
        return;
      }

      setitemSocket({
        tipo: "editar",
        item: data,
      });
    });
    socket.current.on("server:eliminadoExitoso", (data) => {
      if (data.tabla !== tabla) {
        return;
      }
      setitemSocket({
        tipo: "eliminar",
        item: data,
      });
    });
    socket.current.on("server:eliminadoExitosoSeleccionado", (data) => {
      if (data.tabla !== tabla) {
        return;
      }
      setitemSocket({
        tipo: "eliminarSeleccion",
        item: data,
      });
    });

    socket.current.on("server:error", (data) => {
      mostrarAlerta(
        data.msg ? data.msg : "Hubo un error",
        data.alert ? data.alert : "error"
      );
    });
    return () => {
      socket.current.disconnect();
    };
    // Destroys the socket reference
    // when the connection is closed
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const { item, tipo } = itemSocket;

    if (tipo === "") {
      return;
    }
    if (!rows) {
      return;
    }
    if (usuario.rucempresa !== item.rucempresa) {
      return;
    }

    const funcionAalerta = () => {
      if (item.msg === "") {
        return null;
      }
      if (!item.msg) {
        return null;
      }
      mostrarAlerta(item.msg, item.alert ? item.alert : "success");
    };

    if (tipo === "agregar") {
      agregarState(item);
    }
    if (tipo === "editar") {
      console.log({ item });
      editarState(item);
    }
    if (tipo === "eliminar") {
      eliminarState(item.perId);
    }
    if (tipo === "eliminarSeleccion") {
      eliminarSeleccionState(item.lista);
    }
    funcionAalerta();
    setitemSocket({ tipo: "", item: {} });
    // eslint-disable-next-line
  }, [itemSocket]);
  // FIN FUNCIONES SOCKET
  const [arrayExport, setarrayExport] = useState([]);
  useEffect(() => {
    if (!desdeOtroLugarMenu) {
      return;
    }

    setpersonasSeleccionadas(arrayExport);
    //eslint-disable-next-line
  }, [arrayExport]);

  const [open, setopen] = useState(false);
  const [buscando, setbuscando] = useState(false);
  const [editar, seteditar] = useState(false);
  const [search, setsearch] = useState("");
  const [cargando, setcargando] = useState(false);
  const [input, setinput] = useState({
    id: "perFecUpd",
    label: "ID",
    minWidth: 150,
    tipo: "string",

    propiedad: "perFecUpd",
  });

  const { mostrarAlerta } = useContext(AlertaContext);
  const {
    usuario: { rucempresa, empRoles },
    usuario,
  } = useContext(AuthContext);
  const [orden, setorden] = useState(false);
  const [openMB, setopenMB] = useState(false);
  const [fullBuscador, setfullBuscador] = useState({});
  //pagina actual
  const [page, setPage] = React.useState(0);
  //filas por pagina
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  // input
  //
  const handleChange = (e) => {
    setfullBuscador({ ...fullBuscador, [e.target.name]: e.target.value });
  };
  const funcionB = async () => {
    setcargando(true);
    await remplazarRowsState(
      rowsPerPage,
      0,
      search,
      input.id,
      orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      estadoRequest,
      cumple
    );
    setPage(0);
    setbuscando(true);
    setopenMB(false);
    setcargando(false);
  };

  const handleSearch = async () => {
    setarrayExport([]);
    setcargando(true);
    await remplazarRowsState(
      rowsPerPage,
      0,
      search,
      input.id,
      orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      estadoRequest,
      cumple
    );

    setPage(0);
    setcargando(false);
  };
  useEffect(() => {
    const fullBRes = defaultFullBuscador();

    setfullBuscador(fullBRes);
    // eslint-disable-next-line
  }, []);
  const funcionDefault = async () => {
    setcargando(true);
    const fullBRes = defaultFullBuscador();
    setfullBuscador(fullBRes);
    await remplazarRowsState(
      rowsPerPage,
      0,
      "",
      input.id,
      orden,
      JSON.stringify(fullBRes),
      rucempresa,
      estadoRequest,
      cumple
    );

    setPage(0);
    setsearch("");
    setbuscando(false);
    setcargando(false);
    setopenMB(false);
  };
  const funcArchivo = async (e) => {
    if (!e.target.files[0]) {
      return;
    } else {
      if (e.target.files[0].type !== "text/plain") {
        return mostrarAlerta("El archivo no es de texto");
      } else {
        const formDataLogos = new FormData();
        formDataLogos.append("archivo", e.target.files[0]);
        formDataLogos.append("tipo", "compras");
        const res = await clienteAxios.post(
          "/uploads_comprastxt",
          formDataLogos
        );
        rowsImportState({ res });
      }
    }
  };

  return (
    <Box>
      <BarraTablas
        search={search}
        setsearch={setsearch}
        funcion={handleSearch}
        buscando={buscando}
        setbuscando={setbuscando}
        setopen={setopen}
        open={open}
        openMB={openMB}
        setopenMB={setopenMB}
        LabelBuscador="Buscar por todos los campos"
        funcionDefault={funcionDefault}
        subirArchivo={false}
        funcArchivo={funcArchivo}
        ocultarBuscar={false}
        ocultarMigasDePan={desdeOtroLugarMascota || desdeOtroLugarMenu}
        ocultarAgregar={desdeOtroLugarMenu}
        ocultarOpciones={desdeOtroLugarMenu}
      />{" "}
      <ModalB
        defaultFullBuscador={defaultFullBuscador}
        openMB={openMB}
        setopenMB={setopenMB}
        titulo={`Busqueda avanzada (Personas${
          desdeOtroLugarMenu ? "" : `: ${estadoRequest}`
        })`}
        fullBuscador={fullBuscador}
        setfullBuscador={setfullBuscador}
        arrayProps={columns}
        handleChange={handleChange}
        funcionB={funcionB}
        buscando={buscando}
        funcionDefault={funcionDefault}
      />{" "}
      {desdeOtroLugarMenu ? null : (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems=" stretch"
          width="100%"
        >
          <Box>
            {empRoles.slice(2).map((itemRol, index) => {
              const rolCliente = usuario.rol.find((item) => item === "CLIENTE");
              if (
                usuario.rol.length === 1 &&
                rolCliente &&
                itemRol !== "CLIENTE"
              ) {
                return null;
              }

              return (
                <Button
                  size="small"
                  key={index}
                  variant={estadoRequest === itemRol ? "contained" : null}
                  onClick={async () => {
                    setcargando(true);
                    await remplazarRowsState(
                      rowsPerPage,
                      0,
                      "",
                      input.id,
                      orden,
                      JSON.stringify(fullBuscador),
                      rucempresa,
                      itemRol,
                      cumple
                    );

                    setPage(0);
                    setestadoRequest(itemRol);
                    setcargando(false);
                  }}
                  disableElevation
                  style={{
                    padding: ".5rem",
                    borderRadius: "15px 15px  0px 0px",
                  }}
                  color="primary"
                >
                  {itemRol}
                </Button>
              );
            })}
          </Box>{" "}
          <Button
            size="small"
            variant={cumple ? "contained" : null}
            onClick={async () => {
              setcargando(true);
              await remplazarRowsState(
                rowsPerPage,
                0,
                "",
                input.id,
                orden,
                JSON.stringify(fullBuscador), 
                rucempresa,
                estadoRequest,
                !cumple
              );
              setcumple(!cumple);
              setPage(0);
              setcargando(false);
            }}
            disableElevation
            style={{
              padding: ".5rem",
              borderRadius: "15px 15px  0px 0px",
            }}
            color="success"
          >
            CUMPLEAÑOS
          </Button>
        </Box>
      )}
      <Divider />
      <Tabla
        estadoRequest={estadoRequest}
        cumple={cumple}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        search={search}
        input={input}
        orden={orden}
        setinput={setinput}
        setorden={setorden}
        seteditar={seteditar}
        setopen={setopen}
        buscando={buscando}
        cargando={cargando}
        setcargando={setcargando}
        fullBuscador={fullBuscador}
        //  STATE
        rows={rows}
        columns={columns}
        ObjectoActivo={ObjectoActivo}
        pagination={pagination}
        // FUNCIONES STATE
        getDataState={getDataState}
        obtenerState={obtenerState}
        addRowsState={addRowsState}
        remplazarRowsState={remplazarRowsState}
        agregarState={agregarState}
        editarState={editarState}
        eliminarState={eliminarState}
        eliminarSeleccionState={eliminarSeleccionState}
        changeObjetoActivoState={changeObjetoActivoState}
        rowsImportState={rowsImportState}
        arrayExport={arrayExport}
        setarrayExport={setarrayExport}
        desdeOtroLugarMascota={desdeOtroLugarMascota}
        socket={socket}
        desdeOtroLugarMenu={desdeOtroLugarMenu}
        rolesSeleccionados={rolesSeleccionados}
      />
      <Modal
        estadoRequest={estadoRequest}
        editar={editar}
        seteditar={seteditar}
        setopen={setopen}
        open={open}
        ObjectoActivo={ObjectoActivo}
        changeObjetoActivoState={changeObjetoActivoState}
        socket={socket}
      />
      {/* <>
        <Editor
          apiKey="gjwcm6gty3wp959ec6t6utn0tlrn192rvg6p1qswidouaggl"
          onInit={(evt, editor) => (editorRef.current = editor)}
          initialValue="<p>This is the initial content of the editor.</p>"
          onKeyUp={(e) => {}}
          init={{
            height: 500,
            menubar: true,
            plugins: [
              "advlist",
              "autolink",
              "lists",
              "link",
              "image",
              "charmap",
              "preview",
              "anchor",
              "searchreplace",
              "visualblocks",
              "code",
              "fullscreen",
              "insertdatetime",
              "media",
              "table",
              "code",
              "help",
              "wordcount",
            ],
            toolbar:
              "undo redo | blocks | " +
              "bold italic forecolor | alignleft aligncenter " +
              "alignright alignjustify | bullist numlist outdent indent | " +
              "removeformat | help",
            content_style:
              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
          }}
        />
      </> */}
    </Box>
  );
};

export default memo(Proveedores);
