import React, { memo, useContext } from "react";
import { makeStyles } from "@mui/styles";
import { TableCell, TableRow, TextField } from "@mui/material";

import { useState } from "react";
import ConfirmacionDialog from "../../../../components/Extra/ConfirmacionDialog";
import { withRouter } from "react-router";
import ButtonsAction from "./ButtonsAction";
import AuthContext from "../../../../Context/Auth/AuthContext";
import { tienePermisoRuta } from "../../../../config/validaciones";
import MenuContext from "../../../../Context/Menu/MenuContext";
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import { permisoDenagado, trimPropsItem } from "../../../../config/const";
const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  hover: {
    "&:hover": {
      opacity: ".7",
      cursor: "pointer",
    },
  },
  celda: {
    fontSize: ".7rem !important",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));

const Row = (props) => {
  const {
    arrayExport,
    setarrayExport,
    row,
    index,
    campoExport,
    puedeExportar,
    desdeOtroLugar,
    socket,
  } = props;
  const { usuario } = useContext(AuthContext);
  const { rows: rowsMenu } = useContext(MenuContext);
  const { mostrarAlerta } = useContext(AlertaContext);
  const [item, setitem] = useState(row);
  const [editar, seteditar] = useState(false);
  const classes = useRowStyles();

  const handleEliminar = async (_id) => {
    try {
      socket.current.emit("client:eliminarData", {
        nombre: usuario.nombre,
        tabla: "parametro",
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        paramUser: usuario.usuario,
        paramEmpresa: usuario.rucempresa,
        paramId: row.paramId,
      });
    } catch (error) {}
  };

  const handleChange = (e) => {
    setitem({
      ...item,
      [e.target.name]: e.target.value,
    });
  };
  const funcionEdit = () => {
    if (
      !tienePermisoRuta(
        rowsMenu,
        props.location.pathname,
        usuario.rol,
        "editar"
      )
    ) {
      return mostrarAlerta(permisoDenagado, "error");
    }
    seteditar(true);
  };

  const funcCancel = () => {
    setitem(row);
    seteditar(false);
  };
  const funcSubmit = () => {
    try {
      socket.current.emit(
        "client:actualizarData",
        trimPropsItem({
          ...item,
          tabla: "parametro",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          paramUser: `${row.paramUser.split(" ** ")[0]} ** ${usuario.usuario}`,
          paramEmpresa: usuario.rucempresa,
        })
      );
      seteditar(false);
    } catch (error) {}
  };
  const botonDisabled = () => {
    if (item.paramNombre === "") {
      return true;
    } else {
      return false;
    }
  };
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        titulo={`¿Esta seguro de eliminar este registro?`}
        _id={row._id}
        funcion={handleEliminar}
      />
      <TableRow className={classes.root} selected={index % 2 === 0}>
        {/* boton */}
        <ButtonsAction
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          row={row}
          setopenConfirmDialog={setopenConfirmDialog}
          funcionEdit={funcionEdit}
          ocultarEditar={false}
          ocultarDelete={false}
          mostrarEstado={false}
          campoExport={campoExport}
          editar={editar}
          campoEstado=""
          puedeExportar={puedeExportar}
          desdeOtroLugar={desdeOtroLugar}
          funcCancel={funcCancel}
          funcSubmit={funcSubmit}
          botonDisabled={botonDisabled}
        />
        {editar ? (
          <>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              <TextField
                value={item.paramNombre}
                name="paramNombre"
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
              />
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              <TextField
                value={item.paramValor}
                name="paramValor"
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
              />
            </TableCell>
          </>
        ) : (
          <>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              {row.paramNombre}
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              {row.paramValor}
            </TableCell>
          </>
        )}
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
