export const columns = [
  {
    id: "tablaNombre",
    label: "Nombre",
    minWidth: 125,
    tipo: "string",
    orden: 1,
    propiedad: "tablaNombre",
    align: "center",
    ocultar: true,
  },
];
export const objectDefault = {
  // tablaId: 1117,
  doc: "",
  razonsocial: "",
  cliEmpresa: [""],
  tablaColumnas: [],
  cliFecReg: "",
};
export const itemHijoDefecto = {
  consignas: [],
  guardias: "1",
  horasservicio: "4",
  puesto: "",
  supervisor: "1",
  tipoinstalacion: "",
  ubicacion: "",
  coordenadas: {
    longitud: "",
    latitud: "",
  },
  vulnerabilidades: "",
};
export const columnasHijo = [
  {
    id: "nombre",
    label: "Nombre",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "nombre",
    align: "center",
    ocultar: true,
  },
 
];
