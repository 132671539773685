import React, { memo, useContext } from "react";

import { tienePermisoRuta } from "../../../../config/validaciones";
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import { permisoDenagado } from "../../../../config/const";
import AuthContext from "../../../../Context/Auth/AuthContext";
import MenuContext from "../../../../Context/Menu/MenuContext";
import { withRouter } from "react-router-dom";
import { Download, IntegrationInstructions } from "@mui/icons-material";
import { serverphp } from "../../../../config/const";

// MATERIAL
import { makeStyles } from "@mui/styles";
import {
  Avatar,
  List,
  ListItemAvatar,
  ListItemText,
  DialogTitle,
  Dialog,
  Box,
  ListItemButton,
} from "@mui/material";
import { DeleteForever, Email, Error } from "@mui/icons-material";
const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  delete: {
    backgroundColor: theme.palette.error.main,
  },
  bg: {
    backgroundColor: theme.palette.primary.dark,
  },
}));
const ExportDialog = (props) => {
  const classes = useStyles();
  const {
    open,
    setopen,
    setopenConfirmDialog,
    row,
    setopenModalEmail,
    socket,
  } = props;
  const { mostrarAlerta } = useContext(AlertaContext);
  const { usuario } = useContext(AuthContext);
  const { rows } = useContext(MenuContext);

  return (
    <Dialog
      onClose={() => setopen(false)}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <Box padding={2}>
        <DialogTitle id="simple-dialog-title">Acciones.</DialogTitle>
        <List>
          <ListItemButton
            disabled={row.estado === "AUTORIZADO"}
            onClick={() => {
              if (
                !tienePermisoRuta(
                  rows,
                  props.location.pathname,
                  usuario.rol,
                  "eliminar"
                )
              ) {
                return mostrarAlerta(permisoDenagado, "error");
              }
              setopenConfirmDialog(true);
            }}
          >
            <ListItemAvatar>
              <Avatar className={classes.delete}>
                <DeleteForever />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Eliminar" />
          </ListItemButton>
          <ListItemButton
            disabled={!(row.estado === "AUTORIZADO")}
            onClick={() => {
              // Abres la nueva ventas

              window.open(
                `${serverphp}/reportes/xml.php?letra=R&empresa=${row.rucEmpresa}&codigo=${row.rDocumento}`,
                "_blank"
              );
            }}
          >
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <IntegrationInstructions />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="XML" />
          </ListItemButton>
          <ListItemButton
            disabled={
              !(
                row.estado === "AUTORIZADO" ||
                row.estado === "ANULADA" ||
                row.estado === "PROCESADO" ||
                row.estado === "GASTOS PERSONALES"
              )
            }
            onClick={() => {
              setopenModalEmail(true);
            }}
          >
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <Email />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Correo" />
          </ListItemButton>
          <ListItemButton
            disabled={!(row.errorAutorizacion === "SI")}
            onClick={() => {
              window.open(
                `${serverphp}/procesosSRI/errores/${row.rucEmpresa}/R${row.rDocumento}.txt`,
                "_blank"
              );
            }}
          >
            <ListItemAvatar>
              <Avatar className={classes.delete}>
                <Error />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Error" />
          </ListItemButton>
          <ListItemButton
            onClick={() => {
              socket.current.emit("client:actualizarData", {
                tabla: "descagar_xml_factura_compra",
                rucempresa: usuario.rucempresa,
                compId: row.compId,
              });
              setopen(false);
            }}
          >
            <ListItemAvatar>
              <Avatar className={classes.bg}>
                <Download />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Descargar XML" />
          </ListItemButton>
        </List>
      </Box>
    </Dialog>
  );
};

export default memo(withRouter(ExportDialog));
