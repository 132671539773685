import React, { useState } from "react";

import {
  Box,
  Divider,
  Typography,
  Paper,
  TableContainer,
  Table,
  TablePagination,
  TableBody,
  TableHead,
} from "@mui/material";

import ToolBarTable from "../ToolBarTable";
import TablaCabecera from "../TablaCabecera";
// MEDICAMENTOS
import RowAgregarAlimento from "./AlimentosCabecera/RowAgregarAlimento";
import RowAlimento from "./AlimentosCabecera/RowAlimento";
import RowAlimentoHorario from "./AlimentoHorario/RowAlimentoHorario";
const TablaMedicamentos = (props) => {
  // const objetcD = {
  //   alimentosCabecera: [
  //     {
  //       order: "",
  //       alimento: "",
  //       dosis: "",
  //       cantidad: "1",
  //       frecuencia: "1",
  //       inicio: "0",
  //     },
  //   ],
  //   alimentosHorario: [
  //     {
  //       nameAlimentosHorarios: "NUTRAPRO",
  //     },
  //   ],
  // };

  const { hospAlimentos, setitem, item, error } = props;

  // TABLA MEDICAMENTOS
  const [agregandoMedicamento, setagregandoMedicamento] = useState(false);
  const [pageMedicamento, setPageMedicamento] = React.useState(0);
  const [rowsPerPageMedicamento, setRowsPerPageMedicamento] =
    React.useState(10);
  const handleChangePageMedicamento = async (event, newPage) => {
    setPageMedicamento(newPage);
  };
  const handleChangeRowsPerPageMedicamento = (event) => {
    setRowsPerPageMedicamento(+event.target.value);
    setPageMedicamento(0);
  };

  // TABLA MEDICAMENTOS HORARIOS
  // const [agregandoMedicamentoHorario, setagregandoMedicamentoHorario] =
  //   useState(false);
  const [pageMedicamentoHorario, setPageMedicamentoHorario] = React.useState(0);
  const [rowsPerPageMedicamentoHorario, setRowsPerPageMedicamentoHorario] =
    React.useState(10);
  const handleChangePageMedicamentoHorario = async (event, newPage) => {
    setPageMedicamentoHorario(newPage);
  };
  const handleChangeRowsPerPageMedicamentoHorario = (event) => {
    setRowsPerPageMedicamentoHorario(+event.target.value);
    setPageMedicamentoHorario(0);
  };

  return (
    <>
      {/* CABECERA  */}
      <Box
        sx={{
          flexGrow: 1,
          padding: ".5rem",
          margin: ".5rem",
          width: "95%",
        }}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Divider style={{ width: "40%" }} />
        <Typography
          style={{ width: "100%" }}
          variant="subtitle1"
          color="secondary"
          align="center"
          component={"span"}
        >
          Cabecera
        </Typography>
        <Divider style={{ width: "40%" }} />
      </Box>
      <>
        <Paper sx={{ width: "100%" }}>
          {error.hospAlimentos ? (
            <Typography variant="caption" color="error">
              Ingrese al menos un alimento
            </Typography>
          ) : null}
          <ToolBarTable
            titulo={"Alimentos"}
            soloTitulo={true}
            botonAdd={true}
            funcionAdd={() => {
              setagregandoMedicamento(true);
            }}
          />
          <TableContainer>
            <Table
              stickyHeader
              aria-label="sticky table"
              id="tabla"
              size="small"
            >
              <caption
                style={{
                  padding: "0px",
                  paddingRight: "1rem",
                  paddingLeft: "1rem",
                }}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <TablePagination
                    labelRowsPerPage="Filas por pagina"
                    rowsPerPageOptions={[10, 20, 50, 100, 500, 1000,  ]}
                    component="div"
                    count={hospAlimentos.alimentosCabecera.length}
                    rowsPerPage={rowsPerPageMedicamento}
                     
                    page={pageMedicamento}
                    onPageChange={handleChangePageMedicamento}
                    onRowsPerPageChange={handleChangeRowsPerPageMedicamento}
                  />
                </Box>
              </caption>
              {/* <ModalCarnet setopen={setopen} onChangeCarnetActivo={onChangeCarnetActivo}/> */}

              <TableHead>
                <TablaCabecera
                  columns={[
                    { id: "alimento", label: "alimento", minWidth: 100 },
                    { id: "dosis", label: "dosis", minWidth: 100 },
                    { id: "cantidad", label: "cantidad", minWidth: 100 },
                    { id: "inicio", label: "inicio", minWidth: 100 },
                    { id: "frecuencia", label: "frecuencia", minWidth: 100 },
                  ]}
                  habilitarOrdenar={false}
                />
              </TableHead>

              <TableBody>
                {agregandoMedicamento ? (
                  <RowAgregarAlimento
                    setagregar={setagregandoMedicamento}
                    setitem={setitem}
                    item={item}
                  />
                ) : null}
                {hospAlimentos.alimentosCabecera.map((row, index) => {
                  return (
                    <RowAlimento
                      deshabilitarbotones={false}
                      item={item}
                      setitem={setitem}
                      row={row}
                      index={index}
                      key={row.order}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </>
      {/* HORARIOS */}
      <Box
        sx={{
          flexGrow: 1,
          padding: ".5rem",
          margin: ".5rem",
          width: "95%",
        }}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Divider style={{ width: "40%" }} />
        <Typography
          style={{ width: "100%" }}
          variant="subtitle1"
          color="secondary"
          align="center"
          component={"span"}
        >
          Horarios
        </Typography>
        <Divider style={{ width: "40%" }} />
      </Box>
      <>
        <Paper sx={{ width: "100%" }}>
          {error.hospAlimentos ? (
            <Typography variant="caption" color="error">
              Ingrese al menos un alimento
            </Typography>
          ) : null}

          <TableContainer>
            <Table
              stickyHeader
              aria-label="sticky table"
              id="tabla"
              size="small"
            >
              <caption
                style={{
                  padding: "0px",
                  paddingRight: "1rem",
                  paddingLeft: "1rem",
                }}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <TablePagination
                    labelRowsPerPage="Filas por pagina"
                    rowsPerPageOptions={[10, 20, 50, 100, 500, 1000,  ]}
                    component="div"
                    count={hospAlimentos.alimentosHorario.length}
                    rowsPerPage={rowsPerPageMedicamentoHorario}
                     
                    page={pageMedicamentoHorario}
                    onPageChange={handleChangePageMedicamentoHorario}
                    onRowsPerPageChange={
                      handleChangeRowsPerPageMedicamentoHorario
                    }
                  />
                </Box>
              </caption>
              {/* <ModalCarnet setopen={setopen} onChangeCarnetActivo={onChangeCarnetActivo}/> */}

              <TableHead>
                <TablaCabecera
                  columns={[
                    {
                      id: "fecha",
                      label: "fecha",
                      minWidth: 50,
                    },
                    {
                      id: "nameMedicinaHorarios",
                      label: "Medicina",
                      minWidth: 100,
                    },

                    {
                      id: "h0",
                      label: "0",
                      minWidth: 15,
                    },

                    {
                      id: "h1",
                      label: "1",
                      minWidth: 15,
                    },

                    {
                      id: "h2",
                      label: "2",
                      minWidth: 15,
                    },

                    {
                      id: "h3",
                      label: "3",
                      minWidth: 15,
                    },

                    {
                      id: "h4",
                      label: "4",
                      minWidth: 15,
                    },

                    {
                      id: "h5",
                      label: "5",
                      minWidth: 15,
                    },

                    {
                      id: "h6",
                      label: "6",
                      minWidth: 15,
                    },

                    {
                      id: "h7",
                      label: "7",
                      minWidth: 15,
                    },

                    {
                      id: "h8",
                      label: "8",
                      minWidth: 15,
                    },

                    {
                      id: "h9",
                      label: "9",
                      minWidth: 15,
                    },

                    {
                      id: "h10",
                      label: "10",
                      minWidth: 15,
                    },

                    {
                      id: "h11",
                      label: "11",
                      minWidth: 15,
                    },

                    {
                      id: "h12",
                      label: "12",
                      minWidth: 15,
                    },

                    {
                      id: "h13",
                      label: "13",
                      minWidth: 15,
                    },

                    {
                      id: "h14",
                      label: "14",
                      minWidth: 15,
                    },

                    {
                      id: "h15",
                      label: "15",
                      minWidth: 15,
                    },

                    {
                      id: "h16",
                      label: "16",
                      minWidth: 15,
                    },

                    {
                      id: "h17",
                      label: "17",
                      minWidth: 15,
                    },

                    {
                      id: "h18",
                      label: "18",
                      minWidth: 15,
                    },

                    {
                      id: "h19",
                      label: "19",
                      minWidth: 15,
                    },

                    {
                      id: "h20",
                      label: "20",
                      minWidth: 15,
                    },

                    {
                      id: "h21",
                      label: "21",
                      minWidth: 15,
                    },

                    {
                      id: "h22",
                      label: "22",
                      minWidth: 15,
                    },
                    {
                      id: "h23",
                      label: "23",
                      minWidth: 15,
                    },
                  ]}
                  habilitarOrdenar={false}
                />
              </TableHead>

              <TableBody>
                {hospAlimentos.alimentosHorario.map((row, index) => {
                  return (
                    <RowAlimentoHorario
                      deshabilitarbotones={false}
                      item={item}
                      setitem={setitem}
                      row={row}
                      index={index}
                      key={row.order}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </>
    </>
  );
};

export default TablaMedicamentos;
