import React, { useState, useContext, useEffect, memo } from "react";
import { makeStyles } from "@mui/styles";
import {
  Modal,
  Box,
  Button,
  Divider,
  Typography,
  TextField,
  FormControl,
  Autocomplete,
  MenuItem,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@mui/material";

import Draggable from "react-draggable";
import { Cancel, Receipt } from "@mui/icons-material";
import { IconButton } from "@mui/material";
// eslint-disable-next-line
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import AuthContext from "../../../../Context/Auth/AuthContext";
import clienteAxios from "../../../../config/axios";

import { trimPropsItem } from "../../../../config/const";
import { usePermiso } from "../../../../hooks/usePermiso";
import Editor from "../../../../components/Extra/Editor";
function getModalStyle() {
  return {
    borderRadius: "15px",
    position: "absolute",
    overflow: "scroll",
    maHexight: "80vh",
  };
}
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "55%",
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1, 2),
    borderRadius: "15px",
    marginTop: "5vh",
    [theme.breakpoints.down("sm")]: { width: "90%", marginTop: "2vh" },
  },
  margin: {
    margin: theme.spacing(1),
    width: "45%",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  ocultar: { display: "none" },
  inputPadding: {
    padding: theme.spacing(0.5),
    margin: theme.spacing(0),
    fontSize: "1rem",
  },
  formD: {
    flexGrow: 1,
    width: "45%",
    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  formAllW: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "95%",
    [theme.breakpoints.down("md")]: { padding: "0", margin: "2px" },
    [theme.breakpoints.down("sm")]: { padding: "0", margin: "2px" },
  },

  form30PC: {
    flexGrow: 1,
    width: "30%",
    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "30%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },

  center: { justifyContent: "center", alignItems: "center" },
  root: { width: "100%", marginTop: theme.spacing(1) },
  correo: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "45%",
    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  error: { color: "red", fontWeight: "bold" },
}));
const ModalData = (props) => {
  const { tienePermiso, alertaPermiso } = usePermiso("Peluqueria");
  const { mostrarAlerta } = useContext(AlertaContext);
  // eslint-disable-next-line
  const { usuario } = useContext(AuthContext); //props
  const {
    open,
    setopen,
    editar,
    seteditar,
    ObjectoActivo,
    changeObjetoActivoState,
    socket,
    setopenFactura,
    seteditarFactura,
    changeObjetoActivoStateFactura,
    ObjectoActivoFactura,
  } = props;
  // eslint-disable-next-line
  const [item, setitem] = useState(ObjectoActivo);
  const {
    // pelMascId,
    pelMascNombre,
    // pelPerId,
    // pelPerNombre,
    // pelPerDocNumero,
    // pelPerTelefono,
    // pelPerCelular,
    // pelPerDireccion,
    // pelPerEmail,
    // pelPerIdDoc,
    pelPerNombreDoc,
    pelTemperamento,
    pelAutorizo,
    pelTurno,
    pelFecha,
    pelHoraIngreso,
    pelHoraSalida,
    pelEstado,
    pelLlamar30min,
    pelProxCita,
    pelCara,
    pelFormaCabeza,
    pelCopete,
    pelOreja,
    pelBigotes,
    pelBigotesForma,
    pelCejas,
    pelCuerpo,
    pelLomo,
    pelFaldon,
    pelCola,
    pelColaForma,
    pelPatitas,
    pelDeclaro,
    pelOidos,
    pelLimpiezaOcular,
    pelLavadoDientes,
    pelCorteUna,
    pelUnasDedos,
    pelRapadoGenitales,
    pelDrenaje,
    pelBano,
    pelSecado,
    pelCortesAcabados,
    pelPerfume,
    pelPresencia,
    pelRetira,
    pelCarnet,
    // pelUser,
    // pelEmpresa,
    // pelValor,
    // pelCorreo,
    // pelObservacion,
    // pelOtrosProcesos,
    // pelDejaAccesorios,
    pelPerNombre,
    // pelFecUpdate,
    // pelFecReg,
    // Todos,
    idFactura,
    pelLazoCentral,
    pelDosLazos,
    pelBufanda,
    pelPanuelo,
  } = item;

  const [itemSocket, setitemSocket] = useState({
    tipo: "",
    itemSocketData: {},
  });

  useEffect(() => {
    socket.current.on("server:guardadoExitoso", (data) => {
      setitemSocket({
        tipo: "agregar",
        itemSocketData: data,
      });
    });
    socket.current.on("server:actualizadoExitoso", (data) => {
      setitemSocket({
        tipo: "editar",
        itemSocketData: data,
      });
    });

    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    const { itemSocketData, tipo } = itemSocket;

    if (!open) {
      return;
    }

    if (tipo === "") {
      return;
    }

    if (usuario.rucempresa !== itemSocketData.rucempresa) {
      return;
    }

    const funcionAalerta = () => {
      if (itemSocketData.msg === "") {
        return null;
      }
      if (!itemSocketData.msg) {
        return null;
      }
      mostrarAlerta(
        itemSocketData.msg,
        itemSocketData.alert ? itemSocketData.alert : "success"
      );
    };

    if (tipo === "agregar") {
      if (itemSocketData.tabla === "venta") {
        if (usuario.usuario !== itemSocketData.venUser) {
          return;
        }

        socket.current.emit(
          "client:actualizarData",
          trimPropsItem({
            ...ObjectoActivo,
            idFactura: itemSocketData.venId,
            rucempresa: usuario.rucempresa,
            rol: usuario.rol,
            tabla: "peluqueria",
            pelUser: `${ObjectoActivo.pelUser.split(" ** ")[0]} ** ${
              usuario.usuario
            }`,
            pelEmpresa: usuario.rucempresa,
          })
        );

        setitem({
          ...item,
          idFactura: String(itemSocketData.venId),
        });
      }
    }

    funcionAalerta();
    setitemSocket({ tipo: "", itemSocketData: {} });
    // eslint-disable-next-line
  }, [itemSocket]);
  const [veterinariosData, setveterinariosData] = useState([]);
  const [mascotasData, setmascotasData] = useState([]);
  const [peluqueriaData, setpeluqueriaData] = useState(null);
  useEffect(() => {
    if (peluqueriaData) {
      const pelCaraDataItem = peluqueriaData.find(
        (itemPeluqueria) => itemPeluqueria.pedipeNombre === "CARA"
      );

      setpelCaraData(
        pelCaraDataItem
          ? pelCaraDataItem.pedipeOptions.map((item) => item.nombre)
          : []
      );
      const pelFormaCabezaDataItem = peluqueriaData.find(
        (itemPeluqueria) => itemPeluqueria.pedipeNombre === "FORMA DE LA CABEZA"
      );

      setpelFormaCabezaData(
        pelFormaCabezaDataItem
          ? pelFormaCabezaDataItem.pedipeOptions.map((item) => item.nombre)
          : []
      );
      const pelCopeteDataItem = peluqueriaData.find(
        (itemPeluqueria) => itemPeluqueria.pedipeNombre === "COPETE"
      );

      setpelCopeteData(
        pelCopeteDataItem
          ? pelCopeteDataItem.pedipeOptions.map((item) => item.nombre)
          : []
      );
      const pelOrejaDataItem = peluqueriaData.find(
        (itemPeluqueria) => itemPeluqueria.pedipeNombre === "OREJAS"
      );

      setpelOrejaData(
        pelOrejaDataItem
          ? pelOrejaDataItem.pedipeOptions.map((item) => item.nombre)
          : []
      );
      const pelBigotesDataItem = peluqueriaData.find(
        (itemPeluqueria) => itemPeluqueria.pedipeNombre === "BIGOTES"
      );

      setpelBigotesData(
        pelBigotesDataItem
          ? pelBigotesDataItem.pedipeOptions.map((item) => item.nombre)
          : []
      );
      const pelBigotesFormaDataItem = peluqueriaData.find(
        (itemPeluqueria) => itemPeluqueria.pedipeNombre === "BIGOTES FORMA"
      );

      setpelBigotesFormaData(
        pelBigotesFormaDataItem
          ? pelBigotesFormaDataItem.pedipeOptions.map((item) => item.nombre)
          : []
      );
      const pelCejasDataItem = peluqueriaData.find(
        (itemPeluqueria) => itemPeluqueria.pedipeNombre === "CEJAS"
      );

      setpelCejasData(
        pelCejasDataItem
          ? pelCejasDataItem.pedipeOptions.map((item) => item.nombre)
          : []
      );
      const pelCuerpoDataItem = peluqueriaData.find(
        (itemPeluqueria) => itemPeluqueria.pedipeNombre === "TODO EL CUERPO"
      );

      setpelCuerpoData(
        pelCuerpoDataItem
          ? pelCuerpoDataItem.pedipeOptions.map((item) => item.nombre)
          : []
      );
      const pelLomoDataItem = peluqueriaData.find(
        (itemPeluqueria) => itemPeluqueria.pedipeNombre === "SOLO LOMO"
      );

      setpelLomoData(
        pelLomoDataItem
          ? pelLomoDataItem.pedipeOptions.map((item) => item.nombre)
          : []
      );
      const pelFaldonDataItem = peluqueriaData.find(
        (itemPeluqueria) => itemPeluqueria.pedipeNombre === "FALDON-PECHO-PATAS"
      );

      setpelFaldonData(
        pelFaldonDataItem
          ? pelFaldonDataItem.pedipeOptions.map((item) => item.nombre)
          : []
      );
      const pelColaDataItem = peluqueriaData.find(
        (itemPeluqueria) => itemPeluqueria.pedipeNombre === "COLA"
      );

      setpelColaData(
        pelColaDataItem
          ? pelColaDataItem.pedipeOptions.map((item) => item.nombre)
          : []
      );
      const pelColaFormaDataItem = peluqueriaData.find(
        (itemPeluqueria) => itemPeluqueria.pedipeNombre === "COLA FORMA"
      );

      setpelColaFormaData(
        pelColaFormaDataItem
          ? pelColaFormaDataItem.pedipeOptions.map((item) => item.nombre)
          : []
      );
      const pelPatitasDataItem = peluqueriaData.find(
        (itemPeluqueria) => itemPeluqueria.pedipeNombre === "MANOS Y PATITAS"
      );

      setpelPatitasData(
        pelPatitasDataItem
          ? pelPatitasDataItem.pedipeOptions.map((item) => item.nombre)
          : []
      );
    }
  }, [peluqueriaData]);

  const [pelCaraData, setpelCaraData] = useState([]);
  const [pelFormaCabezaData, setpelFormaCabezaData] = useState([]);
  const [pelCopeteData, setpelCopeteData] = useState([]);
  const [pelOrejaData, setpelOrejaData] = useState([]);
  const [pelBigotesData, setpelBigotesData] = useState([]);
  const [pelBigotesFormaData, setpelBigotesFormaData] = useState([]);
  const [pelCejasData, setpelCejasData] = useState([]);
  const [pelCuerpoData, setpelCuerpoData] = useState([]);
  const [pelLomoData, setpelLomoData] = useState([]);
  const [pelFaldonData, setpelFaldonData] = useState([]);
  const [pelColaData, setpelColaData] = useState([]);
  const [pelColaFormaData, setpelColaFormaData] = useState([]);
  const [pelPatitasData, setpelPatitasData] = useState([]);
  const handleChange = (e) => {
    setitem({ ...item, [e.target.name]: e.target.value });
  };
  const [cargando, setcargando] = useState(false);
  const [seccionActive, setseccionActive] = useState({
    MascotaSeccion: true,
    PedidosClienteSeccion: false,
    ProcesosdePeluqueriaSeccion: false,
    AdicionalesSeccion: false,
    FacturaSeccion: false,
  });
  const {
    MascotaSeccion,
    PedidosClienteSeccion,
    ProcesosdePeluqueriaSeccion,
    AdicionalesSeccion,
    FacturaSeccion,
  } = seccionActive;
  const [error, seterror] = useState({
    pelMascNombre: false,
    pelPerNombreDoc: false,
    pelTemperamento: false,
    pelAutorizo: false,
    pelFecha: false,
    pelTurno: false,
    pelHoraIngreso: false,
    pelHoraSalida: false,
    pelEstado: false,
    pelProxCita: false,
    pelLlamar30min: false,
    pelObservacion: false,
    pelCara: false,
    pelFormaCabeza: false,
    pelCopete: false,
    pelOreja: false,
    pelBigotes: false,
    pelBigotesForma: false,
    pelCejas: false,
    pelCuerpo: false,
    pelLomo: false,
    pelFaldon: false,
    pelCola: false,
    pelColaForma: false,
    pelPatitas: false,
    pelDeclaro: false,
    pelOidos: false,
    pelLimpiezaOcular: false,
    pelLavadoDientes: false,
    pelCorteUna: false,
    pelUnasDedos: false,
    pelRapadoGenitales: false,
    pelDrenaje: false,
    pelBano: false,
    pelSecado: false,
    pelCortesAcabados: false,
    pelPerfume: false,
    pelOtrosProcesos: false,
    pelPresencia: false,
    pelRetira: false,
    pelCarnet: false,
    pelDejaAccesorios: false,
  });

  useEffect(() => {
    const getpeluqueriaData = async () => {
      try {
        const respeluqueriaData = await clienteAxios.get(
          `/pedidospeluqueria/filtro/0?search=`
        );

        setpeluqueriaData(respeluqueriaData.data.data);
      } catch (error) {
        setpeluqueriaData([]);
      }
    };

    if (!peluqueriaData) {
      getpeluqueriaData();
    }

    setitem(ObjectoActivo); // eslint-disable-next-line
  }, [open]);
  const defaultActive = () => {
    changeObjetoActivoState({
      // pelId: "",
      pelMascId: "",
      pelMascNombre: "",
      pelPerId: "",
      pelPerNombre: "",
      pelPerDocNumero: "",
      pelPerTelefono: "",
      pelPerCelular: [],
      pelPerDireccion: "",
      pelPerEmail: [],
      pelPerIdDoc: "",
      pelPerNombreDoc: "",
      pelTemperamento: "",
      pelAutorizo: "",
      pelTurno: "",
      pelFecha: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60 * 1000
      )
        .toISOString()
        .substring(0, 10),
      pelHoraIngreso: "",
      pelHoraSalida: "",
      pelEstado: "",
      pelLlamar30min: "SI",
      pelProxCita: "",
      pelCara: "",
      pelFormaCabeza: "",
      pelCopete: "",
      pelOreja: "",
      pelBigotes: "",
      pelBigotesForma: "",
      pelCejas: "",
      pelCuerpo: "",
      pelLomo: "",
      pelFaldon: "",
      pelCola: "",
      pelColaForma: "",
      pelPatitas: "",
      pelDeclaro: "NO",
      pelOidos: "NO",
      pelLimpiezaOcular: "NO",
      pelLavadoDientes: "NO",
      pelCorteUna: "NO",
      pelUnasDedos: "NO",
      pelRapadoGenitales: "NO",
      pelDrenaje: "NO",
      pelBano: "NO",
      pelSecado: "NO",
      pelCortesAcabados: "NO",
      pelPerfume: "NO",
      pelPresencia: "NO",
      pelRetira: "NO",
      pelCarnet: "NO",
      pelLazoCentral: "NO",
      pelDosLazos: "NO",
      pelBufanda: "NO",
      pelPanuelo: "NO",
      pelUser: "",
      pelEmpresa: "",
      pelValor: "0",
      pelCorreo: "PENDIENTE",
      pelObservacion: "",
      pelOtrosProcesos: "",
      pelDejaAccesorios: "",
      pelFecUpdate: "",
      pelFecReg: "",
      Todos: "",
      idFactura: "",
    });
    setopen(false);
    seteditar(false);
    seterror({});
    setseccionActive({
      MascotaSeccion: true,
      PedidosClienteSeccion: false,
      ProcesosdePeluqueriaSeccion: false,
      AdicionalesSeccion: false,
    });
    setcargando(false);
  };

  const validacion = () => {
    if (
      pelMascNombre.trim() === "" ||
      pelPerNombreDoc.trim() === "" ||
      pelTemperamento.trim() === "" ||
      pelHoraIngreso.trim() === ""
    ) {
      seterror({
        pelMascNombre: pelMascNombre.trim() === "" ? true : false,
        pelPerNombreDoc: pelPerNombreDoc.trim() === "" ? true : false,
        pelTemperamento: pelTemperamento.trim() === "" ? true : false,
        pelHoraIngreso: pelHoraIngreso.trim() === "" ? true : false,
      });
      return true;
    } else {
      return false;
    }
  };
  const handleCrear = async () => {
    if (validacion()) {
      return mostrarAlerta("Faltan campos obligatorios", "error");
    }
    try {
      setcargando(true);

      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...item,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          tabla: "peluqueria",
          pelUser: usuario.usuario,
          pelEmpresa: usuario.rucempresa,
        })
      );
      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);
      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  const handleEditar = async () => {
    if (validacion()) {
      return mostrarAlerta("Faltan campos obligatorios", "error");
    }
    try {
      setcargando(true);

      socket.current.emit(
        "client:actualizarData",
        trimPropsItem({
          ...item,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          tabla: "peluqueria",
          pelUser: `${item.pelUser.split(" ** ")[0]} ** ${usuario.usuario}`,
          pelEmpresa: usuario.rucempresa,
        })
      );
      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);
      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  //hook de estilos
  const clases = useStyles();
  //definiendo los estilos del modal
  const [modalStyle] = useState(getModalStyle);

  if (!open) {
    return null;
  }
  if (!peluqueriaData) {
    return null;
  }
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Draggable handle="#handle">
          <Box display="flex" justifyContent="center">
            <div style={modalStyle} className={clases.paper}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography
                  style={{ cursor: "-webkit-grab" }}
                  id="handle"
                  variant="button"
                  color="initial"
                  align="center"
                >
                  {editar ? "Editar" : "Agregar"}
                  {" Peluqueria"}
                </Typography>
                <Box display="flex" alignItems="center">
                  <IconButton
                    aria-label=""
                    style={{ margin: "0px" }}
                    onClick={() => {
                      defaultActive();
                    }}
                  >
                    <Cancel color="secondary" />
                  </IconButton>
                </Box>
              </Box>
              <Divider />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems=" stretch"
                width="100%"
              >
                <Box>
                  {/* Mascota {pelMascNombre} */}
                  {/* Mvz. Respon {pelPerNombreDoc} */}
                  {/* Temperamento {pelTemperamento} */}
                  {/* Autorizo {pelAutorizo} */}
                  {/* Fecha {pelFecha} */}
                  {/* Turno {pelTurno} */}
                  {/* Ingreso {pelHoraIngreso} */}
                  {/* Salida {pelHoraSalida} */}
                  {/* Estado {pelEstado} */}
                  {/* Proxima Cita {pelProxCita} */}
                  {/* Llamar 30min antes {pelLlamar30min} */}
                  {/* Obsr. al ingreso de la mascota {pelObservacion} */}
                  <Button
                    variant={
                      MascotaSeccion
                        ? "contained"
                        : error.pelMascNombre ||
                          error.pelPerNombreDoc ||
                          error.pelTemperamento ||
                          error.pelAutorizo ||
                          error.pelFecha ||
                          error.pelTurno ||
                          error.pelHoraIngreso ||
                          error.pelHoraSalida ||
                          error.pelEstado ||
                          error.pelProxCita ||
                          error.pelLlamar30min ||
                          error.pelObservacion
                        ? "outlined"
                        : null
                    }
                    disableElevation
                    style={{ padding: "8px" }}
                    color={
                      error.pelMascNombre ||
                      error.pelPerNombreDoc ||
                      error.pelTemperamento ||
                      error.pelAutorizo ||
                      error.pelFecha ||
                      error.pelTurno ||
                      error.pelHoraIngreso ||
                      error.pelHoraSalida ||
                      error.pelEstado ||
                      error.pelProxCita ||
                      error.pelLlamar30min ||
                      error.pelObservacion
                        ? "error"
                        : "secondary"
                    }
                    onClick={() => setseccionActive({ MascotaSeccion: true })}
                  >
                    Mascota
                  </Button>
                  {/* Cara {pelCara} */}
                  {/* Forma de la Cabeza {pelFormaCabeza} */}
                  {/* Copete {pelCopete} */}
                  {/* Oreja {pelOreja} */}
                  {/* Bigotes {pelBigotes} */}
                  {/* Bigotes Forma {pelBigotesForma} */}
                  {/* Cejas {pelCejas} */}
                  {/* Todo el cuerpo {pelCuerpo} */}
                  {/* Solo lomo {pelLomo} */}
                  {/* Faldon-pecho-patitas {pelFaldon} */}
                  {/* Cola {pelCola} */}
                  {/* Cola Forma {pelColaForma} */}
                  <Button
                    variant={
                      PedidosClienteSeccion
                        ? "contained"
                        : error.pelCara ||
                          error.pelFormaCabeza ||
                          error.pelCopete ||
                          error.pelOreja ||
                          error.pelBigotes ||
                          error.pelBigotesForma ||
                          error.pelCejas ||
                          error.pelCuerpo ||
                          error.pelLomo ||
                          error.pelFaldon ||
                          error.pelCola ||
                          error.pelColaForma ||
                          error.pelPatitas
                        ? "outlined"
                        : null
                    }
                    disableElevation
                    style={{ padding: "8px" }}
                    color={
                      error.pelCara ||
                      error.pelFormaCabeza ||
                      error.pelCopete ||
                      error.pelOreja ||
                      error.pelBigotes ||
                      error.pelBigotesForma ||
                      error.pelCejas ||
                      error.pelCuerpo ||
                      error.pelLomo ||
                      error.pelFaldon ||
                      error.pelCola ||
                      error.pelColaForma ||
                      error.pelPatitas
                        ? "error"
                        : "secondary"
                    }
                    onClick={() =>
                      setseccionActive({ PedidosClienteSeccion: true })
                    }
                  >
                    Pedidos Cliente
                  </Button>
                  {/* Manos y patitas {pelPatitas}  */}
                  {/* Declaro haber pedido el corte al  gusto de la veterinaria {pelDeclaro}  */}
                  {/* Oidos {pelOidos}  */}
                  {/* Limpieza Ocular {pelLimpiezaOcular}  */}
                  {/* Lavado de dientes {pelLavadoDientes}  */}
                  {/* Corte de uñas {pelCorteUna}  */}
                  {/* Uñas dedos supernumerarios {pelUnasDedos}  */}
                  {/* Rapado Genitales {pelRapadoGenitales}  */}
                  {/* Drenaje G. Perinales {pelDrenaje}  */}
                  {/* Baño {pelBano}  */}
                  {/* Secado {pelSecado}  */}
                  {/* Corte y acabados {pelCortesAcabados}  */}
                  {/* Perfume {pelPerfume}  */}
                  {/* Otros procesos {pelOtrosProcesos}  */}
                  <Button
                    variant={
                      ProcesosdePeluqueriaSeccion
                        ? "contained"
                        : error.pelDeclaro ||
                          error.pelOidos ||
                          error.pelLimpiezaOcular ||
                          error.pelLavadoDientes ||
                          error.pelCorteUna ||
                          error.pelUnasDedos ||
                          error.pelRapadoGenitales ||
                          error.pelDrenaje ||
                          error.pelBano ||
                          error.pelSecado ||
                          error.pelCortesAcabados ||
                          error.pelPerfume ||
                          error.pelOtrosProcesos
                        ? "outlined"
                        : null
                    }
                    disableElevation
                    style={{ padding: "8px" }}
                    color={
                      error.pelDeclaro ||
                      error.pelOidos ||
                      error.pelLimpiezaOcular ||
                      error.pelLavadoDientes ||
                      error.pelCorteUna ||
                      error.pelUnasDedos ||
                      error.pelRapadoGenitales ||
                      error.pelDrenaje ||
                      error.pelBano ||
                      error.pelSecado ||
                      error.pelCortesAcabados ||
                      error.pelPerfume ||
                      error.pelOtrosProcesos
                        ? "error"
                        : "secondary"
                    }
                    onClick={() =>
                      setseccionActive({ ProcesosdePeluqueriaSeccion: true })
                    }
                  >
                    Procesos de Peluqueria
                  </Button>
                  {/* Realizar en presencia del propietario o persona Autorizada {pelPresencia} */}
                  {/* El cliente retira la mascota lista {pelRetira} */}
                  {/* Deja Carnet de Vacunas {pelCarnet} */}
                  {/* Deja accesorios. Cuales.? {pelDejaAccesorios} */}
                  <Button
                    variant={
                      AdicionalesSeccion
                        ? "contained"
                        : error.pelPresencia ||
                          error.pelRetira ||
                          error.pelCarnet ||
                          error.pelDejaAccesorios
                        ? "outlined"
                        : null
                    }
                    disableElevation
                    style={{ padding: "8px" }}
                    color={
                      error.pelPresencia ||
                      error.pelRetira ||
                      error.pelCarnet ||
                      error.pelDejaAccesorios
                        ? "error"
                        : "secondary"
                    }
                    onClick={() =>
                      setseccionActive({ AdicionalesSeccion: true })
                    }
                  >
                    Adicionales
                  </Button>
                </Box>
                <Button
                  variant={
                    idFactura !== ""
                      ? "contained"
                      : error.pelProductos
                      ? "outlined"
                      : null
                  }
                  disableElevation
                  endIcon={idFactura === "" ? null : <Receipt />}
                  style={{ padding: "8px" }}
                  color={"primary"}
                  onClick={async () => {
                    if (validacion()) {
                      return mostrarAlerta("Faltan campos por llenar", "error");
                    }
                    try {
                      const resProveedor = await clienteAxios(
                        `/proveedores/${item.pelPerId}`
                      );
                      const proveedorF = resProveedor.data.data[0];
                      if (idFactura.trim() !== "") {
                        const resFactura = await clienteAxios.get(
                          `/ventas/${idFactura}`
                        );

                        seteditarFactura(true);

                        changeObjetoActivoStateFactura({
                          ...resFactura.data.data[0],
                          venOption: "3",
                        });
                        return setopenFactura(true);
                      }
                      changeObjetoActivoStateFactura({
                        ...ObjectoActivoFactura,
                        venIdCliente: proveedorF.perId,
                        venRucCliente: proveedorF.perDocNumero,
                        venTipoDocuCliente: proveedorF.perDocTipo,
                        venNomCliente: proveedorF.perNombre,
                        venEmailCliente: proveedorF.perEmail,
                        venTelfCliente: proveedorF.perTelefono,
                        venCeluCliente: proveedorF.perCelular,
                        venDirCliente: proveedorF.perDireccion,
                        venOption: "1",
                      });
                      setopenFactura(true);
                    } catch (error) {
                      mostrarAlerta("Hubo un error", "error");
                    }
                  }}
                >
                  Factura
                </Button>
              </Box>
              <Divider />
              <Box
                display="flex"
                flexWrap="wrap"
                alignItems="flex-end"
                borderRadius="0px 50px 50px 0px"
                mt={1}
                maxHeight="70vh"
                overflow={"scroll"}
                sx={{ overflowX: "hidden" }}
              >
                {/* Mascota  */}
                {MascotaSeccion ? (
                  <>
                    <FormControl fullWidth className={clases.formD}>
                      <Autocomplete
                        filterOptions={(x) => x}
                        options={mascotasData.map((item) => {
                          return (
                            item.mascNombre +
                            "/+/" +
                            item.mascPerNombre +
                            "/+/" +
                            item.mascId
                          );
                        })}
                        getOptionLabel={(option) => {
                          return option.replace("/+/", " - ").split("/+/")[0];
                        }}
                        id="controlled-demo"
                        value={
                          (pelMascNombre + " " + pelPerNombre).trim() === ""
                            ? ""
                            : pelMascNombre + " - " + pelPerNombre
                        }
                        paperprops
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            const options = newValue.split("/+/");
                            const itemF = mascotasData.find((itemPersona) => {
                              return itemPersona.mascId === Number(options[2]);
                            });

                            // pelPerIdDoc
                            setitem({
                              ...item,
                              pelMascId: itemF.mascId,
                              pelMascNombre: itemF.mascNombre,
                              pelPerId: itemF.mascPerId,
                              pelPerNombre: itemF.mascPerNombre,
                              pelPerDocNumero: itemF.mascPerDocNumero,
                              pelPerTelefono: itemF.mascPerTelefono,
                              pelPerCelular: itemF.mascPerCelular,
                              pelPerDireccion: itemF.mascPerDireccion,
                              pelPerEmail: itemF.mascPerEmail,
                            });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            error={error.pelMascNombre}
                            label="Mascota"
                            fullWidth
                            onChange={async (e) => {
                              if (e.target.value === "") {
                                return;
                              }
                              try {
                                const res = await clienteAxios.get(
                                  `/mascotas/filtro/0?search=${e.target.value}`
                                );
                                if (!res.data.data) {
                                  return mostrarAlerta(res.data.msg, "error");
                                }

                                setmascotasData(res.data.data);
                              } catch (error) {}
                            }}
                            // InputProps={{
                            //   ...params.InputProps,
                            //   endAdornment: (
                            //     <React.Fragment>
                            //       {!editar ? (
                            //         <IconButton
                            //           size="small"
                            //           aria-label=""
                            //           onClick={async () => {
                            //             seteditarProveedor(false);
                            //             setopenProveedor(true);
                            //           }}
                            //         >
                            //           <AddCircleOutlineTwoTone color="primary" />
                            //         </IconButton>
                            //       ) : null}
                            //       {params.InputProps.endAdornment}
                            //     </React.Fragment>
                            //   ),
                            // }}
                          />
                        )}
                      />
                    </FormControl>
                    <FormControl fullWidth className={clases.formD}>
                      <Autocomplete
                        filterOptions={(x) => x}
                        options={veterinariosData.map((item) => {
                          return item.perNombre + "/+/" + item.perId;
                        })}
                        getOptionLabel={(option) => {
                          return option.split("/+/")[0];
                        }}
                        id="controlled-demo"
                        value={pelPerNombreDoc}
                        paperprops
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            const options = newValue.split("/+/");
                            const itemF = veterinariosData.find(
                              (itemPersona) => {
                                return itemPersona.perId === Number(options[1]);
                              }
                            );

                            setitem({
                              ...item,
                              pelPerNombreDoc: itemF.perNombre,
                              pelPerIdDoc: itemF.perId,
                            });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            error={error.pelPerNombreDoc}
                            label="Mvz. Respon"
                            fullWidth
                            onChange={async (e) => {
                              if (e.target.value === "") {
                                return;
                              }
                              try {
                                const res = await clienteAxios.get(
                                  `/proveedores/filtro/0?search=${e.target.value}&estado=VETERINARIO`
                                );
                                if (!res.data.data) {
                                  return mostrarAlerta(res.data.msg, "error");
                                }
                                setveterinariosData(res.data.data);
                              } catch (error) {}
                            }}
                            // InputProps={{
                            //   ...params.InputProps,
                            //   endAdornment: (
                            //     <React.Fragment>
                            //       {!editar ? (
                            //         <IconButton
                            //           size="small"
                            //           aria-label=""
                            //           onClick={async () => {
                            //             seteditarProveedor(false);
                            //             setopenProveedor(true);
                            //           }}
                            //         >
                            //           <AddCircleOutlineTwoTone color="primary" />
                            //         </IconButton>
                            //       ) : null}
                            //       {params.InputProps.endAdornment}
                            //     </React.Fragment>
                            //   ),
                            // }}
                          />
                        )}
                      />
                    </FormControl>

                    <TextField
                      className={clases.formD}
                      label="Temperamento"
                      value={pelTemperamento}
                      name="pelTemperamento"
                      error={error.pelTemperamento}
                      onChange={(e) => handleChange(e)}
                      select
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                    >
                      <MenuItem value="">Seleccione.</MenuItem>
                      {[
                        "AGRESIVO",
                        "NERVIOSO",
                        "DOCIL",
                        "INQUIETO",
                        "REQ. SEDACIÓN",
                      ].map((item) => {
                        return <MenuItem value={item}>{item} </MenuItem>;
                      })}
                    </TextField>

                    <TextField
                      className={clases.formD}
                      label="Autorizo"
                      value={pelAutorizo}
                      name="pelAutorizo"
                      error={error.pelAutorizo}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      className={clases.formD}
                      label="Fecha"
                      value={pelFecha}
                      name="pelFecha"
                      error={error.pelFecha}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      type="number"
                      className={clases.formD}
                      label="Turno"
                      value={pelTurno}
                      name="pelTurno"
                      error={error.pelTurno}
                      onChange={(e) =>
                        setitem({
                          ...item,
                          pelTurno:
                            e.target.valueAsNumber < 0 ? 0 : e.target.value,
                        })
                      }
                    />
                    <TextField
                      type="time"
                      InputLabelProps={{ shrink: true }}
                      className={clases.formD}
                      label="Ingreso"
                      value={pelHoraIngreso}
                      name="pelHoraIngreso"
                      error={error.pelHoraIngreso}
                      onChange={(e) => handleChange(e)}
                    />

                    <TextField
                      type="time"
                      InputLabelProps={{ shrink: true }}
                      className={clases.formD}
                      label="Salida"
                      value={pelHoraSalida}
                      name="pelHoraSalida"
                      error={error.pelHoraSalida}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      className={clases.formD}
                      label="Estado"
                      value={pelEstado}
                      name="pelEstado"
                      error={error.pelEstado}
                      onChange={(e) => handleChange(e)}
                      select
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                    >
                      <MenuItem value="">Seleccione.</MenuItem>
                      {[
                        "NORMAL",
                        "POCO ENREDADO",
                        "ENREDADO",
                        "MUY ENREDADO",
                        "ANULADA",
                      ].map((item) => {
                        return <MenuItem value={item}>{item} </MenuItem>;
                      })}
                    </TextField>

                    <TextField
                      type="datetime-local"
                      InputLabelProps={{ shrink: true }}
                      className={clases.formD}
                      label="Proxima Cita"
                      value={pelProxCita}
                      name="pelProxCita"
                      error={error.pelProxCita}
                      onChange={(e) => handleChange(e)}
                    />
                    <FormControl
                      component="fieldset"
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      error={error.pelLlamar30min}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <FormLabel
                          component="span"
                          color="primary"
                          style={{
                            marginRight: ".5rem",
                            fontWeight: "bold",
                          }}
                        >
                          Llamar 30min antes
                        </FormLabel>
                        <RadioGroup
                          onChange={(e) => handleChange(e)}
                          row
                          aria-label="gender"
                          value={pelLlamar30min}
                          name="pelLlamar30min"
                        >
                          <FormControlLabel
                            value="SI"
                            control={<Radio />}
                            label="SI"
                          />
                          <FormControlLabel
                            value="NO"
                            control={<Radio />}
                            label="NO"
                          />
                        </RadioGroup>
                      </Box>
                    </FormControl>
                    <Editor
                      label="Obsr. al ingreso de la mascota"
                      propiedad={"pelObservacion"}
                      item={item}
                      setitem={setitem}
                    />
                  </>
                ) : null}
                {/* Pedidos Cliente */}
                {PedidosClienteSeccion ? (
                  <>
                    <TextField
                      className={clases.formD}
                      label="Cara"
                      value={pelCara}
                      name="pelCara"
                      error={error.pelCara}
                      onChange={(e) => handleChange(e)}
                      select
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                    >
                      <MenuItem value="">Seleccione Cara.</MenuItem>
                      {pelCaraData.map((item) => {
                        return <MenuItem value={item}>{item} </MenuItem>;
                      })}
                    </TextField>
                    <TextField
                      className={clases.formD}
                      label="Forma de la Cabeza"
                      value={pelFormaCabeza}
                      name="pelFormaCabeza"
                      error={error.pelFormaCabeza}
                      onChange={(e) => handleChange(e)}
                      select
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                    >
                      <MenuItem value="">
                        Seleccione Forma de la Cabeza.
                      </MenuItem>
                      {pelFormaCabezaData.map((item) => {
                        return <MenuItem value={item}>{item} </MenuItem>;
                      })}
                    </TextField>
                    <TextField
                      className={clases.formD}
                      label="Copete"
                      value={pelCopete}
                      name="pelCopete"
                      error={error.pelCopete}
                      onChange={(e) => handleChange(e)}
                      select
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                    >
                      <MenuItem value="">Seleccione Copete.</MenuItem>
                      {pelCopeteData.map((item) => {
                        return <MenuItem value={item}>{item} </MenuItem>;
                      })}
                    </TextField>
                    <TextField
                      className={clases.formD}
                      label="Oreja"
                      value={pelOreja}
                      name="pelOreja"
                      error={error.pelOreja}
                      onChange={(e) => handleChange(e)}
                      select
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                    >
                      <MenuItem value="">Seleccione Oreja.</MenuItem>
                      {pelOrejaData.map((item) => {
                        return <MenuItem value={item}>{item} </MenuItem>;
                      })}
                    </TextField>
                    <TextField
                      className={clases.formD}
                      label="Bigotes"
                      value={pelBigotes}
                      name="pelBigotes"
                      error={error.pelBigotes}
                      onChange={(e) => handleChange(e)}
                      select
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                    >
                      <MenuItem value="">Seleccione Bigotes.</MenuItem>
                      {pelBigotesData.map((item) => {
                        return <MenuItem value={item}>{item} </MenuItem>;
                      })}
                    </TextField>
                    <TextField
                      className={clases.formD}
                      label="Bigotes Forma"
                      value={pelBigotesForma}
                      name="pelBigotesForma"
                      error={error.pelBigotesForma}
                      onChange={(e) => handleChange(e)}
                      select
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                    >
                      <MenuItem value="">Seleccione Bigotes Forma.</MenuItem>
                      {pelBigotesFormaData.map((item) => {
                        return <MenuItem value={item}>{item} </MenuItem>;
                      })}
                    </TextField>
                    <TextField
                      className={clases.formD}
                      label="Cejas"
                      value={pelCejas}
                      name="pelCejas"
                      error={error.pelCejas}
                      onChange={(e) => handleChange(e)}
                      select
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                    >
                      <MenuItem value="">Seleccione Cejas.</MenuItem>
                      {pelCejasData.map((item) => {
                        return <MenuItem value={item}>{item} </MenuItem>;
                      })}
                    </TextField>
                    <TextField
                      className={clases.formD}
                      label="Todo el cuerpo"
                      value={pelCuerpo}
                      name="pelCuerpo"
                      error={error.pelCuerpo}
                      onChange={(e) => handleChange(e)}
                      select
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                    >
                      <MenuItem value="">Seleccione Todo el cuerpo.</MenuItem>
                      {pelCuerpoData.map((item) => {
                        return <MenuItem value={item}>{item} </MenuItem>;
                      })}
                    </TextField>
                    <TextField
                      className={clases.formD}
                      label="Solo lomo"
                      value={pelLomo}
                      name="pelLomo"
                      error={error.pelLomo}
                      onChange={(e) => handleChange(e)}
                      select
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                    >
                      <MenuItem value="">Seleccione Solo lomo.</MenuItem>
                      {pelLomoData.map((item) => {
                        return <MenuItem value={item}>{item} </MenuItem>;
                      })}
                    </TextField>
                    <TextField
                      className={clases.formD}
                      label="Faldon-pecho-patitas"
                      value={pelFaldon}
                      name="pelFaldon"
                      error={error.pelFaldon}
                      onChange={(e) => handleChange(e)}
                      select
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                    >
                      <MenuItem value="">
                        Seleccione Faldon-pecho-patitas.
                      </MenuItem>
                      {pelFaldonData.map((item) => {
                        return <MenuItem value={item}>{item} </MenuItem>;
                      })}
                    </TextField>
                    <TextField
                      className={clases.formD}
                      label="Cola"
                      value={pelCola}
                      name="pelCola"
                      error={error.pelCola}
                      onChange={(e) => handleChange(e)}
                      select
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                    >
                      <MenuItem value="">Seleccione Cola.</MenuItem>
                      {pelColaData.map((item) => {
                        return <MenuItem value={item}>{item} </MenuItem>;
                      })}
                    </TextField>
                    <TextField
                      className={clases.formD}
                      label="Cola Forma"
                      value={pelColaForma}
                      name="pelColaForma"
                      error={error.pelColaForma}
                      onChange={(e) => handleChange(e)}
                      select
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                    >
                      <MenuItem value="">Seleccione Cola Forma.</MenuItem>
                      {pelColaFormaData.map((item) => {
                        return <MenuItem value={item}>{item} </MenuItem>;
                      })}
                    </TextField>{" "}
                    <TextField
                      className={clases.formAllW}
                      label="Manos y patitas"
                      value={pelPatitas}
                      name="pelPatitas"
                      error={error.pelPatitas}
                      onChange={(e) => handleChange(e)}
                      select
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                    >
                      <MenuItem value="">Seleccione Manos y Patitas.</MenuItem>
                      {pelPatitasData.map((item) => {
                        return <MenuItem value={item}>{item} </MenuItem>;
                      })}
                    </TextField>{" "}
                  </>
                ) : null}
                {/* Procesos de Peluqueria */}
                {ProcesosdePeluqueriaSeccion ? (
                  <>
                    <FormControl
                      component="fieldset"
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formAllW}
                      error={error.pelDeclaro}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <FormLabel
                          component="span"
                          color="primary"
                          style={{
                            marginRight: ".2rem",
                            fontWeight: "bold",
                          }}
                        >
                          Declaro haber pedido el corte al gusto de la
                          veterinaria
                        </FormLabel>
                        <RadioGroup
                          onChange={(e) => handleChange(e)}
                          row
                          aria-label="gender"
                          value={pelDeclaro}
                          name="pelDeclaro"
                        >
                          <FormControlLabel
                            value="SI"
                            control={<Radio />}
                            label="SI"
                          />
                          <FormControlLabel
                            value="NO"
                            control={<Radio />}
                            label="NO"
                          />
                        </RadioGroup>
                      </Box>
                    </FormControl>
                    <FormControl
                      component="fieldset"
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      error={error.pelOidos}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <FormLabel
                          component="span"
                          color="primary"
                          style={{
                            marginRight: ".5rem",
                            fontWeight: "bold",
                          }}
                        >
                          Oidos
                        </FormLabel>
                        <RadioGroup
                          onChange={(e) => handleChange(e)}
                          row
                          aria-label="gender"
                          value={pelOidos}
                          name="pelOidos"
                        >
                          <FormControlLabel
                            value="SI"
                            control={<Radio />}
                            label="SI"
                          />
                          <FormControlLabel
                            value="NO"
                            control={<Radio />}
                            label="NO"
                          />
                        </RadioGroup>
                      </Box>
                    </FormControl>
                    <FormControl
                      component="fieldset"
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      error={error.pelLimpiezaOcular}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <FormLabel
                          component="span"
                          color="primary"
                          style={{
                            marginRight: ".5rem",
                            fontWeight: "bold",
                          }}
                        >
                          Limpieza Ocular
                        </FormLabel>
                        <RadioGroup
                          onChange={(e) => handleChange(e)}
                          row
                          aria-label="gender"
                          value={pelLimpiezaOcular}
                          name="pelLimpiezaOcular"
                        >
                          <FormControlLabel
                            value="SI"
                            control={<Radio />}
                            label="SI"
                          />
                          <FormControlLabel
                            value="NO"
                            control={<Radio />}
                            label="NO"
                          />
                        </RadioGroup>
                      </Box>
                    </FormControl>
                    <FormControl
                      component="fieldset"
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      error={error.pelLavadoDientes}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <FormLabel
                          component="span"
                          color="primary"
                          style={{
                            marginRight: ".5rem",
                            fontWeight: "bold",
                          }}
                        >
                          Lavado de dientes
                        </FormLabel>
                        <RadioGroup
                          onChange={(e) => handleChange(e)}
                          row
                          aria-label="gender"
                          value={pelLavadoDientes}
                          name="pelLavadoDientes"
                        >
                          <FormControlLabel
                            value="SI"
                            control={<Radio />}
                            label="SI"
                          />
                          <FormControlLabel
                            value="NO"
                            control={<Radio />}
                            label="NO"
                          />
                        </RadioGroup>
                      </Box>
                    </FormControl>
                    <FormControl
                      component="fieldset"
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      error={error.pelCorteUna}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <FormLabel
                          component="span"
                          color="primary"
                          style={{
                            marginRight: ".5rem",
                            fontWeight: "bold",
                          }}
                        >
                          Corte de uñas
                        </FormLabel>
                        <RadioGroup
                          onChange={(e) => handleChange(e)}
                          row
                          aria-label="gender"
                          value={pelCorteUna}
                          name="pelCorteUna"
                        >
                          <FormControlLabel
                            value="SI"
                            control={<Radio />}
                            label="SI"
                          />
                          <FormControlLabel
                            value="NO"
                            control={<Radio />}
                            label="NO"
                          />
                        </RadioGroup>
                      </Box>
                    </FormControl>
                    <FormControl
                      component="fieldset"
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      error={error.pelUnasDedos}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <FormLabel
                          component="span"
                          color="primary"
                          style={{
                            marginRight: ".5rem",
                            fontWeight: "bold",
                          }}
                        >
                          Uñas dedos supernumerarios
                        </FormLabel>
                        <RadioGroup
                          onChange={(e) => handleChange(e)}
                          row
                          aria-label="gender"
                          value={pelUnasDedos}
                          name="pelUnasDedos"
                        >
                          <FormControlLabel
                            value="SI"
                            control={<Radio />}
                            label="SI"
                          />
                          <FormControlLabel
                            value="NO"
                            control={<Radio />}
                            label="NO"
                          />
                        </RadioGroup>
                      </Box>
                    </FormControl>
                    <FormControl
                      component="fieldset"
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      error={error.pelRapadoGenitales}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <FormLabel
                          component="span"
                          color="primary"
                          style={{
                            marginRight: ".5rem",
                            fontWeight: "bold",
                          }}
                        >
                          Rapado Genitales
                        </FormLabel>
                        <RadioGroup
                          onChange={(e) => handleChange(e)}
                          row
                          aria-label="gender"
                          value={pelRapadoGenitales}
                          name="pelRapadoGenitales"
                        >
                          <FormControlLabel
                            value="SI"
                            control={<Radio />}
                            label="SI"
                          />
                          <FormControlLabel
                            value="NO"
                            control={<Radio />}
                            label="NO"
                          />
                        </RadioGroup>
                      </Box>
                    </FormControl>
                    <FormControl
                      component="fieldset"
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      error={error.pelDrenaje}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <FormLabel
                          component="span"
                          color="primary"
                          style={{
                            marginRight: ".5rem",
                            fontWeight: "bold",
                          }}
                        >
                          Drenaje G. Perinales
                        </FormLabel>
                        <RadioGroup
                          onChange={(e) => handleChange(e)}
                          row
                          aria-label="gender"
                          value={pelDrenaje}
                          name="pelDrenaje"
                        >
                          <FormControlLabel
                            value="SI"
                            control={<Radio />}
                            label="SI"
                          />
                          <FormControlLabel
                            value="NO"
                            control={<Radio />}
                            label="NO"
                          />
                        </RadioGroup>
                      </Box>
                    </FormControl>
                    <FormControl
                      component="fieldset"
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      error={error.pelBano}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <FormLabel
                          component="span"
                          color="primary"
                          style={{
                            marginRight: ".5rem",
                            fontWeight: "bold",
                          }}
                        >
                          Baño
                        </FormLabel>
                        <RadioGroup
                          onChange={(e) => handleChange(e)}
                          row
                          aria-label="gender"
                          value={pelBano}
                          name="pelBano"
                        >
                          <FormControlLabel
                            value="SI"
                            control={<Radio />}
                            label="SI"
                          />
                          <FormControlLabel
                            value="NO"
                            control={<Radio />}
                            label="NO"
                          />
                        </RadioGroup>
                      </Box>
                    </FormControl>
                    <FormControl
                      component="fieldset"
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      error={error.pelSecado}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <FormLabel
                          component="span"
                          color="primary"
                          style={{
                            marginRight: ".5rem",
                            fontWeight: "bold",
                          }}
                        >
                          Secado
                        </FormLabel>
                        <RadioGroup
                          onChange={(e) => handleChange(e)}
                          row
                          aria-label="gender"
                          value={pelSecado}
                          name="pelSecado"
                        >
                          <FormControlLabel
                            value="SI"
                            control={<Radio />}
                            label="SI"
                          />
                          <FormControlLabel
                            value="NO"
                            control={<Radio />}
                            label="NO"
                          />
                        </RadioGroup>
                      </Box>
                    </FormControl>
                    <FormControl
                      component="fieldset"
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      error={error.pelCortesAcabados}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <FormLabel
                          component="span"
                          color="primary"
                          style={{
                            marginRight: ".5rem",
                            fontWeight: "bold",
                          }}
                        >
                          Corte y acabados
                        </FormLabel>
                        <RadioGroup
                          onChange={(e) => handleChange(e)}
                          row
                          aria-label="gender"
                          value={pelCortesAcabados}
                          name="pelCortesAcabados"
                        >
                          <FormControlLabel
                            value="SI"
                            control={<Radio />}
                            label="SI"
                          />
                          <FormControlLabel
                            value="NO"
                            control={<Radio />}
                            label="NO"
                          />
                        </RadioGroup>
                      </Box>
                    </FormControl>
                    <FormControl
                      component="fieldset"
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      error={error.pelPerfume}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <FormLabel
                          component="span"
                          color="primary"
                          style={{
                            marginRight: ".5rem",
                            fontWeight: "bold",
                          }}
                        >
                          Perfume
                        </FormLabel>
                        <RadioGroup
                          onChange={(e) => handleChange(e)}
                          row
                          aria-label="gender"
                          value={pelPerfume}
                          name="pelPerfume"
                        >
                          <FormControlLabel
                            value="SI"
                            control={<Radio />}
                            label="SI"
                          />
                          <FormControlLabel
                            value="NO"
                            control={<Radio />}
                            label="NO"
                          />
                        </RadioGroup>
                      </Box>
                    </FormControl>{" "}
                    <FormControl
                      component="fieldset"
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      error={error.pelLazoCentral}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <FormLabel
                          component="span"
                          color="primary"
                          style={{
                            marginRight: ".5rem",
                            fontWeight: "bold",
                          }}
                        >
                          Lazo Central
                        </FormLabel>
                        <RadioGroup
                          onChange={(e) => handleChange(e)}
                          row
                          aria-label="gender"
                          value={pelLazoCentral}
                          name="pelLazoCentral"
                        >
                          <FormControlLabel
                            value="SI"
                            control={<Radio />}
                            label="SI"
                          />
                          <FormControlLabel
                            value="NO"
                            control={<Radio />}
                            label="NO"
                          />
                        </RadioGroup>
                      </Box>
                    </FormControl>
                    <FormControl
                      component="fieldset"
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      error={error.pelDosLazos}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <FormLabel
                          component="span"
                          color="primary"
                          style={{
                            marginRight: ".5rem",
                            fontWeight: "bold",
                          }}
                        >
                          Dos Lazos
                        </FormLabel>
                        <RadioGroup
                          onChange={(e) => handleChange(e)}
                          row
                          aria-label="gender"
                          value={pelDosLazos}
                          name="pelDosLazos"
                        >
                          <FormControlLabel
                            value="SI"
                            control={<Radio />}
                            label="SI"
                          />
                          <FormControlLabel
                            value="NO"
                            control={<Radio />}
                            label="NO"
                          />
                        </RadioGroup>
                      </Box>
                    </FormControl>
                    <FormControl
                      component="fieldset"
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      error={error.pelBufanda}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <FormLabel
                          component="span"
                          color="primary"
                          style={{
                            marginRight: ".5rem",
                            fontWeight: "bold",
                          }}
                        >
                          Bufanda
                        </FormLabel>
                        <RadioGroup
                          onChange={(e) => handleChange(e)}
                          row
                          aria-label="gender"
                          value={pelBufanda}
                          name="pelBufanda"
                        >
                          <FormControlLabel
                            value="SI"
                            control={<Radio />}
                            label="SI"
                          />
                          <FormControlLabel
                            value="NO"
                            control={<Radio />}
                            label="NO"
                          />
                        </RadioGroup>
                      </Box>
                    </FormControl>
                    <FormControl
                      component="fieldset"
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      error={error.pelPanuelo}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <FormLabel
                          component="span"
                          color="primary"
                          style={{
                            marginRight: ".5rem",
                            fontWeight: "bold",
                          }}
                        >
                          Pañuelo
                        </FormLabel>
                        <RadioGroup
                          onChange={(e) => handleChange(e)}
                          row
                          aria-label="gender"
                          value={pelPanuelo}
                          name="pelPanuelo"
                        >
                          <FormControlLabel
                            value="SI"
                            control={<Radio />}
                            label="SI"
                          />
                          <FormControlLabel
                            value="NO"
                            control={<Radio />}
                            label="NO"
                          />
                        </RadioGroup>
                      </Box>
                    </FormControl>
                    <Editor
                      label="Otros procesos"
                      propiedad={"pelOtrosProcesos"}
                      item={item}
                      setitem={setitem}
                    />
                  </>
                ) : null}
                {/* Adicionales */}
                {AdicionalesSeccion ? (
                  <>
                    {" "}
                    <FormControl
                      component="fieldset"
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      error={error.pelRetira}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <FormLabel
                          component="span"
                          color="primary"
                          style={{
                            marginRight: ".5rem",
                            fontWeight: "bold",
                          }}
                        >
                          El cliente retira la mascota lista
                        </FormLabel>
                        <RadioGroup
                          onChange={(e) => handleChange(e)}
                          row
                          aria-label="gender"
                          value={pelRetira}
                          name="pelRetira"
                        >
                          <FormControlLabel
                            value="SI"
                            control={<Radio />}
                            label="SI"
                          />
                          <FormControlLabel
                            value="NO"
                            control={<Radio />}
                            label="NO"
                          />
                        </RadioGroup>
                      </Box>
                    </FormControl>
                    <FormControl
                      component="fieldset"
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      error={error.pelCarnet}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <FormLabel
                          component="span"
                          color="primary"
                          style={{
                            marginRight: ".5rem",
                            fontWeight: "bold",
                          }}
                        >
                          Deja Carnet de Vacunas
                        </FormLabel>
                        <RadioGroup
                          onChange={(e) => handleChange(e)}
                          row
                          aria-label="gender"
                          value={pelCarnet}
                          name="pelCarnet"
                        >
                          <FormControlLabel
                            value="SI"
                            control={<Radio />}
                            label="SI"
                          />
                          <FormControlLabel
                            value="NO"
                            control={<Radio />}
                            label="NO"
                          />
                        </RadioGroup>
                      </Box>
                    </FormControl>{" "}
                    <FormControl
                      component="fieldset"
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      error={error.pelPresencia}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <FormLabel
                          component="span"
                          color="primary"
                          style={{
                            marginRight: ".5rem",
                            fontWeight: "bold",
                          }}
                        >
                          Realizar en presencia del propietario o persona
                          Autorizada
                        </FormLabel>
                        <RadioGroup
                          onChange={(e) => handleChange(e)}
                          row
                          aria-label="gender"
                          value={pelPresencia}
                          name="pelPresencia"
                        >
                          <FormControlLabel
                            value="SI"
                            control={<Radio />}
                            label="SI"
                          />
                          <FormControlLabel
                            value="NO"
                            control={<Radio />}
                            label="NO"
                          />
                        </RadioGroup>
                      </Box>
                    </FormControl>
                    <Editor
                      label="Deja accesorios. Cuales.?"
                      propiedad={"pelDejaAccesorios"}
                      item={item}
                      setitem={setitem}
                    />
                  </>
                ) : null}
                {FacturaSeccion ? <>FacturaSeccion</> : null}
              </Box>
              {/* <Divider /> */}{" "}
              <Box
                mt={2}
                display="flex"
                width="100%"
                justifyContent="space-between"
                alignItems="center"
                flexWrap={"wrap"}
              >
                <Box
                  className={clases.formD}
                  alignItems="center"
                  display={"flex"}
                  justifyContent="space-around"
                >
                  <Typography
                    className={clases.formD}
                    color="secondary"
                    fontSize={".9rem"}
                    fontWeight="bold"
                  >
                    Crea:{" "}
                    <Typography
                      className={clases.formD}
                      color="primary"
                      fontSize={".9rem"}
                      component="span"
                    >
                      {item.pelUser.split(" ** ")[0]}
                    </Typography>
                  </Typography>
                  <Typography
                    className={clases.formD}
                    color="secondary"
                    fontSize={".9rem"}
                    fontWeight="bold"
                  >
                    Act:{" "}
                    <Typography
                      className={clases.formD}
                      color="primary"
                      fontSize={".9rem"}
                      component="span"
                    >
                      {item.pelUser.split(" ** ")[1]}
                    </Typography>
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  mt={1}
                  className={clases.formD}
                >
                  <Button
                    className={clases.formD}
                    variant="contained"
                    color={item.restaurado ? "success" : "primary"}
                    disabled={cargando}
                    onClick={() => {
                      if (editar) {
                        if (!tienePermiso("editar")) {
                          return alertaPermiso("editar");
                        }
                        handleEditar();
                      } else {
                        if (!tienePermiso("agregar")) {
                          return alertaPermiso("agregar");
                        }
                        handleCrear();
                      }
                    }}
                  >
                    {item.restaurado ? "Restaurar" : "Guardar"}
                  </Button>
                </Box>
              </Box>
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default memo(ModalData);
