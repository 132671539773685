import React, { memo, useState } from "react";

// MATERIAL
import { makeStyles } from "@mui/styles";
import { Box, IconButton, TableCell, TableRow, TextField } from "@mui/material";
import { Cancel, Check } from "@mui/icons-material";
import { v4 } from "uuid";

const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  margin: {
    margin: "0px",
    padding: "2px",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));
const RowAgregarArma = (props) => {
  const { setagregar, setitem, item } = props;
  const [itemRow, setItemRow] = useState({
    order: "",
    fluido: "",
    dosis: "",
    cantidad: "1",
    inicio: "0",
  });
  const handleChange = (e) => {
    setItemRow({ ...itemRow, [e.target.name]: e.target.value });
  };
  const botonDisabled = () => {
    if (itemRow.nombre === "" || itemRow.cantidad === "") {
      return true;
    } else {
      return false;
    }
  };

  const classes = useRowStyles();
  //gql eliminar user

  const handleAgregar = async () => {
    try {
      const order = v4();
      const hospFluidos = {
        fluidosCabecera: [
          {
            ...itemRow,
            order,
          },
          ...item.hospFluidos.fluidosCabecera,
        ],
      };

      setitem({
        ...item,
        hospFluidos,
      });
    } catch (error) {}
  };

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            alignItems="center"
          >
            <IconButton
              className={classes.margin}
              onClick={() => {
                setagregar(false);
                setItemRow({
                  order: "",
                  fluido: "",
                  dosis: "",
                  cantidad: "1",
                  inicio: "0",
                });
              }}
            >
              <Cancel fontSize="small" color="secondary" />
            </IconButton>
            <IconButton
              className={classes.margin}
              disabled={botonDisabled()}
              color={botonDisabled() ? "secondary" : "inherit"}
              onClick={() => {
                handleAgregar();

                setagregar(false);
                setItemRow({
                  order: "",
                  fluido: "",
                  dosis: "",
                  cantidad: "1",
                  inicio: "0",
                });
              }}
            >
              <Check fontSize="small" />
            </IconButton>{" "}
          </Box>
        </TableCell>
        {/* las demas columnas */}

        <>
          <TableCell
            checkbox
            size="small"
            align="center"
            className={classes.celda}
          >
            <TextField
              name="fluido"
              value={itemRow.fluido}
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
            />
          </TableCell>
          <TableCell
            checkbox
            size="small"
            align="center"
            className={classes.celda}
          >
            <TextField
              name="dosis"
              value={itemRow.dosis}
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
            />
          </TableCell>
          <TableCell
            checkbox
            size="small"
            align="center"
            className={classes.celda}
          >
            <TextField
              name="cantidad"
              value={itemRow.cantidad}
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
            />
          </TableCell>
          <TableCell
            checkbox
            size="small"
            align="center"
            className={classes.celda}
          >
            <TextField
              type={"number"}
              name="inicio"
              value={itemRow.inicio}
              onChange={(e) => {
                setItemRow({
                  ...itemRow,
                  inicio:
                    e.target.valueAsNumber < 0 ? 0 : e.target.valueAsNumber,
                });
              }}
              size="small"
              fullWidth
            />
          </TableCell>
        </>
      </TableRow>
    </React.Fragment>
  );
};

export default memo(RowAgregarArma);
