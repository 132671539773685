export const OBTENER_USUARIO = "OBTENER_USUARIO";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_EXITOSO = "LOGIN_EXITOSO";
export const CERRAR_SESION = "CERRAR_SESION";
export const REGISTRO_EXITOSO = "REGISTRO_EXITOSO";
export const ELIMINAR_MENSAJE = "ELIMINAR_MENSAJE";
//ALERTAS
export const MOSTRAR_ALERTA = "MOSTRAR_ALERTA";
export const OCULTAR_ALERTA = "OCULTAR_ALERTA";
//GLOBALES

export const OBTENER = "OBTENER";
export const CREAR = "CREAR";
export const EDITAR = "EDITAR";
export const ELIMINAR = "ELIMINAR";
export const CHANGE_ACTIVO = "CHANGE_ACTIVO";
export const CLEAR_ROWS = "CLEAR_ROWS";
export const ADD_ROWS = "ADD_ROWS";
export const REMPLAZAR_ROWS = "REMPLAZAR_ROWS";
export const IMPORTAR_ROWS = "IMPORTAR_ROWS";
export const ELIMINAR_SELECCION = "ELIMINAR_SELECCION";

//
export const CHANGE_GUARDADO = "CHANGE_GUARDADO";
