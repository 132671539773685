import {
  TableRow,
  TableCell,
  Button,
  Checkbox,
  FormControlLabel,
  Box,
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import React, { memo } from "react";
import { useRowStyles } from "../../../styles/stylesRows";

const TablaCabecera = (props) => {
  const classes = useRowStyles();
  const {
    rows,
    setarrayExport,
    campoExport,
    columns,
    habilitarOrdenar,
    input,
    orden,
    funcionOrdenar,
    ocultarPrimeraColumna,
    agrupado,
    rowPadre,
    agregarColumna,
  } = props;

  return (
    <TableRow>
      {setarrayExport ? (
        agrupado ? (
          <TableCell
            size="small"
            padding="checkbox"
            style={{
              borderRight: "1px solid #ccc",
              maxWidth: "1rem",
            }}
            className={classes.celdaHeader}
          >
            <Box display="flex" alignItems="center" justifyContent="center">
              <FormControlLabel
                sx={{
                  margin: {
                    margin: "0px",
                    padding: "2px",
                  },
                }}
                control={
                  <Checkbox
                    size="small"
                    sx={{
                      margin: {
                        margin: "0px",
                        padding: "2px",
                      },
                    }}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setarrayExport((prev) => {
                          return {
                            ...prev,
                            [rowPadre[campoExport]]: rows.map(
                              (item) => item[campoExport]
                            ),
                          };
                        });
                      } else {
                        setarrayExport((prev) => {
                          return {
                            ...prev,
                            [rowPadre[campoExport]]: [],
                          };
                        });
                      }
                    }}
                    color="secondary"
                  />
                }
              />
            </Box>
          </TableCell>
        ) : (
          <TableCell
            size="small"
            padding="checkbox"
            style={{
              borderRight: "1px solid #ccc",
              maxWidth: "1rem",
            }}
            className={classes.celdaHeader}
          >
            <Box display="flex" alignItems="center" justifyContent="center">
              <FormControlLabel
                sx={{
                  margin: {
                    margin: "0px",
                    padding: "2px",
                  },
                }}
                control={
                  <Checkbox
                    size="small"
                    sx={{
                      margin: {
                        margin: "0px",
                        padding: "2px",
                      },
                    }}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setarrayExport(rows.map((item) => item[campoExport]));
                      } else {
                        setarrayExport([]);
                      }
                    }}
                    color="secondary"
                  />
                }
              />
            </Box>
          </TableCell>
        )
      ) : null}
      {agregarColumna ? (
        <TableCell
          size="small"
          padding="checkbox"
          style={{
            borderRight: "1px solid #ccc",
            maxWidth: "1rem",
          }}
          className={classes.celdaHeader}
        ></TableCell>
      ) : null}
      {ocultarPrimeraColumna ? null : (
        <TableCell
          size="small"
          padding="checkbox"
          style={{
            borderRight: "1px solid #ccc",
            maxWidth: "1rem",
          }}
          className={classes.celdaHeader}
        ></TableCell>
      )}
      {columns.map((column) => {
        const { id, label, minWidth, tipo, align } = column;
        return (
          <TableCell
            key={id}
            size="small"
            style={{
              minWidth: minWidth,
            }}
            padding="none"
            className={classes.celdaHeader}
          >
            <Button
              size="small"
              style={{
                fontSize: ".75rem",
                fontWeight: "bold",
              }}
              variant={
                habilitarOrdenar && input.id === id ? "contained" : "text"
              }
              color="secondary"
              onClick={async () => {
                if (!habilitarOrdenar) {
                  return;
                }
                if (
                  tipo !== "fecha" &&
                  tipo !== "string" &&
                  tipo !== "numero"
                ) {
                  return;
                }
                if (tipo === "") {
                  return;
                }
                await funcionOrdenar(column);
              }}
              fullWidth
              startIcon={
                !habilitarOrdenar ? (
                  ""
                ) : input.id === id ? (
                  orden ? (
                    <KeyboardArrowDown color="primary" />
                  ) : (
                    <KeyboardArrowUp color="primary" />
                  )
                ) : null
              }
              endIcon={
                !habilitarOrdenar ? (
                  ""
                ) : input.id === id ? (
                  orden ? (
                    <KeyboardArrowDown color="primary" />
                  ) : (
                    <KeyboardArrowUp color="primary" />
                  )
                ) : null
              }
              sx={{
                justifyContent:
                  align === "left"
                    ? "flex-start"
                    : align === "right"
                    ? "flex-end"
                    : "center",
              }}
            >
              {label}
            </Button>
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default memo(TablaCabecera);
