import React, { useState, useContext, useEffect, memo } from "react";
import Draggable from "react-draggable";
// eslint-disable-next-line
import AlertaContext from "../../../../Context/Alerta/AlertaContext";  
// MATERIAL
import {
  Modal,
  Box,
  Button,
  Divider,
  Typography,
  IconButton,
  TextField,
  MenuItem,
} from "@mui/material";
import { Cancel } from "@mui/icons-material";
import { useModalStyle } from "../../../../styles/styleModal";

function getModalStyle() {
  return {
    borderRadius: "15px",
    position: "absolute",
    overflow: "scroll",
    maxHeight: "95vh",
  };
}

const ModalATS = (props) => {
  // eslint-disable-next-line
  const { mostrarAlerta } = useContext(AlertaContext); 
  //props
  const { open, setopen } = props;

  const [item, setitem] = useState({
    semestre: "",
    tipoAts: "Mensual",
    mensual: "",
    anio: 2023,
    tipoDocumento: "Todos",
  });

  const [cargando, setcargando] = useState(false);

  // eslint-disable-next-line
  const [error, seterror] = useState({});

  useEffect(() => {
    // eslint-disable-next-line
  }, [open]);

  const defaultActive = () => {
    setitem({
      semestre: "",
      tipoAts: "Mensual",
      mensual: "",
      anio: 2023,
      tipoDocumento: "Todos",
    });
    setopen(false);
    seterror({});
    setcargando(false);
  };

  //hook de estilos
  const clases = useModalStyle();
  //definiendo los estilos del modal

  const [modalStyle] = useState(getModalStyle);
  // obteniendo los años

  const submit = () => {
    if (item.fecha1 === "" || item.fecha2 === "") {
      return true;
    } else {
      return false;
    }
  }; // eslint-disable-next-line
  const obtenerAnios = () => {
    const anioActual = 2023;
    const anios = [];
    for (let i = 2; i >= -2; i--) {
      anios.push(anioActual - i);
    }
    return [anioActual];
  };

  const handleCrear = async () => {
    if (false) {
      return seterror({});
    }
    try {
      setcargando(true);
      // const res = await clienteAxios.post("/ats", {
      //   ...item,
      //   rucEmpresa: rucempresa,
      // });

      // window.open(res.data.ruta, "_blank");
      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);
      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  const handleChange = (e) => {
    setitem({
      ...item,
      [e.target.name]: e.target.value,
    });
  };
  // 1 + 2 + 3 + 4
  if (!open) {
    return null;
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Draggable handle="#handle">
          <Box display="flex" justifyContent="center">
            <div style={modalStyle} className={clases.paper}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                style={{ cursor: "-webkit-grab" }}
              >
                <Typography
                  id="handle"
                  variant="button"
                  color="initial"
                  align="center"
                >
                  ATS
                </Typography>
                <Box display="flex" alignItems="center">
                  <IconButton
                    aria-label=""
                    style={{ margin: "0px" }}
                    onClick={() => {
                      defaultActive();
                    }}
                  >
                    <Cancel color="secondary" />
                  </IconButton>
                </Box>
              </Box>
              <Divider />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems=" stretch"
                width="100%"
              >
                <Box></Box>
              </Box>
              <Divider />
              <Box className={clases.container}>
                <TextField
                  className={clases.formD}
                  value={item.tipoAts}
                  name="tipoAts"
                  onChange={handleChange}
                  label={`Tipo ATS`}
                  select
                  InputLabelProps={{ shrink: true }}
                  SelectProps={{ displayEmpty: true }}
                >
                  <MenuItem value="">Seleccione un Tipo de Compra</MenuItem>
                  {["Mensual", "Semestral"].map((item) => {
                    return (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </TextField>
                {item.tipoAts === "Mensual" ? (
                  <TextField
                    className={clases.formD}
                    value={item.mensual}
                    name="mensual"
                    onChange={handleChange}
                    label={`Mes`}
                    select
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ displayEmpty: true }}
                  >
                    <MenuItem value="">Seleccione un mes</MenuItem>
                    {[
                      "Enero",
                      "Febrero",
                      "Marzo",
                      "Abril",
                      "Mayo",
                      "Junio",
                      "Julio",
                      "Agosto",
                      "Septiembre",
                      "Octubre",
                      "Noviembre",
                      "Diciembre",
                    ].map((item) => {
                      return (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                ) : (
                  <TextField
                    className={clases.formD}
                    value={item.semestre}
                    name="semestre"
                    onChange={handleChange}
                    label={`Semestre`}
                    select
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ displayEmpty: true }}
                  >
                    <MenuItem value="">Seleccione un semestre</MenuItem>
                    {["1er Semestre", "2do Semestre"].map((item) => {
                      return (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                )}

                <TextField
                  className={clases.formD}
                  value={item.anio}
                  name="anio"
                  onChange={handleChange}
                  label={`Año`}
                  select
                  InputLabelProps={{ shrink: true }}
                  SelectProps={{ displayEmpty: true }}
                >
                  <MenuItem value="">Seleccione un año</MenuItem>
                  {obtenerAnios().map((item) => {
                    return (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </TextField>
                <TextField
                  className={clases.formD}
                  value={item.tipoDocumento}
                  name="tipoDocumento"
                  onChange={handleChange}
                  label={`Tipo Documento`}
                  select
                  InputLabelProps={{ shrink: true }}
                  SelectProps={{ displayEmpty: true }}
                >
                  <MenuItem value="">Seleccione un tipo de documento</MenuItem>
                  {["Todos"].map((item) => {
                    return (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Box>

              <Box
                display="flex"
                width="100%"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="flex-end"
                  mt={1}
                >
                  <Button
                    style={{ width: "30%" }}
                    variant="contained"
                    color="primary"
                    disabled={submit() || cargando}
                    onClick={() => {
                      handleCrear();
                    }}
                  >
                    Generar
                  </Button>
                </Box>
              </Box>
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default memo(ModalATS);
