import { AddCircle, Delete } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useModalStyle } from "../../../styles/styleModal";
import clienteAxios from "../../../config/axios";
import AlertaContext from "../../../Context/Alerta/AlertaContext";

const CamposAcumulablesTecnicos = ({ tecnicos, settecnicos, error }) => {
  const [tecnicosData, settecnicosData] = useState([]);
  const { mostrarAlerta } = useContext(AlertaContext);
  const getTecnicosData = async () => {
    try {
      const res = await clienteAxios(
        "/proveedores/filtro/0?search&estado=TECNICO&tabla=personas&listadoporperfil=true"
      );
      console.log({ res });
      settecnicosData(res.data.data);
    } catch (error) {
      settecnicosData([]);
    }
  };
  useEffect(() => {
    getTecnicosData();
  }, []);
  const clases = useModalStyle();
  return (
    <>
      <Divider className={clases.formAllW}>
        <Box display="flex" alignItems="center">
          <Typography variant="subtitle2" color={error ? "error" : "primary"}>
            Tecnicos:
          </Typography>

          <IconButton
            onClick={async () => {
              settecnicos([...tecnicos, ""]);
            }}
          >
            <AddCircle color="secondary" />
          </IconButton>
        </Box>
      </Divider>
      {tecnicos.map((value, index) => {
        return (
          <FormControl fullWidth className={clases.formD} key={index}>
            <Autocomplete 
              options={tecnicosData.map((item) => {
                return (
                  item.perDocNumero +
                  "/+/" +
                  item.perNombre +
                  "/+/" +
                  item.perDocTipo
                );
              })}
              getOptionLabel={(option) => {
                return option.replace("/+/", " ").replace("/+/", " ");
              }}
              id="controlled-demo"
              value={value}
              paperprops
              onChange={async (event, newValue) => {
                if (newValue) {
                  const options = newValue.split("/+/");

                  const findItem = tecnicosData.find(
                    (clienteData) => clienteData.perDocNumero === options[0]
                  );

                  if (tecnicos.some((tec) => tec === findItem.perNombre)) {
                    return mostrarAlerta(
                      "Este técnico ya se encuentra listado",
                      "error"
                    );
                  }

                  settecnicos(
                    tecnicos.map((item, indexItem) => {
                      if (indexItem === index) return findItem.perNombre;
                      return item;
                    })
                  );
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  required
                  label={`Tecnico #${index + 1}`}
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <InputAdornment>
                        <IconButton
                          onClick={() => {
                            settecnicos(
                              tecnicos.filter(
                                (item, indexItem) => indexItem !== index
                              )
                            );
                          }}
                        >
                          <Delete color="error" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </FormControl>
          // <TextField
          //   className={clases.formD}
          //   key={index}
          //   value={value}
          //   autoComplete="false"
          //   size="small"
          //   onChange={(e) => {
          //     settecnicos(
          //       tecnicos.map((item, indexItem) => {
          //         if (indexItem === index) return e.target.value;
          //         return item;
          //       })
          //     );
          //   }}
          //   label={`Tecnico #${index + 1}`}
          //   InputProps={{
          //     endAdornment: (
          //       <InputAdornment>
          //         <IconButton
          //           onClick={() => {
          //             settecnicos(
          //               tecnicos.filter(
          //                 (item, indexItem) => indexItem !== index
          //               )
          //             );
          //           }}
          //         >
          //           <Delete color="error" />
          //         </IconButton>
          //       </InputAdornment>
          //     ),
          //   }}
          // />
        );
      })}
    </>
  );
};

export default CamposAcumulablesTecnicos;
