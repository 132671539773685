import React, { memo, useContext } from "react";

import { useState } from "react";
import AuthContext from "../../../../../Context/Auth/AuthContext";
// MATERIAL
import { TableCell, TableRow, TextField } from "@mui/material";
import { Cancel, Check } from "@mui/icons-material";

import ButtonsAction from "../../../../components/Tablas/ButtonsAction";
import {
  useRowStyles,
  butonIconTransition,
} from "../../../../../styles/stylesRows";

const RowAgregarItem = (props) => {
  // eslint-disable-next-line
  const { item, setitem, setagregarItemLista, socket } = props;
  const { usuario } = useContext(AuthContext);
  const itemHijoDefecto = { nombre: "" };
  const [itemNuevo, setItemNuevo] = useState(itemHijoDefecto);
  const botonDisabled = () => {
    if (itemNuevo.nombre === "") {
      return true;
    } else {
      return false;
    }
  };

  const botones = [
    {
      tooltip: "Cancelar",
      texto: "",
      funcion: () => {
        setagregarItemLista(false);
        setItemNuevo(itemHijoDefecto);
      },
      disabled: false,
      Icon: Cancel,
      color: "secondary",
      id: 1,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
    },
    {
      tooltip: "Guardar",
      texto: "",
      funcion: () => {
        handleAgregar();
        setagregarItemLista(false);
        setItemNuevo(itemHijoDefecto);
      },
      disabled: botonDisabled(),
      Icon: Check,
      color: "success",
      id: 2,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
      submit: true,
    },
  ];

  const classes = useRowStyles();

  const handleChange = (e) => {
    setItemNuevo({
      ...itemNuevo,
      [e.target.name]: e.target.value,
    });
  };

  const handleAgregar = async () => {
    try {
      const ciuSectores = [
        {
          ...itemNuevo,
        },
        ...item.ciuSectores,
      ];

      socket.current.emit("client:actualizarData", {
        ...item,
        tabla: "ciudad",
        ciuSectores,
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        ciuUser: usuario.usuario,
        ciuEmpresa: usuario.rucempresa,
      });
      setitem({
        ...item,
        ciuSectores,
      });
    } catch (error) {}
  };

  return (
    <React.Fragment>
      <TableRow
        className={classes.root}
        component={"form"}
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <ButtonsAction botones={botones} />

        {/* las demas columnas */}

        <TableCell
          fontSize="small"
          sx={{ paddingLeft: "5px" }}
          padding="none"
          align="center"
          className={classes.celda}
        >
          <TextField
            value={itemNuevo.nombre}
            name="nombre"
            onChange={(e) => handleChange(e)}
            size="small"
            fullWidth
          />
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default memo(RowAgregarItem);
