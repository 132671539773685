import React, { memo, useState } from "react";

// MATERIAL
import { makeStyles } from "@mui/styles";
import {
  Autocomplete,
  Box,
  FormControl,
  IconButton,
  TableCell,
  TableRow,
  TextField,
  Popper,
} from "@mui/material";
import { Cancel, Check } from "@mui/icons-material";
import { useContext } from "react";
import AlertaContext from "../../../../../Context/Alerta/AlertaContext";
import clienteAxios from "../../../../../config/axios";
import { trimPropsItem } from "../../../../../config/const";
import { v4 } from "uuid";

const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  margin: {
    margin: "0px",
    padding: "2px",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));
const RowAgregarArma = (props) => {
  const { setagregar, setitem, item } = props;
  const { mostrarAlerta } = useContext(AlertaContext);
  const [medicinasData, setmedicinasData] = useState([]);
  const [itemRow, setItemRow] = useState({
    order: 1,
    cantidad: "1",
    medicina: "",
    serie: "",
    tratamiento: "",
  });
  const PopperMy = function(props) {
    return (
      <Popper {...props} style={useRowStyles.popper} placement="bottom-start" />
    );
  };
  const handleChange = (e) => {
    setItemRow({ ...itemRow, [e.target.name]: e.target.value });
  };
  const botonDisabled = () => {
    if (
      itemRow.medicina.trim() === "" ||
      itemRow.cantidad.trim() === "" ||
      itemRow.tratamiento.trim() === ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  const classes = useRowStyles();
  //gql eliminar user

  const handleAgregar = async () => {
    try {
      const recMedicamentos = {
        arrayMedicamentos: [
          trimPropsItem({ ...itemRow, id: v4() }),
          ...item.recMedicamentos.arrayMedicamentos.map((item) => {
            return { ...item, order: item.order + 1 };
          }),
        ],
      };

      setitem({
        ...item,
        recMedicamentos,
      });
    } catch (error) {}
  };

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            alignItems="center"
          >
            <IconButton
              className={classes.margin}
              onClick={() => {
                setagregar(false);
                setItemRow({
                  order: 1,
                  cantidad: "1",
                  medicina: "",
                  serie: "",
                  tratamiento: "",
                });
              }}
            >
              <Cancel fontSize="small" color="secondary" />
            </IconButton>
            <IconButton
              className={classes.margin}
              disabled={botonDisabled()}
              color={botonDisabled() ? "secondary" : "inherit"}
              onClick={() => {
                handleAgregar();

                setagregar(false);
                setItemRow({
                  order: 1,
                  cantidad: "1",
                  medicina: "",
                  serie: "",
                  tratamiento: "",
                });
              }}
            >
              <Check fontSize="small" />
            </IconButton>
          </Box>
        </TableCell>
        {/* las demas columnas */}

        <>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            <FormControl fullWidth>
              <Autocomplete
                filterOptions={(x) => x}
                PopperComponent={PopperMy}
                options={medicinasData.map((item) => {
                  return item.invNombre + "/+/" + item.invId;
                })}
                getOptionLabel={(option) => {
                  return option.split("/+/")[0];
                }}
                id="controlled-demo"
                value={itemRow.medicina}
                paperprops
                onChange={async (event, newValue) => {
                  if (newValue) {
                    const options = newValue.split("/+/");
                    const itemF = medicinasData.find((itemPersona) => {
                      return itemPersona.invId === Number(options[1]);
                    });

                    setItemRow({
                      ...itemRow,
                      serie: itemF.invSerie,
                      medicina: itemF.invNombre,
                    });
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    // error={error.recMascNombre}

                    fullWidth
                    onChange={async (e) => {
                      if (e.target.value === "") {
                        return;
                      }
                      try {
                        setItemRow({
                          ...itemRow,
                          medicina: e.target.value,
                        });
                        const res = await clienteAxios.get(
                          `/inventario/filtro/0?search=${e.target.value}&tipo=`
                        );

                        setmedicinasData(res.data.data);
                      } catch (error) {
                        mostrarAlerta("Hubo un error", "error");
                      }
                    }}
                    // InputProps={{
                    //   ...params.InputProps,
                    //   endAdornment: (
                    //     <React.Fragment>
                    //       {!editar ? (
                    //         <IconButton
                    //           size="small"
                    //           aria-label=""
                    //           onClick={async () => {
                    //             seteditarProveedor(false);
                    //             setopenProveedor(true);
                    //           }}
                    //         >
                    //           <AddCircleOutlineTwoTone color="primary" />
                    //         </IconButton>
                    //       ) : null}
                    //       {params.InputProps.endAdornment}
                    //     </React.Fragment>
                    //   ),
                    // }}
                  />
                )}
              />
            </FormControl>
            {/* <TextField
              name="medicina"
              value={itemRow.medicina}
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
            /> */}
          </TableCell>
          <TableCell
            padding="checkbox"
            size="small"
            align="center"
            className={classes.celda}
          >
            <TextField
              type={"number"}
              name="cantidad"
              value={itemRow.cantidad}
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
            />
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            <TextField
              name="tratamiento"
              value={itemRow.tratamiento}
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
            />
          </TableCell>
        </>
      </TableRow>
    </React.Fragment>
  );
};

export default memo(RowAgregarArma);
