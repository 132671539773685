import { blueGrey, blue, red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";
const theme = (p) => {
  if (p) {
    return createTheme({
      palette: {
        secondary: {
          main: p.colorSecundario,
        },
        primary: {
          main: p.colorPrimario,
        },
        error: {
          main: red[400],
        },
      },
      typography: {
        fontFamily: ["Prompt"],
      },
      components: {
        MuiTextField: {
          defaultProps: {
            variant: "standard",
          },
        },
      },
    });
  }
  return createTheme({
    palette: {
      secondary: {
        main: blueGrey[900],
      },
      primary: {
        main: blue[500],
        dark: blue[800],
      },
    },
    typography: {
      fontFamily: ["Prompt"],
    },
    components: {
      MuiTextField: {
        defaultProps: {
          variant: "standard",
        },
      },
    },
  });
};

export default theme;
