import React, { memo, useContext, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  TableCell,
  Tooltip,
} from "@mui/material";
import {
  Cancel,
  Check,
  Create,
  PictureAsPdf,
  ListRounded,
  ExpandLess,
  ExpandMore,
  Receipt,
  CopyAll,
  Notifications,
} from "@mui/icons-material";
import { withRouter } from "react-router";
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import { tienePermisoRuta } from "../../../../config/validaciones";
import AuthContext from "../../../../Context/Auth/AuthContext";
import MenuContext from "../../../../Context/Menu/MenuContext";
import { serverphp, permisoDenagado } from "../../../../config/const";
import ButtonsActionsDialog from "./ButtonsActionsDialog";
import ButtonsActionsNotificaciones from "./ButtonsActionsNotificaciones";
const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  margin: {
    margin: "0px",
    padding: "2px",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));

const ButtonsAction = (props) => {
  const {
    arrayExport,
    setarrayExport,
    campoExport,
    row,
    setopenConfirmDialog,
    funcionEdit,
    ocultarEditar,
    // ocultarDelete,
    mostrarEstado,
    campoEstado,
    editar,
    funcCancel,
    funcSubmit,
    botonDisabled,
    setopenModalEmail,
    setopen: setopenModal,
    expandir,
    setexpandir,
    ObjectoActivo,
    changeObjetoActivoState,
  } = props;
  const classes = useRowStyles();
  const { usuario } = useContext(AuthContext);
  const { rows } = useContext(MenuContext);
  const { mostrarAlerta } = useContext(AlertaContext);
  const [open, setopen] = useState(false);
  const [openNotificaciones, setopenNotificaciones] = useState(false);
  return (
    <>
      <ButtonsActionsDialog
        setopenModal={setopenModal}
        open={open}
        setopen={setopen}
        setopenConfirmDialog={setopenConfirmDialog}
        row={row}
        setopenModalEmail={setopenModalEmail}
        ObjectoActivo={ObjectoActivo}
        changeObjetoActivoState={changeObjetoActivoState}
      />
      <ButtonsActionsNotificaciones
        row={row}
        open={openNotificaciones}
        setopen={setopenNotificaciones}
      />
      {arrayExport ? (
        <TableCell
          size="small"
          padding="checkbox"
          style={{
            borderRight: "1px solid #ccc",
            maxWidth: "1rem",
          }}
        >
          <Box display="flex" alignItems="center" justifyContent="center">
            <FormControlLabel
              className={classes.margin}
              control={
                <Checkbox
                  size="small"
                  className={classes.margin}
                  checked={arrayExport.some((item) => {
                    return item === row[campoExport];
                  })}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setarrayExport([...arrayExport, row[campoExport]]);
                    } else {
                      setarrayExport(
                        arrayExport.filter((item) => item !== row[campoExport])
                      );
                    }
                  }}
                  color="secondary"
                />
              }
            />
          </Box>
        </TableCell>
      ) : null}
      <TableCell padding="none" style={{ borderRight: "1px solid #ccc" }}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-around"
          ml={1}
          mr={1}
        >
          <>
            {editar ? (
              <>
                <IconButton
                  className={classes.margin}
                  onClick={() => {
                    funcCancel();
                  }}
                >
                  <Cancel fontSize="small" color="secondary" />
                </IconButton>
                <IconButton
                  className={classes.margin}
                  disabled={botonDisabled ? botonDisabled() : false}
                  onClick={() => {
                    funcSubmit();
                  }}
                >
                  <Check
                    fontSize="small"
                    color={
                      botonDisabled
                        ? botonDisabled()
                          ? "inherit"
                          : "secondary"
                        : "secondary"
                    }
                  />
                </IconButton>
              </>
            ) : (
              <>
                {ocultarEditar ? null : (
                  <IconButton
                    className={classes.margin}
                    onClick={() => {
                      if (
                        !tienePermisoRuta(
                          rows,
                          props.location.pathname,
                          usuario.rol,
                          "editar"
                        )
                      ) {
                        return mostrarAlerta(permisoDenagado, "error");
                      }
                      funcionEdit();
                    }}
                  >
                    <Create color="secondary" />
                  </IconButton>
                )}
                <Tooltip title="Copiar" arrow>
                  <IconButton
                    className={classes.margin}
                    color="secondary"
                    onClick={() => {
                      // Abres la nueva ventas
                      setopenModal(true);
                      console.log("hola");

                      changeObjetoActivoState({
                        ...ObjectoActivo,
                        venProductos: row.venProductos,
                        venOption: "2",
                        venTipoDocumento: row.venTipoDocumento,
                        venSubTotal12: row.venSubTotal12,
                        venSubtotal0: row.venSubtotal0,
                        venDescuento: row.venDescuento,
                        venSubTotal: row.venSubTotal,
                        venTotalIva: row.venTotalIva,
                        venTotal: row.venTotal,
                        venCostoProduccion: row.venCostoProduccion,
                        venNumFacturaAnterior: row.venNumFactura,
                        fechaSustentoFactura: row.venFechaFactura,
                        venProductosAntiguos: row.venProductos,
                      });
                    }}
                  >
                    <CopyAll />
                  </IconButton>
                </Tooltip>
                <Tooltip title="PDF" arrow>
                  <IconButton
                    className={classes.margin}
                    disabled={
                      !(
                        row.venEstado === "AUTORIZADO" ||
                        row.venEstado === "ANULADA" ||
                        row.venEstado === "SIN AUTORIZAR" ||
                        row.venEstado === "ACTIVA"
                      )
                    }
                    style={{
                      color:
                        row.venEstado === "AUTORIZADO" ||
                        row.venEstado === "ANULADA" ||
                        row.venEstado === "SIN AUTORIZAR" ||
                        row.venEstado === "ACTIVA"
                          ? "#f44336"
                          : "",
                    }}
                    onClick={() => {
                      // Abres la nueva ventas
                      window.open(
                        `${serverphp}reportes/factura.php?codigo=${row.venId}&empresa=${usuario.rucempresa}`,
                        "_blank"
                      );
                    }}
                  >
                    <PictureAsPdf />
                  </IconButton>
                </Tooltip>
                <Tooltip title="COMP. RETENCIÓN" arrow>
                  <IconButton
                    className={classes.margin}
                    disabled={row.venOtros === "{}" || !row.venOtros}
                    onClick={() => {
                      // Abres la nueva ventas
                      window.open(
                        `${serverphp}reportes/compra_from_factura.php?codigo=${row.venId}&empresa=${usuario.rucempresa}`,
                        "_blank"
                      );
                    }}
                  >
                    <PictureAsPdf
                      color={
                        row.venOtros === "{}" || !row.venOtros
                          ? "inherit"
                          : "secondary"
                      }
                    />
                  </IconButton>
                </Tooltip>
                {row.venEstado === "ANULADA" ||
                row.venEstado === "SIN AUTORIZAR" ||
                row.venEstado === "AUTORIZADO" ? (
                  <Tooltip title="Ticket" arrow>
                    <IconButton
                      className={classes.margin}
                      color="secondary"
                      onClick={() => {
                        // Abres la nueva ventas
                        window.open(
                          `${serverphp}reportes/facturaticket.php?codigo=${row.venId}&empresa=${usuario.rucempresa}`,
                          "_blank"
                        );
                      }}
                    >
                      <Receipt />
                    </IconButton>
                  </Tooltip>
                ) : null}
                <Tooltip title="Mas acciones" arrow>
                  <IconButton
                    className={classes.margin}
                    onClick={() => {
                      setopen(true);
                    }}
                  >
                    <ListRounded />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Notificaciones" arrow>
                  <IconButton
                    className={classes.margin}
                    onClick={() => {
                      setopenNotificaciones(true);
                    }}
                  >
                    <Notifications />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </>

          {mostrarEstado ? (
            <FormControlLabel
              disabled
              className={classes.margin}
              control={
                <Checkbox
                  value="ACTIVO"
                  className={classes.margin}
                  checked={
                    row[campoEstado] === "ACTIVA" ||
                    row[campoEstado] === "ACTIVO"
                      ? true
                      : false
                  }
                  color="primary"
                />
              }
            />
          ) : null}

          {expandir ? (
            <IconButton
              className={classes.margin}
              onClick={() => {
                setexpandir(false);
              }}
            >
              <ExpandLess color="secondary" />
            </IconButton>
          ) : (
            <IconButton
              className={classes.margin}
              onClick={() => {
                setexpandir(true);
              }}
            >
              <ExpandMore color="secondary" />
            </IconButton>
          )}
        </Box>
      </TableCell>
    </>
  );
};

export default withRouter(memo(ButtonsAction));
