import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import LaptopMacIcon from "@mui/icons-material/LaptopMac";
import HotelIcon from "@mui/icons-material/Hotel";
import RepeatIcon from "@mui/icons-material/Repeat";
import Typography from "@mui/material/Typography";
import Cargando from "../../../components/Cargando/Cargando";
import { useEffect, useState } from "react";
import clienteAxios from "../../../../config/axios";
import { CollectionsBookmark } from "@mui/icons-material";
import { Tooltip, useMediaQuery } from "@mui/material";
import { serverphpvet } from "../../../../config/const";

export const TimelineComponent = ({ item }) => {
  const [dataCitas, setdataCitas] = useState(null);
  const getCitas = async () => {
    const resCitas = await clienteAxios(
      `historiasclinicas/getByIdMascota/${item.mascId}`
    );
    console.log({ resCitas });
    setdataCitas(resCitas.data.data);
  };
  useEffect(() => {
    getCitas();
  }, []);
  const celular = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  console.log({ celular });
  if (!dataCitas) {
    return <Cargando titulo={"Cargando timeline"} />;
  }
  return (
    <Timeline position={celular ? "left" : "alternate-reverse"}>
      {dataCitas.length === 0 && (
        <Typography variant="body1" color="error" textAlign={"center"}>
          NO TIENE HISTORIAL!
        </Typography>
      )}
      {dataCitas.map((cita, index) =>
        index % 2 === 0 ? (
          <TimelineItem key={cita.hcliId}>
            <TimelineOppositeContent
              sx={{ m: "auto 0" }}
              align="right"
              variant="body2"
              color="primary"
            >
              {cita.hcliFecha}
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector />
              <TimelineDot
                sx={{
                  "&:hover": {
                    opacity: ".7",
                    cursor: "pointer",
                  },
                }}
                color="primary"
                onClick={() => {
                  window.open(
                    `${serverphpvet}reportes/historiaIndividual.php?id=${cita.hcliId}&empresa=${cita.hcliEmpresa}`,
                    "_blank"
                  );
                }}
              >
                <Tooltip title="PDF" arrow>
                  <CollectionsBookmark />
                </Tooltip>
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: "12px", px: 2 }}>
              <Typography variant="h6" component="span" color={"secondary"}>
                {cita.hcliMotiConsulMedica}
              </Typography>
              <Typography>{cita.hcliPerNombreVetInt}</Typography>
            </TimelineContent>
          </TimelineItem>
        ) : (
          <TimelineItem key={cita.hcliId}>
            <TimelineOppositeContent
              sx={{ m: "auto 0" }}
              variant="body2"
              color="text.secondary"
            >
              {cita.hcliFecha}
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector />
              <TimelineDot
                color="secondary"
                sx={{
                  "&:hover": {
                    opacity: ".7",
                    cursor: "pointer",
                  },
                }}
                onClick={() => {
                  window.open(
                    `${serverphpvet}reportes/historiaIndividual.php?id=${cita.hcliId}&empresa=${cita.hcliEmpresa}`,
                    "_blank"
                  );
                }}
              >
                <Tooltip title="PDF" arrow>
                  <CollectionsBookmark />
                </Tooltip>
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: "12px", px: 2 }}>
              <Typography variant="h6" component="span" color={"secondary"}>
                {cita.hcliMotiConsulMedica}
              </Typography>
              <Typography>{cita.hcliPerNombreVetInt}</Typography>
            </TimelineContent>
          </TimelineItem>
        )
      )}
    </Timeline>
  );
};
