import React, { memo, useContext } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  Checkbox,
  Tooltip,
} from "@mui/material";

import { Create, ExpandLess, ExpandMore } from "@mui/icons-material";

import { useState } from "react";

import MenuContext from "../../../Context/Menu/MenuContext";
import { tienePermiso } from "../../../config/validaciones";
import AlertaContext from "../../../Context/Alerta/AlertaContext";
import ConfirmacionDialog from "../../../components/Extra/ConfirmacionDialog";

const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  margin: {
    margin: "0px",
    padding: "2px",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));

const Row = (props) => {
  const {
    row,
    index,
    // eslint-disable-next-line
    setopen,
    expandir,
    expand,
    rol,
    permisosUsario,
    handlePermisoIndividual,
    cargando,
  } = props;
  const { mostrarAlerta } = useContext(AlertaContext);
  const { editarCategoria, changeCategoriaActiva } = useContext(MenuContext);
  const classes = useRowStyles();
  //gql eliminar usuario

  //gql eliminar usuario

  const handleEditar = (e, param) => {
    let permisos = row.sidInfo.permisos;
    if (e.target.checked) {
      permisos.push(rol);
    } else {
      permisos = permisos.filter((permiso) => permiso !== rol);
    }

    editarCategoria({
      ...row,
      sidInfo: { ...row.sidInfo, [e.target.name]: [...new Set([...permisos])] },
    });
    return e.target.checked
      ? mostrarAlerta("Permiso exitoso", "success")
      : mostrarAlerta("Permiso denegado", "error");
  };
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);

  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        titulo="¿Esta seguro de que quiere eliminar esta asignatura?"
        contenido={`La asignatura   ${row.sidInfo.name} , una vez borrado no podra ser recuperado`}
        _id={row.sidInfo._id}
        funcion={() => {}}
      />
      <TableRow className={classes.root} selected={index % 2 === 0}>
        {/* boton */}
        <TableCell
          style={{ borderRight: "1px solid #ccc", paddingRight: "1.5rem" }}
        >
          <Box display="flex" alignItems="center">
            <IconButton
              className={classes.margin}
              aria-label=""
              onClick={() => {
                changeCategoriaActiva(row);
                setopen(true);
              }}
            >
              <Create fontSize="small" color="secondary" />
            </IconButton>
            {row.sidInfo.expandible ? (
              <IconButton
                className={classes.margin}
                onClick={() => {
                  expandir(row.sidInfo.name, Boolean(expand[row.sidInfo.name]));
                }}
              >
                {Boolean(expand[row.sidInfo.name]) ? (
                  <ExpandLess fontSize="small" color="secondary" />
                ) : (
                  <ExpandMore fontSize="small" color="secondary" />
                )}
              </IconButton>
            ) : null}
          </Box>
        </TableCell>
        {/* las demas columnas */}
        <TableCell size="small" align="center">
          {row.sidInfo.name}
        </TableCell>
        {row.sidInfo.expandible ? (
          <>
            <TableCell size="small" align="center" padding="none"></TableCell>
            <TableCell size="small" align="center" padding="none"></TableCell>
            <TableCell size="small" align="center" padding="none"></TableCell>
            <TableCell size="small" align="center" padding="none">
              {permisosUsario ? (
                <>
                  <Tooltip title="Por defecto">
                    <Checkbox
                      onChange={() => {
                        handlePermisoIndividual(0, "permisos", row);
                      }}
                      color={cargando ? "default" : "secondary"}
                      disabled={cargando}
                      style={{ padding: "0", margin: "0" }}
                      size="small"
                    />
                  </Tooltip>
                  <Tooltip title="SI">
                    <Checkbox
                      onChange={() => {
                        handlePermisoIndividual(1, "permisos", row);
                      }}
                      color={cargando ? "default" : "secondary"}
                      disabled={cargando}
                      style={{ padding: "0", margin: "0" }}
                      size="small"
                    />
                  </Tooltip>
                  <Tooltip title="NO">
                    <Checkbox
                      onChange={() => {
                        handlePermisoIndividual(2, "permisos", row);
                      }}
                      color={cargando ? "default" : "secondary"}
                      disabled={cargando}
                      style={{ padding: "0", margin: "0" }}
                      size="small"
                    />
                  </Tooltip>
                </>
              ) : (
                <Checkbox
                  value="ACTIVO"
                  onChange={(e) => {
                    handleEditar(e, row.sidInfo.permisos);
                  }}
                  checked={tienePermiso(rol, row.sidInfo.permisos)}
                  name="permisos"
                  color="secondary"
                  style={{ padding: "0", margin: "0" }}
                  size="small"
                />
              )}
            </TableCell>
            <TableCell size="small" align="center" padding="none"></TableCell>
          </>
        ) : (
          <>
            <TableCell size="small" align="center" padding="none">
              {permisosUsario ? (
                <>
                  <Tooltip title="Por defecto">
                    <Checkbox
                      onChange={() => {
                        handlePermisoIndividual(0, "agregar", row);
                      }}
                      color={cargando ? "default" : "secondary"}
                      disabled={cargando}
                      style={{ padding: "0", margin: "0" }}
                      size="small"
                    />
                  </Tooltip>
                  <Tooltip title="SI">
                    <Checkbox
                      onChange={() => {
                        handlePermisoIndividual(1, "agregar", row);
                      }}
                      color={cargando ? "default" : "secondary"}
                      disabled={cargando}
                      style={{ padding: "0", margin: "0" }}
                      size="small"
                    />
                  </Tooltip>
                  <Tooltip title="NO">
                    <Checkbox
                      onChange={() => {
                        handlePermisoIndividual(2, "agregar", row);
                      }}
                      color={cargando ? "default" : "secondary"}
                      disabled={cargando}
                      style={{ padding: "0", margin: "0" }}
                      size="small"
                    />
                  </Tooltip>
                </>
              ) : (
                <Checkbox
                  value="ACTIVO"
                  onChange={(e) => {
                    handleEditar(e, row.sidInfo.agregar);
                  }}
                  checked={tienePermiso(rol, row.sidInfo.agregar)}
                  name="agregar"
                  color="secondary"
                  style={{ padding: "0", margin: "0" }}
                  size="small"
                />
              )}
            </TableCell>
            <TableCell size="small" align="center" padding="none">
              {permisosUsario ? (
                <>
                  <Tooltip title="Por defecto">
                    <Checkbox
                      onChange={() => {
                        handlePermisoIndividual(0, "editar", row);
                      }}
                      color={cargando ? "default" : "secondary"}
                      disabled={cargando}
                      style={{ padding: "0", margin: "0" }}
                      size="small"
                    />
                  </Tooltip>
                  <Tooltip title="SI">
                    <Checkbox
                      onChange={() => {
                        handlePermisoIndividual(1, "editar", row);
                      }}
                      color={cargando ? "default" : "secondary"}
                      disabled={cargando}
                      style={{ padding: "0", margin: "0" }}
                      size="small"
                    />
                  </Tooltip>
                  <Tooltip title="NO">
                    <Checkbox
                      onChange={() => {
                        handlePermisoIndividual(2, "editar", row);
                      }}
                      color={cargando ? "default" : "secondary"}
                      disabled={cargando}
                      style={{ padding: "0", margin: "0" }}
                      size="small"
                    />
                  </Tooltip>
                </>
              ) : (
                <Checkbox
                  value="ACTIVO"
                  onChange={(e) => {
                    handleEditar(e, row.sidInfo.editar);
                  }}
                  checked={tienePermiso(rol, row.sidInfo.editar)}
                  name="editar"
                  color="secondary"
                  style={{ padding: "0", margin: "0" }}
                  size="small"
                />
              )}
            </TableCell>
            <TableCell size="small" align="center" padding="none">
              {permisosUsario ? (
                <>
                  <Tooltip title="Por defecto">
                    <Checkbox
                      onChange={() => {
                        handlePermisoIndividual(0, "eliminar", row);
                      }}
                      color={cargando ? "default" : "secondary"}
                      disabled={cargando}
                      style={{ padding: "0", margin: "0" }}
                      size="small"
                    />
                  </Tooltip>
                  <Tooltip title="SI">
                    <Checkbox
                      onChange={() => {
                        handlePermisoIndividual(1, "eliminar", row);
                      }}
                      color={cargando ? "default" : "secondary"}
                      disabled={cargando}
                      style={{ padding: "0", margin: "0" }}
                      size="small"
                    />
                  </Tooltip>
                  <Tooltip title="NO">
                    <Checkbox
                      onChange={() => {
                        handlePermisoIndividual(2, "eliminar", row);
                      }}
                      color={cargando ? "default" : "secondary"}
                      disabled={cargando}
                      style={{ padding: "0", margin: "0" }}
                      size="small"
                    />
                  </Tooltip>
                </>
              ) : (
                <Checkbox
                  value="ACTIVO"
                  onChange={(e) => {
                    handleEditar(e, row.sidInfo.eliminar);
                  }}
                  checked={tienePermiso(rol, row.sidInfo.eliminar)}
                  name="eliminar"
                  color="secondary"
                  style={{ padding: "0", margin: "0" }}
                  size="small"
                />
              )}
            </TableCell>
            <TableCell size="small" align="center" padding="none">
              {permisosUsario ? (
                <>
                  <Tooltip title="Por defecto">
                    <Checkbox
                      onChange={() => {
                        handlePermisoIndividual(0, "permisos", row);
                      }}
                      color={cargando ? "default" : "secondary"}
                      disabled={cargando}
                      style={{ padding: "0", margin: "0" }}
                      size="small"
                    />
                  </Tooltip>
                  <Tooltip title="SI">
                    <Checkbox
                      onChange={() => {
                        handlePermisoIndividual(1, "permisos", row);
                      }}
                      color={cargando ? "default" : "secondary"}
                      disabled={cargando}
                      style={{ padding: "0", margin: "0" }}
                      size="small"
                    />
                  </Tooltip>
                  <Tooltip title="NO">
                    <Checkbox
                      onChange={() => {
                        handlePermisoIndividual(2, "permisos", row);
                      }}
                      color={cargando ? "default" : "secondary"}
                      disabled={cargando}
                      style={{ padding: "0", margin: "0" }}
                      size="small"
                    />
                  </Tooltip>
                </>
              ) : (
                <Checkbox
                  value="ACTIVO"
                  onChange={(e) => {
                    handleEditar(e, row.sidInfo.permisos);
                  }}
                  checked={tienePermiso(rol, row.sidInfo.permisos)}
                  name="permisos"
                  color="secondary"
                  style={{ padding: "0", margin: "0" }}
                  size="small"
                />
              )}
            </TableCell>
            <TableCell size="small" align="center" padding="none">
              {permisosUsario ? (
                <>
                  <Tooltip title="Por defecto">
                    <Checkbox
                      onChange={() => {
                        handlePermisoIndividual(0, "allData", row);
                      }}
                      color={cargando ? "default" : "secondary"}
                      disabled={cargando}
                      style={{ padding: "0", margin: "0" }}
                      size="small"
                    />
                  </Tooltip>
                  <Tooltip title="SI">
                    <Checkbox
                      onChange={() => {
                        handlePermisoIndividual(1, "allData", row);
                      }}
                      color={cargando ? "default" : "secondary"}
                      disabled={cargando}
                      style={{ padding: "0", margin: "0" }}
                      size="small"
                    />
                  </Tooltip>
                  <Tooltip title="NO">
                    <Checkbox
                      onChange={() => {
                        handlePermisoIndividual(2, "allData", row);
                      }}
                      color={cargando ? "default" : "secondary"}
                      disabled={cargando}
                      style={{ padding: "0", margin: "0" }}
                      size="small"
                    />
                  </Tooltip>
                </>
              ) : (
                <Checkbox
                  value="ACTIVO"
                  onChange={(e) => {
                    handleEditar(e, row.sidInfo.allData);
                  }}
                  checked={tienePermiso(rol, row.sidInfo.allData)}
                  name="allData"
                  color="secondary"
                  style={{ padding: "0", margin: "0" }}
                  size="small"
                />
              )}
            </TableCell>
          </>
        )}
      </TableRow>
    </React.Fragment>
  );
};

export default memo(Row);
