export const objectDefault = {
  // notId: 5,
  notTipo: "",
  notIdPersona: "",
  notVisto: "",
  notTitulo: "",
  notContenido: "",
  notUser: "",
  notEmpresa: "",
  id_registro: "",
  url_web: "",
  notFecReg: "",
  notFecUpd: "",
  perDocNumero: "",
  perNombre: "",
  perFoto: "",
  perPerfil: [],
  notInformacionAdicional: {
    alerAsunto: "",
    perCelular: "",
    perEmail: "",
    coordenadas: {
      latitud: -0.239079,
      longitud: -79.171853,
    },
  },
};

export const columns = [
  {
    id: "notVisto",
    label: "Visto",
    minWidth: 25,
    tipo: "string",
    propiedad: "notVisto",
  },
  {
    id: "perFotoCasa",
    label: "Casa",
    minWidth: 25,
    tipo: "string",
    propiedad: "perFotoCasa",
  },
  {
    id: "perFotoVehiculo",
    label: "Transporte",
    minWidth: 25,
    tipo: "string",
    propiedad: "perFotoVehiculo",
  },

  {
    id: "perDocNumero",
    label: "Persona",
    minWidth: 25,
    tipo: "string",
    propiedad: "perDocNumero",
  },
  {
    id: "notTipo",
    label: "Tipo",
    minWidth: 25,
    tipo: "string",
    propiedad: "notTipo",
  },

  {
    id: "notFecReg",
    label: "FecReg ",
    minWidth: 25,
    tipo: "string",
    propiedad: "notFecReg",
  },
];
