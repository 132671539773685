import { Typography } from "@mui/material";
import { CampoDinamico } from "./CampoDinamico";

export const CamposDinamicos = ({
  item,
  setitem,
  textoPropiedad,
  tabsDinamicos,
  seccionActive,
  error,
  cargando,
  setcargando,
}) => {
  const tabActivo = tabsDinamicos.find((tab) => seccionActive[tab.nombreTab]);
  if (!tabActivo) {
    return (
      <Typography variant="h3" color={"Error"}>
        Hubo un error, vuelva a selecionar el tab dinamico por favor
      </Typography>
    );
  }
  return (
    <>
      {tabActivo.campos
        .sort((a, b) => a.order - b.order)
        .map((campo) => (
          <CampoDinamico
            key={campo.id}
            campo={campo}
            {...campo}
            item={item}
            setitem={setitem}
            textoPropiedad={textoPropiedad}
            cargando={cargando}
            setcargando={setcargando}
            error={error}
          />
        ))}
    </>
  );
};
