import { AddCircle, Delete } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useModalStyle } from "../../../styles/styleModal";

const CamposAcumulablesSector = ({
  actaSector,
  sectoresData,
  error,
  setitem,
}) => {
  const clases = useModalStyle();
  useEffect(() => {
    if (actaSector === null) {
      setitem((prev) => ({ ...prev, actaSector: [] }));
    }
  }, [actaSector]);
  console.log({ actaSector });

  if (actaSector === null) {
    return null;
  }
  return (
    <>
      <Divider className={clases.formAllW}>
        <Box display="flex" alignItems="center">
          <Typography variant="subtitle2" color={error ? "error" : "primary"}>
            Sectores:
          </Typography>

          <IconButton
            size="small"
            onClick={async () => {
              setitem((prev) => {
                console.log({ ...prev, actaSector: [...actaSector, ""] });
                return { ...prev, actaSector: [...actaSector, ""] };
              });
            }}
          >
            <AddCircle fontSize="small" color="secondary" />
          </IconButton>
        </Box>
      </Divider>
      {actaSector.map((value, index) => {
        return (
          <FormControl key={index} fullWidth className={clases.formD}>
            <Autocomplete
              options={sectoresData.map((item) => {
                return item.nombre;
              })}
              getOptionLabel={(option) => {
                return option;
              }}
              disableClearable
              id="controlled-demo"
              value={value}
              paperprops
              onChange={async (event, newValue) => {
                if (newValue) {
                  setitem((prev) => ({
                    ...prev,
                    actaSector: actaSector.map((item, indexItem) => {
                      if (indexItem === index) return newValue;
                      return item;
                    }),
                  }));
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  required
                  label={`Sector #${index + 1}`}
                  name="actaCiudad"
                  error={error}
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <InputAdornment>
                        <IconButton
                          onClick={() => {
                            setitem((prev) => ({
                              ...prev,
                              actaSector: actaSector.filter(
                                (item, indexItem) => indexItem !== index
                              ),
                            }));
                          }}
                        >
                          <Delete color="error" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </FormControl>
        );
      })}
      <Divider className={clases.formAllW} sx={{ mb: "1rem !important" }} />
    </>
  );
};

export default CamposAcumulablesSector;
