import React, { useState, useContext, useEffect, memo } from "react";
import { makeStyles } from "@mui/styles";
import {
  Modal,
  Box,
  Button,
  Divider,
  Typography,
  TextField,
  FormControl,
  Autocomplete,
  Paper,
  TableContainer,
  Table,
  TablePagination,
  TableBody,
  TableHead,
  InputAdornment,
  Tooltip,
} from "@mui/material";

import Draggable from "react-draggable";
import { Cancel, Delete } from "@mui/icons-material";
import { IconButton, FormLabel, FormHelperText } from "@mui/material";
// eslint-disable-next-line
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import AuthContext from "../../../../Context/Auth/AuthContext";
import {
  FechaExacta,
  fechaHora,
  trimPropsItem,
} from "../../../../config/const";
import dayjs from "dayjs";
import clienteAxios from "../../../../config/axios";
import RowBono from "./RowBono/RowBono";
import RowAgregarBono from "./RowBono/RowAgregarBono";
import ToolBarTable from "./ToolBarTable";
import TablaCabecera from "./TablaCabecera";
import { usePermiso } from "../../../../hooks/usePermiso";

import Editor from "../../../../components/Extra/Editor";
import { useDraggable } from "../../../../hooks/useDraggable";
import { v4 } from "uuid";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
function getModalStyle() {
  return {
    borderRadius: "15px",
    position: "absolute",
    overflow: "scroll",
    maxHeight: "95vh",
  };
}
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "70%",
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "15px",
    marginTop: "2vh",
    [theme.breakpoints.down("sm")]: { width: "90%", marginTop: "2vh" },
  },
  margin: {
    margin: theme.spacing(1),
    width: "45%",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  ocultar: { display: "none" },
  inputPadding: {
    padding: theme.spacing(0.5),
    margin: theme.spacing(0),
    fontSize: "1rem",
  },
  formD: {
    flexGrow: 1,
    width: "45%",
    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  formAllW: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "95%",
    [theme.breakpoints.down("md")]: { padding: "0", margin: "2px" },
    [theme.breakpoints.down("sm")]: { padding: "0", margin: "2px" },
  },

  form30PC: {
    flexGrow: 1,
    width: "30%",
    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "30%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },

  large: {
    width: theme.spacing(13),
    height: theme.spacing(13),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    "&:hover": { opacity: ".7" },
  },
  center: { justifyContent: "center", alignItems: "center" },
  root: { width: "100%", marginTop: theme.spacing(1) },
  correo: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "45%",
    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  error: { color: "red", fontWeight: "bold" },
}));
const ModalData = (props) => {
  const { tienePermiso, alertaPermiso } = usePermiso("Recetas");
  const { mostrarAlerta } = useContext(AlertaContext);
  const { usuario } = useContext(AuthContext); //props
  const {
    open,
    setopen,
    editar,
    seteditar,
    ObjectoActivo,
    changeObjetoActivoState,
    socket,
  } = props;
  // eslint-disable-next-line
  const [item, setitem] = useState(ObjectoActivo);
  const {
    // recMascId,
    recMascNombre,
    // recPerIdDoc,
    recPerNombreDoc,
    // recPerIdPropietario,
    recPerNombrePropietario,
    recPeso,
    // recUser,
    // recEmpresa,
    // recTipo,
    // recCorreo,
    // recRecomendacion,
    recMedicamentos,
    recProxCita,
    recProxCitaHora,
    recMascRaza,
    recMascSexo,
    recMascEdad,
    recPerTelefonoPropietario,
    recPerDocNumeroPropietario,
    recPerDireccionPropietario,
    recPerCelularPropietario,
    recPerEmailPropietario,
    recObservacionProxCita,
    // recFecReg,
  } = item;

  const [veterinariosData, setveterinariosData] = useState([]);
  const [mascotasData, setmascotasData] = useState([]);

  const handleChange = (e) => {
    setitem({ ...item, [e.target.name]: e.target.value });
  };
  const [cargando, setcargando] = useState(false);
  const [seccionActive, setseccionActive] = useState({
    datosSeccion: true,
    medicamentosSeccion: false,
  });
  const { datosSeccion, medicamentosSeccion } = seccionActive;
  const [error, seterror] = useState({});
  const [agregandoBodega, setagregandoBodega] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if (usuario.rucempresa === "MEDIPET") {
      if (editar) {
        setitem(ObjectoActivo);
      } else {
        setitem({
          ...ObjectoActivo,
          recProxCita: ObjectoActivo.recId ? ObjectoActivo.recProxCita : "",
          recProxCitaHora: ObjectoActivo.recId
            ? ObjectoActivo.recProxCitaHora
            : "",
        });
      }
    } else {
      setitem(ObjectoActivo);
    }

    // eslint-disable-next-line
  }, [open, editar]);

  const defaultActive = () => {
    changeObjetoActivoState({
      // recId: 1,
      recMascId: "",
      recMascNombre: "",
      recPerIdDoc: "",
      recPerNombreDoc: "",
      recPerIdPropietario: "",
      recPerNombrePropietario: "",
      recPeso: "",
      recUser: "",
      recEmpresa: "",
      recTipo: "",
      recCorreo: "PENDIENTE",
      recRecomendacion: "",
      recMascRaza: "",
      recMascSexo: "",
      recMascEdad: "",
      recPerTelefonoPropietario: "",
      recPerDocNumeroPropietario: "",
      recPerDireccionPropietario: "",
      recPerCelularPropietario: "",
      recPerEmailPropietario: "",
      recMedicamentos: {
        arrayMedicamentos: [],
      },
      recProductosAntiguos: [],
      recProxCita: FechaExacta,
      recFecReg: "",
      recObservacionProxCita: "",
      recProxCitaHora: "",
    });
    setopen(false);
    seteditar(false);
    seterror({});

    setcargando(false);
  };

  //hook de estilos
  const clases = useStyles();
  //definiendo los estilos del modal
  const [modalStyle] = useState(getModalStyle);

  const validacion = () => {
    if (
      recMascNombre.trim() === "" ||
      recPeso.trim() === "" ||
      recPerNombreDoc.trim() === "" ||
      recProxCita === "" ||
      !recProxCita ||
      recMedicamentos.arrayMedicamentos.length === 0 ||
      recMedicamentos.arrayMedicamentos.some(
        (itemMedicamento) =>
          itemMedicamento.medicina.trim() === "" ||
          itemMedicamento.cantidad.trim() === "" ||
          itemMedicamento.tratamiento.trim() === "" ||
          itemMedicamento.editar
      ) ||
      error.recProxCitaHora
    ) {
      if (
        recMedicamentos.arrayMedicamentos.some(
          (itemMedicamento) =>
            itemMedicamento.medicina.trim() === "" ||
            itemMedicamento.cantidad.trim() === "" ||
            itemMedicamento.tratamiento.trim() === "" ||
            itemMedicamento.editar
        )
      ) {
        setitem({
          ...item,
          recMedicamentos: {
            arrayMedicamentos: recMedicamentos.arrayMedicamentos.map(
              (itemMedicamento) => {
                const error =
                  itemMedicamento.medicina.trim() === "" ||
                  itemMedicamento.cantidad.trim() === "" ||
                  itemMedicamento.tratamiento.trim() === "" ||
                  itemMedicamento.editar;
                return { ...itemMedicamento, error };
              }
            ),
          },
        });
      }
      seterror({
        recProxCitaHora: error.recProxCitaHora,
        recMascNombre: recMascNombre.trim() === "" ? true : false,
        recPeso: recPeso.trim() === "" ? true : false,
        recPerNombreDoc: recPerNombreDoc.trim() === "" ? true : false,
        recMedicamentos:
          recMedicamentos.arrayMedicamentos.length === 0 ||
          recMedicamentos.arrayMedicamentos.some(
            (itemMedicamento) =>
              itemMedicamento.medicina.trim() === "" ||
              itemMedicamento.cantidad.trim() === "" ||
              itemMedicamento.tratamiento.trim() === "" ||
              itemMedicamento.editar
          ),
        recProxCita: recProxCita === "" || !recProxCita,
      });

      return true;
    } else {
      return false;
    }
  };
  const handleCrear = async () => {
    if (validacion()) {
      return mostrarAlerta("Faltan campos obligatorios", "error");
    }
    try {
      setcargando(true);
      console.log(
        trimPropsItem({
          ...item,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          tabla: "receta",
          recUser: usuario.usuario,
          recEmpresa: usuario.rucempresa,
        })
      );
      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...item,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          tabla: "receta",
          recUser: usuario.usuario,
          recEmpresa: usuario.rucempresa,
        })
      );
      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);
      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  const handleEditar = async () => {
    if (validacion()) {
      return mostrarAlerta("Faltan campos obligatorios", "error");
    }
    try {
      setcargando(true);

      socket.current.emit(
        "client:actualizarData",
        trimPropsItem({
          ...item,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          tabla: "receta",
          recUser: `${item.recUser.split(" ** ")[0]} ** ${usuario.usuario}`,
          recEmpresa: usuario.rucempresa,
          recProductosAntiguos: ObjectoActivo.recMedicamentos.arrayMedicamentos,
        })
      );

      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);
      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  const {
    handleDragStart,
    handleDragEnter,
    dragItem,
    draOverItem,
  } = useDraggable();
  const handleDragEnd = (e, index) => {
    let copy = [...recMedicamentos.arrayMedicamentos];
    const draggedItemContent = copy.splice(dragItem.current, 1)[0];
    // switch position
    copy.splice(draOverItem.current, 0, draggedItemContent);
    // reset
    dragItem.current = null;
    draOverItem.current = null;
    setitem({
      ...item,
      recMedicamentos: {
        arrayMedicamentos: copy.map((item, index) => ({
          ...item,
          order: index + 1,
        })),
      },
    });
  };

  if (!open) {
    return null;
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Draggable handle="#handle">
          <Box display="flex" justifyContent="center">
            <div style={modalStyle} className={clases.paper}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography
                  style={{ cursor: "-webkit-grab" }}
                  id="handle"
                  variant="button"
                  color="initial"
                  align="center"
                >
                  {editar ? "Editar" : "Agregar"}
                  {" Receta"}
                </Typography>
                <Box display="flex" alignItems="center">
                  <IconButton
                    aria-label=""
                    style={{ margin: "0px" }}
                    onClick={() => {
                      defaultActive();
                    }}
                  >
                    <Cancel color="secondary" />
                  </IconButton>
                </Box>
              </Box>
              <Divider />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems=" stretch"
                width="100%"
              >
                <Box>
                  <Button
                    variant={
                      datosSeccion
                        ? "contained"
                        : error.recMascNombre ||
                          error.recPeso ||
                          error.recPerNombreDoc ||
                          error.recProxCita
                        ? "outlined"
                        : null
                    }
                    disableElevation
                    style={{ padding: "8px" }}
                    color={
                      error.recMascNombre ||
                      error.recPeso ||
                      error.recPerNombreDoc ||
                      error.recProxCita
                        ? "error"
                        : "secondary"
                    }
                    onClick={() => setseccionActive({ datosSeccion: true })}
                  >
                    Datos
                  </Button>

                  <Button
                    variant={
                      medicamentosSeccion
                        ? "contained"
                        : error.recMedicamentos
                        ? "outlined"
                        : null
                    }
                    disableElevation
                    style={{ padding: "8px" }}
                    color={error.recMedicamentos ? "error" : "secondary"}
                    onClick={() =>
                      setseccionActive({ medicamentosSeccion: true })
                    }
                  >
                    Medicamentos
                  </Button>
                </Box>
                {/* <FormControlLabel
                control={
                  <Switch
                    checked={perEstado === "ACTIVO" ? true : false}
                    onChange={(e) => {
                      setitem({
                        ...item,
                        perEstado: e.target.checked ? "ACTIVO" : "INACTIVO",
                      });
                    }}
                    name="antoine"
                  />
                }
                label="Estado"
              /> */}
              </Box>
              <Divider />
              <Box
                display="flex"
                flexWrap="wrap"
                alignItems="flex-end"
                borderRadius="0px 50px 50px 0px"
                mt={1}
              >
                {datosSeccion ? (
                  <>
                    {/* <TextField
                      className={clases.formD}
                      label="Paciente"
                      value={recMascNombre}
                      name="recMascNombre"
                      error={error.recMascNombre}
                      onChange={(e) => handleChange(e)}
                    /> */}
                    <FormControl fullWidth className={clases.formD}>
                      <Autocomplete
                        filterOptions={(x) => x}
                        options={mascotasData.map((item) => {
                          return (
                            item.mascNombre +
                            "/+/" +
                            item.mascPerNombre +
                            "/+/" +
                            item.mascId
                          );
                        })}
                        disabled={editar}
                        getOptionLabel={(option) => {
                          return option.replace("/+/", " - ").split("/+/")[0];
                        }}
                        id="controlled-demo"
                        value={recMascNombre}
                        paperprops
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            const options = newValue.split("/+/");
                            const itemF = mascotasData.find((itemPersona) => {
                              return itemPersona.mascId === Number(options[2]);
                            });

                            setitem({
                              ...item,
                              recMascId: itemF.mascId,
                              recMascNombre: itemF.mascNombre,
                              recPerIdPropietario: itemF.mascPerId,
                              recPerNombrePropietario: itemF.mascPerNombre,
                              recMascRaza: itemF.mascRaza,
                              recMascSexo: itemF.mascSexo,
                              recMascEdad: itemF.mascEdad,
                              recPerTelefonoPropietario: itemF.mascPerTelefono,
                              recPerDocNumeroPropietario:
                                itemF.mascPerDocNumero,
                              recPerDireccionPropietario:
                                itemF.mascPerDireccion,
                              recPerCelularPropietario: itemF.mascPerCelular,
                              recPerEmailPropietario: itemF.mascPerEmail,
                            });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            error={error.recMascNombre}
                            label="Paciente"
                            fullWidth
                            disabled={editar}
                            onChange={async (e) => {
                              if (e.target.value === "") {
                                return;
                              }
                              try {
                                const res = await clienteAxios.get(
                                  `/mascotas/filtro/0?search=${e.target.value}`
                                );
                                if (!res.data.data) {
                                  return mostrarAlerta(res.data.msg, "error");
                                }

                                setmascotasData(res.data.data);
                              } catch (error) {}
                            }}
                            // InputProps={{
                            //   ...params.InputProps,
                            //   endAdornment: (
                            //     <React.Fragment>
                            //       {!editar ? (
                            //         <IconButton
                            //           size="small"
                            //           aria-label=""
                            //           onClick={async () => {
                            //             seteditarProveedor(false);
                            //             setopenProveedor(true);
                            //           }}
                            //         >
                            //           <AddCircleOutlineTwoTone color="primary" />
                            //         </IconButton>
                            //       ) : null}
                            //       {params.InputProps.endAdornment}
                            //     </React.Fragment>
                            //   ),
                            // }}
                          />
                        )}
                      />
                    </FormControl>
                    <TextField
                      disabled
                      className={clases.formD}
                      label="Raza"
                      value={recMascRaza}
                      name="recMascRaza"
                      error={error.recMascRaza}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      disabled
                      className={clases.formD}
                      label="Sexo"
                      value={recMascSexo}
                      name="recMascSexo"
                      error={error.recMascSexo}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      disabled
                      className={clases.formD}
                      label="Edad"
                      value={recMascEdad}
                      name="recMascEdad"
                      error={error.recMascEdad}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      disabled
                      className={clases.formD}
                      label="Propietario"
                      value={recPerNombrePropietario}
                      name="recPerNombrePropietario"
                      error={error.recPerNombrePropietario}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      disabled
                      className={clases.formD}
                      label="Teléfono"
                      value={recPerTelefonoPropietario}
                      name="recPerTelefonoPropietario"
                      error={error.recPerTelefonoPropietario}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      disabled
                      className={clases.formD}
                      label="DocNumero"
                      value={recPerDocNumeroPropietario}
                      name="recPerDocNumeroPropietario"
                      error={error.recPerDocNumeroPropietario}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      disabled
                      className={clases.formD}
                      label="Dirección"
                      value={recPerDireccionPropietario}
                      name="recPerDireccionPropietario"
                      error={error.recPerDireccionPropietario}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      disabled
                      className={clases.formD}
                      label="Celular"
                      value={recPerCelularPropietario}
                      name="recPerCelularPropietario"
                      error={error.recPerCelularPropietario}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      disabled
                      className={clases.formD}
                      label="Email"
                      value={recPerEmailPropietario}
                      name="recPerEmailPropietario"
                      error={error.recPerEmailPropietario}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      type="number"
                      className={clases.formD}
                      label="Peso kg."
                      value={recPeso}
                      name="recPeso"
                      error={error.recPeso}
                      onChange={(e) => handleChange(e)}
                    />
                    {/* <TextField
                      className={clases.formD}
                      label="Doctor"
                      value={recPerNombreDoc}
                      name="recPerNombreDoc"
                      error={error.recPerNombreDoc}
                      onChange={(e) => handleChange(e)}
                    /> */}
                    <FormControl fullWidth className={clases.formD}>
                      <Autocomplete
                        filterOptions={(x) => x}
                        options={veterinariosData.map((item) => {
                          return item.perNombre + "/+/" + item.perId;
                        })}
                        getOptionLabel={(option) => {
                          return option.split("/+/")[0];
                        }}
                        id="controlled-demo"
                        value={recPerNombreDoc}
                        paperprops
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            const options = newValue.split("/+/");
                            const itemF = veterinariosData.find(
                              (itemPersona) => {
                                return itemPersona.perId === Number(options[1]);
                              }
                            );

                            setitem({
                              ...item,
                              recPerNombreDoc: itemF.perNombre,
                              recPerIdDoc: itemF.perId,
                            });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            error={error.recPerNombreDoc}
                            label="Doctor"
                            fullWidth
                            onChange={async (e) => {
                              if (e.target.value === "") {
                                return;
                              }
                              try {
                                const res = await clienteAxios.get(
                                  `/proveedores/filtro/0?search=${e.target.value}&estado=VETERINARIO`
                                );
                                if (!res.data.data) {
                                  return mostrarAlerta(res.data.msg, "error");
                                }
                                setveterinariosData(res.data.data);
                              } catch (error) {}
                            }}
                          />
                        )}
                      />
                    </FormControl>
                    <TextField
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      className={clases.formD}
                      label="Próxima Cita"
                      value={recProxCita}
                      name="recProxCita"
                      error={error.recProxCita}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      type="time"
                      InputLabelProps={{ shrink: true }}
                      className={clases.formD}
                      label="Próxima Cita: Hora"
                      value={recProxCitaHora}
                      name="recProxCitaHora"
                      error={error.recProxCitaHora}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip title="Establecer a '00:00'">
                              <IconButton
                                onClick={() => {
                                  setitem({
                                    ...item,
                                    recProxCitaHora: "00:00",
                                  });
                                }}
                              >
                                <Delete color="error" />
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                      onChange={(e) => {
                        const value = e.target.value;
                        const [hours, minutes] = value.split(":").map(Number);
                        if (
                          (hours === 0 && minutes === 0) || // Permitir "00:00"
                          (hours >= 9 &&
                            (hours < 18 || (hours === 18 && minutes === 0))) // Permitir de "09:00" a "18:00"
                        ) {
                          seterror({ ...error, recProxCitaHora: false });
                        } else {
                          seterror({ ...error, recProxCitaHora: true });
                        }
                        handleChange(e);
                      }}
                    />
                    {/* {JSON.stringify(dayjs(recProxCita))}
                    <Box className={clases.formD}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <FormControl sx={{ width: "100%" }}>
                          <FormLabel
                            sx={{ color: error.recProxCita ? "red" : "" }}
                          >
                            Próxima Cita
                          </FormLabel>
                          <DemoContainer components={["MobileDateTimePicker"]}>
                            <MobileDateTimePicker
                              value={dayjs(recProxCita)}
                              onChange={(newValue) =>
                                setitem({ ...item, recProxCita: newValue })
                              }
                            />
                          </DemoContainer>
                        </FormControl>
                      </LocalizationProvider>
                    </Box> */}
                    <TextField
                      multiline
                      rows={1}
                      InputLabelProps={{ shrink: true }}
                      className={clases.formD}
                      label="Observación Prox. Cita"
                      value={recObservacionProxCita}
                      name="recObservacionProxCita"
                      error={error.recObservacionProxCita}
                      onChange={(e) => handleChange(e)}
                    />
                    <Editor
                      label="Recomendación"
                      propiedad={"recRecomendacion"}
                      item={item}
                      setitem={setitem}
                    />
                  </>
                ) : null}
                {medicamentosSeccion ? (
                  <>
                    <Paper className={clases.root}>
                      {error.recMedicamentos ? (
                        <Typography variant="caption" color="error">
                          Ingrese al menos un medicamento
                        </Typography>
                      ) : null}
                      <ToolBarTable
                        titulo={"Items"}
                        soloTitulo={true}
                        botonAdd={true}
                        funcionAdd={() => {
                          setitem((prev) => {
                            const recMedicamentos = {
                              arrayMedicamentos: [
                                trimPropsItem({
                                  order: 1,
                                  cantidad: "1",
                                  medicina: "",
                                  serie: "",
                                  tratamiento: "",
                                  id: v4(),
                                  nuevo: true,
                                  error: false,
                                  editar: true,
                                }),
                                ...prev.recMedicamentos.arrayMedicamentos.map(
                                  (item) => {
                                    return { ...item, order: item.order + 1 };
                                  }
                                ),
                              ],
                            };
                            return {
                              ...prev,
                              recMedicamentos,
                            };
                          });
                        }}
                      />
                      <TableContainer className={clases.container}>
                        <Table
                          stickyHeader
                          aria-label="sticky table"
                          id="tabla"
                          size="small"
                        >
                          <caption
                            style={{
                              padding: "0px",
                              paddingRight: "1rem",
                              paddingLeft: "1rem",
                            }}
                          >
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <TablePagination
                                labelRowsPerPage="Filas por pagina"
                                rowsPerPageOptions={[
                                  10,
                                  20,
                                  50,
                                  100,
                                  500,
                                  1000,
                                ]}
                                component="div"
                                count={recMedicamentos.arrayMedicamentos.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                              />
                            </Box>
                          </caption>

                          <TableHead>
                            <TablaCabecera
                              columns={[
                                {
                                  id: "medicina",
                                  label: "Producto",
                                  minWidth: 200,
                                },
                                {
                                  id: "cantidad",
                                  label: "Cantidad",
                                  minWidth: 20,
                                },

                                {
                                  id: "tratamiento",
                                  label: "Indicaciones",
                                  minWidth: 400,
                                },
                              ]}
                              habilitarOrdenar={false}
                            />
                          </TableHead>

                          <TableBody component={"div"}>
                            {agregandoBodega ? (
                              <RowAgregarBono
                                setagregar={setagregandoBodega}
                                setitem={setitem}
                                item={item}
                                length={
                                  recMedicamentos.arrayMedicamentos.length
                                }
                              />
                            ) : null}
                            {recMedicamentos.arrayMedicamentos
                              .sort((a, b) => a.order - b.order)
                              .map((row, index) => {
                                return (
                                  <RowBono
                                    deshabilitarbotones={false}
                                    item={item}
                                    setitem={setitem}
                                    row={row}
                                    index={index}
                                    key={row.id}
                                    length={
                                      recMedicamentos.arrayMedicamentos.length
                                    }
                                    handleDragStart={handleDragStart}
                                    handleDragEnter={handleDragEnter}
                                    handleDragEnd={handleDragEnd}
                                  />
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                  </>
                ) : null}
              </Box>
              {/* <Divider /> */}
              <Box
                mt={2}
                display="flex"
                width="100%"
                justifyContent="space-between"
                alignItems="center"
                flexWrap={"wrap"}
              >
                <Box
                  className={clases.formD}
                  alignItems="center"
                  display={"flex"}
                  justifyContent="space-around"
                >
                  <Typography
                    className={clases.formD}
                    color="secondary"
                    fontSize={".9rem"}
                    fontWeight="bold"
                  >
                    Crea:
                    <Typography
                      className={clases.formD}
                      color="primary"
                      fontSize={".9rem"}
                      component="span"
                    >
                      {item.recUser.split(" ** ")[0]}
                    </Typography>
                  </Typography>
                  <Typography
                    className={clases.formD}
                    color="secondary"
                    fontSize={".9rem"}
                    fontWeight="bold"
                  >
                    Act:
                    <Typography
                      className={clases.formD}
                      color="primary"
                      fontSize={".9rem"}
                      component="span"
                    >
                      {item.recUser.split(" ** ")[1]}
                    </Typography>
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  mt={1}
                  className={clases.formD}
                >
                  <Button
                    className={clases.formD}
                    variant="contained"
                    color={item.restaurado ? "success" : "primary"}
                    disabled={cargando}
                    onClick={() => {
                      if (editar) {
                        if (!tienePermiso("editar")) {
                          return alertaPermiso("editar");
                        }
                        handleEditar();
                      } else {
                        if (!tienePermiso("agregar")) {
                          return alertaPermiso("agregar");
                        }
                        handleCrear();
                      }
                    }}
                  >
                    {item.restaurado ? "Restaurar" : "Guardar"}
                  </Button>
                </Box>
              </Box>
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default memo(ModalData);
