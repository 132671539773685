import { useContext } from "react";
import AlertaContext from "../Context/Alerta/AlertaContext";
import AuthContext from "../Context/Auth/AuthContext";
import MenuContext from "../Context/Menu/MenuContext";

export const usePermiso = (component) => {
  const {
    usuario,
    usuario: { rucempresa },
  } = useContext(AuthContext);
  const { rows } = useContext(MenuContext);
  const { mostrarAlerta } = useContext(AlertaContext);
  const alertaPermiso = (tipoPermiso) => {
    mostrarAlerta(`No tiene permiso para: ${tipoPermiso}`, "error");
  };

  const resPermiso = rows.find(
    (itemMenu) => itemMenu.sidInfo.component === component
  );
  const tienePermiso = (tipoPermiso, rol) => {
    if (rol) {
      const res = resPermiso.sidInfo[tipoPermiso].some((itemPermiso) => {
        return rol === itemPermiso;
      });
      return res;
    }
    if (!usuario.permisos_usuario[rucempresa]) {
      const res = resPermiso.sidInfo[tipoPermiso].some((itemPermiso) => {
        return usuario.rol.some(
          (itemRolUsuario) => itemRolUsuario === itemPermiso
        );
      });

      return res;
    }
    if (!usuario.permisos_usuario[rucempresa][component]) {
      const res = resPermiso.sidInfo[tipoPermiso].some((itemPermiso) => {
        return usuario.rol.some(
          (itemRolUsuario) => itemRolUsuario === itemPermiso
        );
      });

      return res;
    }
    if (usuario.permisos_usuario[rucempresa][component][tipoPermiso] === 0) {
      const res = resPermiso.sidInfo[tipoPermiso].some((itemPermiso) => {
        return usuario.rol.some(
          (itemRolUsuario) => itemRolUsuario === itemPermiso
        );
      });

      return res;
    }
    if (usuario.permisos_usuario[rucempresa][component][tipoPermiso] === 1) {
      return true;
    }
    if (usuario.permisos_usuario[rucempresa][component][tipoPermiso] === 2) {
      return false;
    }
  };

  return { tienePermiso, alertaPermiso };
};
