import React, { useState, useContext, useEffect, memo, createRef } from "react";
import { makeStyles } from "@mui/styles";
import {
  Modal,
  Box,
  Button,
  TextField,
  Divider,
  Typography,
  IconButton,
  MenuItem,
  InputAdornment,
  Avatar,
  Switch,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  FormControl,
  Autocomplete,
  Tooltip,
} from "@mui/material";
import Draggable from "react-draggable";
import {
  Cancel,
  Visibility,
  VisibilityOff,
  CloudUpload,
  Download,
  DeleteForever,
  Delete,
  AddCircle,
  Close,
} from "@mui/icons-material";
// eslint-disable-next-line
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import AuthContext from "../../../../Context/Auth/AuthContext";
import clienteAxios from "../../../../config/axios"; // eslint-disable-next-line
import { replaceCaracteres, trimPropsItem } from "../../../../config/const";

import MuiPhoneNumber from "material-ui-phone-number";
import { WhatsappConexion } from "./WhatsappConexion";
import { PlantillaTab } from "./PlantillaTab";
import ConfirmacionDialog from "../../../components/Dialogs/ConfirmacionDialog";
function getModalStyle() {
  return {
    borderRadius: "15px",
    position: "absolute",
    overflow: "scroll",
    maxHeight: "95vh",
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",

    width: "60%",
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "15px",
    marginTop: "5vh",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      marginTop: "2vh",
    },
  },
  margin: {
    margin: theme.spacing(1),
    width: "45%",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  ocultar: {
    display: "none",
  },
  inputPadding: {
    fontSize: "1rem",
  },
  formD: {
    flexGrow: 1,

    width: "45%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  formAllW: {
    flexGrow: 1,

    width: "95%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  large: {
    width: theme.spacing(13),
    height: theme.spacing(13),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    "&:hover": {
      opacity: ".7",
    },
  },

  center: {
    justifyContent: "center",
    alignItems: "center",
  },
}));

const ModalAsistencia = (props) => {
  // eslint-disable-next-line
  const { mostrarAlerta } = useContext(AlertaContext);
  const { usuario } = useContext(AuthContext);
  //props
  const {
    open,
    setopen,
    editar,
    seteditar,
    ObjectoActivo,
    changeObjetoActivoState,
    socket,
  } = props;
  const [empresa, setempresa] = useState(ObjectoActivo);
  const [verpass, setverpass] = useState(false);
  const [seccionActive, setseccionActive] = useState({
    datos: true,
    archivos: false,
    notificacion: false,
    roles: false,
    whatsapp: false,
    plantillasSeccion: false,
  });
  const [archivoHorizontal, setarchivoHorizontal] = useState("");
  const [imagenMostradaHorizontal, setimagenMostradaHorizontal] = useState(
    null
  );
  const onChangeFileHorizontal = (e) => {
    if (!e.target.files[0]) {
      setimagenMostradaHorizontal(null);
      return setarchivoHorizontal("");
    }

    setarchivoHorizontal(e.target.files[0]);
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = (e) => {
      e.preventDefault();
      setimagenMostradaHorizontal(e.target.result);
    };
  };
  const [firma, setfirma] = useState("");
  const [cargando, setcargando] = useState(false);
  const inputEl = createRef();

  const focusInput = () => {
    inputEl.current.click();
  };
  const [imagenMostrada, setimagenMostrada] = useState(null);
  const [archivo, setarchivo] = useState("");
  const onChangeFile = (e) => {
    if (!e.target.files[0]) {
      setimagenMostrada(null);
      return setarchivo("");
    }

    setarchivo(e.target.files[0]);
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = (e) => {
      e.preventDefault();
      setimagenMostrada(e.target.result);
    };
  };
  const onChangeFirma = (e) => {
    if (!e.target.files[0]) {
      return setfirma("");
    } else {
      setfirma(e.target.files[0]);
    }
  };
  const {
    datos,
    archivos,
    notificacion,
    roles,
    whatsapp,
    plantillasSeccion,
  } = seccionActive;
  const [tabPlantilla, settabsPlantilla] = useState("TRANSACCIONES");
  const [provinciasData, setprovinciasData] = useState(null);
  const [cantonesData, setcantonesData] = useState([]);
  const {
    // empTipografia,
    empRuc,
    empNombre,
    empRepresentante,
    empComercial,
    empObligado,
    empDireccion,
    empTelefono,
    empTipo,
    empIva,
    empIce,
    empEstado,
    empLogo,
    empFirma,
    empClavef,
    empCodigo,
    empFechaCaducaFirma,
    empRegimen,
    empCategoria,
    empLeyenda,
    empProvincia,
    empCanton,
    empAgenteRetencion,
    empContribuyenteEspecial,
    empColorPrimario,
    empColorSecundario,
    empLogoRectangular,
    empRoles,
    empEstableciActivos,
    // empPlantillaVenta,
    // empIdentificadorNumCelular,
  } = empresa;
  const [error, seterror] = useState({
    empRuc: false,
    empNombre: false,
    empRepresentante: false,
    empComercial: false,
    empObligado: false,
    empDireccion: false,
    empTelefono: false,
    empTipo: false,
    empIva: false,
    empIce: false,
    empEstado: false,
    empLogo: false,
    empFirma: false,
    empClavef: false,
    empCodigo: false,
  });
  useEffect(() => {
    const getprovinciasData = async () => {
      try {
        const resprovinciasData = await clienteAxios.get(
          `/provincias/filtro/1`
        );

        setprovinciasData(resprovinciasData.data.data);
      } catch (error) {
        setprovinciasData([]);
      }
    };

    if (!provinciasData) {
      getprovinciasData();
    }

    setempresa(ObjectoActivo);
    // eslint-disable-next-line
  }, [ObjectoActivo]);

  const defaultActive = () => {
    changeObjetoActivoState({
      // empId: 3,
      empRuc: "",
      empNombre: "",
      empRepresentante: "",
      empComercial: "",
      empObligado: "",
      empDireccion: "",
      empTelefono: "",
      empTipo: "1",
      empIva: "12",
      empIce: "0",
      empCategoria: "CONTABLE",
      empEstado: "",
      empLogo: "",
      empFirma: "",
      empClavef: "",
      empCodigo: "",
      empRegimen: "",
      empFechaCaducaFirma: "",
      correoEnv: "",
      correoResp: "",
      empTipografia: "",
      empCopiaEmail: "",
      empPaginaWeb: "",
      empFacebook: "",
      empInstagram: "",
      empColor: "",
      empUser: "",
      empFecReg: "",
      empLeyenda: "",
      empProvincia: "SANTO DOMINGO DE LOS TSÁCHILAS",
      empCanton: "SANTO DOMINGO",
      empAgenteRetencion: "NO",
      empContribuyenteEspecial: "",
      empColorPrimario: "#2196f3",
      empColorSecundario: "#263238",
      empLogoRectangular: "",
      empRoles: [],
      empEstableciActivos: "0",
      empIdentificadorNumCelular: "0",
      empPlantillaVenta: { plantilla: "", variables: [] },
      empPlantillaMascota: { plantilla: "", variables: [] },
      empPlantillaHistoriaClinica: { plantilla: "", variables: [] },
      empPlantillaVacuna: { plantilla: "", variables: [] },
      empPlantillaReceta: { plantilla: "", variables: [] },
      empPlantillaHospitalizacion: { plantilla: "", variables: [] },
      empPlantillaPeluqueria: { plantilla: "", variables: [] },
      empPlantillaReserva: { plantilla: "", variables: [] },
      empPlantillaExamen: { plantilla: "", variables: [] },
    });
    setopen(false);
    seteditar(false);
    seterror({
      empRuc: false,
      empNombre: false,
      empRepresentante: false,
      empComercial: false,
      empObligado: false,
      empDireccion: false,
      empTelefono: false,
      empTipo: false,

      empIva: false,
      empIce: false,
      empEstado: false,
      empLogo: false,
      empFirma: false,
      empClavef: false,
      empCodigo: false,
      empFechaCaducaFirma: false,
      empRegimen: false,
    });
    setseccionActive({
      datos: true,
      archivos: false,
    });
    setarchivo("");
    setimagenMostrada(null);
    setarchivoHorizontal("");
    setimagenMostradaHorizontal(null);
    setfirma("");
    setcargando(false);
  };

  const handleChange = (e) => {
    const res = replaceCaracteres(e.target.value);
    setempresa({
      ...empresa,
      [e.target.name]: res,
    });
  };

  //hook de estilos
  const clases = useStyles();
  //definiendo los estilos del modal

  const [modalStyle] = useState(getModalStyle);
  // obteniendo los años

  const validacion = () => {
    if (
      empRuc.trim() === "" ||
      empNombre.trim() === "" ||
      empRepresentante.trim() === "" ||
      empComercial.trim() === "" ||
      empObligado.trim() === "" ||
      empDireccion.trim() === "" ||
      empTelefono.trim() === "" ||
      empTipo === "" ||
      empIva === "" ||
      empIce === "" ||
      empEstado.trim() === "" ||
      empClavef.trim() === "" ||
      empCodigo.trim() === "" ||
      empFechaCaducaFirma.trim() === "" ||
      empContribuyenteEspecial.trim() === ""
    ) {
      seterror({
        empRuc: empRuc.trim() === "" ? true : false,
        empNombre: empNombre.trim() === "" ? true : false,
        empRepresentante: empRepresentante.trim() === "" ? true : false,
        empComercial: empComercial.trim() === "" ? true : false,
        empObligado: empObligado.trim() === "" ? true : false,
        empDireccion: empDireccion.trim() === "" ? true : false,
        empTelefono: empTelefono.trim() === "" ? true : false,
        empTipo: empTipo === "" ? true : false,
        empContribuyenteEspecial:
          empContribuyenteEspecial.trim() === "" ? true : false,
        empIva: empIva === "" ? true : false,
        empIce: empIce === "" ? true : false,
        empEstado: empEstado.trim() === "" ? true : false,
        empClavef: empClavef.trim() === "" ? true : false,
        empCodigo: empCodigo.trim() === "" ? true : false,
        empFechaCaducaFirma: empFechaCaducaFirma.trim() === "" ? true : false,
      });
      return true;
    } else {
      return false;
    }
  };

  const handleCrear = async () => {
    if (validacion()) {
      return mostrarAlerta("Faltan datos obligatorios", "error");
    }

    try {
      const resLogo = ["gif", "jpeg", "jpg", "png"].some(
        (item) => item === archivo.type.split("/")[1]
      );
      if (!resLogo) {
        return mostrarAlerta(
          "Comprueba la extensión de tus imagenes, recuerda que los formatos aceptados son .gif, .jpeg, .jpg y .png",
          "error"
        );
      }

      const resFirma = ["x-pkcs12"].some(
        (item) => item === firma.type.split("/")[1]
      );
      if (!resFirma) {
        return mostrarAlerta(
          "Comprueba la extensión de tus firma, recuerda que los formatos aceptados son p12",
          "error"
        );
      }

      setcargando(true);
      // Logo

      let resLogos = "";
      if (archivo !== "") {
        const formDataLogos = new FormData();
        formDataLogos.append("archivo", archivo);
        formDataLogos.append("tipo", "logos");
        formDataLogos.append("rucempresa", usuario.rucempresa);
        resLogos = await clienteAxios.post(
          "/upload_delete_multiple_files/upload",
          formDataLogos
        );
      }
      let resFirmas = "";
      if (firma !== "") {
        const formDataFirmas = new FormData();
        formDataFirmas.append("archivo", firma);
        formDataFirmas.append("tipo", "firmas");
        formDataFirmas.append("rucempresa", usuario.rucempresa);
        resFirmas = await clienteAxios.post(
          "/upload_delete_multiple_files/upload",
          formDataFirmas
        );
      }
      let resLogoH = "";
      if (archivoHorizontal !== "") {
        const formDataLogoH = new FormData();
        formDataLogoH.append("archivo", archivoHorizontal);
        formDataLogoH.append("tipo", "logos");
        formDataLogoH.append("rucempresa", usuario.rucempresa);
        resLogoH = await clienteAxios.post(
          "/upload_delete_multiple_files/upload",
          formDataLogoH
        );
      }
      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...empresa,
          tabla: "empresa",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          empUser: usuario.usuario,
          empEmpresa: usuario.rucempresa,
          empLogo: archivo === "" ? empresa.empLogo : resLogos.data.nombre,
          empLogoRectangular:
            archivoHorizontal === ""
              ? empresa.empLogoRectangular
              : resLogoH.data.nombre,
          empFirma: firma === "" ? empresa.empFirma : resFirmas.data.nombre,
        })
      );
      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);
      mostrarAlerta("Selecciona una foto o una firma", "error");
    }
  };
  const handleEditar = async () => {
    if (validacion()) {
      return mostrarAlerta("Faltan datos obligatorios", "error");
    }
    if (
      empRuc.trim() === "" ||
      empNombre.trim() === "" ||
      empRepresentante.trim() === "" ||
      empComercial.trim() === "" ||
      empObligado.trim() === "" ||
      empDireccion.trim() === "" ||
      empTelefono.trim() === "" ||
      empTipo === "" ||
      empIva === "" ||
      empIce === "" ||
      empEstado.trim() === "" ||
      empClavef.trim() === "" ||
      empCodigo.trim() === "" ||
      empFechaCaducaFirma.trim() === ""
    ) {
      return seterror({
        empRuc: empRuc.trim() === "" ? true : false,
        empNombre: empNombre.trim() === "" ? true : false,
        empRepresentante: empRepresentante.trim() === "" ? true : false,
        empComercial: empComercial.trim() === "" ? true : false,
        empObligado: empObligado.trim() === "" ? true : false,
        empDireccion: empDireccion.trim() === "" ? true : false,
        empTelefono: empTelefono.trim() === "" ? true : false,
        empTipo: empTipo === "" ? true : false,

        empIva: empIva === "" ? true : false,
        empIce: empIce === "" ? true : false,
        empEstado: empEstado.trim() === "" ? true : false,
        empClavef: empClavef.trim() === "" ? true : false,
        empCodigo: empCodigo.trim() === "" ? true : false,
        empFechaCaducaFirma: empFechaCaducaFirma.trim() === "" ? true : false,
      });
    }
    try {
      let resLogo = true;
      if (archivo.type) {
        resLogo = ["gif", "jpeg", "jpg", "png"].some(
          (item) => item === archivo.type.split("/")[1]
        );
      }
      if (!resLogo) {
        return mostrarAlerta(
          "Comprueba la extensión de tus imagenes, recuerda que los formatos aceptados son .gif, .jpeg, .jpg y .png",
          "error"
        );
      }
      let resFirma = true;
      if (firma.type) {
        resFirma = ["x-pkcs12"].some(
          (item) => item === firma.type.split("/")[1]
        );
      }
      if (!resFirma) {
        return mostrarAlerta(
          "Comprueba la extensión de tus firma, recuerda que los formatos aceptados son p12",
          "error"
        );
      }

      setcargando(true);
      // Logo

      let resLogos = "";
      if (archivo !== "") {
        const formDataLogos = new FormData();
        formDataLogos.append("archivo", archivo);
        formDataLogos.append("tipo", "logos");
        formDataLogos.append("rucempresa", usuario.rucempresa);

        await clienteAxios.post("/upload_delete_multiple_files/delete", {
          urls: [empresa.empLogo],
        });
        resLogos = await clienteAxios.post(
          "/upload_delete_multiple_files/upload",
          formDataLogos
        );
      }
      let resFirmas = "";
      if (firma !== "") {
        const formDataFirmas = new FormData();
        formDataFirmas.append("archivo", firma);
        formDataFirmas.append("tipo", "firmas");
        formDataFirmas.append("rucempresa", usuario.rucempresa);

        await clienteAxios.post("/upload_delete_multiple_files/delete", {
          urls: [empresa.empFirma],
        });
        resFirmas = await clienteAxios.post(
          "/subir_firma_electronica/upload",
          formDataFirmas
        );
      }
      let resLogoH = "";
      if (archivoHorizontal !== "") {
        const formDataLogoH = new FormData();
        formDataLogoH.append("archivo", archivoHorizontal);
        formDataLogoH.append("tipo", "logos");
        formDataLogoH.append("rucempresa", usuario.rucempresa);
        resLogoH = await clienteAxios.post(
          "/upload_delete_multiple_files/upload",
          formDataLogoH
        );
      }
      console.log(
        trimPropsItem({
          ...empresa,
          tabla: "empresa",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          empUser: usuario.usuario,
          empEmpresa: usuario.rucempresa,
          empLogoRectangular:
            archivoHorizontal === ""
              ? empresa.empLogoRectangular
              : resLogoH.data.nombre,
          empLogo: archivo === "" ? empresa.empLogo : resLogos.data.nombre,
          empFirma: firma === "" ? empresa.empFirma : resFirmas.data.url,
        })
      );

      socket.current.emit(
        "client:actualizarData",
        trimPropsItem({
          ...empresa,
          tabla: "empresa",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          empUser: usuario.usuario,
          empEmpresa: usuario.rucempresa,
          empLogoRectangular:
            archivoHorizontal === ""
              ? empresa.empLogoRectangular
              : resLogoH.data.nombre,
          empLogo: archivo === "" ? empresa.empLogo : resLogos.data.nombre,
          empFirma: firma === "" ? empresa.empFirma : resFirmas.data.url,
        })
      );
      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);
      mostrarAlerta("Hubo un error", "error");
    }
  };
  useEffect(() => {
    if (seccionActive === "cargando") {
      setTimeout(() => {
        setseccionActive({ whatsapp: true });
      }, 2000);
    }
  }, [seccionActive]);
  const [openDialog1, setopenDialog1] = useState(false);

  if (!open) {
    return null;
  }
  if (!provinciasData) {
    return null;
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Draggable handle="#handle">
          <Box display="flex" justifyContent="center">
            <div style={modalStyle} className={clases.paper}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                style={{ cursor: "-webkit-grab" }}
              >
                <Typography
                  id="handle"
                  variant="button"
                  color="initial"
                  align="center"
                >
                  {editar ? "Editar" : "Agregar"} empresa.
                </Typography>
                <Box display="flex" alignItems="center">
                  <IconButton
                    aria-label=""
                    style={{ margin: "0px" }}
                    onClick={() => {
                      defaultActive();
                    }}
                  >
                    <Cancel color="secondary" />
                  </IconButton>
                </Box>
              </Box>
              <Divider />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems=" stretch"
                width="100%"
              >
                <Box>
                  <Button
                    variant={datos ? "contained" : null}
                    disableElevation
                    style={{ padding: "8px" }}
                    color="secondary"
                    onClick={() => setseccionActive({ datos: true })}
                  >
                    Datos principales
                  </Button>
                  <Button
                    variant={notificacion ? "contained" : null}
                    disableElevation
                    style={{ padding: "8px" }}
                    color="secondary"
                    onClick={() => setseccionActive({ notificacion: true })}
                  >
                    Notificación
                  </Button>
                  <Button
                    variant={archivos ? "contained" : null}
                    disableElevation
                    style={{ padding: "8px" }}
                    color="secondary"
                    onClick={() => setseccionActive({ archivos: true })}
                  >
                    Archivos
                  </Button>
                  <Button
                    variant={roles ? "contained" : null}
                    disableElevation
                    style={{ padding: "8px" }}
                    color="secondary"
                    onClick={() => setseccionActive({ roles: true })}
                  >
                    Roles
                  </Button>

                  <Button
                    variant={whatsapp ? "contained" : "outlined"}
                    disableElevation
                    style={{ padding: "8px" }}
                    color={"success"}
                    onClick={() => setseccionActive({ whatsapp: true })}
                  >
                    whatsapp
                  </Button>

                  <Button
                    variant={plantillasSeccion ? "contained" : null}
                    disableElevation
                    style={{ padding: "8px" }}
                    color="success"
                    onClick={() =>
                      setseccionActive({ plantillasSeccion: true })
                    }
                  >
                    Plantillas
                  </Button>
                </Box>

                {editar ? (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={empEstado === "ACTIVO" ? true : false}
                        onChange={(e) => {
                          setempresa({
                            ...empresa,
                            empEstado: e.target.checked ? "ACTIVO" : "INACTIVO",
                          });
                        }}
                        name="antoine"
                        color="primary"
                      />
                    }
                    label="Estado"
                  />
                ) : null}
              </Box>
              <Divider />
              <Box
                display="flex"
                flexWrap="wrap"
                alignItems="flex-end"
                borderRadius="0px 50px 50px 0px"
                p={1}
              >
                {datos ? (
                  <>
                    <TextField
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      id="empRuc"
                      disabled={editar}
                      label="Ruc"
                      value={empRuc}
                      name="empRuc"
                      error={error.empRuc}
                      onChange={(e) => {
                        setempresa({
                          ...empresa,
                          [e.target.name]: e.target.value,
                        });
                      }}
                      type="number"
                    />
                    <TextField
                      disabled={editar}
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      id="empCodigo"
                      label="Código Empresa"
                      value={empCodigo}
                      name="empCodigo"
                      error={error.empCodigo}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      id="empNombre"
                      label="Nombre"
                      value={empNombre}
                      name="empNombre"
                      error={error.empNombre}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      id="empRepresentante"
                      label="Representante"
                      value={empRepresentante}
                      name="empRepresentante"
                      error={error.empRepresentante}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      id="empComercial"
                      label="Nombre Comercial"
                      value={empComercial}
                      name="empComercial"
                      error={error.empComercial}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      id="empObligado"
                      label="Obligado"
                      value={empObligado}
                      name="empObligado"
                      error={error.empObligado}
                      onChange={(e) => handleChange(e)}
                      select
                      SelectProps={{
                        displayEmpty: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                    >
                      <MenuItem value="">Seleccione </MenuItem>;
                      {["SI", "NO"].map((item) => {
                        return <MenuItem value={item}>{item}</MenuItem>;
                      })}
                    </TextField>
                    <TextField
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      id="empDireccion"
                      label="Dirección"
                      value={empDireccion}
                      name="empDireccion"
                      error={error.empDireccion}
                      onChange={(e) => handleChange(e)}
                    />
                    <MuiPhoneNumber
                      inputClass={clases.formD}
                      defaultCountry="ec"
                      regions={"america"}
                      label="Teléfono"
                      value={empTelefono}
                      name="empTelefono"
                      error={error.empTelefono}
                      onChange={(e) => {
                        setempresa({
                          ...empresa,
                          empTelefono: e,
                        });
                      }}
                    />
                    <TextField
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      id="empTipo"
                      label="Tipo"
                      value={empTipo}
                      name="empTipo"
                      type="number"
                      error={error.empTipo}
                      onChange={(e) => {
                        setempresa({
                          ...empresa,
                          [e.target.name]: e.target.valueAsNumber,
                        });
                      }}
                    />
                    <TextField
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      id="empIva"
                      label="Iva"
                      value={empIva}
                      name="empIva"
                      type="number"
                      error={error.empIva}
                      onChange={(e) => {
                        setempresa({
                          ...empresa,
                          [e.target.name]: e.target.valueAsNumber,
                        });
                      }}
                    />
                    <TextField
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      id="empIce"
                      label="Ice"
                      value={empIce}
                      name="empIce"
                      type="number"
                      error={error.empIce}
                      onChange={(e) => {
                        setempresa({
                          ...empresa,
                          [e.target.name]: e.target.valueAsNumber,
                        });
                      }}
                    />
                    <TextField
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      id="empFechaCaducaFirma"
                      name="empFechaCaducaFirma"
                      InputLabelProps={{ shrink: true }}
                      value={empFechaCaducaFirma.substr(0, 10)}
                      label="F.Caducidad Firma"
                      type="date"
                      error={error.empFechaCaducaFirma}
                      onChange={(e) => {
                        setempresa({
                          ...empresa,
                          [e.target.name]: e.target.value,
                        });
                      }}
                    />
                    <TextField
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      id="empRegimen"
                      name="empRegimen"
                      value={empRegimen}
                      label="Régimen"
                      error={error.empRegimen}
                      onChange={(e) => {
                        setempresa({
                          ...empresa,
                          [e.target.name]: e.target.value,
                        });
                      }}
                    />
                    <TextField
                      disabled={editar}
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      id="empCategoria"
                      label="Categoria"
                      value={empCategoria}
                      name="empCategoria"
                      error={error.empCategoria}
                      onChange={(e) => handleChange(e)}
                      select
                      SelectProps={{
                        displayEmpty: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                    >
                      <MenuItem value="">Seleccione </MenuItem>;
                      {["CONTABLE", "ISP", "GASOLINERA", "VETERINARIA"].map(
                        (item) => {
                          return <MenuItem value={item}>{item}</MenuItem>;
                        }
                      )}
                    </TextField>
                    <TextField
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      id="empLeyenda"
                      name="empLeyenda"
                      value={empLeyenda}
                      label="Leyenda"
                      error={error.empLeyenda}
                      onChange={(e) => {
                        setempresa({
                          ...empresa,
                          [e.target.name]: e.target.value,
                        });
                      }}
                    />
                    <TextField
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      label="Contribuyente Especial"
                      name="empContribuyenteEspecial"
                      value={empContribuyenteEspecial}
                      error={error.empContribuyenteEspecial}
                      onChange={(e) => {
                        setempresa({
                          ...empresa,
                          [e.target.name]: e.target.value,
                        });
                      }}
                      select
                      SelectProps={{
                        displayEmpty: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                    >
                      <MenuItem value="">Seleccione </MenuItem>;
                      {["PERSONA NATURAL", "PERSONA JURIDICA", "SOCIEDAD"].map(
                        (item, index) => {
                          return (
                            <MenuItem key={index} value={item}>
                              {item}
                            </MenuItem>
                          );
                        }
                      )}
                    </TextField>
                    <TextField
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      label="Agente Retención"
                      name="empAgenteRetencion"
                      value={empAgenteRetencion}
                      error={error.empAgenteRetencion}
                      onChange={(e) => handleChange(e)}
                      select
                      SelectProps={{
                        displayEmpty: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                    >
                      <MenuItem value="">Seleccione </MenuItem>;
                      {["SI", "NO"].map((item) => {
                        return <MenuItem value={item}>{item}</MenuItem>;
                      })}
                    </TextField>
                    <FormControl fullWidth className={clases.formD}>
                      <Autocomplete
                        autoComplete="false"
                        options={provinciasData.map(
                          (item) => item.provNombre + "/+/" + item.provId
                        )}
                        getOptionLabel={(option) => {
                          return option.split("/+/")[0];
                        }}
                        id="controlled-demo"
                        value={empProvincia}
                        paperprops
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            try {
                              const options = newValue.split("/+/");
                              const cantonesRes = await clienteAxios.get(
                                `/cantones/filtro/${options[1]}`
                              );

                              setcantonesData(cantonesRes.data.data);
                              setempresa({
                                ...empresa,
                                empProvincia: options[0],
                                empCanton: "",
                              });
                            } catch (error) {
                              mostrarAlerta("Hubo un error", "error");
                            }
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            required
                            autoComplete={false}
                            label="Provincia"
                            error={error.empProvincia}
                            fullWidth
                            onChange={async (e) => {}}
                            InputProps={{
                              ...params.InputProps,
                            }}
                          />
                        )}
                      />
                    </FormControl>
                    <FormControl fullWidth className={clases.formD}>
                      <Autocomplete
                        options={cantonesData.map((item) => item.canNombre)}
                        getOptionLabel={(option) => {
                          return option;
                        }}
                        id="controlled-demo"
                        value={empCanton}
                        paperprops
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            try {
                              setempresa({
                                ...empresa,
                                empCanton: newValue,
                              });
                            } catch (error) {}
                          } else {
                            // setrucString("");
                            // setempresa({
                            //   ...item,
                            //   rucProveedor: "",
                            //   nomProveedor: "",
                            // });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            required
                            label="Cantón"
                            error={error.empCanton}
                            fullWidth
                            onChange={async (e) => {}}
                            InputProps={{
                              ...params.InputProps,
                            }}
                          />
                        )}
                      />
                    </FormControl>
                    <TextField
                      type="color"
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formAllW}
                      label="Color Primario"
                      value={empColorSecundario}
                      name="empColorSecundario"
                      error={error.empColorSecundario}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                    <TextField
                      type="color"
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formAllW}
                      label="Color Secundario"
                      value={empColorPrimario}
                      name="empColorPrimario"
                      error={error.empColorPrimario}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  </>
                ) : null}
                {roles ? (
                  <>
                    <FormControl className={clases.formAllW}>
                      <Box display="flex" alignItems="center">
                        <Typography
                          variant="subtitle1"
                          color={error.empRoles ? "error" : "primary"}
                        >
                          Roles:
                        </Typography>
                        <IconButton
                          aria-label=""
                          size="small"
                          onClick={async () => {
                            setempresa({
                              ...empresa,
                              empRoles: [...empresa.empRoles, ""],
                            });
                          }}
                        >
                          <AddCircle fontSize="small" color="secondary" />
                        </IconButton>
                      </Box>
                    </FormControl>
                    {empresa.empRoles ? (
                      <>
                        {empRoles.map((precio, index) => {
                          return (
                            <TextField
                              key={index}
                              className={clases.formD}
                              disabled={index < 3}
                              value={precio}
                              onChange={(e) => {
                                setempresa({
                                  ...empresa,
                                  empRoles: empRoles.map((item, indexItem) => {
                                    if (indexItem === index) {
                                      return e.target.value.toUpperCase();
                                    } else {
                                      return item;
                                    }
                                  }),
                                });
                              }}
                              label={`ROL #${index + 1}`}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment>
                                    <IconButton
                                      aria-label=""
                                      disabled={index < 3}
                                      onClick={async () => {
                                        const res = await clienteAxios.delete(
                                          `/empresas/rol/${precio}`
                                        );
                                        if (res.data.eliminar) {
                                          return setempresa({
                                            ...empresa,
                                            empRoles: empRoles.filter(
                                              (item, indexItem) => {
                                                return indexItem !== index;
                                              }
                                            ),
                                          });
                                        }
                                        mostrarAlerta(
                                          "Ya hay personas registradas con este rol.",
                                          "error"
                                        );
                                      }}
                                    >
                                      <Delete
                                        color={index < 3 ? "inherit" : "error"}
                                      />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          );
                        })}
                      </>
                    ) : null}
                  </>
                ) : null}
                {notificacion ? (
                  <>
                    <TextField
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      id="correoEnv"
                      label="Email de Envio"
                      value={empresa.correoEnv}
                      name="correoEnv"
                      error={error.correoEnv}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      id="correoResp"
                      label="Correo de Respuesta"
                      value={empresa.correoResp}
                      name="correoResp"
                      error={error.correoResp}
                      onChange={(e) => handleChange(e)}
                    />
                    <FormControl
                      component="fieldset"
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <FormLabel
                          component="span"
                          color="primary"
                          style={{ marginRight: ".5rem", fontWeight: "bold" }}
                        >
                          Recibir copia de email
                        </FormLabel>
                        <RadioGroup
                          onChange={(e) => handleChange(e)}
                          row
                          aria-label="gender"
                          value={empresa.empCopiaEmail}
                          name="empCopiaEmail"
                        >
                          <FormControlLabel
                            value="SI"
                            control={<Radio />}
                            label="SI"
                          />
                          <FormControlLabel
                            value="NO"
                            control={<Radio />}
                            label="NO"
                          />
                        </RadioGroup>
                      </Box>
                    </FormControl>
                    <TextField
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      id="empPaginaWeb"
                      label="Página"
                      value={empresa.empPaginaWeb}
                      name="empPaginaWeb"
                      error={error.empPaginaWeb}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      id="empFacebook"
                      label="Facebook"
                      value={empresa.empFacebook}
                      name="empFacebook"
                      error={error.empFacebook}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      id="empInstagram"
                      label="Instagram"
                      value={empresa.empInstagram}
                      name="empInstagram"
                      error={error.empInstagram}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      type="number"
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      label="Establecimientos Activos (ATS)"
                      value={empEstableciActivos}
                      name="empEstableciActivos"
                      error={error.empEstableciActivos}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      type="color"
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      label="Color de reporteria"
                      value={empresa.empColor}
                      name="empColor"
                      error={error.empColor}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  </>
                ) : null}

                {archivos ? (
                  <>
                    <Box
                      display="flex"
                      justifyContent="center"
                      width="100%"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <input
                        id="contained-button-file"
                        className={clases.ocultar}
                        type="file"
                        name="mi imagen"
                        onChange={onChangeFile}
                        accept=".jpg, .jpeg, .png"
                      />
                      {/* //si un archivo exoste  */}
                      {archivo ? (
                        <Box
                          display="flex"
                          flexDirection="column"
                          className={clases.center}
                        >
                          <label htmlFor="contained-button-file">
                            <Avatar
                              htmlFor="contained-button-file"
                              alt="Remy Sharp"
                              src={imagenMostrada}
                              className={clases.large}
                            />
                          </label>
                          <Typography
                            variant="body1"
                            color="initial"
                            align="center"
                          >
                            {archivo.name}
                          </Typography>
                        </Box>
                      ) : (
                        <Box
                          display="flex"
                          flexDirection="column"
                          className={clases.center}
                        >
                          <label htmlFor="contained-button-file">
                            <Avatar
                              htmlFor="contained-button-file"
                              alt="Logo"
                              src={empLogo}
                              className={clases.large}
                            />
                          </label>
                          <Typography
                            variant="body1"
                            color="initial"
                            align="center"
                          >
                            SELECCIONE LOGO DE FACTURACION
                          </Typography>
                        </Box>
                      )}
                    </Box>{" "}
                    <ConfirmacionDialog
                      open={openDialog1}
                      setopen={() => setopenDialog1(false)}
                      titulo={
                        "Si elimina el archivo y no guarda los cambios es posible que deje de existir en los documentos."
                      }
                      categoria="warning"
                      botones={[
                        {
                          tooltip: "Eliminar Archivo",
                          texto: "Eliminar",
                          funcion: async () => {
                            try {
                              setcargando(false);
                              await clienteAxios.post(
                                "/upload_delete_multiple_files/delete",
                                { urls: [empFirma] }
                              );

                              setempresa({
                                ...empresa,
                                empFirma: "",
                              });
                              setfirma("");
                              setcargando(false);
                              setopenDialog1(false);
                            } catch (error) {
                              setopenDialog1(false);
                              mostrarAlerta(
                                "Hubo un error al eliminar el archivo"
                              );
                            }
                          },
                          disabled: cargando,
                          Icon: DeleteForever,
                          color: "secondary",
                          id: 1,
                          ocultar: false,
                          tipo: "boton",
                          variante: "contained",
                          size: "medium",
                          sx: { marginRight: ".3rem" },
                        },
                        {
                          tooltip: "Cancelar",
                          texto: "Cancelar",
                          funcion: () => {
                            setopenDialog1(false);
                          },
                          disabled: cargando,
                          Icon: Close,
                          color: "error",
                          id: 2,
                          ocultar: false,
                          tipo: "boton",
                          variante: "contained",
                          size: "medium",
                          sx: {},
                        },
                      ]}
                    />
                    <input
                      className={clases.ocultar}
                      type="file"
                      name="myImage"
                      onChange={onChangeFirma}
                      ref={inputEl}
                    />
                    <label
                      htmlFor="contained-button-firma"
                      className={clases.formD}
                    >
                      <Box htmlFor="contained-button-firma" fullWidth>
                        <TextField
                          error={error.empFirma}
                          inputProps={{
                            className: clases.inputPadding,
                          }}
                          InputLabelProps={{
                            shrink:
                              firma !== "" || empFirma !== "" ? true : false,
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment>
                                <IconButton aria-label="" onClick={focusInput}>
                                  <CloudUpload color="secondary" />
                                </IconButton>
                                {empFirma === "" ? null : (
                                  <Tooltip
                                    title="Eliminar Archivo: Documento"
                                    arrow
                                  >
                                    <Box>
                                      <IconButton
                                        color="primary"
                                        onClick={async () => {
                                          try {
                                            setopenDialog1(true);
                                          } catch (error) {}
                                        }}
                                        aria-label=""
                                      >
                                        <DeleteForever color="error" />
                                      </IconButton>
                                    </Box>
                                  </Tooltip>
                                )}
                                {empFirma === "" ? null : (
                                  <Tooltip
                                    title="Descargar Archivo: Documento"
                                    arrow
                                  >
                                    <Box>
                                      <IconButton
                                        color="primary"
                                        onClick={async () => {
                                          window.open(empFirma);
                                        }}
                                        aria-label=""
                                      >
                                        <Download />
                                      </IconButton>
                                    </Box>
                                  </Tooltip>
                                )}
                              </InputAdornment>
                            ),
                          }}
                          fullWidth
                          id="empFirma"
                          label={`Firma`}
                          value={
                            empFirma && !firma.name
                              ? empFirma.split("/")[
                                  empFirma.split("/").length - 1
                                ]
                              : firma.name
                          }
                          name="empFirma"
                        />
                      </Box>
                    </label>
                    <TextField
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      id="empClavef"
                      label="Clave"
                      value={empClavef}
                      name="empClavef"
                      error={error.empClavef}
                      onChange={(e) => handleChange(e)}
                      type={verpass ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment>
                            {verpass ? (
                              <IconButton
                                aria-label=""
                                onClick={() => setverpass(!verpass)}
                              >
                                <Visibility color="secondary" />
                              </IconButton>
                            ) : (
                              <IconButton
                                aria-label=""
                                onClick={() => setverpass(!verpass)}
                              >
                                <VisibilityOff color="secondary" />
                              </IconButton>
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                    {usuario.empCategoria === "VETERINARIA" ? (
                      <Box
                        display="flex"
                        justifyContent="center"
                        width="100%"
                        flexDirection="column"
                        alignItems="center"
                      >
                        <input
                          id="imagen-rectangular"
                          className={clases.ocultar}
                          type="file"
                          name="mi imagen"
                          onChange={onChangeFileHorizontal}
                          accept=".jpg, .jpeg, .png"
                        />
                        {/* //si un archivo exoste  */}
                        {archivoHorizontal ? (
                          <Box
                            display="flex"
                            flexDirection="column"
                            className={clases.center}
                          >
                            <label htmlFor="imagen-rectangular">
                              <img
                                htmlFor="imagen-rectangular"
                                alt="Imagen Rectangular"
                                src={imagenMostradaHorizontal}
                                className={clases.large}
                                style={{ width: "12rem" }}
                              />
                            </label>
                            <Typography
                              variant="body1"
                              color="initial"
                              align="center"
                            >
                              {archivoHorizontal.name}
                            </Typography>
                          </Box>
                        ) : (
                          <Box
                            display="flex"
                            flexDirection="column"
                            className={clases.center}
                          >
                            <label htmlFor="imagen-rectangular">
                              <Avatar
                                htmlFor="imagen-rectangular"
                                alt="Logo"
                                src={empLogoRectangular}
                                className={clases.large}
                              />
                            </label>
                            <Typography
                              variant="body1"
                              color="initial"
                              align="center"
                            >
                              SELECCIONE LOGO DE REPORTE
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    ) : null}
                  </>
                ) : null}
                {plantillasSeccion && (
                  <>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems=" stretch"
                      width="100%"
                    >
                      <Box>
                        {/* <Button
                          variant={
                            tabPlantilla === "Notificaciones"
                              ? "contained"
                              : null
                          }
                          disableElevation
                          style={{ padding: "8px" }}
                          color="primary"
                          onClick={() => settabsPlantilla("Notificaciones")}
                        >
                          Notificaciones
                        </Button> */}
                        <Button
                          variant={
                            tabPlantilla === "TRANSACCIONES"
                              ? "contained"
                              : null
                          }
                          disableElevation
                          style={{ padding: "8px" }}
                          color="primary"
                          onClick={() => settabsPlantilla("TRANSACCIONES")}
                        >
                          Venta
                        </Button>
                        {empCategoria === "ISP" && (
                          <Button
                            variant={
                              tabPlantilla === "Internet" ? "contained" : null
                            }
                            disableElevation
                            style={{ padding: "8px" }}
                            color="primary"
                            onClick={() => settabsPlantilla("Internet")}
                          >
                            Internet
                          </Button>
                        )}
                        {empCategoria === "VETERINARIA" && (
                          <>
                            <Button
                              variant={
                                tabPlantilla === "MASCOTAS" ? "contained" : null
                              }
                              disableElevation
                              style={{ padding: "8px" }}
                              color="primary"
                              onClick={() => settabsPlantilla("MASCOTAS")}
                            >
                              Mascota
                            </Button>
                            <Button
                              variant={
                                tabPlantilla === "HistoriaClinica"
                                  ? "contained"
                                  : null
                              }
                              disableElevation
                              style={{ padding: "8px" }}
                              color="primary"
                              onClick={() =>
                                settabsPlantilla("HistoriaClinica")
                              }
                            >
                              Historia Clinica
                            </Button>
                            <Button
                              variant={
                                tabPlantilla === "Vacuna" ? "contained" : null
                              }
                              disableElevation
                              style={{ padding: "8px" }}
                              color="primary"
                              onClick={() => settabsPlantilla("Vacuna")}
                            >
                              Vacuna
                            </Button>
                            <Button
                              variant={
                                tabPlantilla === "Receta" ? "contained" : null
                              }
                              disableElevation
                              style={{ padding: "8px" }}
                              color="primary"
                              onClick={() => settabsPlantilla("Receta")}
                            >
                              Receta
                            </Button>
                            <Button
                              variant={
                                tabPlantilla === "Hospitalizacion"
                                  ? "contained"
                                  : null
                              }
                              disableElevation
                              style={{ padding: "8px" }}
                              color="primary"
                              onClick={() =>
                                settabsPlantilla("Hospitalizacion")
                              }
                            >
                              Hospitalización
                            </Button>
                            <Button
                              variant={
                                tabPlantilla === "Peluqueria"
                                  ? "contained"
                                  : null
                              }
                              disableElevation
                              style={{ padding: "8px" }}
                              color="primary"
                              onClick={() => settabsPlantilla("Peluqueria")}
                            >
                              Peluqueria
                            </Button>
                            <Button
                              variant={
                                tabPlantilla === "Reserva" ? "contained" : null
                              }
                              disableElevation
                              style={{ padding: "8px" }}
                              color="primary"
                              onClick={() => settabsPlantilla("Reserva")}
                            >
                              Reserva
                            </Button>
                            <Button
                              variant={
                                tabPlantilla === "Examen" ? "contained" : null
                              }
                              disableElevation
                              style={{ padding: "8px" }}
                              color="primary"
                              onClick={() => settabsPlantilla("Examen")}
                            >
                              Examen
                            </Button>{" "}
                          </>
                        )}
                      </Box>
                    </Box>
                    <Divider className={clases.formAllW} sx={{ mb: 1 }} />
                    {tabPlantilla === "Notificaciones" && (
                      <PlantillaTab
                        propiedadPlantilla="empPlantillaNotificacion"
                        empresa={empresa}
                        setempresa={setempresa}
                        clases={clases}
                        arregloPropiedades={[]}
                      />
                    )}
                    {tabPlantilla === "TRANSACCIONES" && (
                      <PlantillaTab
                        propiedadPlantilla="empPlantillaVenta"
                        empresa={empresa}
                        setempresa={setempresa}
                        clases={clases}
                        arregloPropiedades={[
                          {
                            propiedad: "venNumFactura",
                            etiqueta: "Num. Documento",
                          },
                          {
                            propiedad: "venEmpresa",
                            etiqueta: "Empresa",
                          },
                          {
                            propiedad: "venEstado",
                            etiqueta: "Estado",
                          },
                          {
                            propiedad: "venNomCliente",
                            etiqueta: "Cliente",
                          },
                          {
                            propiedad: "venRucCliente",
                            etiqueta: "Ruc Cliente",
                          },
                          {
                            propiedad: "venFormaPago",
                            etiqueta: "Forma de pago",
                          },
                          {
                            propiedad: "venTotal",
                            etiqueta: "Total",
                          },
                          {
                            propiedad: "venDias",
                            etiqueta: "Dias",
                          },
                          {
                            propiedad: "venAbono",
                            etiqueta: "Abono",
                          },
                          {
                            propiedad: "venDescPorcentaje",
                            etiqueta: "Desc. Porcentaje",
                          },
                          {
                            propiedad: "venFechaFactura",
                            etiqueta: "F. Factura",
                          },
                        ]}
                      />
                    )}
                    {empCategoria === "ISP" && tabPlantilla === "Internet" && (
                      <PlantillaTab
                        propiedadPlantilla="empPlantillaInternet"
                        empresa={empresa}
                        setempresa={setempresa}
                        clases={clases}
                        arregloPropiedades={[]}
                      />
                    )}
                    {empCategoria === "VETERINARIA" && (
                      <>
                        {tabPlantilla === "MASCOTAS" && (
                          <PlantillaTab
                            propiedadPlantilla="empPlantillaMascota"
                            empresa={empresa}
                            setempresa={setempresa}
                            clases={clases}
                            arregloPropiedades={[
                              {
                                propiedad: "mascNombre",
                                etiqueta: "Nombre Mascota",
                              },
                              {
                                propiedad: "mascId",
                                etiqueta: "#Carnet",
                              },
                              {
                                propiedad: "mascPerDocNumero",
                                etiqueta: "Documento Propietario",
                              },
                              {
                                propiedad: "mascPerNombre",
                                etiqueta: "Nombre Propietario",
                              },

                              {
                                propiedad: "mascRaza",
                                etiqueta: "Raza",
                              },
                              {
                                propiedad: "mascSexo",
                                etiqueta: "Sexo",
                              },
                              {
                                propiedad: "mascEstado",
                                etiqueta: "Estado",
                              },
                            ]}
                          />
                        )}

                        {tabPlantilla === "HistoriaClinica" && (
                          <PlantillaTab
                            propiedadPlantilla="empPlantillaHistoriaClinica"
                            empresa={empresa}
                            setempresa={setempresa}
                            clases={clases}
                            arregloPropiedades={[
                              {
                                propiedad: "hcliMascNombre",
                                etiqueta: "Mascota",
                              },
                              {
                                propiedad: "hcliMascId",
                                etiqueta: "#Carnet",
                              },
                              {
                                propiedad: "hcliPerDocumento",
                                etiqueta: "Documento Propietario",
                              },
                              {
                                propiedad: "hcliPerNombre",
                                etiqueta: "Propietario",
                              },

                              {
                                propiedad: "hcliPerNombreVetInt",
                                etiqueta: "Vet. Interno",
                              },
                              {
                                propiedad: "hcliFecha",
                                etiqueta: "Fecha",
                              },
                              {
                                propiedad: "hcliMotiConsulMedica",
                                etiqueta: "Motivo",
                              },
                            ]}
                          />
                        )}
                        {tabPlantilla === "Vacuna" && (
                          <PlantillaTab
                            propiedadPlantilla="empPlantillaVacuna"
                            empresa={empresa}
                            setempresa={setempresa}
                            clases={clases}
                            arregloPropiedades={[
                              {
                                propiedad: "carnMascNombre",
                                etiqueta: "Mascota",
                              },
                              {
                                propiedad: "carnMascId",
                                etiqueta: "#Carnet",
                              },
                              {
                                propiedad: "carnPerDocumento",
                                etiqueta: "Documento Propietario",
                              },
                              {
                                propiedad: "carnPerNombre",
                                etiqueta: "Propietario",
                              },

                              {
                                propiedad: "carnPeso",
                                etiqueta: "Peso kg.",
                              },
                              {
                                propiedad: "carnProdTipo",
                                etiqueta: "Tipo Producto",
                              },
                              {
                                propiedad: "carnPerNombreVet",
                                etiqueta: "Veterinario",
                              },
                              {
                                propiedad: "carnFecVacuColocacion",
                                etiqueta: "Fec. Col.",
                              },
                              {
                                propiedad: "carnFecVacuRecolocacion",
                                etiqueta: "Fec. Rec.",
                              },
                            ]}
                          />
                        )}
                        {tabPlantilla === "Receta" && (
                          <PlantillaTab
                            propiedadPlantilla="empPlantillaReceta"
                            empresa={empresa}
                            setempresa={setempresa}
                            clases={clases}
                            arregloPropiedades={[
                              {
                                propiedad: "recMascNombre",
                                etiqueta: "Paciente",
                              },
                              {
                                propiedad: "recMascId",
                                etiqueta: "#Carnet",
                              },
                              {
                                propiedad: "recPerDocNumeroPropietario",
                                etiqueta: "Documento Propietario",
                              },

                              {
                                propiedad: "recPerNombrePropietario",
                                etiqueta: "Propietario",
                              },

                              {
                                propiedad: "recMascRaza",
                                etiqueta: "Raza",
                              },
                              {
                                propiedad: "recMascSexo",
                                etiqueta: "Sexo",
                              },
                              {
                                propiedad: "recMascEdad",
                                etiqueta: "Edad",
                              },
                              {
                                propiedad: "recPeso",
                                etiqueta: "Peso kg.",
                              },
                              {
                                propiedad: "recPerNombreDoc",
                                etiqueta: "Doctor",
                              },
                              {
                                propiedad: "recProxCita",
                                etiqueta: "Próxima Cita",
                              },
                            ]}
                          />
                        )}
                        {tabPlantilla === "Hospitalizacion" && (
                          <PlantillaTab
                            propiedadPlantilla="empPlantillaHospitalizacion"
                            empresa={empresa}
                            setempresa={setempresa}
                            clases={clases}
                            arregloPropiedades={[
                              {
                                propiedad: "hospMascNombre",
                                etiqueta: "Paciente",
                              },
                              {
                                propiedad: "hospMascId",
                                etiqueta: "#Carnet",
                              },
                              {
                                propiedad: "hospPerDocNumeroPropietario",
                                etiqueta: "Documento Propietario",
                              },

                              {
                                propiedad: "hospPerNombrePropietario",
                                etiqueta: "Propietario",
                              },

                              {
                                propiedad: "hospMascRaza",
                                etiqueta: "Raza",
                              },
                              {
                                propiedad: "hospMascPeso",
                                etiqueta: "Peso kg.",
                              },
                              {
                                propiedad: "hospMascSexo",
                                etiqueta: "Sexo",
                              },
                              {
                                propiedad: "hospEstado",
                                etiqueta: "Estado",
                              },
                              {
                                propiedad: "hospPerNombreDoc",
                                etiqueta: "DR. General",
                              },
                              {
                                propiedad: "hospPerNombreSecundario",
                                etiqueta: "DR. Secundario",
                              },
                              {
                                propiedad: "hospPerNombreNocturno",
                                etiqueta: "DR. Nocturno",
                              },
                              {
                                propiedad: "hospCondicion",
                                etiqueta: "Condiciones",
                              },
                            ]}
                          />
                        )}
                        {tabPlantilla === "Peluqueria" && (
                          <PlantillaTab
                            propiedadPlantilla="empPlantillaPeluqueria"
                            empresa={empresa}
                            setempresa={setempresa}
                            clases={clases}
                            arregloPropiedades={[
                              {
                                propiedad: "pelMascNombre",
                                etiqueta: "Mascota",
                              },
                              {
                                propiedad: "pelMascId",
                                etiqueta: "#Carnet",
                              },
                              {
                                propiedad: "pelPerDocNumero",
                                etiqueta: "Documento Propietario",
                              },

                              {
                                propiedad: "pelPerNombre",
                                etiqueta: "Propietario",
                              },

                              {
                                propiedad: "pelPerNombreDoc",
                                etiqueta: "Mvz. Respon ",
                              },
                              {
                                propiedad: "pelTemperamento",
                                etiqueta: "Temperamento",
                              },
                              {
                                propiedad: "pelAutorizo",
                                etiqueta: "Autorizo",
                              },
                              {
                                propiedad: "pelFecha",
                                etiqueta: "Fecha",
                              },
                              {
                                propiedad: "pelEstado",
                                etiqueta: "Estado",
                              },
                              {
                                propiedad: "pelProxCita",
                                etiqueta: "Proxima Cita",
                              },
                            ]}
                          />
                        )}
                        {tabPlantilla === "Reserva" && (
                          <PlantillaTab
                            propiedadPlantilla="empPlantillaReserva"
                            empresa={empresa}
                            setempresa={setempresa}
                            clases={clases}
                            arregloPropiedades={[
                              {
                                propiedad: "resMascNombre",
                                etiqueta: "Paciente",
                              },
                              {
                                propiedad: "resMascId",
                                etiqueta: "#Carnet",
                              },
                              {
                                propiedad: "resPerDocumento",
                                etiqueta: "Documento Propietario",
                              },
                              {
                                propiedad: "resPerNombre",
                                etiqueta: "Propietario",
                              },

                              {
                                propiedad: "resMascRaza",
                                etiqueta: "Raza",
                              },

                              {
                                propiedad: "resTipoReserva",
                                etiqueta: "Reserva",
                              },
                              {
                                propiedad: "resFecha",
                                etiqueta: "Fecha",
                              },
                              {
                                propiedad: "resFechaFinal",
                                etiqueta: "Fecha Final",
                              },
                              {
                                propiedad: "resPerDocVeterinario",
                                etiqueta: "Veterinario",
                              },
                            ]}
                          />
                        )}
                        {tabPlantilla === "Examen" && (
                          <PlantillaTab
                            propiedadPlantilla="empPlantillaExamen"
                            empresa={empresa}
                            setempresa={setempresa}
                            clases={clases}
                            arregloPropiedades={[
                              {
                                propiedad: "exaMascNombre",
                                etiqueta: "Mascota",
                              },
                              {
                                propiedad: "exaMascId",
                                etiqueta: "#Carnet",
                              },
                              {
                                propiedad: "exaPerDocumento",
                                etiqueta: "Documento Propietario",
                              },
                              {
                                propiedad: "exaPerNombre",
                                etiqueta: "Propietario",
                              },

                              {
                                propiedad: "exaTipo",
                                etiqueta: "Tipo",
                              },
                            ]}
                          />
                        )}
                      </>
                    )}
                  </>
                )}
                {whatsapp && (
                  <WhatsappConexion setseccionActive={setseccionActive} />
                )}
              </Box>
              {/* <Divider /> */}
              <Box
                display="flex"
                width="100%"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="flex-end"
                  mt={1}
                >
                  <Button
                    style={{ width: "30%" }}
                    variant="contained"
                    color="primary"
                    disabled={cargando}
                    onClick={() => {
                      if (editar) {
                        handleEditar();
                      } else {
                        handleCrear();
                      }
                    }}
                  >
                    Guardar
                  </Button>
                </Box>
              </Box>
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default memo(ModalAsistencia);
