import React, { useState, useEffect } from "react";

import L from "leaflet";
import "./GlobalCssSlider.css";
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMapEvents,
  useMap,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-geosearch/dist/geosearch.css";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import { OpenStreetMapProvider, GeoSearchControl } from "leaflet-geosearch";
// MATERIAL
import { Dialog, Slide, IconButton, Box } from "@mui/material";
import { Save } from "@mui/icons-material";
let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
});

L.Marker.prototype.options.icon = DefaultIcon;

export default function DialogMap(props) {
  const { open, setopen, campo, setitem, item, editar } = props;

  useEffect(() => {
    const funcionInit = async () => {
      if (!navigator.geolocation) {
        alert(
          "Tu navegador no soporta el acceso a la ubicación. Intenta con otro"
        );
        if (editar) {
          return;
        }
        return setitem({
          ...item,
          [campo]: {
            longitud: -79.178298,
            latitud: -0.254916,
          },
        });
      }

      const onUbicacionConcedida = async (ubicacion) => {
        
        if (editar) {
          return;
        }
        return setitem({
          ...item,
          [campo]: {
            longitud: ubicacion.coords.longitude,
            latitud: ubicacion.coords.latitude,
          },
        });
      };

      const onErrorDeUbicacion = (err) => {};

      // Solicitar
      await navigator.geolocation.getCurrentPosition(
        onUbicacionConcedida,
        onErrorDeUbicacion,
        { enableHighAccuracy: true }
      );
    };
    funcionInit(); // eslint-disable-next-line
  }, []);

  // const clases = useStyles();
  const handleClose = () => {
    setopen(false);
  };

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  function AddMarkerToClick() {
    const [marker, setMarker] = useState(item[campo]);

    useMapEvents({
      click: async (e) => {
        const newMarker = {
          latitud: e.latlng.lat + 0.0002,
          longitud: e.latlng.lng - 0.00009,
        };
     
        setMarker(newMarker);
      },
    });
    const map = useMap(); //here use useMap hook

    useEffect(() => {
      const provider = new OpenStreetMapProvider();
      map.on("geosearch/showlocation", function(e) {
        const newMarker = {
          latitud: e.location.y,
          longitud: e.location.x,
        };
        map.flyTo([e.location.y, e.location.x], 13);

        setMarker(newMarker);
      });
      const searchControl = new GeoSearchControl({
        provider,
        marker: {
          icon: DefaultIcon,
        },
        options: {
          showMarker: false,
        },
      });

      searchControl.options.showMarker = false;
      map.addControl(searchControl);

      return () => map.removeControl(searchControl);
      // eslint-disable-next-line
    }, []);

    return (
      <>
        <Marker eventHandlers={{}} position={[marker.latitud, marker.longitud]}>
          <Popup>
            <Box display={"flex"} alignItems="center">
              Guardar Esta localización - {marker.latitud}, {marker.longitud}
              <IconButton
                aria-label=""
                color="secondary"
                size="small"
                onClick={() => {
                  handleClose();
                  setitem({
                    ...item,
                    [campo]: {
                      latitud: marker.latitud,
                      longitud: marker.longitud,
                    },
                  });
                }}
              >
                <Save fontSize="small" />
              </IconButton>
            </Box>
          </Popup>
        </Marker>
      </>
    );
  }
  if (!open) {
    return null;
  }

  return (
    <Dialog
      maxWidth="xl"
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <MapContainer
        center={[item[campo].latitud, item[campo].longitud]}
        zoom={30}
        style={{ width: "80vw", height: "80vh" }}
      >
        <TileLayer
          eventHandlers={{
            click: (e) => {},
          }}
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution={`&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors`}
        />
        <AddMarkerToClick />
      </MapContainer>
    </Dialog>
  );
}
