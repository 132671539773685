import React, { useState, useContext, useEffect, memo } from "react";
import { makeStyles } from "@mui/styles";
import {
  Modal,
  Box,
  Button,
  Divider,
  Typography,
  TextField,
  FormControl,
  Autocomplete,
  MenuItem,
} from "@mui/material";

import Draggable from "react-draggable";
import { Cancel } from "@mui/icons-material";
import { IconButton } from "@mui/material";
// eslint-disable-next-line
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import AuthContext from "../../../../Context/Auth/AuthContext";
import clienteAxios from "../../../../config/axios";

// MEDICAMENTOS
import TablaMedicamentos from "./Medicamentos/TablaMedicamentos";

// ALIMENTOS
import TablaAlimentos from "./Alimentos/TablaAlimentos";
// PARAMETROS
import TablaParametros from "./Parametros/TablaParametros";
// FLUIDOS
import TablaFluidos from "./Fluidos/TablaFluidos";
// FLUIDOS
import TablaInfusion from "./Infusion/TablaInfusion";
import { trimPropsItem } from "../../../../config/const";
import { usePermiso } from "../../../../hooks/usePermiso";
function getModalStyle() {
  return {
    borderRadius: "15px",
    position: "absolute",
    overflow: "scroll",
    maxHeight: "85vh",
  };
}
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "80%",
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1, 2),
    borderRadius: "15px",
    marginTop: "5vh",
    [theme.breakpoints.down("sm")]: { width: "90%", marginTop: "2vh" },
  },
  margin: {
    margin: theme.spacing(1),
    width: "45%",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  ocultar: { display: "none" },
  inputPadding: {
    padding: theme.spacing(0.5),
    margin: theme.spacing(0),
    fontSize: "1rem",
  },
  formD: {
    flexGrow: 1,
    width: "45%",
    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  formAllW: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "95%",
    [theme.breakpoints.down("md")]: { padding: "0", margin: "2px" },
    [theme.breakpoints.down("sm")]: { padding: "0", margin: "2px" },
  },

  form30PC: {
    flexGrow: 1,
    width: "30%",
    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "30%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },

  large: {
    width: theme.spacing(13),
    height: theme.spacing(13),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    "&:hover": { opacity: ".7" },
  },
  center: { justifyContent: "center", alignItems: "center" },
  root: { width: "100%", marginTop: theme.spacing(1) },
  correo: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "45%",
    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  error: { color: "red", fontWeight: "bold" },
}));
const ModalData = (props) => {
  const { tienePermiso, alertaPermiso } = usePermiso("Hospitalizacion");
  const { mostrarAlerta } = useContext(AlertaContext);
  // eslint-disable-next-line
  const { usuario } = useContext(AuthContext); //props
  const {
    open,
    setopen,
    editar,
    seteditar,
    ObjectoActivo,
    changeObjetoActivoState,
    socket,
  } = props;
  // eslint-disable-next-line
  const [item, setitem] = useState(ObjectoActivo);
  const {
    // hospMascId,
    hospMascNombre,
    hospMascPeso,
    hospMascRaza,
    hospMascSexo,
    hospMascEdad,
    // hospPerIdPropietario,
    hospPerNombrePropietario,
    // hospPerDocNumeroPropietario,
    // hospPerTelefonoPropietario,
    // hospPerCelularPropietario,
    // hospPerDireccionPropietario,
    // hospPerEmailPropietario,
    // hospPerIdDoc,
    hospPerNombreDoc,
    // hospPerIdDocSecundario,
    hospPerNombreSecundario,
    // hospPerIdDocNocturno,
    hospPerNombreNocturno,
    hospEstado,
    hospCondicion,
    // hospEmpresa,
    // hospUser,
    hospDiagnostico,
    hospObservacion,
    hospExaComplementario,
    hospMedicamentos,
    hospAlimentos,
    hospFluidos,
    hospInfusion,
    hospParametros,
    // recFecReg,
  } = item;

  const [veterinariosData, setveterinariosData] = useState([]);
  const [mascotasData, setmascotasData] = useState([]);

  const handleChange = (e) => {
    setitem({ ...item, [e.target.name]: e.target.value });
  };
  const [cargando, setcargando] = useState(false);
  const [seccionActive, setseccionActive] = useState({
    datosSeccion: true,

    MedicamentosSeccion: false,
    AlimentosSeccion: false,
    FluidosSeccion: false,
    InfusionSeccion: false,
    ParametrosSeccion: false,
  });
  const {
    datosSeccion,

    MedicamentosSeccion,
    AlimentosSeccion,
    FluidosSeccion,
    InfusionSeccion,
    ParametrosSeccion,
  } = seccionActive;
  const [error, seterror] = useState({
    hospMascNombre: false,
    hospMascRaza: false,
    hospMascPeso: false,
    hospMascSexo: false,
    hospEstado: false,
    hospMascEdad: false,
    hospPerNombrePropietario: false,
    hospPerNombreDoc: false,
    hospPerNombreSecundario: false,
    hospPerNombreNocturno: false,
    hospDiagnostico: false,
    hospCondicion: false,
    hospExaComplementario: false,
    hospObservacion: false,
    hospMedicamentos: false,
    hospAlimentos: false,
    hospFluidos: false,
    hospInfusion: false,
    hospParametros: false,
  });
  // const [agregandoMedicamento, setagregandoMedicamento] = useState(false);
  // const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(10);
  // const handleChangePage = async (event, newPage) => {
  //   setPage(newPage);
  // };
  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(0);
  // };

  useEffect(() => {
    setitem(ObjectoActivo); // eslint-disable-next-line
  }, [open]);
  const defaultActive = () => {
    changeObjetoActivoState({
      // hospId: 2,
      hospMascId: "",
      hospMascNombre: "",
      hospMascPeso: "",
      hospMascRaza: "",
      hospMascSexo: "",
      hospMascEdad: "",
      hospPerIdPropietario: "",
      hospPerNombrePropietario: "",
      hospPerDocNumeroPropietario: "",
      hospPerTelefonoPropietario: "",
      hospPerCelularPropietario: [],
      hospPerDireccionPropietario: "",
      hospPerEmailPropietario: [],
      hospPerIdDoc: "",
      hospPerNombreDoc: "",
      hospPerIdDocSecundario: "",
      hospPerNombreSecundario: "",
      hospPerIdDocNocturno: "",
      hospPerNombreNocturno: "",
      hospEstado: "HOSPITALIZADO",
      hospCondicion: "ESTABLE",
      hospEmpresa: "",
      hospUser: "",
      hospDiagnostico: "",
      hospObservacion: "",
      hospExaComplementario: "",
      hospMedicamentos: {
        medicamentosCabecera: [],
        medicamentosHorario: [],
      },
      hospAlimentos: {
        alimentosCabecera: [],
        alimentosHorario: [],
      },
      hospFluidos: {
        fluidosCabecera: [],
        fluidosHorario: [],
      },
      hospInfusion: {
        infusionCabecera: [],
        infusionHorario: [],
      },
      hospParametros: {
        parametrosCabecera: [],
        parametrosHorario: [],
      },
      hospFecReg: "2022-10-31T16:05:35.000Z",
    });
    setopen(false);
    seteditar(false);
    seterror({});

    setcargando(false);
  };

  const validacion = () => {
    if (
      hospMascNombre.trim() === "" ||
      hospMascRaza.trim() === "" ||
      hospMascPeso.trim() === "" ||
      hospMascSexo.trim() === "" ||
      hospEstado.trim() === "" ||
      hospMascEdad.trim() === "" ||
      hospPerNombrePropietario.trim() === "" ||
      hospPerNombreDoc.trim() === "" ||
      hospCondicion.trim() === "" ||
      (hospMedicamentos.medicamentosCabecera.length === 0 &&
        hospAlimentos.alimentosCabecera.length === 0 &&
        hospFluidos.fluidosCabecera.length === 0 &&
        hospInfusion.infusionCabecera.length === 0 &&
        hospParametros.parametrosCabecera.length === 0)
    ) {
      seterror({
        hospMascNombre: hospMascNombre.trim() === "" ? true : false,
        hospMascRaza: hospMascRaza.trim() === "" ? true : false,
        hospMascPeso: hospMascPeso.trim() === "" ? true : false,
        hospMascSexo: hospMascSexo.trim() === "" ? true : false,
        hospEstado: hospEstado.trim() === "" ? true : false,
        hospMascEdad: hospMascEdad.trim() === "" ? true : false,
        hospPerNombrePropietario:
          hospPerNombrePropietario.trim() === "" ? true : false,
        hospPerNombreDoc: hospPerNombreDoc.trim() === "" ? true : false,

        hospCondicion: hospCondicion.trim() === "" ? true : false,

        hospMedicamentos:
          hospMedicamentos.medicamentosCabecera.length === 0 &&
          hospAlimentos.alimentosCabecera.length === 0 &&
          hospFluidos.fluidosCabecera.length === 0 &&
          hospInfusion.infusionCabecera.length === 0 &&
          hospParametros.parametrosCabecera.length === 0
            ? true
            : false,
        hospAlimentos:
          hospMedicamentos.medicamentosCabecera.length === 0 &&
          hospAlimentos.alimentosCabecera.length === 0 &&
          hospFluidos.fluidosCabecera.length === 0 &&
          hospInfusion.infusionCabecera.length === 0 &&
          hospParametros.parametrosCabecera.length === 0
            ? true
            : false,
        hospFluidos:
          hospMedicamentos.medicamentosCabecera.length === 0 &&
          hospAlimentos.alimentosCabecera.length === 0 &&
          hospFluidos.fluidosCabecera.length === 0 &&
          hospInfusion.infusionCabecera.length === 0 &&
          hospParametros.parametrosCabecera.length === 0
            ? true
            : false,
        hospInfusion:
          hospMedicamentos.medicamentosCabecera.length === 0 &&
          hospAlimentos.alimentosCabecera.length === 0 &&
          hospFluidos.fluidosCabecera.length === 0 &&
          hospInfusion.infusionCabecera.length === 0 &&
          hospParametros.parametrosCabecera.length === 0
            ? true
            : false,
        hospParametros:
          hospMedicamentos.medicamentosCabecera.length === 0 &&
          hospAlimentos.alimentosCabecera.length === 0 &&
          hospFluidos.fluidosCabecera.length === 0 &&
          hospInfusion.infusionCabecera.length === 0 &&
          hospParametros.parametrosCabecera.length === 0
            ? true
            : false,
      });
      return true;
    } else {
      return false;
    }
  };
  const handleCrear = async () => {
    if (validacion()) {
      return mostrarAlerta("Faltan campos obligatorios", "error");
    }
    try {
      setcargando(true);

      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...item,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          tabla: "hospitalizacion",
          hospUser: usuario.usuario,
          hospEmpresa: usuario.rucempresa,
        })
      );
      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);
      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  const handleEditar = async () => {
    if (validacion()) {
      return mostrarAlerta("Faltan campos obligatorios", "error");
    }
    try {
      setcargando(true);
      console.log(
        trimPropsItem({
          ...item,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          tabla: "hospitalizacion",
          hospUser: `${item.hospUser.split(" ** ")[0]} ** ${usuario.usuario}`,
          hospEmpresa: usuario.rucempresa,
          hospProductosAntiguos:
            ObjectoActivo.hospMedicamentos.medicamentosCabecera,
        })
      );
      socket.current.emit(
        "client:actualizarData",
        trimPropsItem({
          ...item,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          tabla: "hospitalizacion",
          hospUser: `${item.hospUser.split(" ** ")[0]} ** ${usuario.usuario}`,
          hospEmpresa: usuario.rucempresa,
          hospProductosAntiguos:
            ObjectoActivo.hospMedicamentos.medicamentosCabecera,
        })
      );
      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);
      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  //hook de estilos
  const clases = useStyles();
  //definiendo los estilos del modal
  const [modalStyle] = useState(getModalStyle);

  if (!open) {
    return null;
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Draggable handle="#handle">
          <Box display="flex" justifyContent="center">
            <div style={modalStyle} className={clases.paper}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography
                  style={{ cursor: "-webkit-grab" }}
                  id="handle"
                  variant="button"
                  color="initial"
                  align="center"
                >
                  {editar ? "Editar" : "Agregar"}
                  {" Hospitalización"}
                  {editar ? `#${item.hospId}` : null}
                </Typography>
                <Box display="flex" alignItems="center">
                  <IconButton
                    aria-label=""
                    style={{ margin: "0px" }}
                    onClick={() => {
                      defaultActive();
                    }}
                  >
                    <Cancel color="secondary" />
                  </IconButton>
                </Box>
              </Box>
              <Divider />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems=" stretch"
                width="100%"
              >
                <Box>
                  <Button
                    variant={
                      datosSeccion
                        ? "contained"
                        : error.hospMascNombre ||
                          error.hospMascRaza ||
                          error.hospMascPeso ||
                          error.hospMascSexo ||
                          error.hospEstado ||
                          error.hospMascEdad ||
                          error.hospPerNombrePropietario ||
                          error.hospPerNombreDoc ||
                          error.hospPerNombreSecundario ||
                          error.hospPerNombreNocturno ||
                          error.hospCondicion ||
                          error.hospObservacion
                        ? "outlined"
                        : null
                    }
                    disableElevation
                    style={{ padding: "8px" }}
                    color={
                      error.hospMascNombre ||
                      error.hospMascRaza ||
                      error.hospMascPeso ||
                      error.hospMascSexo ||
                      error.hospEstado ||
                      error.hospMascEdad ||
                      error.hospPerNombrePropietario ||
                      error.hospPerNombreDoc ||
                      error.hospPerNombreSecundario ||
                      error.hospPerNombreNocturno ||
                      error.hospCondicion ||
                      error.hospObservacion
                        ? "error"
                        : "secondary"
                    }
                    onClick={() => setseccionActive({ datosSeccion: true })}
                  >
                    Datos
                  </Button>

                  <Button
                    variant={
                      MedicamentosSeccion
                        ? "contained"
                        : error.hospMedicamentos
                        ? "outlined"
                        : null
                    }
                    disableElevation
                    style={{ padding: "8px" }}
                    color={error.hospMedicamentos ? "error" : "secondary"}
                    onClick={() =>
                      setseccionActive({ MedicamentosSeccion: true })
                    }
                  >
                    Medicamentos
                  </Button>
                  <Button
                    variant={
                      AlimentosSeccion
                        ? "contained"
                        : error.hospAlimentos
                        ? "outlined"
                        : null
                    }
                    disableElevation
                    style={{ padding: "8px" }}
                    color={error.hospAlimentos ? "error" : "secondary"}
                    onClick={() => setseccionActive({ AlimentosSeccion: true })}
                  >
                    Alimentos
                  </Button>
                  <Button
                    variant={
                      FluidosSeccion
                        ? "contained"
                        : error.hospFluidos
                        ? "outlined"
                        : null
                    }
                    disableElevation
                    style={{ padding: "8px" }}
                    color={error.hospFluidos ? "error" : "secondary"}
                    onClick={() => setseccionActive({ FluidosSeccion: true })}
                  >
                    Fluidos
                  </Button>
                  <Button
                    variant={
                      InfusionSeccion
                        ? "contained"
                        : error.hospInfusion
                        ? "outlined"
                        : null
                    }
                    disableElevation
                    style={{ padding: "8px" }}
                    color={error.hospInfusion ? "error" : "secondary"}
                    onClick={() => setseccionActive({ InfusionSeccion: true })}
                  >
                    Infusion
                  </Button>
                  <Button
                    variant={
                      ParametrosSeccion
                        ? "contained"
                        : error.hospParametros
                        ? "outlined"
                        : null
                    }
                    disableElevation
                    style={{ padding: "8px" }}
                    color={error.hospParametros ? "error" : "secondary"}
                    onClick={() =>
                      setseccionActive({ ParametrosSeccion: true })
                    }
                  >
                    Parametros
                  </Button>
                </Box>
                {/* <FormControlLabel
                control={
                  <Switch
                    checked={perEstado === "ACTIVO" ? true : false}
                    onChange={(e) => {
                      setitem({
                        ...item,
                        perEstado: e.target.checked ? "ACTIVO" : "INACTIVO",
                      });
                    }}
                    name="antoine"
                  />
                }
                label="Estado"
              /> */}
              </Box>
              <Divider />
              <Box
                display="flex"
                flexWrap="wrap"
                alignItems="flex-end"
                borderRadius="0px 50px 50px 0px"
                mt={1}
              >
                {datosSeccion ? (
                  <>
                    <FormControl fullWidth className={clases.formD}>
                      <Autocomplete
                        filterOptions={(x) => x}
                        options={mascotasData.map((item) => {
                          return (
                            item.mascNombre +
                            "/+/" +
                            item.mascPerNombre +
                            "/+/" +
                            item.mascId
                          );
                        })}
                        getOptionLabel={(option) => {
                          return option.replace("/+/", " - ").split("/+/")[0];
                        }}
                        id="controlled-demo"
                        value={hospMascNombre}
                        paperprops
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            const options = newValue.split("/+/");

                            const itemF = mascotasData.find((itemPersona) => {
                              return itemPersona.mascId === Number(options[2]);
                            });

                            setitem({
                              ...item,
                              hospMascId: itemF.mascId,
                              hospMascNombre: itemF.mascNombre,
                              hospMascRaza: itemF.mascRaza,
                              hospMascSexo: itemF.mascSexo,
                              hospMascEdad: itemF.mascEdad,
                              hospPerIdPropietario: itemF.mascPerId,
                              hospPerNombrePropietario: itemF.mascPerNombre,
                              hospPerDocNumeroPropietario:
                                itemF.mascPerDocNumero,
                              hospPerTelefonoPropietario: itemF.mascPerTelefono,
                              hospPerCelularPropietario: itemF.mascPerCelular,
                              hospPerDireccionPropietario:
                                itemF.mascPerDireccion,
                              hospPerEmailPropietario: itemF.mascPerEmail,
                            });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            error={error.hospMascNombre}
                            label="Paciente"
                            fullWidth
                            onChange={async (e) => {
                              if (e.target.value === "") {
                                return;
                              }
                              try {
                                const res = await clienteAxios.get(
                                  `/mascotas/filtro/0?search=${e.target.value}`
                                );
                                if (!res.data.data) {
                                  return mostrarAlerta(res.data.msg, "error");
                                }

                                setmascotasData(res.data.data);
                              } catch (error) {}
                            }}
                            // InputProps={{
                            //   ...params.InputProps,
                            //   endAdornment: (
                            //     <React.Fragment>
                            //       {!editar ? (
                            //         <IconButton
                            //           size="small"
                            //           aria-label=""
                            //           onClick={async () => {
                            //             seteditarProveedor(false);
                            //             setopenProveedor(true);
                            //           }}
                            //         >
                            //           <AddCircleOutlineTwoTone color="primary" />
                            //         </IconButton>
                            //       ) : null}
                            //       {params.InputProps.endAdornment}
                            //     </React.Fragment>
                            //   ),
                            // }}
                          />
                        )}
                      />
                    </FormControl>
                    <TextField
                      disabled
                      className={clases.formD}
                      label="Raza"
                      value={hospMascRaza}
                      name="hospMascRaza"
                      error={error.hospMascRaza}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      type="number"
                      className={clases.formD}
                      label="Peso kg."
                      value={hospMascPeso}
                      name="hospMascPeso"
                      error={error.hospMascPeso}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      disabled
                      className={clases.formD}
                      label="Sexo"
                      value={hospMascSexo}
                      name="hospMascSexo"
                      error={error.hospMascSexo}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      className={clases.formD}
                      label="Estado"
                      value={hospEstado}
                      name="hospEstado"
                      error={error.hospEstado}
                      onChange={(e) => handleChange(e)}
                      select
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                    >
                      <MenuItem value="">Seleccione.</MenuItem>
                      {["HOSPITALIZADO", "ALTA", "FALLECIDO", "SOLICITADO"].map(
                        (item) => {
                          return <MenuItem value={item}>{item} </MenuItem>;
                        }
                      )}
                    </TextField>
                    <TextField
                      disabled
                      className={clases.formD}
                      label="Edad"
                      value={hospMascEdad}
                      name="hospMascEdad"
                      error={error.hospMascEdad}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      disabled
                      className={clases.formD}
                      label="Propietario"
                      value={hospPerNombrePropietario}
                      name="hospPerNombrePropietario"
                      error={error.hospPerNombrePropietario}
                      onChange={(e) => handleChange(e)}
                    />
                    <FormControl fullWidth className={clases.formD}>
                      <Autocomplete
                        filterOptions={(x) => x}
                        options={veterinariosData.map((item) => {
                          return item.perNombre + "/+/" + item.perId;
                        })}
                        getOptionLabel={(option) => {
                          return option.split("/+/")[0];
                        }}
                        id="controlled-demo"
                        value={hospPerNombreDoc}
                        paperprops
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            const options = newValue.split("/+/");
                            const itemF = veterinariosData.find(
                              (itemPersona) => {
                                return itemPersona.perId === Number(options[1]);
                              }
                            );

                            setitem({
                              ...item,
                              hospPerNombreDoc: itemF.perNombre,
                              hospPerIdDoc: itemF.perId,
                            });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            error={error.hospPerNombreDoc}
                            label="DR. General"
                            fullWidth
                            onChange={async (e) => {
                              if (e.target.value === "") {
                                return;
                              }
                              try {
                                const res = await clienteAxios.get(
                                  `/proveedores/filtro/0?search=${e.target.value}&estado=VETERINARIO`
                                );
                                if (!res.data.data) {
                                  return mostrarAlerta(res.data.msg, "error");
                                }
                                setveterinariosData(res.data.data);
                              } catch (error) {}
                            }}
                            // InputProps={{
                            //   ...params.InputProps,
                            //   endAdornment: (
                            //     <React.Fragment>
                            //       {!editar ? (
                            //         <IconButton
                            //           size="small"
                            //           aria-label=""
                            //           onClick={async () => {
                            //             seteditarProveedor(false);
                            //             setopenProveedor(true);
                            //           }}
                            //         >
                            //           <AddCircleOutlineTwoTone color="primary" />
                            //         </IconButton>
                            //       ) : null}
                            //       {params.InputProps.endAdornment}
                            //     </React.Fragment>
                            //   ),
                            // }}
                          />
                        )}
                      />
                    </FormControl>
                    <FormControl fullWidth className={clases.formD}>
                      <Autocomplete
                        filterOptions={(x) => x}
                        options={veterinariosData.map((item) => {
                          return item.perNombre + "/+/" + item.perId;
                        })}
                        getOptionLabel={(option) => {
                          return option.split("/+/")[0];
                        }}
                        id="controlled-demo"
                        value={hospPerNombreSecundario}
                        paperprops
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            const options = newValue.split("/+/");
                            const itemF = veterinariosData.find(
                              (itemPersona) => {
                                return itemPersona.perId === Number(options[1]);
                              }
                            );

                            setitem({
                              ...item,
                              hospPerNombreSecundario: itemF.perNombre,
                              hospPerIdDocSecundario: itemF.perId,
                            });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            error={error.hospPerNombreSecundario}
                            label="DR. Secundario"
                            fullWidth
                            onChange={async (e) => {
                              if (e.target.value === "") {
                                return;
                              }
                              try {
                                const res = await clienteAxios.get(
                                  `/proveedores/filtro/0?search=${e.target.value}&estado=VETERINARIO`
                                );
                                if (!res.data.data) {
                                  return mostrarAlerta(res.data.msg, "error");
                                }
                                setveterinariosData(res.data.data);
                              } catch (error) {}
                            }}
                            // InputProps={{
                            //   ...params.InputProps,
                            //   endAdornment: (
                            //     <React.Fragment>
                            //       {!editar ? (
                            //         <IconButton
                            //           size="small"
                            //           aria-label=""
                            //           onClick={async () => {
                            //             seteditarProveedor(false);
                            //             setopenProveedor(true);
                            //           }}
                            //         >
                            //           <AddCircleOutlineTwoTone color="primary" />
                            //         </IconButton>
                            //       ) : null}
                            //       {params.InputProps.endAdornment}
                            //     </React.Fragment>
                            //   ),
                            // }}
                          />
                        )}
                      />
                    </FormControl>
                    <FormControl fullWidth className={clases.formD}>
                      <Autocomplete
                        filterOptions={(x) => x}
                        options={veterinariosData.map((item) => {
                          return item.perNombre + "/+/" + item.perId;
                        })}
                        getOptionLabel={(option) => {
                          return option.split("/+/")[0];
                        }}
                        id="controlled-demo"
                        value={hospPerNombreNocturno}
                        paperprops
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            const options = newValue.split("/+/");
                            const itemF = veterinariosData.find(
                              (itemPersona) => {
                                return itemPersona.perId === Number(options[1]);
                              }
                            );

                            setitem({
                              ...item,
                              hospPerNombreNocturno: itemF.perNombre,
                              hospPerIdDocNocturno: itemF.perId,
                            });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            error={error.hospPerNombreNocturno}
                            label="DR. Nocturno"
                            fullWidth
                            onChange={async (e) => {
                              if (e.target.value === "") {
                                return;
                              }
                              try {
                                const res = await clienteAxios.get(
                                  `/proveedores/filtro/0?search=${e.target.value}&estado=VETERINARIO`
                                );
                                if (!res.data.data) {
                                  return mostrarAlerta(res.data.msg, "error");
                                }
                                setveterinariosData(res.data.data);
                              } catch (error) {}
                            }}
                            // InputProps={{
                            //   ...params.InputProps,
                            //   endAdornment: (
                            //     <React.Fragment>
                            //       {!editar ? (
                            //         <IconButton
                            //           size="small"
                            //           aria-label=""
                            //           onClick={async () => {
                            //             seteditarProveedor(false);
                            //             setopenProveedor(true);
                            //           }}
                            //         >
                            //           <AddCircleOutlineTwoTone color="primary" />
                            //         </IconButton>
                            //       ) : null}
                            //       {params.InputProps.endAdornment}
                            //     </React.Fragment>
                            //   ),
                            // }}
                          />
                        )}
                      />
                    </FormControl>
                    <TextField
                      className={clases.formD}
                      label="Condiciones"
                      value={hospCondicion}
                      name="hospCondicion"
                      error={error.hospCondicion}
                      onChange={(e) => handleChange(e)}
                      select
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                    >
                      <MenuItem value="">Seleccione.</MenuItem>
                      {[
                        "ESTABLE",
                        "CRITICO",
                        "INFECCIOSO",
                        "NO INFECCIOSO",
                      ].map((item) => {
                        return <MenuItem value={item}>{item} </MenuItem>;
                      })}
                    </TextField>
                    <>
                      {" "}
                      <TextField
                        multiline
                        rows={2}
                        variant={"outlined"}
                        className={clases.formAllW}
                        label="Observación"
                        value={hospObservacion}
                        name="hospObservacion"
                        error={error.hospObservacion}
                        onChange={(e) => handleChange(e)}
                      />
                      <TextField
                        multiline
                        rows={2}
                        variant={"outlined"}
                        className={clases.formAllW}
                        label="Diagnóstico"
                        value={hospDiagnostico}
                        name="hospDiagnostico"
                        error={error.hospDiagnostico}
                        onChange={(e) => handleChange(e)}
                      />
                      <TextField
                        multiline
                        rows={2}
                        variant={"outlined"}
                        className={clases.formAllW}
                        label="Exámenes complemetarios"
                        value={hospExaComplementario}
                        name="hospExaComplementario"
                        error={error.hospExaComplementario}
                        onChange={(e) => handleChange(e)}
                      />
                    </>{" "}
                  </>
                ) : null}

                {MedicamentosSeccion ? (
                  <TablaMedicamentos
                    hospMedicamentos={hospMedicamentos}
                    setitem={setitem}
                    item={item}
                    error={error}
                  />
                ) : null}
                {AlimentosSeccion ? (
                  <TablaAlimentos
                    hospAlimentos={hospAlimentos}
                    setitem={setitem}
                    item={item}
                    error={error}
                  />
                ) : null}
                {FluidosSeccion ? (
                  <TablaFluidos
                    hospFluidos={hospFluidos}
                    setitem={setitem}
                    item={item}
                    error={error}
                  />
                ) : null}
                {InfusionSeccion ? (
                  <TablaInfusion
                    hospInfusion={hospInfusion}
                    setitem={setitem}
                    item={item}
                    error={error}
                  />
                ) : null}
                {ParametrosSeccion ? (
                  <TablaParametros
                    hospParametros={hospParametros}
                    setitem={setitem}
                    item={item}
                    error={error}
                  />
                ) : null}
              </Box>
              {/* <Divider /> */}{" "}
              <Box
                mt={2}
                display="flex"
                width="100%"
                justifyContent="space-between"
                alignItems="center"
                flexWrap={"wrap"}
              >
                <Box
                  className={clases.formD}
                  alignItems="center"
                  display={"flex"}
                  justifyContent="space-around"
                >
                  <Typography
                    className={clases.formD}
                    color="secondary"
                    fontSize={".9rem"}
                    fontWeight="bold"
                  >
                    Crea:{" "}
                    <Typography
                      className={clases.formD}
                      color="primary"
                      fontSize={".9rem"}
                      component="span"
                    >
                      {item.hospUser.split(" ** ")[0]}
                    </Typography>
                  </Typography>
                  <Typography
                    className={clases.formD}
                    color="secondary"
                    fontSize={".9rem"}
                    fontWeight="bold"
                  >
                    Act:{" "}
                    <Typography
                      className={clases.formD}
                      color="primary"
                      fontSize={".9rem"}
                      component="span"
                    >
                      {item.hospUser.split(" ** ")[1]}
                    </Typography>
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  mt={1}
                  className={clases.formD}
                >
                  <Button
                    className={clases.formD}
                    variant="contained"
                    color={item.restaurado ? "success" : "primary"}
                    disabled={cargando}
                    onClick={() => {
                      if (editar) {
                        if (!tienePermiso("editar")) {
                          return alertaPermiso("editar");
                        }
                        handleEditar();
                      } else {
                        if (!tienePermiso("agregar")) {
                          return alertaPermiso("agregar");
                        }
                        handleCrear();
                      }
                    }}
                  >
                    {item.restaurado ? "Restaurar" : "Guardar"}
                  </Button>
                </Box>
              </Box>
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default memo(ModalData);
