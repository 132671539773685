import React, { memo, useState, useContext, useEffect, useRef } from "react";

import { Box, Divider } from "@mui/material";

import Tabla from "./Componentes/Tabla";
import ModalB from "../../../components/Extra/ModalB";
import BarraTablas from "../../../components/BarraTablas/BarraTablas";
import clienteAxios from "../../../config/axios";
import AlertaContext from "../../../Context/Alerta/AlertaContext";
import AuthContext from "../../../Context/Auth/AuthContext";
import Modal from "./Componentes/Modal";
import io from "socket.io-client";
import { socketUrl } from "../../../config/const";
import ModalFactura from "../../Ventas/Facturas/Componentes/Modal";
import ModalInventario from "../../Configuración/Inventario/Componentes/Modal";
import ModalProveedor from "../../Compras/Proveedores/Personas_Contable/Componentes/Modal";
import ModalTraido from "./ModalTraido";
import Categorias from "../../Configuración/Categorias//Categorias";
import Subcategorias from "../../Configuración/Subcategorias/Subcategorias";
import { usePermiso } from "../../../hooks/usePermiso";
import { TabsRolEliminado } from "../../components/Tablas/TabsRolEliminado";

const Contabilidad = (props) => {
  const { tienePermiso } = usePermiso("Peluqueria");
  const {
    idmascota,
    settab,
    setarrayExportHijos,
    desdeOtroLugarMascota,
    puedeExportar,
    itemRelacionadoPadre,
  } = props;
  // DATA DEL STATE

  const [rows, setrows] = useState(null);

  const columns = [
    {
      id: "pelId",
      label: "Cod",
      minWidth: 25,
      tipo: "string",

      propiedad: "pelId",
    },

    {
      id: "pelPerNombreDoc",
      label: "Mvz.Respon",
      minWidth: 150,
      tipo: "string",

      propiedad: "pelPerNombreDoc",
      variante: "autocompleteDinamico",
      propiedadesMostrar: 2,
      campoData1: "perDocNumero",
      campoData2: "perNombre",
      getData: async (data) => {
        const res = await clienteAxios.get(
          `/proveedores/filtro/0?search=${data}&estado=VETERINARIO`
        );
        return res;
      },
    },

    {
      id: "pelMascNombre",
      label: "Mascota",
      minWidth: 150,
      tipo: "string",

      propiedad: "pelMascNombre",
      variante: "autocompleteDinamico",
      propiedadesMostrar: 2,
      campoData1: "mascNombre",
      campoData2: "mascPerNombre",
      getData: async (data) => {
        const res = await clienteAxios.get(`/mascotas/filtro/0?search=${data}`);
        return res;
      },
    },

    {
      id: "pelPerDocNumero",
      label: "Propietario",
      minWidth: 150,
      tipo: "string",

      propiedad: "pelPerDocNumero",
      variante: "autocompleteDinamico",
      propiedadesMostrar: 2,
      campoData1: "perDocNumero",
      campoData2: "perNombre",
      getData: async (data) => {
        const res = await clienteAxios.get(
          `/proveedores/filtro/0?search=${data}&estado=CLIENTE`
        );
        return res;
      },
    },

    {
      id: "pelTemperamento",
      label: "Temperamento",
      minWidth: 50,
      tipo: "string",

      propiedad: "pelTemperamento",
      variante: "autocompleteComun",
      data: ["AGRESIVO", "NERVIOSO", "DOCIL", "INQUIETO", "REQ. SEDACIÓN"],
    },

    {
      id: "pelProxCita",
      label: "Proxima Cita",
      minWidth: 50,
      tipo: "fecha",

      propiedad: "pelProxCita",
    },

    {
      id: "pelEstado",
      label: "Estado",
      minWidth: 50,
      tipo: "string",

      propiedad: "pelEstado",
      variante: "autocompleteComun",
      data: ["NORMAL", "POCO ENREDADO", "ENREDADO", "MUY ENREDADO", "ANULADA"],
    },

    {
      id: "pelFecha",
      label: "Fecha",
      minWidth: 50,
      tipo: "fecha",

      propiedad: "pelFecha",
    },

    {
      id: "pelTurno",
      label: "Turno",
      minWidth: 50,
      tipo: "number",

      propiedad: "pelTurno",
    },

    {
      id: "Valor",
      label: "Valor",
      minWidth: 50,
      tipo: "number",

      propiedad: "Valor",
    },

    {
      id: "pelHoraIngreso",
      label: "Ingreso",
      minWidth: 50,
      tipo: "hora",

      propiedad: "pelHoraIngreso",
    },

    {
      id: "pelObservacion",
      label: "Obsr. al ingreso de la mascota",
      minWidth: 300,
      tipo: "string",

      propiedad: "pelObservacion",
    },

    {
      id: "pelUser",
      label: "User",
      minWidth: 50,
      tipo: "string",

      propiedad: "pelUser",
    },
    {
      id: "pelFecUpd",
      label: "Fec Upd",
      minWidth: 50,
      tipo: "fecha",
      propiedad: "pelFecUpd",
    },
  ];
  const defaultFullBuscador = () => {
    const json = {};
    columns.forEach((itemPropiedad) => {
      Object.entries({ itemPropiedad }).forEach((item) => {
        if (itemPropiedad.tipo === "string") {
          json[`${item[1].propiedad}`] = "";
        } else if (itemPropiedad.tipo === "numero") {
          json[`${item[1].propiedad}1`] = "";
          json[`${item[1].propiedad}2`] = "";
        } else if (itemPropiedad.tipo === "fecha") {
          json[`${item[1].propiedad}1`] = "";
          json[`${item[1].propiedad}2`] = "";
        } else if (itemPropiedad.tipo === "hora") {
          json[`${item[1].propiedad}1`] = "";
          json[`${item[1].propiedad}2`] = "";
        }
      });
    });

    return json;
  };

  const [ObjectoActivo, setObjetoActivo] = useState({
    // pelId: "",
    pelMascId: "",
    pelMascNombre: "",
    pelPerId: "",
    pelPerNombre: "",
    pelPerDocNumero: "",
    pelPerTelefono: "",
    pelPerCelular: [],
    pelPerDireccion: "",
    pelPerEmail: [],
    pelPerIdDoc: "",
    pelPerNombreDoc: "",
    pelTemperamento: "",
    pelAutorizo: "",
    pelTurno: "",
    pelFecha: new Date(Date.now() - new Date().getTimezoneOffset() * 60 * 1000)
      .toISOString()
      .substring(0, 10),
    pelHoraIngreso: "",
    pelHoraSalida: "",
    pelEstado: "",
    pelLlamar30min: "SI",
    pelProxCita: "",
    pelCara: "",
    pelFormaCabeza: "",
    pelCopete: "",
    pelOreja: "",
    pelBigotes: "",
    pelBigotesForma: "",
    pelCejas: "",
    pelCuerpo: "",
    pelLomo: "",
    pelFaldon: "",
    pelCola: "",
    pelColaForma: "",
    pelPatitas: "",
    pelDeclaro: "NO",
    pelOidos: "NO",
    pelLimpiezaOcular: "NO",
    pelLavadoDientes: "NO",
    pelCorteUna: "NO",
    pelUnasDedos: "NO",
    pelRapadoGenitales: "NO",
    pelDrenaje: "NO",
    pelBano: "NO",
    pelSecado: "NO",
    pelCortesAcabados: "NO",
    pelPerfume: "NO",
    pelPresencia: "NO",
    pelRetira: "NO",
    pelCarnet: "NO",
    pelLazoCentral: "NO",
    pelDosLazos: "NO",
    pelBufanda: "NO",
    pelPanuelo: "NO",
    pelUser: "",
    pelEmpresa: "",
    pelValor: "0",
    pelCorreo: "PENDIENTE",
    pelObservacion: "",
    pelOtrosProcesos: "",
    pelDejaAccesorios: "",
    pelFecUpdate: "",
    pelFecReg: "",
    Todos: "",
    idFactura: "",
  });
  const [pagination, setpagination] = useState(null);
  // FUNCIONES STATE
  const getDataState = async (
    cantidad,
    page,
    search,
    input,
    orden,
    datos,
    rucempresa,
    eliminadoEstadoNuevo = eliminadoEstado,
    rolEstadoNuevo = rolEstado
  ) => {
    const res = await clienteAxios.get(
      `/peluqueria/?cantidad=${cantidad}&page=${page}&search=${
        search ? search : ""
      }&input=${input ? input : ""}&orden=${orden ? orden : ""}&datos=${
        datos ? datos : ""
      }&rucempresa=${rucempresa ? rucempresa : ""}&idmascota=${
        idmascota ? idmascota : ""
      }&allData=${tienePermiso(
        "allData",
        rolEstadoNuevo
      )}&rol=${rolEstadoNuevo}&eliminado=${eliminadoEstadoNuevo}`
    );

    return res;
  };

  const obtenerState = async (
    cantidad = 10,
    page = 0,
    search,
    input,
    orden,
    datos,
    rucempresa,
    eliminadoEstado,
    rolEstado
  ) => {
    const res = await getDataState(
      cantidad,
      page,
      search,
      input,
      orden,
      datos,
      rucempresa,
      eliminadoEstado,
      rolEstado
    );
    setrows(res.data.data.results);
    setpagination(res.data.data.pagination);
  };
  const addRowsState = async (items) => {
    setrows([...rows, ...items]);
  };
  const remplazarRowsState = async (
    cantidad,
    page,
    search,
    input,
    orden,
    datos,
    rucempresa,
    eliminadoEstado,
    rolEstado
  ) => {
    const res = await getDataState(
      cantidad,
      page,
      search,
      input,
      orden,
      datos,
      rucempresa,
      eliminadoEstado,
      rolEstado
    );
    setrows(res.data.data.results);
    setpagination(res.data.data.pagination);
    // dispatch({
    //   type: OBTENER,
    //   payload: {
    //     results: res.data.data.results,
    //     pagination: res.data.data.pagination,
    //   },
    // });
  };
  const agregarState = async (item) => {
    try {
      setrows([item, ...rows]);

      setpagination({
        ...pagination,
        numRows: pagination.numRows + 1,
      });
    } catch (error) {}
  };

  const editarState = async (item) => {
    try {
      const resRows = rows.map((itemR) => {
        if (itemR.pelId === item.pelId) {
          return item;
        } else {
          return itemR;
        }
      });

      setrows(resRows);
    } catch (error) {}
  };

  const eliminarState = async (_id) => {
    try {
      const resRows = rows.filter((row) => {
        return row.pelId !== _id;
      });
      setrows(resRows);
      setpagination({
        ...pagination,
        numRows: pagination.numRows - 1,
      });
    } catch (error) {}
  };
  const eliminarSeleccionState = async (_ids) => {
    const resRows = rows.filter((row) => {
      return !_ids.some((item) => {
        return item === row.pelId;
      });
    });
    setpagination({
      ...pagination,
      numRows: pagination.numRows - _ids.length,
    });
    setrows(resRows);
  };

  const changeObjetoActivoState = (item) => {
    setObjetoActivo(item);
    // dispatch({
    //   type: CHANGE_ACTIVO,
    //   payload: item,
    // });
  };

  const rowsImportState = (rows) => {
    // dispatch({
    //   type: IMPORTAR_ROWS,
    //   payload: rows,
    // });
    setrows(rows);
  };
  // FIN FUNCIONES STATE
  // FIN DATA STATE

  // FUNCIONES SOCKET
  const [tabla] = useState("peluqueria");
  const [itemSocket, setitemSocket] = useState({
    tipo: "",
    item: {},
  });

  const socket = useRef();
  useEffect(() => {
    // Creates a WebSocket connection
    socket.current = io(socketUrl);
    socket.current.on("server:guardadoExitoso", (data) => {
      if (data.tabla !== tabla) {
        return;
      }

      setitemSocket({
        tipo: "agregar",
        item: data,
      });
    });
    socket.current.on("server:actualizadoExitoso", (data) => {
      if (data.tabla !== tabla) {
        return;
      }

      setitemSocket({
        tipo: "editar",
        item: data,
      });
    });
    socket.current.on("server:eliminadoExitoso", (data) => {
      if (data.tabla !== tabla) {
        return;
      }
      setitemSocket({
        tipo: "eliminar",
        item: data,
      });
    });
    socket.current.on("server:eliminadoExitosoSeleccionado", (data) => {
      if (data.tabla !== tabla) {
        return;
      }
      setitemSocket({
        tipo: "eliminarSeleccion",
        item: data,
      });
    });

    socket.current.on("server:error", (data) => {
      mostrarAlerta(
        data.msg ? data.msg : "Hubo un error",
        data.alert ? data.alert : "error"
      );
    });
    return () => {
      socket.current.disconnect();
    };
    // Destroys the socket reference
    // when the connection is closed
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    const { item, tipo } = itemSocket;

    if (tipo === "") {
      return;
    }
    if (!rows) {
      return;
    }
    if (usuario.rucempresa !== item.rucempresa) {
      return;
    }
    if (item.tabla !== tabla) {
      return;
    }

    if (desdeOtroLugarMascota && Number(item.pelMascId) !== idmascota) {
      return;
    }
    const funcionAalerta = () => {
      if (item.msg === "") {
        return null;
      }
      if (!item.msg) {
        return null;
      }
      mostrarAlerta(item.msg, item.alert ? item.alert : "success");
    };

    if (tipo === "agregar") {
      agregarState(item);
    }
    if (tipo === "editar") {
      editarState(item);
    }
    if (tipo === "eliminar") {
      eliminarState(item.pelId);
    }
    if (tipo === "eliminarSeleccion") {
      eliminarSeleccionState(item.lista);
    }
    funcionAalerta();
    setitemSocket({ tipo: "", item: {} });
    // eslint-disable-next-line
  }, [itemSocket]);

  const [open, setopen] = useState(false);
  const [buscando, setbuscando] = useState(false);
  const [editar, seteditar] = useState(false);
  const [search, setsearch] = useState("");
  const [cargando, setcargando] = useState(false);
  const [input, setinput] = useState({
    id: "pelFecUpd",
    label: "ID",
    minWidth: 150,
    tipo: "string",

    propiedad: "pelFecUpd",
  });
  const [arrayExport, setarrayExport] = useState([]);
  const [campoExport] = useState("pelId");
  useEffect(() => {
    if (desdeOtroLugarMascota) {
      settab("");
      setarrayExportHijos(arrayExport.map((item) => item[campoExport]));
    }
    // eslint-disable-next-line
  }, [arrayExport]);
  const { mostrarAlerta } = useContext(AlertaContext);
  const {
    usuario: { rucempresa },
    usuario,
  } = useContext(AuthContext);
  const [orden, setorden] = useState(false);
  const [openMB, setopenMB] = useState(false);
  const [fullBuscador, setfullBuscador] = useState({});

  //pagina actual
  const [page, setPage] = React.useState(0);
  //filas por pagina
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [eliminadoEstado, seteliminadoEstado] = useState(false);
  const [rolEstado, setrolEstado] = useState(usuario.rol[0]);
  //
  const handleChange = (e) => {
    setfullBuscador({ ...fullBuscador, [e.target.name]: e.target.value });
  };
  const funcionB = async () => {
    setcargando(true);
    await remplazarRowsState(
      rowsPerPage,
      0,
      search,
      input.id,
      orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      eliminadoEstado,
      rolEstado
    );
    setPage(0);
    setbuscando(true);
    setopenMB(false);
    setcargando(false);
  };

  const handleSearch = async () => {
    setarrayExport([]);
    setcargando(true);
    await remplazarRowsState(
      rowsPerPage,
      0,
      search,
      input.id,
      orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      eliminadoEstado,
      rolEstado
    );

    setPage(0);
    setcargando(false);
  };

  useEffect(() => {
    const fullBRes = defaultFullBuscador();

    setfullBuscador(fullBRes);
    // eslint-disable-next-line
  }, []);

  const funcionDefault = async (
    { eliminadoEstado, rolEstado } = { eliminadoEstado, rolEstado }
  ) => {
    setcargando(true);
    const fullBRes = defaultFullBuscador();
    setfullBuscador(fullBRes);
    await remplazarRowsState(
      rowsPerPage,
      0,
      "",
      input.id,
      orden,
      JSON.stringify(fullBRes),
      rucempresa,
      eliminadoEstado,
      rolEstado
    );

    setPage(0);
    setsearch("");
    setbuscando(false);
    setcargando(false);
    setopenMB(false);
  };

  //DATA DE OTROS LUGARES
  // sub-categoria
  const [openSubCategoria, setopenSubCategoria] = useState(false);
  // categoria
  const [openCategoria, setopenCategoria] = useState(false);
  // Inventario
  const [OpenProveedorModalI, setOpenProveedorModalI] = useState(false);
  const [openInventario, setopenInventario] = useState(false);
  // Proveedor
  const [editarInventario, seteditarInventario] = useState(false);
  const [OpenProveedor, setOpenProveedor] = useState(false);
  const [editarProveedor, seteditarProveedor] = useState(false);
  // FACTURA
  const [openFactura, setopenFactura] = useState(false);
  const [editarFactura, seteditarFactura] = useState(false);
  // INVENTARIO
  const [ObjectoActivoInventario, setObjetoActivoInventario] = useState({
    invSerie: "0",
    invTipo: "",
    invNombre: "",
    invDescripcion: "",
    invCosto1: "",
    invCosto2: "",
    invCosto3: "",
    invStock: "",
    invIva: "SI",
    invIncluyeIva: "SI",
    invEstado: "ACTIVA",
    invBodegas: [],
    invprecios: [],
    invProveedor: "",
    invCategoria: "",
    invSubCategoria: "",
    invUnidad: "",
    invMarca: "",
    invSubsidio: "0",
    invPlanCuenta: "",
    invEmpresa: "",
    invUser: "admin",
    invFecReg: "",
    Todos: "",
  });
  const changeObjetoActivoStateInventario = (item) => {
    setObjetoActivoInventario(item);
    // dispatch({
    //   type: CHANGE_ACTIVO,
    //   payload: item,
    // });
  };

  // Proveedor
  const [ObjectoActivoProveedor, setObjetoActivoProveedor] = useState({
    perISPContratos: [],
    perNombreComercial: "",
    perEmpresa: [],
    perPais: "",
    perProvincia: "",
    perCanton: "",
    perTipoProveedor: "BIENES",
    perTiempoCredito: "0",
    perDocTipo: "",
    perDocNumero: "",
    perPerfil: [],
    perNombre: "",
    perDireccion: "",
    perObligado: "NO",
    perCredito: "NO",
    perTelefono: "0",
    perCelular: ["+593"],
    perEstado: "ACTIVO",
    perObsevacion: "",
    perEmail: ["sin@sincorreo.com"],
    perOtros: ["ZZZ9999"],
    perNickname: "",
    perUser: "",
    perFecReg: "",
    perFoto: "",
    perUbicacion: {
      longitud: "",
      latitud: "",
    },
    perDocumento: "",
    perGenero: "M",
    perRecomendacion: "OTROS",
    perFecNacimiento: "",
    perEspecialidad: "",
    perTitulo: "",
    perSenescyt: "",
    perPersonal: "INTERNO",
    validateEmail: 1,
  });
  const changeObjetoActivoStateProveedor = (item) => {
    setObjetoActivoProveedor(item);
    // dispatch({
    //   type: CHANGE_ACTIVO,
    //   payload: item,
    // });
  };
  // FACTURA DATA
  const [ObjectoActivoFactura, setObjetoActivoFactura] = useState({
    venEmpRegimen: "",
    venProductosAntiguos: [],
    optionDocumento: "",
    venTotalRetencion: "0.00",
    venOption: "1",
    venTipoDocumento: "N",
    venIdCliente: "1",
    venRucCliente: "9999999999999",
    venTipoDocuCliente: "RUC",
    venNomCliente: "CONSUMIDOR FINAL",
    venEmailCliente: ["sin@sincorreo.com"],
    venTelfCliente: "0000000001",
    venCeluCliente: "0000000001",
    venDirCliente: "s/n",
    venEmpRuc: "",
    venEmpNombre: "",
    venEmpComercial: "",
    venEmpDireccion: "",
    venEmpTelefono: "",
    venEmpEmail: "",
    venEmpObligado: "",
    venFormaPago: "TRANSFERENCIA",
    venNumero: "0",
    venFacturaCredito: "NO",
    venDias: "0",
    venAbono: "0",
    venDescPorcentaje: "0",
    venOtrosDetalles: "",
    venObservacion: "",
    venSubTotal12: "",
    venSubtotal0: "",
    venDescuento: "",
    venSubTotal: "",
    venTotalIva: "",
    venTotal: "0.00",
    venCostoProduccion: "",
    venUser: "",
    venFechaFactura: `${new Date().getFullYear()}-${(
      "0" +
      (new Date().getMonth() + 1)
    ).slice(-2)}-${("0" + new Date().getDate()).slice(-2)}`,
    venNumFactura: "",
    venNumFacturaAnterior: "",
    venAutorizacion: "0",
    venFechaAutorizacion: "",
    venErrorAutorizacion: "NO",
    venEstado: "ACTIVA",
    venEnvio: "NO",
    fechaSustentoFactura: "",
    venEmpresa: "",
    venProductos: [],
  });
  const changeObjetoActivoStateFactura = (item) => {
    setObjetoActivoFactura(item);
    // dispatch({
    //   type: CHANGE_ACTIVO,
    //   payload: item,
    // });
  };

  return (
    <Box>
      <ModalTraido
        open={openCategoria}
        setopen={setopenCategoria}
        titulo="Categoría"
        Componente={Categorias}
        socket={socket}
      />
      <ModalTraido
        open={openSubCategoria}
        setopen={setopenSubCategoria}
        titulo="Sub Categoría"
        Componente={Subcategorias}
        socket={socket}
      />
      <ModalProveedor
        estadoRequest={"PROVEEDOR"}
        open={OpenProveedorModalI}
        setopen={setOpenProveedorModalI}
        editar={editarProveedor}
        seteditar={seteditarProveedor}
        socket={socket}
        ObjectoActivo={ObjectoActivoProveedor}
        changeObjetoActivoState={changeObjetoActivoStateProveedor}
      />
      <ModalProveedor
        estadoRequest={"CLIENTE"}
        open={OpenProveedor}
        setopen={setOpenProveedor}
        editar={editarProveedor}
        seteditar={seteditarProveedor}
        socket={socket}
        ObjectoActivo={ObjectoActivoProveedor}
        changeObjetoActivoState={changeObjetoActivoStateProveedor}
      />
      {socket.current ? (
        <ModalInventario
          open={openInventario}
          setopen={setopenInventario}
          editar={editarInventario}
          seteditar={seteditarInventario}
          setopenSubCategoria={setopenSubCategoria}
          setopenCategoria={setopenCategoria}
          setOpenProveedor={setOpenProveedorModalI}
          ObjectoActivo={ObjectoActivoInventario}
          changeObjetoActivoState={changeObjetoActivoStateInventario}
          socket={socket}
          seteditarProveedor={seteditarProveedor}
          changeObjetoActivoStateProveedor={changeObjetoActivoStateProveedor}
        />
      ) : null}
      {socket.current ? (
        <ModalFactura
          estadoRequest={"NOTA VENTAS"}
          editar={editarFactura}
          seteditar={seteditarFactura}
          setopen={setopenFactura}
          open={openFactura}
          setopenInventario={setopenInventario}
          setOpenProveedor={setOpenProveedor}
          ObjectoActivo={ObjectoActivoFactura}
          changeObjetoActivoState={changeObjetoActivoStateFactura}
          socket={socket}
          desdePeluqueria={true}
        />
      ) : null}
      <BarraTablas
        search={search}
        setsearch={setsearch}
        funcion={handleSearch}
        buscando={buscando}
        setbuscando={setbuscando}
        setopen={(boolean) => {
          if (desdeOtroLugarMascota) {
            setObjetoActivo({
              ...ObjectoActivo,
              pelMascId: itemRelacionadoPadre.mascId,
              pelMascNombre: itemRelacionadoPadre.mascNombre,
              pelPerId: itemRelacionadoPadre.mascPerId,
              pelPerNombre: itemRelacionadoPadre.mascPerNombre,
              pelPerDocNumero: itemRelacionadoPadre.mascPerDocNumero,
              pelPerTelefono: itemRelacionadoPadre.mascPerTelefono,
              pelPerCelular: itemRelacionadoPadre.mascPerCelular,
              pelPerDireccion: itemRelacionadoPadre.mascPerDireccion,
              pelPerEmail: itemRelacionadoPadre.mascPerEmail,
            });
          }
          setopen(boolean);
        }}
        open={open}
        openMB={openMB}
        setopenMB={setopenMB}
        LabelBuscador="Buscar por todos los campos"
        funcionDefault={funcionDefault}
        subirArchivo={false}
        ocultarMigasDePan={desdeOtroLugarMascota}
        ocultarOpciones={true}
      />
      <ModalB
        defaultFullBuscador={defaultFullBuscador}
        openMB={openMB}
        setopenMB={setopenMB}
        titulo={"Busqueda avanzada (Peluqueria)"}
        fullBuscador={fullBuscador}
        setfullBuscador={setfullBuscador}
        arrayProps={columns}
        handleChange={handleChange}
        funcionB={funcionB}
        buscando={buscando}
        funcionDefault={funcionDefault}
      />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems=" stretch"
        width="100%"
      >
        <TabsRolEliminado
          setarrayExport={setarrayExport}
          filtrarEliminar={async (nuevoEstadoEliminado) => {
            seteliminadoEstado(nuevoEstadoEliminado);
            await funcionDefault({
              eliminadoEstado: nuevoEstadoEliminado,
              rolEstado,
            });
          }}
          cargando={cargando}
          filtrarRol={async (nuevoEstadoRol) => {
            setrolEstado(nuevoEstadoRol);
            await funcionDefault({
              eliminadoEstado,
              rolEstado: nuevoEstadoRol,
            });
          }}
          eliminadoEstado={eliminadoEstado}
          rolEstado={rolEstado}
        />
      </Box>
      <Divider />
      <Tabla
        eliminadoEstado={eliminadoEstado}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        search={search}
        input={input}
        orden={orden}
        setinput={setinput}
        setorden={setorden}
        open={open}
        setopen={setopen}
        buscando={buscando}
        cargando={cargando}
        setcargando={setcargando}
        fullBuscador={fullBuscador}
        //  STATE
        rows={rows}
        columns={columns}
        ObjectoActivo={ObjectoActivo}
        pagination={pagination}
        // FUNCIONES STATE
        getDataState={getDataState}
        obtenerState={obtenerState}
        addRowsState={addRowsState}
        remplazarRowsState={remplazarRowsState}
        agregarState={agregarState}
        editarState={editarState}
        eliminarState={eliminarState}
        eliminarSeleccionState={eliminarSeleccionState}
        changeObjetoActivoState={changeObjetoActivoState}
        rowsImportState={rowsImportState}
        arrayExport={arrayExport}
        setarrayExport={setarrayExport}
        puedeExportar={puedeExportar}
        desdeOtroLugarMascota={desdeOtroLugarMascota}
        seteditar={seteditar}
        socket={socket}
        seteditarFactura={seteditarFactura}
        changeObjetoActivoStateFactura={changeObjetoActivoStateFactura}
        setopenFactura={setopenFactura}
        ObjectoActivoFactura={ObjectoActivoFactura}
      />
      {socket.current ? (
        <Modal
          editar={editar}
          seteditar={seteditar}
          setopen={setopen}
          open={open}
          ObjectoActivo={ObjectoActivo}
          changeObjetoActivoState={changeObjetoActivoState}
          socket={socket}
          setopenFactura={setopenFactura}
          seteditarFactura={seteditarFactura}
          changeObjetoActivoStateFactura={changeObjetoActivoStateFactura}
          ObjectoActivoFactura={ObjectoActivoFactura}
        />
      ) : null}
      <>
        <></>
      </>
    </Box>
  );
};

export default memo(Contabilidad);
