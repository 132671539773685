import React, { useState, useContext, useEffect, memo } from "react";
import { makeStyles } from "@mui/styles";
import {
  Modal,
  Box,
  Button,
  Divider,
  Typography,
  TextField,
  FormControl,
  Autocomplete,
  InputAdornment,
  Tooltip,
  MenuItem,
} from "@mui/material";

import Draggable from "react-draggable";
import {
  Cancel,
  CloudUpload,
  DeleteForever,
  Download,
} from "@mui/icons-material";
import { IconButton } from "@mui/material";
// eslint-disable-next-line
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import AuthContext from "../../../../Context/Auth/AuthContext";
import { trimPropsItem } from "../../../../config/const";
import clienteAxios from "../../../../config/axios";
import { createRef } from "react";
import { usePermiso } from "../../../../hooks/usePermiso";
import Editor from "../../../../components/Extra/Editor";
import CampoDoc from "../../../components/Campos/CampoDoc";
function getModalStyle() {
  return {
    borderRadius: "15px",
    position: "absolute",
    overflow: "scroll",
    maxHeight: "95vh",
  };
}
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "55%",
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "15px",
    marginTop: "5vh",
    [theme.breakpoints.down("sm")]: { width: "90%", marginTop: "2vh" },
  },
  margin: {
    margin: theme.spacing(1),
    width: "45%",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  ocultar: { display: "none" },
  inputPadding: {
    padding: theme.spacing(0.5),
    margin: theme.spacing(0),
    fontSize: "1rem",
  },
  formD: {
    flexGrow: 1,
    width: "45%",
    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  formAllW: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "95%",
    [theme.breakpoints.down("md")]: { padding: "0", margin: "2px" },
    [theme.breakpoints.down("sm")]: { padding: "0", margin: "2px" },
  },

  form30PC: {
    flexGrow: 1,
    width: "30%",
    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "30%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },

  large: {
    width: theme.spacing(13),
    height: theme.spacing(13),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    "&:hover": { opacity: ".7" },
  },
  center: { justifyContent: "center", alignItems: "center" },
  root: { width: "100%", marginTop: theme.spacing(1) },
  correo: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "45%",
    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  error: { color: "red", fontWeight: "bold" },
}));
const ModalData = (props) => {
  const { tienePermiso, alertaPermiso } = usePermiso("Examenes");
  const { mostrarAlerta } = useContext(AlertaContext);
  const { usuario } = useContext(AuthContext); //props
  const {
    open,
    setopen,
    editar,
    seteditar,
    ObjectoActivo,
    changeObjetoActivoState,
    socket,
  } = props;
  // eslint-disable-next-line
  const [item, setitem] = useState(ObjectoActivo);
  const {
    exaTipo,
    // exaMascId,
    exaMascNombre,
    // exaPerId,
    exaPerNombre,
    exaFoto,
    exaArchivo,
    // exaUser,
    // exaEmpresa,
    // exaFecReg,
    // Todos,
  } = item;
  const [mascotasData, setmascotasData] = useState([]);
  //documento
  const [documentoexaArchivo, setdocumentoexaArchivo] = useState("");
  const refArchivoexaArchivo = createRef();
  const focusInputexaArchivo = () => {
    refArchivoexaArchivo.current.click();
  };
  const onChangeArchivoexaArchivo = async (e) => {
    if (!e.target.files[0]) {
      return;
    }
    if (
      !["pdf"].some((item) => item === e.target.files[0].type.split("/")[1])
    ) {
      setdocumentoexaArchivo("");
      return mostrarAlerta("El archivo debe ser un PDF", "error");
    }

    try {
      setcargando(true);
      const formDataLogos = new FormData();

      if (exaArchivo === "") {
        await formDataLogos.append("tipo", exaTipo);
        await formDataLogos.append("archivo", e.target.files[0]);
      } else {
        await clienteAxios.post("/upload_delete_multiple_files/delete", {
          urls: [exaArchivo],
        });
        await formDataLogos.append("tipo", exaTipo);
        await formDataLogos.append("archivo", e.target.files[0]);
      }

      const resDocumentos = await clienteAxios.post(
        "/upload_delete_multiple_files/upload",
        formDataLogos
      );

      setitem({
        ...item,
        exaArchivo: resDocumentos.data.nombre,
      });

      setdocumentoexaArchivo(e.target.files[0]);
      setcargando(false);
    } catch (error) {
      setcargando(false);
      setdocumentoexaArchivo(e.target.files[0]);

      return mostrarAlerta("Hubo un error", "error");
    }
    setdocumentoexaArchivo(e.target.files[0]);
  };

  //foto
  const [documentoexaFoto, setdocumentoexaFoto] = useState("");
  const refArchivoexaFoto = createRef();
  const focusInputexaFoto = () => {
    refArchivoexaFoto.current.click();
  };
  const onChangeArchivoexaFoto = async (e) => {
    if (!e.target.files[0]) {
      return;
    }
    if (
      !["jpg", "avif", "webp", "png", "jpeg"].some(
        (item) => item === e.target.files[0].type.split("/")[1]
      )
    ) {
      setdocumentoexaFoto("");
      return mostrarAlerta(
        `El archivo debe ser una foto ("jpg", "avif", "webp", "png", "jpeg")`,
        "error"
      );
    }

    try {
      setcargando(true);
      const formDataLogos = new FormData();

      if (exaFoto === "") {
        await formDataLogos.append("tipo", exaTipo);
        await formDataLogos.append("archivo", e.target.files[0]);
      } else {
        await clienteAxios.post("/upload_delete_multiple_files/delete", {
          urls: [exaFoto],
        });
        await formDataLogos.append("tipo", exaTipo);
        await formDataLogos.append("archivo", e.target.files[0]);
      }

      const resDocumentos = await clienteAxios.post(
        "/upload_delete_multiple_files/upload",
        formDataLogos
      );

      setitem({
        ...item,
        exaFoto: resDocumentos.data.nombre,
      });

      setdocumentoexaFoto(e.target.files[0]);
      setcargando(false);
    } catch (error) {
      setcargando(false);
      setdocumentoexaFoto(e.target.files[0]);

      return mostrarAlerta("Hubo un error", "error");
    }
    setdocumentoexaFoto(e.target.files[0]);
  };
  const handleChange = (e) => {
    setitem({ ...item, [e.target.name]: e.target.value });
  };
  const [cargando, setcargando] = useState(false);

  const [error, seterror] = useState({});
  const [motivosData, setmotivosData] = useState(null);
  // GET => /api/sexo/filtro/0
  useEffect(() => {
    if (!motivosData) {
      const getresmotivosData = async () => {
        try {
          const resmotivosData = await clienteAxios.get(
            `/consultasmedicas/filtro/0`
          );

          setmotivosData(resmotivosData.data.data);
        } catch (error) {
          setmotivosData([]);
        }
      };
      getresmotivosData();
    }

    setitem(ObjectoActivo); // eslint-disable-next-line
  }, [open]);
  const defaultActive = () => {
    changeObjetoActivoState({
      exaTipo: "Ecografia",
      exaMascId: "",
      exaMascNombre: "",
      exaPerId: "",
      exaPerNombre: "",
      exaPerDocumento: "",
      exaFoto: "",
      exaArchivo: "",
      exaDescripcion: "",
      exaUser: "",
      exaEmpresa: "",
      exaFecReg: "",
      Todos: "",
    });
    setopen(false);
    seteditar(false);
    seterror({});
    setdocumentoexaArchivo("");
    setdocumentoexaFoto("");
    setcargando(false);
  };

  //hook de estilos
  const clases = useStyles();
  //definiendo los estilos del modal
  const [modalStyle] = useState(getModalStyle);
  const validacion = () => {
    if (
      exaTipo.trim() === "" ||
      exaMascNombre.trim() === "" ||
      exaPerNombre.trim() === ""
    ) {
      seterror({
        exaTipo: exaTipo.trim() === "" ? true : false,
        exaMascNombre: exaMascNombre.trim() === "" ? true : false,
        exaPerNombre: exaPerNombre.trim() === "" ? true : false,
      });
      return true;
    } else {
      return false;
    }
  };
  const handleCrear = async () => {
    if (validacion()) {
      return mostrarAlerta("Faltan campos obligatorios", "error");
    }
    try {
      setcargando(true);
      // let resFoto = null;
      // if (archivo !== "") {
      //   const formDataLogos = new FormData();
      //   formDataLogos.append("tipo", "mascotas");
      //   formDataLogos.append("rucempresa", usuario.rucempresa);
      //   formDataLogos.append("archivoAnterior", item.hcliFoto);
      //   formDataLogos.append("archivo", archivo);
      //   resFoto = await clienteAxios.post(
      //     "/upload_delete_multiple_files/upload",
      //     formDataLogos
      //   );
      // }

      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...item,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          tabla: "examen",
          exaUser: usuario.usuario,
          exaEmpresa: usuario.rucempresa,
          // exaFoto: resFoto ? resFoto.data.nombre : item.hcliFoto,
        })
      );
      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);
      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  const handleEditar = async () => {
    if (validacion()) {
      return mostrarAlerta("Faltan campos obligatorios", "error");
    }
    try {
      setcargando(true);
      // let resFoto = null;
      // if (archivo !== "") {
      //   const formDataLogos = new FormData();
      //   formDataLogos.append("tipo", "mascotas");
      //   formDataLogos.append("rucempresa", usuario.rucempresa);
      //   formDataLogos.append("archivoAnterior", item.hcliFoto);
      //   formDataLogos.append("archivo", archivo);
      //   resFoto = await clienteAxios.post(
      //     "/upload_delete_multiple_files/upload",
      //     formDataLogos
      //   );
      // }

      socket.current.emit(
        "client:actualizarData",
        trimPropsItem({
          ...item,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          tabla: "examen",
          exaUser: `${item.exaUser.split(" ** ")[0]} ** ${usuario.usuario}`,
          exaEmpresa: usuario.rucempresa,
          // exaFoto: resFoto ? resFoto.data.nombre : item.hcliFoto,
        })
      );
      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);
      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  //filas por pagina

  // 1 + 2 + 3 + 4

  if (!open) {
    return null;
  }
  if (!motivosData) {
    return null;
  }
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Draggable handle="#handle">
          <Box display="flex" justifyContent="center">
            <div style={modalStyle} className={clases.paper}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography
                  style={{ cursor: "-webkit-grab" }}
                  id="handle"
                  variant="button"
                  color="initial"
                  align="center"
                >
                  {editar ? "Editar" : "Agregar"}
                  {" Examen"}
                </Typography>
                <Box display="flex" alignItems="center">
                  <IconButton
                    aria-label=""
                    style={{ margin: "0px" }}
                    onClick={() => {
                      defaultActive();
                    }}
                  >
                    <Cancel color="secondary" />
                  </IconButton>
                </Box>
              </Box>
              <Divider />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems=" stretch"
                width="100%"
              >
                <Box></Box>
              </Box>
              <Divider />

              <Box
                display="flex"
                flexWrap="wrap"
                alignItems="flex-end"
                borderRadius="0px 50px 50px 0px"
                mt={1}
              >
                <TextField
                  className={clases.formD}
                  label="Tipo"
                  value={exaTipo}
                  name="exaTipo"
                  error={error.exaTipo}
                  onChange={(e) => handleChange(e)}
                  select
                  InputLabelProps={{ shrink: true }}
                >
                  <MenuItem value="" disabled>
                    Seleccione.
                  </MenuItem>
                  {[
                    "Ecografia",
                    "Radiografico",
                    "Hemograma",
                    "Bioquimica",
                    "Otros Examenes",
                  ].map((item) => {
                    return <MenuItem value={item}>{item} </MenuItem>;
                  })}
                </TextField>
                <FormControl fullWidth className={clases.formD}>
                  <Autocomplete
                    filterOptions={(x) => x}
                    options={mascotasData.map((item) => {
                      return (
                        item.mascNombre +
                        "/+/" +
                        item.mascPerNombre +
                        "/+/" +
                        item.mascId
                      );
                    })}
                    getOptionLabel={(option) => {
                      return option.replace("/+/", " - ").split("/+/")[0];
                    }}
                    id="controlled-demo"
                    value={exaMascNombre}
                    paperprops
                    onChange={async (event, newValue) => {
                      if (newValue) {
                        const options = newValue.split("/+/");
                        const itemF = mascotasData.find((itemPersona) => {
                          return itemPersona.mascId === Number(options[2]);
                        });

                        setitem({
                          ...item,
                          exaMascId: itemF.mascId,
                          exaMascNombre: itemF.mascNombre,
                          exaPerId: itemF.mascPerId,
                          exaPerNombre: itemF.mascPerNombre,
                          exaPerDocumento: itemF.mascPerDocNumero,
                        });
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        error={error.exaMascNombre}
                        label="Mascota"
                        fullWidth
                        onChange={async (e) => {
                          if (e.target.value === "") {
                            return;
                          }
                          try {
                            const res = await clienteAxios.get(
                              `/mascotas/filtro/0?search=${e.target.value}`
                            );
                            if (!res.data.data) {
                              return mostrarAlerta(res.data.msg, "error");
                            }

                            setmascotasData(res.data.data);
                          } catch (error) {}
                        }}
                        // InputProps={{
                        //   ...params.InputProps,
                        //   endAdornment: (
                        //     <React.Fragment>
                        //       {!editar ? (
                        //         <IconButton
                        //           size="small"
                        //           aria-label=""
                        //           onClick={async () => {
                        //             seteditarProveedor(false);
                        //             setopenProveedor(true);
                        //           }}
                        //         >
                        //           <AddCircleOutlineTwoTone color="primary" />
                        //         </IconButton>
                        //       ) : null}
                        //       {params.InputProps.endAdornment}
                        //     </React.Fragment>
                        //   ),
                        // }}
                      />
                    )}
                  />
                </FormControl>

                <TextField
                  disabled
                  className={clases.formD}
                  label="Nombre"
                  value={exaPerNombre}
                  name="exaPerNombre"
                  error={error.exaPerNombre}
                  onChange={(e) => handleChange(e)}
                />

                <>
                  <CampoDoc
                    extension="IMAGEN"
                    disabled={cargando}
                    disabledE={cargando}
                    setcargando={setcargando}
                    label={"Documento"}
                    propiedad={"exaFoto"}
                    item={item}
                    setitem={setitem}
                    celda={false}
                  />
                </>

                <>
                  <CampoDoc
                    disabled={cargando}
                    disabledE={cargando}
                    setcargando={setcargando}
                    label={"Documento"}
                    propiedad={"exaArchivo"}
                    item={item}
                    setitem={setitem}
                    celda={false}
                  />
                </>
                <Editor
                  label="Descripción"
                  propiedad={"exaDescripcion"}
                  item={item}
                  setitem={setitem}
                />

                {/* {historiaSeccion ? <> </> : null} */}
              </Box>
              {/* <Divider /> */}
              <Box
                mt={2}
                display="flex"
                width="100%"
                justifyContent="space-between"
                alignItems="center"
                flexWrap={"wrap"}
              >
                <Box
                  className={clases.formD}
                  alignItems="center"
                  display={"flex"}
                  justifyContent="space-around"
                >
                  <Typography
                    className={clases.formD}
                    color="secondary"
                    fontSize={".9rem"}
                    fontWeight="bold"
                  >
                    Crea:{" "}
                    <Typography
                      className={clases.formD}
                      color="primary"
                      fontSize={".9rem"}
                      component="span"
                    >
                      {item.exaUser.split(" ** ")[0]}
                    </Typography>
                  </Typography>
                  <Typography
                    className={clases.formD}
                    color="secondary"
                    fontSize={".9rem"}
                    fontWeight="bold"
                  >
                    Act:{" "}
                    <Typography
                      className={clases.formD}
                      color="primary"
                      fontSize={".9rem"}
                      component="span"
                    >
                      {item.exaUser.split(" ** ")[1]}
                    </Typography>
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  mt={1}
                  className={clases.formD}
                >
                  <Button
                    className={clases.formD}
                    variant="contained"
                    color={item.restaurado ? "success" : "primary"}
                    disabled={cargando}
                    onClick={() => {
                      if (editar) {
                        if (!tienePermiso("editar")) {
                          return alertaPermiso("editar");
                        }
                        handleEditar();
                      } else {
                        if (!tienePermiso("agregar")) {
                          return alertaPermiso("agregar");
                        }
                        handleCrear();
                      }
                    }}
                  >
                    {item.restaurado ? "Restaurar" : "Guardar"}
                  </Button>
                </Box>
              </Box>
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default memo(ModalData);
