import React, { memo, useContext } from "react";
import { makeStyles } from "@mui/styles";
import { TableCell, TableRow } from "@mui/material";

import { useState } from "react";
import ConfirmacionDialog from "../../../../components/Extra/ConfirmacionDialog";
import { withRouter } from "react-router";
import ButtonsAction from "./ButtonsAction";
import AuthContext from "../../../../Context/Auth/AuthContext";
import CollapseCell from "../../../../components/Extra/CollapseCell";
import ModalEmail from "../../../../components/Extra/ModalEmail";
import CallNombrePersona from "../../../components/Tablas/CallNombrePersona";
import { v4 } from "uuid";
import { format, parseISO } from "date-fns";
import { es } from "date-fns/locale";
export const formatearFecha = (fecha) =>
  !fecha
    ? ""
    : fecha.trim() === ""
    ? ""
    : format(parseISO(fecha), "eee, dd/MM/yyyy, HH:mm:ss", { locale: es });
const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  hover: {
    "&:hover": {
      opacity: ".7",
      cursor: "pointer",
    },
  },
  celda: {
    fontSize: ".7rem !important",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));

const Row = (props) => {
  const {
    arrayExport,
    setarrayExport,
    row,
    index,
    campoExport,
    puedeExportar,
    desdeOtroLugar,
    seteditar,
    setopen,
    changeObjetoActivoState,
    socket,
    tienePermiso,
    alertaPermiso,
    eliminadoEstado,
  } = props;
  const [celComprimida, setcelComprimida] = useState({});
  const { usuario } = useContext(AuthContext);
  const classes = useRowStyles();

  const handleEliminar = async (_id) => {
    if (!tienePermiso("eliminar")) {
      return alertaPermiso("eliminar");
    }
    try {
      socket.current.emit("client:eliminarData", {
        nombre: usuario.nombre,
        tabla: "receta",
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        recUser: usuario.usuario,
        recEmpresa: usuario.rucempresa,
        recId: row.recId,
      });
    } catch (error) {}
  };
  const funcionEdit = () => {
    console.log(row.recProxCita);
    setopen(true);
    seteditar(true);
    changeObjetoActivoState({
      ...row,
      recProxCita:
        row.recProxCita.length === 10
          ? row.recProxCita
          : row.recProxCita.substring(0, 10),
      recMedicamentos: {
        arrayMedicamentos: row.recMedicamentos.arrayMedicamentos.map(
          (receta) => {
            return { ...receta, id: receta.id ? receta.id : v4() };
          }
        ),
      },
      restaurado: eliminadoEstado,
    });
  };
  const funcionCopiar = () => {
    changeObjetoActivoState({
      ...row,
      recProxCita: usuario.rucempresa === "MEDIPET" ? "" : row.recProxCita,
      recProxCitaHora:
        usuario.rucempresa === "MEDIPET" ? "" : row.recProxCitaHora,
      recMedicamentos: {
        arrayMedicamentos: row.recMedicamentos.arrayMedicamentos.map(
          (receta) => {
            return { ...receta, id: receta.id ? receta.id : v4() };
          }
        ),
      },
    });
    setopen(true);
  };

  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  const [openModalEmail, setopenModalEmail] = useState(false);
  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        titulo={`¿Esta seguro de eliminar este registro?`}
        _id={row._id}
        funcion={handleEliminar}
      />{" "}
      <ModalEmail
        open={openModalEmail}
        setopen={setopenModalEmail}
        id={row.recId}
        documento={"Receta"}
        emailDefecto={row.recPerEmailPropietario}
        campos={[
          {
            label: "Documento",
            propiedad: row.recPerDocNumeroPropietario,
          },
          {
            label: "Nombre",
            propiedad: row.recPerNombrePropietario,
          },
        ]}
      />
      <TableRow className={classes.root} selected={index % 2 === 0}>
        {/* boton */}
        <ButtonsAction
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          row={row}
          setopenConfirmDialog={setopenConfirmDialog}
          funcionEdit={funcionEdit}
          ocultarEditar={false}
          ocultarDelete={false}
          mostrarEstado={false}
          campoExport={campoExport}
          campoEstado=""
          puedeExportar={puedeExportar}
          desdeOtroLugar={desdeOtroLugar}
          funcionCopiar={funcionCopiar}
          setopenModalEmail={setopenModalEmail}
          eliminadoEstado={eliminadoEstado}
        />
        <>
          {" "}
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            {row.recId}
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            {row.recMascNombre}
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            {row.recPerNombrePropietario}
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            {row.recPerNombreDoc}
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            {row.recPeso}
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            {new Date(row.recProxCita).toLocaleString("es-ES", {
              weekday: "short",
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })}
          </TableCell>
          <CollapseCell
            celComprimida={celComprimida}
            setcelComprimida={setcelComprimida}
            row={row}
            expandWidth
            propiedad={"recRecomendacion"}
          />
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
            style={{
              color:
                row.recCorreo === "PENDIENTE"
                  ? //rojo tomate
                    "#ffa726"
                  : row.recCorreo === "ENVIADO"
                  ? //verde
                    "green"
                  : row.recCorreo === "ERROR"
                  ? "#f44336"
                  : "#ffa726",
              fontWeight: "bold",
            }}
          >
            {row.recCorreo}
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            {new Date(row.recFecUpd).toLocaleString("es-ES", {
              weekday: "short",
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour: "2-digit",
            })}
          </TableCell>
          <CallNombrePersona user={row.recUser} />
        </>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
