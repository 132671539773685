import React, { useState, useContext, useEffect, memo } from "react";
import Draggable from "react-draggable";

import clienteAxios from "../../../config/axios";
import AuthContext from "../../../Context/Auth/AuthContext";
import { validarEmail } from "../../../config/validaciones";
import MuiPhoneNumber from "material-ui-phone-number";
// MATERIAL
import { makeStyles } from "@mui/styles";
import {
  Modal,
  Box,
  Button,
  Divider,
  Typography,
  IconButton,
  TextField,
  InputAdornment,
  Avatar,
  FormControl,
} from "@mui/material";
import {
  AddCircle,
  Cancel,
  Delete,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import AlertaContext from "../../../Context/Alerta/AlertaContext";

function getModalStyle() {
  return {
    borderRadius: "15px",
    position: "absolute",
    overflow: "scroll",
    maxHeight: "95vh",
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",

    width: "50%",
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "15px",
    marginTop: "5vh",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      marginTop: "2vh",
    },
  },
  margin: {
    margin: theme.spacing(1),
    width: "45%",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  ocultar: {
    display: "none",
  },
  inputPadding: {
    padding: theme.spacing(0.25),
    margin: theme.spacing(0),
    fontSize: ".9rem",
  },
  formD: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "45%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  formAllW: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "95%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
    },
  },
  large: {
    width: theme.spacing(13),
    height: theme.spacing(13),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    "&:hover": {
      opacity: ".7",
    },
  },

  center: {
    justifyContent: "center",
    alignItems: "center",
  },
  root: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  correo: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "45%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  error: {
    color: "red",
    fontWeight: "bold",
  },
}));

const Perfil = (props) => {
  // eslint-disable-next-line
  const { mostrarAlerta } = useContext(AlertaContext);
  // eslint-disable-next-line
  const { socket, usuario, cerrarSesion } = useContext(AuthContext);
  //props
  const { open, setopen } = props;
  const [item, setitem] = useState({
    perNombre: "",
    perTelefono: "0",
    perCelular: ["+593"],
    perDireccion: "",
    perEmail: ["sin@sincorreo.com"],
  });
  const [verpass, setverpass] = useState(false);
  const [newpassword, setnewpassword] = useState("");

  const {
    // perDocTipo,
    // perDocNumero,
    // perPerfil,

    perNombre,
    // perEstadoCivil,
    perTelefono,
    perCelular,
    // perInstruccion,
    // perCapacitacion,
    perDireccion,
    // perFecNacimiento,
    // perNumContrato,
    // perTipoSangre,
    perEmail,

    // perFoto,
    // perExperiencia,
    // perFecVinculacion,
    // perFormaPago,
    // perRemuneracion,
    // perLugarTrabajo,
    // perAreaDepartamento,
    // perPuestoServicio,
    // perTipoCuenta,
    // perBancoRemuneracion,
    // perFecRetiro,
    // perBonos,
    // perEstado,
    // perTurno,
    // perCargo,
    // perTipoContrato,
    // perLicencias,

    // perCtaBancaria,
    // perEmpresa,

    // perPais,
    // perProvincia,
    // perCanton,
    // perObservacion,
    // perUser,
    // perFecReg,
    // Todos,
  } = item;
  const [cargando, setcargando] = useState(false);

  // eslint-disable-next-line

  // eslint-disable-next-line

  const [error, seterror] = useState({
    perNombre: false,

    perTelefono: false,
    perCelular: false,
    perDireccion: false,
    perEmail: false,
  });
  const [archivo, setarchivo] = useState("");
  const [imagenMostrada, setimagenMostrada] = useState(null);
  const onChangeFile = (e) => {
    if (!e.target.files[0]) {
      setimagenMostrada(null);
      return setarchivo("");
    }

    setarchivo(e.target.files[0]);
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = (e) => {
      e.preventDefault();
      setimagenMostrada(e.target.result);
    };
  };
  useEffect(() => {
    const getDataState = async () => {
      const res = await clienteAxios.get(
        `/proveedores/${usuario.id ? usuario.id : usuario.perId}`,
        {
          ...item,
          newpassword,
        }
      );

      setitem({
        ...res.data.data[0],

        perEmail:
          typeof res.data.data[0].perEmail === "string"
            ? []
            : res.data.data[0].perEmail,
      });
    };
    getDataState();
    // eslint-disable-next-line
  }, [open]);

  const defaultActive = () => {
    setitem({
      perNombre: "",
      perTelefono: "0",
      perCelular: ["+593"],
      perDireccion: "",
      perEmail: ["sin@sincorreo.com"],
    });
    setopen(false);

    seterror({
      perNombre: false,

      perTelefono: false,
      perCelular: false,
      perDireccion: false,
      perEmail: false,
    });
    setverpass(false);
    setnewpassword("");

    setcargando(false);
  };
  // eslint-disable-next-line
  const handleChange = (e) => {
    setitem({
      ...item,
      [e.target.name]: e.target.value,
    });
  };

  //hook de estilos
  const clases = useStyles();
  //definiendo los estilos del modal

  const [modalStyle] = useState(getModalStyle);
  // obteniendo los años

  const submit = () => {
    if (true) {
      return false;
    } else {
      return true;
    }
  };
  // eslint-disable-next-line
  const handleCrear = async () => {
    if (
      perNombre.trim() === "" ||
      perCelular.length === 0 ||
      perDireccion.trim() === "" ||
      perEmail.length === 0 ||
      perEmail.some((email) => {
        return validarEmail(email);
      })
    ) {
      return seterror({
        perNombre: perNombre.trim() === "" ? true : false,
        perCelular: perCelular.length === 0 ? true : false,
        perDireccion: perDireccion.trim() === "" ? true : false,
        perEmail:
          perEmail.length === 0 ||
          perEmail.some((email) => {
            return validarEmail(email);
          })
            ? true
            : false,
      });
    }
    try {
      setcargando(true);
      let resFoto = null;
      if (archivo !== "") {
        const formDataLogos = new FormData();
        formDataLogos.append("tipo", "fotoperfil");
        formDataLogos.append("rucempresa", usuario.rucempresa);

        await clienteAxios.post("/upload_delete_multiple_files/delete", {
          urls: [item.perFoto],
        });
        formDataLogos.append("archivo", archivo);
        resFoto = await clienteAxios.post(
          "/upload_delete_multiple_files/upload",
          formDataLogos
        );
      }

      await clienteAxios.put(
        `/proveedores/${usuario.id ? usuario.id : usuario.perId}`,
        {
          ...item,
          perFoto: resFoto ? resFoto.data.nombre : item.perFoto,
          newpassword,
        }
      );

      cerrarSesion();

      mostrarAlerta("Usuario editado.", "success");
      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);
      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  //filas por pagina

  // eslint-disable-next-line

  // 1 + 2 + 3 + 4
  if (!open) {
    return null;
  }
  if (!item) {
    return null;
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Draggable handle="#handle">
          <Box display="flex" justifyContent="center">
            <div style={modalStyle} className={clases.paper}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography
                  style={{ cursor: "-webkit-grab" }}
                  id="handle"
                  variant="button"
                  color="initial"
                  align="center"
                >
                  Editar Perfil.
                </Typography>
                <Box display="flex" alignItems="center">
                  <IconButton
                    aria-label=""
                    style={{ margin: "0px" }}
                    onClick={() => {
                      defaultActive();
                    }}
                  >
                    <Cancel color="secondary" />
                  </IconButton>
                </Box>
              </Box>
              <Divider />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems=" stretch"
                width="100%"
              >
                {/* <Box>
                <Button
                  variant={DatosGenerales ? "contained" : null}
                  disableElevation
                  style={{ padding: "8px" }}
                  color="secondary"
                  onClick={() => setseccionActive({ DatosGenerales: true })}
                >
                  Datos Generales
                </Button>
                <Button
                  variant={DatosLaborales ? "contained" : null}
                  disableElevation
                  style={{ padding: "8px" }}
                  color="secondary"
                  onClick={() => setseccionActive({ DatosLaborales: true })}
                >
                  Datos Laborales
                </Button>
                <Button
                  variant={HorarioIncapaciad ? "contained" : null}
                  disableElevation
                  style={{ padding: "8px" }}
                  color="secondary"
                  onClick={() => setseccionActive({ HorarioIncapaciad: true })}
                >
                  Horarios e Incapaciad
                </Button>
              </Box>

              <FormControlLabel
                control={
                  <Switch
                    checked={perEstado === "ACTIVO" ? true : false}
                    onChange={(e) => {
                      setitem({
                        ...item,
                        perEstado: e.target.checked ? "ACTIVO" : "INACTIVO",
                      });
                    }}
                    name="antoine"
                  />
                }
                label="Estado"
              /> */}
              </Box>
              <Divider />
              <Box
                display="flex"
                flexWrap="wrap"
                alignItems="flex-end"
                borderRadius="0px 50px 50px 0px"
                p={1}
              >
                <>
                  <Box
                    display="flex"
                    justifyContent="center"
                    width="100%"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <input
                      id="contained-button-file"
                      className={clases.ocultar}
                      type="file"
                      name="myImage"
                      onChange={onChangeFile}
                    />

                    {/* //si un archivo exoste  */}
                    {archivo ? (
                      <Box
                        display="flex"
                        flexDirection="column"
                        className={clases.center}
                      >
                        <label htmlFor="contained-button-file">
                          <Avatar
                            htmlFor="contained-button-file"
                            alt="Remy Sharp"
                            src={imagenMostrada}
                            className={clases.large}
                          />
                        </label>
                        <Typography
                          variant="body1"
                          color="initial"
                          align="center"
                        >
                          {archivo.name}
                        </Typography>
                      </Box>
                    ) : (
                      <Box
                        display="flex"
                        flexDirection="column"
                        className={clases.center}
                      >
                        <label htmlFor="contained-button-file">
                          <Avatar
                            htmlFor="contained-button-file"
                            alt="Logo"
                            src={item.perFoto}
                            className={clases.large}
                          />
                        </label>
                        <Typography
                          variant="body1"
                          color="initial"
                          align="center"
                        >
                          No se ha seleccionado ningun archivo
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </>
                <TextField
                  inputProps={{ className: clases.inputPadding }}
                  className={clases.formD}
                  size="small"
                  id="perNombre"
                  label="Nombres"
                  value={perNombre}
                  name="perNombre"
                  error={error.perNombre}
                  onChange={(e) => handleChange(e)}
                />

                <TextField
                  type="number"
                  inputProps={{ className: clases.inputPadding }}
                  className={clases.formD}
                  size="small"
                  id="perTelefono"
                  label="Teléfono"
                  value={perTelefono}
                  name="perTelefono"
                  error={error.perTelefono}
                  onChange={(e) => handleChange(e)}
                />
                <TextField
                  inputProps={{ className: clases.inputPadding }}
                  className={clases.formD}
                  size="small"
                  id="perDireccion"
                  label="Dirección"
                  value={perDireccion}
                  name="perDireccion"
                  error={error.perDireccion}
                  onChange={(e) => handleChange(e)}
                />
                <FormControl className={clases.formAllW}>
                  <Box display="flex" alignItems="center">
                    <Typography
                      variant="subtitle1"
                      color={error.perCelular ? "error" : "primary"}
                    >
                      Celulares:
                    </Typography>
                    <IconButton
                      aria-label=""
                      size="small"
                      onClick={async () => {
                        setitem({
                          ...item,
                          perCelular: [...item.perCelular, ""],
                        });
                      }}
                    >
                      <AddCircle fontSize="small" color="secondary" />
                    </IconButton>
                  </Box>
                </FormControl>
                {perCelular.map((email, index) => {
                  return (
                    <MuiPhoneNumber
                      defaultCountry={"ec"}
                      type="email"
                      key={index}
                      className={clases.correo}
                      value={email}
                      autoComplete="false"
                      onChange={(e) => {
                        setitem({
                          ...item,
                          perCelular: perCelular.map((item, indexItem) => {
                            if (indexItem === index) {
                              return e;
                            } else {
                              return item;
                            }
                          }),
                        });
                      }}
                      label={`Celular #${index + 1}`}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment>
                            <IconButton
                              aria-label=""
                              onClick={() => {
                                setitem({
                                  ...item,
                                  perCelular: perCelular.filter(
                                    (item, indexItem) => {
                                      return indexItem !== index;
                                    }
                                  ),
                                });
                              }}
                            >
                              <Delete color="error" />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  );
                })}
                <FormControl className={clases.formAllW}>
                  <Box display="flex" alignItems="center">
                    <Typography
                      variant="subtitle1"
                      color={error.perEmail ? "error" : "primary"}
                    >
                      Correos:
                    </Typography>
                    <IconButton
                      aria-label=""
                      size="small"
                      onClick={async () => {
                        setitem({
                          ...item,
                          perEmail: [...item.perEmail, ""],
                        });
                      }}
                    >
                      <AddCircle fontSize="small" color="secondary" />
                    </IconButton>
                  </Box>
                </FormControl>
                {perEmail.map((email, index) => {
                  return (
                    <TextField
                      type="email"
                      key={index}
                      className={clases.correo}
                      value={email}
                      autoComplete="false"
                      onChange={(e) => {
                        setitem({
                          ...item,
                          perEmail: perEmail.map((item, indexItem) => {
                            if (indexItem === index) {
                              return e.target.value;
                            } else {
                              return item;
                            }
                          }),
                        });
                      }}
                      label={`Correo #${index + 1}`}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment>
                            <IconButton
                              aria-label=""
                              onClick={() => {
                                setitem({
                                  ...item,
                                  perEmail: perEmail.filter(
                                    (item, indexItem) => {
                                      return indexItem !== index;
                                    }
                                  ),
                                });
                              }}
                            >
                              <Delete color="error" />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  );
                })}
                <TextField
                  type={verpass ? "text" : "password"}
                  inputProps={{ className: clases.inputPadding }}
                  className={clases.formD}
                  size="small"
                  label="Nueva Contraseña"
                  value={newpassword}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        {verpass ? (
                          <IconButton
                            aria-label=""
                            onClick={() => setverpass(!verpass)}
                          >
                            <Visibility color="secondary" />
                          </IconButton>
                        ) : (
                          <IconButton
                            aria-label=""
                            onClick={() => setverpass(!verpass)}
                          >
                            <VisibilityOff color="secondary" />
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => setnewpassword(e.target.value)}
                />
              </Box>
              {/* <Divider /> */}
              <Box
                display="flex"
                width="100%"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="flex-end"
                  mt={1}
                >
                  <Button
                    style={{ width: "30%" }}
                    variant="contained"
                    color="primary"
                    disabled={submit() || cargando}
                    onClick={() => {
                      handleCrear();
                    }}
                  >
                    Guardar
                  </Button>
                </Box>
              </Box>
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default memo(Perfil);
