import { Box, Button, FormControl, FormLabel } from "@mui/material";
import React, { memo } from "react";
import { useContext } from "react";
import "react-quill/dist/quill.snow.css";
import AlertaContext from "../../Context/Alerta/AlertaContext";
import { makeStyles } from "@mui/styles";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useState } from "react";
import { useEffect } from "react";
import "@ckeditor/ckeditor5-build-classic/build/translations/es";
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  hover: {
    "&:hover": {
      opacity: ".7",
      cursor: "pointer",
    },
  },
  celda: {
    fontSize: ".7rem !important",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));

const Editor = (props) => {
  const { label, propiedad, item, setitem } = props;
  const { mostrarAlerta } = useContext(AlertaContext);
  const clases = useStyles();
  const [data, setdata] = useState("");

  const quitarEntidades = (str) => {
    return str
      .replace(/&nbsp;/g, " ")
      .replace(/&amp;/g, "&")
      .replace(/&lt;/g, "<")
      .replace(/&gt;/g, ">")
      .replace(/&quot;/g, '"')
      .replace(/&#39;/g, "'")
      .replace(/&aacute;/g, "á")
      .replace(/&eacute;/g, "é")
      .replace(/&iacute;/g, "í")
      .replace(/&oacute;/g, "ó")
      .replace(/&uacute;/g, "ú")
      .replace(/&Aacute;/g, "Á")
      .replace(/&Eacute;/g, "É")
      .replace(/&Iacute;/g, "Í")
      .replace(/&Oacute;/g, "Ó")
      .replace(/&Uacute;/g, "Ú")
      .replace(/&ntilde;/g, "ñ")
      .replace(/&Ntilde;/g, "Ñ");
  };

  const onChange = async (data) => {
    // const newContent = await evt.editor.getData();
    await setdata(data);
    setitem((prev) => {
      return { ...prev, [propiedad]: quitarEntidades(data) };
    });
  };
  function generarTabla(datos) {
    const noMostrar = ["id", "order", "selected", "error", "editar"];
    if (datos.length === 0) {
      return "<table></table>";
    }
    let columnas = Object.keys(datos[0]);
    let tabla = "<table><tr>";
    for (let i = 0; i < columnas.length; i++) {
      if (!noMostrar.some((propiedad) => propiedad === columnas[i])) {
        tabla += "<th>" + columnas[i] + "</th>";
      }
    }
    tabla += "</tr>";
    for (let i = 0; i < datos.length; i++) {
      tabla += "<tr>";
      for (let j = 0; j < columnas.length; j++) {
        if (!noMostrar.some((propiedad) => propiedad === columnas[j])) {
          tabla += "<td>" + datos[i][columnas[j]] + "</td>";
        }
      }
      tabla += "</tr>";
    }
    tabla += "</table>";
    return tabla;
  }
  const convetirTabla = (dataConver) => {
    let [texto, tabla] = dataConver.split(`[{"order":`);
    if (!tabla) {
      return texto;
    }
    tabla = `[{"order":` + tabla;
    return texto + generarTabla(JSON.parse(tabla));
  };
  useEffect(() => {
    setdata(convetirTabla(item[propiedad]));
    // eslint-disable-next-line
  }, []);

  return (
    <FormControl fullWidth className={clases.formAllW}>
      <Box
        display={"flex"}
        justifyContent="space-between"
        alignItems={"center"}
      >
        <FormLabel>{label}</FormLabel>
        <Button
          color="secondary"
          variant="outlined"
          onClick={async () => {
            if (!navigator.clipboard) {
              return mostrarAlerta(
                "La API de Clipboard no es compatible con este navegador",
                "error"
              );
            }
            navigator.clipboard
              .readText()
              .then((text) => {
                const regex = /<[^>]*>/g;

                if (!regex.test(text)) {
                  return mostrarAlerta(
                    "El texto copiado no tiene etiquetas HTML, por lo cual oprima CTRL + V para pegar el texto directamente.",
                    "error"
                  );
                }
                setitem({ ...item, [propiedad]: text });
                setdata(text);
                // Aquí puedes hacer lo que quieras con el texto
              })
              .catch((err) => {
                return mostrarAlerta("Error al leer el portapapeles:", "error");
              });
          }}
        >
          Pegar texto con etiquetas
        </Button>
      </Box>

      <CKEditor
        editor={ClassicEditor}
        config={{
          language: "es",
        }}
        data={data}
        onChange={(event, editor) => {
          const data = editor.getData();
          onChange(data);
          // Maneja los cambios aquí
        }}
      />
      {/* <ReactQuill
        theme="snow"
    
        value={item[propiedad]}
        onChange={(e) => {
          setitem({ ...item, [propiedad]: e });
        }}
      /> */}
    </FormControl>
  );
};

export default memo(Editor);
