import { Button } from "@mui/material";
import { useModalStyle } from "../../../../styles/styleModal";

export const TabsDinamicos = ({
  tabsDinamicos,
  setseccionActive,
  seccionActive,
  error,
}) => {
  const clases = useModalStyle();
  return tabsDinamicos.map((tab) => (
    <Button
      key={tab.id}
      variant={seccionActive[tab.nombreTab] ? "contained" : "outlined"}
      disableElevation
      className={clases.buttonTab}
      color={error[tab.id] ? "error" : "primary"}
      onClick={() => setseccionActive({ [tab.nombreTab]: true })}
    >
      {tab.nombreTab}
    </Button>
  ));
};
