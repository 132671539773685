import React, { memo, useContext } from "react";
import { useState } from "react";

import AuthContext from "../../../../Context/Auth/AuthContext";
import { replaceCaracteres, trimPropsItem } from "../../../../config/const";

// MATERIAL
import { makeStyles } from "@mui/styles";
import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Cancel, Check } from "@mui/icons-material";
const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  margin: {
    margin: "0px",
    padding: "2px",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));

const RowAgregar = (props) => {
  const { setagregar, socket } = props;
  const { usuario } = useContext(AuthContext);
  const [item, setitem] = useState({
    cuadUsuario: usuario.usuario,
    cuadfecha: `${new Date().getFullYear()}-${(
      "0" +
      (new Date().getMonth() + 1)
    ).slice(-2)}-${("0" + new Date().getDate()).slice(-2)}`,
    cuadMoneda1c: "0",
    cuadMoneda5c: "0",
    cuadMoneda10c: "0",
    cuadMoneda25c: "0",
    cuadMoneda50c: "0",
    cuadMoneda1d: "0",
    cuadMoneda5d: "0",
    cuadMoneda10d: "0",
    cuadMoneda20d: "0",
    cuadMoneda50d: "0",
    cuadMoneda100d: "0",
    cuadTotal: "",
    cuadTotalCaja: "",
    cuadDiferencia: "",
    cuadEmpresa: "",
    cuadUser: "",
    cuadFecReg: "",
    Todos: "",
  });

  const classes = useRowStyles();
  //gql eliminar user

  const handleAgregar = async () => {
    try {
      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...item,
          tabla: "cuadrecaja",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          cuadUser: usuario.usuario,
          cuadEmpresa: usuario.rucempresa,
        })
      );
    } catch (error) {}
  };
  const botonDisabled = () => {
    if (
      item.cuadMoneda1c === "0" &&
      item.cuadMoneda5c === "0" &&
      item.cuadMoneda10c === "0" &&
      item.cuadMoneda25c === "0" &&
      item.cuadMoneda50c === "0" &&
      item.cuadMoneda1d === "0" &&
      item.cuadMoneda5d === "0" &&
      item.cuadMoneda10d === "0" &&
      item.cuadMoneda20d === "0" &&
      item.cuadMoneda50d === "0" &&
      item.cuadMoneda100d === "0"
    ) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell align="center" padding="none">
          <Typography
            variant="subtitle1"
            color="primary"
            align="center"
            component={"span"}
          >
            {" Nuevo "}
          </Typography>
        </TableCell>{" "}
        {/* boton */}
        <TableCell>
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            alignItems="center"
          >
            <IconButton
              className={classes.margin}
              onClick={() => {
                setagregar(false);
                setitem({
                  cuadUsuario: usuario.usuario,
                  cuadfecha: `${new Date().getFullYear()}-${(
                    "0" +
                    (new Date().getMonth() + 1)
                  ).slice(-2)}-${("0" + new Date().getDate()).slice(-2)}`,
                  cuadMoneda1c: "0",
                  cuadMoneda5c: "0",
                  cuadMoneda10c: "0",
                  cuadMoneda25c: "0",
                  cuadMoneda50c: "0",
                  cuadMoneda1d: "0",
                  cuadMoneda5d: "0",
                  cuadMoneda10d: "0",
                  cuadMoneda20d: "0",
                  cuadMoneda50d: "0",
                  cuadMoneda100d: "0",
                  cuadTotal: "",
                  cuadTotalCaja: "",
                  cuadDiferencia: "",
                  cuadEmpresa: "",
                  cuadUser: "",
                  cuadFecReg: "",
                  Todos: "",
                });
              }}
            >
              <Cancel fontSize="small" color="secondary" />
            </IconButton>
            <IconButton
              color={
                botonDisabled
                  ? botonDisabled()
                    ? "inherit"
                    : "secondary"
                  : "secondary"
              }
              disabled={botonDisabled()}
              className={classes.margin}
              onClick={() => {
                handleAgregar();

                setagregar(false);
                setitem({
                  cuadUsuario: usuario.usuario,
                  cuadfecha: `${new Date().getFullYear()}-${(
                    "0" +
                    (new Date().getMonth() + 1)
                  ).slice(-2)}-${("0" + new Date().getDate()).slice(-2)}`,
                  cuadMoneda1c: "0",
                  cuadMoneda5c: "0",
                  cuadMoneda10c: "0",
                  cuadMoneda25c: "0",
                  cuadMoneda50c: "0",
                  cuadMoneda1d: "0",
                  cuadMoneda5d: "0",
                  cuadMoneda10d: "0",
                  cuadMoneda20d: "0",
                  cuadMoneda50d: "0",
                  cuadMoneda100d: "0",
                  cuadTotal: "",
                  cuadTotalCaja: "",
                  cuadDiferencia: "",
                  cuadEmpresa: "",
                  cuadUser: "",
                  cuadFecReg: "",
                  Todos: "",
                });
              }}
            >
              <Check fontSize="small" />
            </IconButton>{" "}
            {/* <FormControlLabel
              className={classes.margin}
              onChange={(e) => {
                setitem({
                  ...item,
                  cajaEstado: e.target.checked ? "ACTIVA" : "INACTIVA",
                });
              }}
              control={
                <Checkbox
                  value="ACTIVO"
                  className={classes.margin}
                  checked={
                    item["cajaEstado"] === "ACTIVA" ||
                    item["cajaEstado"] === "ACTIVO"
                      ? true
                      : false
                  }
                  color="secondary"
                />
              }
            /> */}
          </Box>
        </TableCell>
        {/* las demas columnas */}
        <TableCell fontSize="small" padding="none">
          {" "}
          <TextField
            id="cuadUsuario"
            disabled
            value={item.cuadUsuario}
            inputProps={{ style: { textAlign: "center", fontSize: ".7rem" } }}
            onChange={(e) => {
              const res = replaceCaracteres(e.target.value);
              setitem({
                ...item,
                cuadUsuario: res,
              });
            }}
            size="small"
            fullWidth
          />
        </TableCell>
        <TableCell fontSize="small" padding="none">
          {" "}
          <TextField
            id="cuadfecha"
            type="date"
            disabled
            value={item.cuadfecha}
            inputProps={{ style: { textAlign: "center", fontSize: ".7rem" } }}
            onChange={(e) => {
              const res = replaceCaracteres(e.target.value);
              setitem({
                ...item,
                cuadfecha: res,
              });
            }}
            size="small"
            fullWidth
          />
        </TableCell>
        <TableCell fontSize="small" padding="none">
          {" "}
          <TextField
            id="cuadMoneda1c"
            type="number"
            value={item.cuadMoneda1c}
            inputProps={{ style: { textAlign: "center", fontSize: ".7rem" } }}
            onChange={(e) => {
              const res = replaceCaracteres(e.target.value);
              setitem({
                ...item,
                cuadMoneda1c: res,
              });
            }}
            size="small"
            fullWidth
          />
        </TableCell>
        <TableCell fontSize="small" padding="none">
          {" "}
          <TextField
            id="cuadMoneda5c"
            type="number"
            value={item.cuadMoneda5c}
            inputProps={{ style: { textAlign: "center", fontSize: ".7rem" } }}
            onChange={(e) => {
              const res = replaceCaracteres(e.target.value);
              setitem({
                ...item,
                cuadMoneda5c: res,
              });
            }}
            size="small"
            fullWidth
          />
        </TableCell>
        <TableCell fontSize="small" padding="none">
          {" "}
          <TextField
            id="cuadMoneda10c"
            type="number"
            value={item.cuadMoneda10c}
            inputProps={{ style: { textAlign: "center", fontSize: ".7rem" } }}
            onChange={(e) => {
              const res = replaceCaracteres(e.target.value);
              setitem({
                ...item,
                cuadMoneda10c: res,
              });
            }}
            size="small"
            fullWidth
          />
        </TableCell>
        <TableCell fontSize="small" padding="none">
          {" "}
          <TextField
            id="cuadMoneda25c"
            type="number"
            value={item.cuadMoneda25c}
            inputProps={{ style: { textAlign: "center", fontSize: ".7rem" } }}
            onChange={(e) => {
              const res = replaceCaracteres(e.target.value);
              setitem({
                ...item,
                cuadMoneda25c: res,
              });
            }}
            size="small"
            fullWidth
          />
        </TableCell>
        <TableCell fontSize="small" padding="none">
          {" "}
          <TextField
            id="cuadMoneda50c"
            type="number"
            value={item.cuadMoneda50c}
            inputProps={{ style: { textAlign: "center", fontSize: ".7rem" } }}
            onChange={(e) => {
              const res = replaceCaracteres(e.target.value);
              setitem({
                ...item,
                cuadMoneda50c: res,
              });
            }}
            size="small"
            fullWidth
          />
        </TableCell>
        <TableCell fontSize="small" padding="none">
          {" "}
          <TextField
            id="cuadMoneda1d"
            type="number"
            value={item.cuadMoneda1d}
            inputProps={{ style: { textAlign: "center", fontSize: ".7rem" } }}
            onChange={(e) => {
              const res = replaceCaracteres(e.target.value);
              setitem({
                ...item,
                cuadMoneda1d: res,
              });
            }}
            size="small"
            fullWidth
          />
        </TableCell>
        <TableCell fontSize="small" padding="none">
          {" "}
          <TextField
            id="cuadMoneda5d"
            type="number"
            value={item.cuadMoneda5d}
            inputProps={{ style: { textAlign: "center", fontSize: ".7rem" } }}
            onChange={(e) => {
              const res = replaceCaracteres(e.target.value);
              setitem({
                ...item,
                cuadMoneda5d: res,
              });
            }}
            size="small"
            fullWidth
          />
        </TableCell>
        <TableCell fontSize="small" padding="none">
          {" "}
          <TextField
            id="cuadMoneda10d"
            type="number"
            value={item.cuadMoneda10d}
            inputProps={{ style: { textAlign: "center", fontSize: ".7rem" } }}
            onChange={(e) => {
              const res = replaceCaracteres(e.target.value);
              setitem({
                ...item,
                cuadMoneda10d: res,
              });
            }}
            size="small"
            fullWidth
          />
        </TableCell>
        <TableCell fontSize="small" padding="none">
          {" "}
          <TextField
            id="cuadMoneda20d"
            type="number"
            value={item.cuadMoneda20d}
            inputProps={{ style: { textAlign: "center", fontSize: ".7rem" } }}
            onChange={(e) => {
              const res = replaceCaracteres(e.target.value);
              setitem({
                ...item,
                cuadMoneda20d: res,
              });
            }}
            size="small"
            fullWidth
          />
        </TableCell>
        <TableCell fontSize="small" padding="none">
          {" "}
          <TextField
            id="cuadMoneda50d"
            type="number"
            value={item.cuadMoneda50d}
            inputProps={{ style: { textAlign: "center", fontSize: ".7rem" } }}
            onChange={(e) => {
              const res = replaceCaracteres(e.target.value);
              setitem({
                ...item,
                cuadMoneda50d: res,
              });
            }}
            size="small"
            fullWidth
          />
        </TableCell>
        <TableCell fontSize="small" padding="none">
          {" "}
          <TextField
            id="cuadMoneda100d"
            type="number"
            value={item.cuadMoneda100d}
            inputProps={{ style: { textAlign: "center", fontSize: ".7rem" } }}
            onChange={(e) => {
              const res = replaceCaracteres(e.target.value);
              setitem({
                ...item,
                cuadMoneda100d: res,
              });
            }}
            size="small"
            fullWidth
          />
        </TableCell>
        <TableCell fontSize="small" padding="none">
          {" "}
          <TextField
            id="cuadTotal"
            disabled
            value={item.cuadTotal}
            inputProps={{ style: { textAlign: "center", fontSize: ".7rem" } }}
            onChange={(e) => {
              const res = replaceCaracteres(e.target.value);
              setitem({
                ...item,
                cuadTotal: res,
              });
            }}
            size="small"
            fullWidth
          />
        </TableCell>
        <TableCell fontSize="small" padding="none">
          {" "}
          <TextField
            id="cuadTotalCaja"
            disabled
            value={item.cuadTotalCaja}
            inputProps={{ style: { textAlign: "center", fontSize: ".7rem" } }}
            onChange={(e) => {
              const res = replaceCaracteres(e.target.value);
              setitem({
                ...item,
                cuadTotalCaja: res,
              });
            }}
            size="small"
            fullWidth
          />
        </TableCell>
        <TableCell fontSize="small" padding="none">
          {" "}
          <TextField
            id="cuadDiferencia"
            disabled
            value={item.cuadDiferencia}
            inputProps={{ style: { textAlign: "center", fontSize: ".7rem" } }}
            onChange={(e) => {
              const res = replaceCaracteres(e.target.value);
              setitem({
                ...item,
                cuadDiferencia: res,
              });
            }}
            size="small"
            fullWidth
          />
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default memo(RowAgregar);
