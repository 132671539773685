import React, { useState, useContext, useEffect, memo } from "react";
import {
  Modal,
  Box,
  Button,
  Divider,
  Typography,
  TextField,
  FormControl,
  Autocomplete,
  Tooltip,
} from "@mui/material";

import Draggable from "react-draggable";
import { Cancel, Clear } from "@mui/icons-material";
import { IconButton } from "@mui/material";
// eslint-disable-next-line
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import AuthContext from "../../../../Context/Auth/AuthContext";
import { trimPropsItem } from "../../../../config/const";
import clienteAxios from "../../../../config/axios";
import ConfirmacionDialog from "../../../../components/Extra/ConfirmacionDialog";
import { usePermiso } from "../../../../hooks/usePermiso";
import "react-quill/dist/quill.snow.css";
import Editor from "../../../../components/Extra/Editor";
import { useModalStyle } from "../../../../styles/styleModal";
import { useDynamicForm } from "../../../../hooks/useDinamicForm";
import CampoDoc from "../../../components/Campos/CampoDoc";
import { TabsDinamicos } from "../../../components/Modal/CamposDinamicos/TabsDinamicos";
import { CamposDinamicos } from "../../../components/Modal/CamposDinamicos/CamposDinamicos";
function getModalStyle() {
  return {
    borderRadius: "15px",
    position: "absolute",
    overflow: "scroll",
    maxHeight: "95vh",
  };
}

const ModalData = (props) => {
  const { tienePermiso, alertaPermiso } = usePermiso("HistorialClinica");
  const { mostrarAlerta } = useContext(AlertaContext);
  const { usuario } = useContext(AuthContext); //props
  const {
    open,
    setopen,
    editar,
    seteditar,
    ObjectoActivo,
    changeObjetoActivoState,
    socket,
    setopenReceta,
    changeObjetoActivoStateReceta,
    ObjectoActivoReceta,
  } = props;
  const { tabsDinamicos, onValidacionCamposDinamicos } = useDynamicForm({
    tabla: "HISTORIA_CLINICA",
    propiedad: "hcliCamposDinamicos",
  });
  // eslint-disable-next-line
  const [item, setitem] = useState(ObjectoActivo);
  const {
    hcliMascId,
    hcliMascNombre,
    hcliPerId,
    // hcliPerNombre,
    hcliPerIdVetInt,
    hcliPerNombreVetInt,
    // hcliPerIdVetExt,
    hcliPerNombreVetExt,
    hcliFecha,
    hcliMotiConsulMedica,
    hcliPeso,
    hcliTemperatura,
    hcliHidratacion,
    hcliFrecuCardiaca,
    hcliPulso,
    hcliFrecuRespiratoria,
    hcliGeneral,
    hcliFoong,
    hcliTp,
    hcliMe,
    hcliCardioVascular,
    hcliRespiratorio,
    hcliGastroIntestinal,
    hcliGr,
    hcliNeurologico,
    hcliLinfatico,
    hcliPatronRespiratorio,
    hcliGlasgowModificado,
    hcliRitmoCardiaco,
    hcliReflejoPupilar,
    hcliReflejoTusigeno,
    hcliTLlenadoVascular,
    hcliColorMocosas,
    hcliAmigdalas,
    hcliDolorPaciente,
    hcliLesiones,
    hcliPasPamPad,
    hcliOtros,
    hcliTratamiento,
    // hcliArchivo,
    // hcliFoto,
    // hcliUser,
    // hcliEmpresa,
    // hcliDescripcion,
    // hcliFecReg,
  } = item;
  const [propsMascota, setpropsMascota] = useState({
    recMascRaza: "",
    recMascSexo: "",
    recMascEdad: "",
  });
  const [personasData, setpersonasData] = useState([]);
  const [mascotasData, setmascotasData] = useState([]);

  const handleChange = (e) => {
    setitem({ ...item, [e.target.name]: e.target.value });
  };
  const [cargando, setcargando] = useState(false);
  const [seccionActive, setseccionActive] = useState({
    historiaSeccion: true,
    check1Seccion: false,
    check2Seccion: false,
    fotoSeccion: false,
  });
  const {
    historiaSeccion,
    check1Seccion,
    check2Seccion,
    fotoSeccion,
  } = seccionActive;
  const [error, seterror] = useState({});
  const [motivosData, setmotivosData] = useState(null);
  // GET => /api/sexo/filtro/0
  useEffect(() => {
    if (!motivosData) {
      const getresmotivosData = async () => {
        try {
          const resmotivosData = await clienteAxios.get(
            `/consultasmedicas/filtro/0`
          );

          setmotivosData(resmotivosData.data.data);
        } catch (error) {
          setmotivosData([]);
        }
      };
      getresmotivosData();
    }

    setitem(ObjectoActivo); // eslint-disable-next-line
  }, [open]);
  const defaultActive = () => {
    changeObjetoActivoState({
      hcliPerId: "",
      hcliPerNombre: "",
      hcliPerDocumento: "",
      hcliMascId: "",
      hcliMascNombre: "",
      hcliPerIdVetInt: "",
      hcliPerNombreVetInt: "",
      hcliPerIdVetExt: "",
      hcliPerNombreVetExt: "",
      hcliFecha: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60 * 1000
      )
        .toISOString()
        .substring(0, 10),
      hcliMotiConsulMedica: "",
      hcliPeso: "",
      hcliTemperatura: "",
      hcliHidratacion: "",
      hcliFrecuCardiaca: "",
      hcliPulso: "",
      hcliFrecuRespiratoria: "",
      hcliGeneral: "",
      hcliFoong: "",
      hcliTp: "",
      hcliMe: "",
      hcliCardioVascular: "",
      hcliRespiratorio: "",
      hcliGastroIntestinal: "",
      hcliGr: "",
      hcliNeurologico: "",
      hcliLinfatico: "",
      hcliPatronRespiratorio: "",
      hcliGlasgowModificado: "",
      hcliRitmoCardiaco: "",
      hcliReflejoPupilar: "",
      hcliReflejoTusigeno: "",
      hcliTLlenadoVascular: "",
      hcliColorMocosas: "",
      hcliAmigdalas: "",
      hcliDolorPaciente: "",
      hcliLesiones: "",
      hcliPasPamPad: "",
      hcliOtros: "",
      hcliTratamiento: "",
      hcliArchivo: "",
      hcliFoto: "",
      hcliUser: "",
      hcliEmpresa: "",
      hcliDescripcion: "",
      hcliFecReg: "",
      hcliCamposDinamicos: {},
    });
    setopen(false);
    seteditar(false);
    seterror({});
    setcargando(false);
  };

  //hook de estilos
  const clases = useModalStyle();
  //definiendo los estilos del modal
  const [modalStyle] = useState(getModalStyle);
  const validacion = () => {
    const {
      error,
      errorTab,
      tienePropiedadVerdadera,
    } = onValidacionCamposDinamicos(item);
    if (
      hcliMascNombre.trim() === "" ||
      hcliPerNombreVetInt.trim() === "" ||
      hcliMotiConsulMedica.trim() === "" ||
      hcliPeso === "" ||
      tienePropiedadVerdadera
    ) {
      seterror({
        ...error,
        ...errorTab,
        hcliMascNombre: hcliMascNombre.trim() === "" ? true : false,
        hcliPerNombreVetInt: hcliPerNombreVetInt.trim() === "" ? true : false,
        hcliMotiConsulMedica: hcliMotiConsulMedica.trim() === "" ? true : false,
        hcliPeso: hcliPeso === "" ? true : false,
      });

      return true;
    } else {
      return false;
    }
  };

  const handleCrear = async () => {
    try {
      setcargando(true);
      let resFoto = null;

      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...item,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          tabla: "historiaclinica",
          hcliUser: usuario.usuario,
          hcliEmpresa: usuario.rucempresa,
          hcliFoto: resFoto ? resFoto.data.nombre : item.hcliFoto,
        })
      );
      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);
      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  const handleEditar = async () => {
    try {
      setcargando(true);
      let resFoto = null;

      socket.current.emit(
        "client:actualizarData",
        trimPropsItem({
          ...item,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          tabla: "historiaclinica",
          hcliUser: `${item.hcliUser.split(" ** ")[0]} ** ${usuario.usuario}`,
          hcliEmpresa: usuario.rucempresa,
          hcliFoto: resFoto ? resFoto.data.nombre : item.hcliFoto,
        })
      );
      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);
      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  //filas por pagina

  // 1 + 2 + 3 + 4
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  const funcionNo = () => {
    handleCrear();
  };
  const funcionSi = async () => {
    const res = await clienteAxios(`/proveedores/${hcliPerId}`);

    if (res.data.data.length === 0) {
      return mostrarAlerta(
        "Hubo un error en la consulta del propietario",
        "error"
      );
    }

    const propietario = res.data.data[0];
    changeObjetoActivoStateReceta({
      ...ObjectoActivoReceta,
      recMascId: hcliMascId,
      recMascNombre: hcliMascNombre,
      recMascRaza: propsMascota.recMascRaza,
      recMascSexo: propsMascota.recMascSexo,
      recMascEdad: propsMascota.recMascEdad,
      recPerIdDoc: hcliPerIdVetInt,
      recPerNombreDoc: hcliPerNombreVetInt,
      recPerNombrePropietario: propietario.perNombre,
      recPerIdPropietario: propietario.perId,
      recPerTelefonoPropietario: propietario.perTelefono,
      recPerDocNumeroPropietario: propietario.perDocNumero,
      recPerDireccionPropietario: propietario.perDireccion,
      recPerCelularPropietario: propietario.perCelular,
      recPerEmailPropietario: propietario.perEmail,
    });
    setopenReceta(true);

    handleCrear();
  };
  if (!open) {
    return null;
  }
  if (!motivosData) {
    return null;
  }
  if (!tabsDinamicos) {
    return null;
  }
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <ConfirmacionDialog
          open={openConfirmDialog}
          setopen={setopenConfirmDialog}
          titulo={`¿Desea crear una receta?`}
          categoria={"success"}
          funcionNo={() => funcionNo()}
          funcion={() => funcionSi()}
        />
        <Draggable handle="#handle">
          <Box display="flex" justifyContent="center">
            <div style={modalStyle} className={clases.paper}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography
                  style={{ cursor: "-webkit-grab" }}
                  id="handle"
                  variant="button"
                  color="initial"
                  align="center"
                >
                  {editar ? "Editar" : "Agregar"}
                  {" Historia clinica"}
                </Typography>
                <Box display="flex" alignItems="center">
                  <IconButton
                    aria-label=""
                    style={{ margin: "0px" }}
                    onClick={() => {
                      defaultActive();
                    }}
                  >
                    <Cancel color="secondary" />
                  </IconButton>
                </Box>
              </Box>
              <Divider />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems=" stretch"
                width="100%"
              >
                <Box display={"flex"} flexWrap={"wrap"}>
                  <Button
                    variant={historiaSeccion ? "contained" : "outlined"}
                    disableElevation
                    className={clases.buttonTab}
                    color={
                      error.hcliMascNombre ||
                      error.hcliPerNombreVetInt ||
                      error.hcliPerNombreVetExt ||
                      error.hcliFecha ||
                      error.hcliMotiConsulMedica ||
                      error.hcliPeso
                        ? "error"
                        : "secondary"
                    }
                    onClick={() => setseccionActive({ historiaSeccion: true })}
                  >
                    Historia
                  </Button>
                  {/* Peso   {hcliPeso} */}
                  {/* Temperatura   {hcliTemperatura} */}
                  {/* Hidratación   {hcliHidratacion} */}
                  {/* Frecuencia Cardiaca   {hcliFrecuCardiaca} */}
                  {/* Pulso   {hcliPulso} */}
                  {/* Frecuencia Respi.   {hcliFrecuRespiratoria} */}
                  {/* General   {hcliGeneral} */}
                  {/* Foong   {hcliFoong} */}
                  {/* TP   {hcliTp} */}
                  {/* ME   {hcliMe} */}
                  {/* Cardio Vascular   {hcliCardioVascular} */}
                  {/* Respiratorio   {hcliRespiratorio} */}
                  {/* Gastro Intestinal   {hcliGastroIntestinal} */}
                  {/* GR   {hcliGr} */}
                  {/* <Button
                    variant={check1Seccion ? "contained" : "outlined"}
                    disableElevation
                    className={clases.buttonTab}
                    color={"secondary"}
                    onClick={() => setseccionActive({ check1Seccion: true })}
                  >
                    Check 1
                  </Button> */}
                  {/* Neurologico {hcliNeurologico} */}
                  {/* Linfatico {hcliLinfatico} */}
                  {/* Patrón Respiratorio {hcliPatronRespiratorio} */}
                  {/* Glasgow Modificado {hcliGlasgowModificado} */}
                  {/* Ritmo Cardiaco {hcliRitmoCardiaco} */}
                  {/* Reflejo Pupilar {hcliReflejoPupilar} */}
                  {/* Reflejo Tusigeno {hcliReflejoTusigeno} */}
                  {/* T.llenado Vascular {hcliTLlenadoVascular} */}
                  {/* Color Mocosas {hcliColorMocosas} */}
                  {/* Amigdalas {hcliAmigdalas} */}
                  {/* Dolor Paciente {hcliDolorPaciente} */}
                  {/* Lesiones {hcliLesiones} */}
                  {/* PAS/PAM/PAD {hcliPasPamPad} */}
                  {/* Otros {hcliOtros} */}
                  {/* Tratamiento {hcliTratamiento} */}
                  {/* <Button
                    variant={check2Seccion ? "contained" : "outlined"}
                    disableElevation
                    className={clases.buttonTab}
                    color={"secondary"}
                    onClick={() => setseccionActive({ check2Seccion: true })}
                  >
                    Check 2
                  </Button> */}
                  <TabsDinamicos
                    tabsDinamicos={tabsDinamicos}
                    setseccionActive={setseccionActive}
                    seccionActive={seccionActive}
                    error={error}
                  />
                  <Button
                    variant={fotoSeccion ? "contained" : "outlined"}
                    disableElevation
                    className={clases.buttonTab}
                    color="secondary"
                    onClick={() => setseccionActive({ fotoSeccion: true })}
                  >
                    Archivo
                  </Button>
                </Box>

                {/* <FormControlLabel
                control={
                  <Switch
                    checked={perEstado === "ACTIVO" ? true : false}
                    onChange={(e) => {
                      setitem({
                        ...item,
                        perEstado: e.target.checked ? "ACTIVO" : "INACTIVO",
                      });
                    }}
                    name="antoine"
                  />
                }
                label="Estado"
              /> */}
              </Box>
              <Divider />

              <Box className={clases.container}>
                {historiaSeccion && (
                  <>
                    <FormControl fullWidth className={clases.formD}>
                      <Autocomplete
                        filterOptions={(x) => x}
                        options={mascotasData.map((item) => {
                          return (
                            item.mascNombre +
                            "/+/" +
                            item.mascPerNombre +
                            "/+/" +
                            item.mascId
                          );
                        })}
                        getOptionLabel={(option) => {
                          return option.replace("/+/", " - ").split("/+/")[0];
                        }}
                        id="controlled-demo"
                        value={hcliMascNombre}
                        paperprops
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            const options = newValue.split("/+/");
                            const itemF = mascotasData.find((itemPersona) => {
                              return itemPersona.mascId === Number(options[2]);
                            });

                            setpropsMascota({
                              recMascRaza: itemF.mascRaza,
                              recMascSexo: itemF.mascSexo,
                              recMascEdad: itemF.mascEdad,
                            });
                            setitem({
                              ...item,
                              hcliMascId: itemF.mascId,
                              hcliMascNombre: itemF.mascNombre,
                              hcliPerId: itemF.mascPerId,
                              hcliPerNombre: itemF.mascPerNombre,
                              hcliPerDocumento: itemF.mascPerDocNumero,
                            });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            error={error.hcliMascNombre}
                            label="Mascota"
                            fullWidth
                            onChange={async (e) => {
                              if (e.target.value === "") {
                                return;
                              }
                              try {
                                const res = await clienteAxios.get(
                                  `/mascotas/filtro/0?search=${e.target.value}`
                                );
                                if (!res.data.data) {
                                  return mostrarAlerta(res.data.msg, "error");
                                }

                                setmascotasData(res.data.data);
                              } catch (error) {}
                            }}
                            // InputProps={{
                            //   ...params.InputProps,
                            //   endAdornment: (
                            //     <React.Fragment>
                            //       {!editar ? (
                            //         <IconButton
                            //           size="small"
                            //           aria-label=""
                            //           onClick={async () => {
                            //             seteditarProveedor(false);
                            //             setopenProveedor(true);
                            //           }}
                            //         >
                            //           <AddCircleOutlineTwoTone color="primary" />
                            //         </IconButton>
                            //       ) : null}
                            //       {params.InputProps.endAdornment}
                            //     </React.Fragment>
                            //   ),
                            // }}
                          />
                        )}
                      />
                    </FormControl>
                    <FormControl fullWidth className={clases.formD}>
                      <Autocomplete
                        filterOptions={(x) => x}
                        options={personasData.map((item) => {
                          return item.perNombre + "/+/" + item.perId;
                        })}
                        getOptionLabel={(option) => {
                          return option.split("/+/")[0];
                        }}
                        id="controlled-demo"
                        value={hcliPerNombreVetInt}
                        paperprops
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            const options = newValue.split("/+/");
                            const itemF = personasData.find((itemPersona) => {
                              return itemPersona.perId === Number(options[1]);
                            });

                            setitem({
                              ...item,
                              hcliPerNombreVetInt: itemF.perNombre,
                              hcliPerIdVetInt: itemF.perId,
                            });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            error={error.hcliPerNombreVetInt}
                            label="Vet. Interno"
                            fullWidth
                            onChange={async (e) => {
                              if (e.target.value === "") {
                                return;
                              }
                              try {
                                const res = await clienteAxios.get(
                                  `/proveedores/filtro/0?search=${e.target.value}&estado=VETERINARIO`
                                );
                                if (!res.data.data) {
                                  return mostrarAlerta(res.data.msg, "error");
                                }
                                setpersonasData(res.data.data);
                              } catch (error) {}
                            }}
                            // InputProps={{
                            //   ...params.InputProps,
                            //   endAdornment: (
                            //     <React.Fragment>
                            //       {!editar ? (
                            //         <IconButton
                            //           size="small"
                            //           aria-label=""
                            //           onClick={async () => {
                            //             seteditarProveedor(false);
                            //             setopenProveedor(true);
                            //           }}
                            //         >
                            //           <AddCircleOutlineTwoTone color="primary" />
                            //         </IconButton>
                            //       ) : null}
                            //       {params.InputProps.endAdornment}
                            //     </React.Fragment>
                            //   ),
                            // }}
                          />
                        )}
                      />
                    </FormControl>
                    <FormControl fullWidth className={clases.formD}>
                      <Autocomplete
                        disableClearable
                        options={personasData.map((item) => {
                          return item.perNombre + "/+/" + item.perId;
                        })}
                        getOptionLabel={(option) => {
                          return option.split("/+/")[0];
                        }}
                        id="controlled-demo"
                        value={hcliPerNombreVetExt}
                        paperprops
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            const options = newValue.split("/+/");
                            const itemF = personasData.find((itemPersona) => {
                              return itemPersona.perId === Number(options[1]);
                            });

                            setitem({
                              ...item,
                              hcliPerNombreVetExt: itemF.perNombre,
                              hcliPerIdVetExt: itemF.perId,
                            });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            error={error.hcliPerNombreVetExt}
                            label="Vet Externo"
                            fullWidth
                            onChange={async (e) => {
                              if (e.target.value === "") {
                                return;
                              }
                              try {
                                const res = await clienteAxios.get(
                                  `/proveedores/filtro/0?search=${e.target.value}&estado=VETERINARIO`
                                );
                                if (!res.data.data) {
                                  return mostrarAlerta(res.data.msg, "error");
                                }
                                setpersonasData(res.data.data);
                              } catch (error) {}
                            }}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  <Tooltip title="Eliminar Vet. Externo">
                                    <IconButton
                                      size="small"
                                      aria-label=""
                                      onClick={async () => {
                                        setitem({
                                          ...item,
                                          hcliPerNombreVetExt: "",
                                          hcliPerIdVetExt: "",
                                        });
                                      }}
                                    >
                                      <Clear color="error" />
                                    </IconButton>
                                  </Tooltip>

                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                    </FormControl>
                    <TextField
                      type="date"
                      size="small"
                      InputLabelProps={{ shrink: true }}
                      className={clases.formD}
                      label="Fecha"
                      value={hcliFecha}
                      name="hcliFecha"
                      error={error.hcliFecha}
                      onChange={(e) => handleChange(e)}
                    />
                    <FormControl fullWidth className={clases.formD}>
                      <Autocomplete
                        options={motivosData.map((item) => {
                          return item.consNombre;
                        })}
                        getOptionLabel={(option) => {
                          return option.split("/+/")[0];
                        }}
                        id="controlled-demo"
                        value={hcliMotiConsulMedica}
                        paperprops
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            setitem({
                              ...item,
                              hcliMotiConsulMedica: newValue,
                            });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            error={error.hcliMotiConsulMedica}
                            label="Motivo"
                            fullWidth

                            // InputProps={{
                            //   ...params.InputProps,
                            //   endAdornment: (
                            //     <React.Fragment>
                            //       {!editar ? (
                            //         <IconButton
                            //           size="small"
                            //           aria-label=""
                            //           onClick={async () => {
                            //             seteditarProveedor(false);
                            //             setopenProveedor(true);
                            //           }}
                            //         >
                            //           <AddCircleOutlineTwoTone color="primary" />
                            //         </IconButton>
                            //       ) : null}
                            //       {params.InputProps.endAdornment}
                            //     </React.Fragment>
                            //   ),
                            // }}
                          />
                        )}
                      />
                    </FormControl>
                    <TextField
                      size="small"
                      type="number"
                      className={clases.formD}
                      label="Peso"
                      value={hcliPeso}
                      name="hcliPeso"
                      error={error.hcliPeso}
                      onChange={(e) => handleChange(e)}
                    />
                    <Editor
                      label="Observación"
                      propiedad={"hcliDescripcion"}
                      item={item}
                      setitem={setitem}
                    />
                  </>
                )}
                {check1Seccion && (
                  <>
                    <TextField
                      className={clases.formD}
                      label="Temperatura"
                      value={hcliTemperatura}
                      name="hcliTemperatura"
                      error={error.hcliTemperatura}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      className={clases.formD}
                      label="Hidratación"
                      value={hcliHidratacion}
                      name="hcliHidratacion"
                      error={error.hcliHidratacion}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      className={clases.formD}
                      label=" FrecuenciaCardiaca"
                      value={hcliFrecuCardiaca}
                      name="hcliFrecuCardiaca"
                      error={error.hcliFrecuCardiaca}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      className={clases.formD}
                      label="Pulso"
                      value={hcliPulso}
                      name="hcliPulso"
                      error={error.hcliPulso}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      className={clases.formD}
                      label=" Frecuencia Respi."
                      value={hcliFrecuRespiratoria}
                      name="hcliFrecuRespiratoria"
                      error={error.hcliFrecuRespiratoria}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      className={clases.formD}
                      label="General"
                      value={hcliGeneral}
                      name="hcliGeneral"
                      error={error.hcliGeneral}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      className={clases.formD}
                      label="Foong"
                      value={hcliFoong}
                      name="hcliFoong"
                      error={error.hcliFoong}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      className={clases.formD}
                      label="TP"
                      value={hcliTp}
                      name="hcliTp"
                      error={error.hcliTp}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      className={clases.formD}
                      label="ME"
                      value={hcliMe}
                      name="hcliMe"
                      error={error.hcliMe}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      className={clases.formD}
                      label=" CardioVascular"
                      value={hcliCardioVascular}
                      name="hcliCardioVascular"
                      error={error.hcliCardioVascular}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      className={clases.formD}
                      label="Respiratorio"
                      value={hcliRespiratorio}
                      name="hcliRespiratorio"
                      error={error.hcliRespiratorio}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      className={clases.formD}
                      label=" GastroIntestinal"
                      value={hcliGastroIntestinal}
                      name="hcliGastroIntestinal"
                      error={error.hcliGastroIntestinal}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      className={clases.formD}
                      label="GR"
                      value={hcliGr}
                      name="hcliGr"
                      error={error.hcliGr}
                      onChange={(e) => handleChange(e)}
                    />
                  </>
                )}
                {check2Seccion && (
                  <>
                    <TextField
                      className={clases.formD}
                      label="Neurologico"
                      value={hcliNeurologico}
                      name="hcliNeurologico"
                      error={error.hcliNeurologico}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      className={clases.formD}
                      label="Linfatico"
                      value={hcliLinfatico}
                      name="hcliLinfatico"
                      error={error.hcliLinfatico}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      className={clases.formD}
                      label="Patrón Respiratorio"
                      value={hcliPatronRespiratorio}
                      name="hcliPatronRespiratorio"
                      error={error.hcliPatronRespiratorio}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      className={clases.formD}
                      label="Glasgow Modificado"
                      value={hcliGlasgowModificado}
                      name="hcliGlasgowModificado"
                      error={error.hcliGlasgowModificado}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      className={clases.formD}
                      label="Ritmo Cardiaco"
                      value={hcliRitmoCardiaco}
                      name="hcliRitmoCardiaco"
                      error={error.hcliRitmoCardiaco}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      className={clases.formD}
                      label="Reflejo Pupilar"
                      value={hcliReflejoPupilar}
                      name="hcliReflejoPupilar"
                      error={error.hcliReflejoPupilar}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      className={clases.formD}
                      label="Reflejo Tusigeno"
                      value={hcliReflejoTusigeno}
                      name="hcliReflejoTusigeno"
                      error={error.hcliReflejoTusigeno}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      className={clases.formD}
                      label="T.llenado Vascular"
                      value={hcliTLlenadoVascular}
                      name="hcliTLlenadoVascular"
                      error={error.hcliTLlenadoVascular}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      className={clases.formD}
                      label="Color Mocosas"
                      value={hcliColorMocosas}
                      name="hcliColorMocosas"
                      error={error.hcliColorMocosas}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      className={clases.formD}
                      label="Amigdalas"
                      value={hcliAmigdalas}
                      name="hcliAmigdalas"
                      error={error.hcliAmigdalas}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      className={clases.formD}
                      label="Dolor Paciente"
                      value={hcliDolorPaciente}
                      name="hcliDolorPaciente"
                      error={error.hcliDolorPaciente}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      className={clases.formD}
                      label="Lesiones"
                      value={hcliLesiones}
                      name="hcliLesiones"
                      error={error.hcliLesiones}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      className={clases.formD}
                      label="PAS/PAM/PAD"
                      value={hcliPasPamPad}
                      name="hcliPasPamPad"
                      error={error.hcliPasPamPad}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      className={clases.formD}
                      label="Otros"
                      value={hcliOtros}
                      name="hcliOtros"
                      error={error.hcliOtros}
                      onChange={(e) => handleChange(e)}
                    />
                  </>
                )}
                {fotoSeccion && (
                  <>
                    <CampoDoc
                      disabled={cargando}
                      disabledE={cargando}
                      setcargando={setcargando}
                      label={"Documento"}
                      propiedad={"hcliArchivo"}
                      item={item}
                      setitem={setitem}
                      celda={false}
                    />
                    <TextField
                      multiline
                      rows={3}
                      variant="outlined"
                      className={clases.formAllW}
                      label="Tratamiento"
                      value={hcliTratamiento}
                      name="hcliTratamiento"
                      error={error.hcliTratamiento}
                      onChange={(e) => handleChange(e)}
                    />
                  </>
                )}

                {!historiaSeccion &&
                  !check1Seccion &&
                  !check2Seccion &&
                  !fotoSeccion && (
                    <CamposDinamicos
                      item={item}
                      setitem={setitem}
                      textoPropiedad={"hcliCamposDinamicos"}
                      tabsDinamicos={tabsDinamicos}
                      seccionActive={seccionActive}
                      error={error}
                      cargando={cargando}
                      setcargando={setcargando}
                    />
                  )}
              </Box>
              {/* <Divider /> */}
              <Box
                mt={2}
                display="flex"
                width="100%"
                justifyContent="space-between"
                alignItems="center"
                flexWrap={"wrap"}
              >
                <Box
                  className={clases.formD}
                  alignItems="center"
                  display={"flex"}
                  justifyContent="space-around"
                >
                  <Typography
                    className={clases.formD}
                    color="secondary"
                    fontSize={".9rem"}
                    fontWeight="bold"
                  >
                    Crea:{" "}
                    <Typography
                      className={clases.formD}
                      color="primary"
                      fontSize={".9rem"}
                      component="span"
                    >
                      {item.hcliUser.split(" ** ")[0]}
                    </Typography>
                  </Typography>
                  <Typography
                    className={clases.formD}
                    color="secondary"
                    fontSize={".9rem"}
                    fontWeight="bold"
                  >
                    Act:{" "}
                    <Typography
                      className={clases.formD}
                      color="primary"
                      fontSize={".9rem"}
                      component="span"
                    >
                      {item.hcliUser.split(" ** ")[1]}
                    </Typography>
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  mt={1}
                  className={clases.formD}
                >
                  <Button
                    className={clases.formD}
                    variant="contained"
                    color={item.restaurado ? "success" : "primary"}
                    disabled={cargando}
                    onClick={() => {
                      if (validacion()) {
                        return mostrarAlerta(
                          "Faltan campos obligatorios",
                          "error"
                        );
                      }
                      if (editar) {
                        if (!tienePermiso("editar")) {
                          return alertaPermiso("editar");
                        }
                        handleEditar();
                      } else {
                        if (!tienePermiso("agregar")) {
                          return alertaPermiso("agregar");
                        }
                        setopenConfirmDialog(true);
                      }
                    }}
                  >
                    {item.restaurado ? "Restaurar" : "Guardar"}
                  </Button>
                </Box>
              </Box>
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default memo(ModalData);
