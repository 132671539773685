import React, { memo, useContext, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  TableCell,
  TableRow,
  Collapse,
  Button,
  Divider,
} from "@mui/material";
import { useState } from "react";
import ConfirmacionDialog from "../../../../components/Extra/ConfirmacionDialog";
import { withRouter } from "react-router";
import ButtonsAction from "./ButtonsAction";
import AuthContext from "../../../../Context/Auth/AuthContext";
import Facturacion from "../../../Ventas/Facturas/Facturacion";
import CuentasXCobrar from "../../../Ventas/CuentasCobrar/CuentasXCobrar";
import Compras from "../../../Compras/Compras/Compras";
import CuentasPorPagar from "../../../Compras/CuantasPorCobrar/CuentasPorCobrar";
import CallNombrePersona from '../../../components/Tablas/CallNombrePersona'
const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  hover: {
    "&:hover": {
      opacity: ".7",
      cursor: "pointer",
    },
  },
  celda: {
    fontSize: ".7rem !important",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));

const Row = (props) => {
  const {
    seteditar,
    arrayExport,
    setarrayExport,
    row,
    index,
    setopen,
    campoExport,
    estadoRequest,
    changeObjetoActivoState,
    socket,
  } = props;

  const { usuario } = useContext(AuthContext);

  const [expandir, setexpandir] = useState(false);
  const [seccion, setseccion] = useState({
    facturacionSeccion: false,
  });
  const {
    facturacionSeccion,
    CuentasXCobrarSeccion,
    ComprasSeccion,
    CuentasPorPagarSeccion,
  } = seccion;
  const classes = useRowStyles();
  const [tab, settab] = useState("");
  const [arrayExportHijos, setarrayExportHijos] = useState("");

  const desdeOtroLugarPersona = true;
  const handleEliminar = async (_id) => {
    try {
      socket.current.emit("client:eliminarData", {
        tabla: "proveedor",
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        perUser: usuario.usuario,
        perEmpresa: usuario.rucempresa,
        perId: row.perId,
      });
    } catch (error) {}
  };
  const funcionEdit = () => {
    changeObjetoActivoState({
      ...row,
    });
    setopen(true);
    seteditar(true);
  };

  useEffect(() => {
    const map = arrayExport.map((itemE) => {
      if (itemE.perId === row.perId) {
        return {
          perId: itemE[campoExport],
          hijos: arrayExportHijos,
          tab,
          seccion: facturacionSeccion
            ? "VENTAS"
            : CuentasXCobrarSeccion
            ? "CUANTAS POR COBRAR"
            : ComprasSeccion
            ? "COMPRAS"
            : CuentasPorPagarSeccion
            ? "CUENTAS POR PAGAR"
            : "",
        };
      } else {
        return itemE;
      }
    });

    setarrayExport(map);
    // eslint-disable-next-line
  }, [arrayExportHijos, tab, seccion]);
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        titulo={`¿Esta seguro de eliminar este registro: ${row.perNombre}?`}
        contenido={`El proveedor ${row.perNombre} será eliminado, no podra ser recuperado`}
        _id={row._id}
        funcion={handleEliminar}
      />
      <TableRow className={classes.root} selected={index % 2 === 0}>
        {/* boton */}
        <ButtonsAction
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          row={row}
          setopenConfirmDialog={setopenConfirmDialog}
          funcionEdit={funcionEdit}
          ocultarEditar={false}
          ocultarDelete={false}
          mostrarEstado={true}
          campoExport={campoExport}
          campoEstado="perEstado"
          expandir={expandir}
          setexpandir={setexpandir}
        />
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {row.perDocTipo}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {row.perDocNumero}
        </TableCell>
        <TableCell size="small" align="left" className={classes.celda}>
          {row.perNombre}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {row.perTelefono}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {row.perCelular}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {row.perObligado}
        </TableCell>
        <TableCell size="small" align="left" className={classes.celda}>
          {row.perEmail}
        </TableCell>{" "}
        {/* <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {row.perUser}
        </TableCell>{" "} */}
        <CallNombrePersona user={row.perUser} />
        <TableCell 
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {new Date(row.perFecUpd).toLocaleString("es-ES", {
            weekday: "short",
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour: "2-digit",
          })}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={21}>
          <Collapse in={expandir} timeout="auto" unmountOnExit>
            <Box
              mt={1}
              display="flex"
              justifyContent="space-between"
              alignItems=" stretch"
              width="100%"
            >
              <Box>
                {estadoRequest === "CLIENTE" ? (
                  <>
                    <Button
                      size="small"
                      variant={facturacionSeccion ? "contained" : null}
                      onClick={async () => {
                        setseccion({ facturacionSeccion: true });
                        settab("");
                        setarrayExportHijos([]);
                      }}
                      disableElevation
                      style={{
                        padding: ".5rem",
                        borderRadius: "15px 15px  0px 0px",
                      }}
                      color="secondary"
                    >
                      Ventas
                    </Button>
                    <Button
                      size="small"
                      variant={CuentasXCobrarSeccion ? "contained" : null}
                      onClick={async () => {
                        setseccion({ CuentasXCobrarSeccion: true });
                        settab("");
                        setarrayExportHijos([]);
                      }}
                      disableElevation
                      style={{
                        padding: ".5rem",
                        borderRadius: "15px 15px  0px 0px",
                      }}
                      color="secondary"
                    >
                      Cuentas Por Cobrar
                    </Button>
                  </>
                ) : null}{" "}
                {estadoRequest === "PROVEEDOR" ? (
                  <>
                    <Button
                      size="small"
                      variant={ComprasSeccion ? "contained" : null}
                      onClick={async () => {
                        setseccion({ ComprasSeccion: true });
                        settab("");
                        setarrayExportHijos([]);
                      }}
                      disableElevation
                      style={{
                        padding: ".5rem",
                        borderRadius: "15px 15px  0px 0px",
                      }}
                      color="secondary"
                    >
                      Compras
                    </Button>
                    <Button
                      size="small"
                      variant={CuentasPorPagarSeccion ? "contained" : null}
                      onClick={async () => {
                        setseccion({ CuentasPorPagarSeccion: true });
                        settab("");
                        setarrayExportHijos([]);
                      }}
                      disableElevation
                      style={{
                        padding: ".5rem",
                        borderRadius: "15px 15px  0px 0px",
                      }}
                      color="secondary"
                    >
                      Cuentas Por Pagar
                    </Button>
                  </>
                ) : null}
              </Box>
            </Box>{" "}
            <Divider style={{ display: "block", width: "100%" }} />
            <Box p={2}>
              {estadoRequest === "CLIENTE" ? (
                <>
                  {facturacionSeccion ? (
                    <Facturacion
                      documento={row.perDocNumero}
                      settab={settab}
                      setarrayExportHijos={setarrayExportHijos}
                      desdeOtroLugarPersona={desdeOtroLugarPersona}
                      itemRelacionadoPadre={row}
                    />
                  ) : null}
                  {CuentasXCobrarSeccion ? (
                    <CuentasXCobrar
                      documento={row.perDocNumero}
                      settab={settab}
                      setarrayExportHijos={setarrayExportHijos}
                      desdeOtroLugarPersona={desdeOtroLugarPersona}
                      itemRelacionadoPadre={row}
                    />
                  ) : null}
                </>
              ) : null}
              {estadoRequest === "PROVEEDOR" ? (
                <>
                  {ComprasSeccion ? (
                    <Compras
                      documento={row.perDocNumero}
                      settab={settab}
                      setarrayExportHijos={setarrayExportHijos}
                      desdeOtroLugarPersona={desdeOtroLugarPersona}
                      itemRelacionadoPadre={row}
                    />
                  ) : null}{" "}
                  {CuentasPorPagarSeccion ? (
                    <CuentasPorPagar
                      documento={row.perDocNumero}
                      settab={settab}
                      setarrayExportHijos={setarrayExportHijos}
                      desdeOtroLugarPersona={desdeOtroLugarPersona}
                      itemRelacionadoPadre={row}
                    />
                  ) : null}
                </>
              ) : null}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
