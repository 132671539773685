import React, { useState } from "react";

import { Dialog, IconButton, TableCell, Typography } from "@mui/material";
import { Image } from "@mui/icons-material";
import MuiImageSlider from "mui-image-slider";
import { useRowStyles } from "../../../styles/stylesRows";
const ImagenCelda = ({ propiedad, array, claseCelda, texto }) => {
  const classes = useRowStyles();
  const [openImagen, setopenImagen] = useState(false);
  return array ? (
    <>
      <Dialog
        open={openImagen}
        onClose={() => setopenImagen(false)}
        aria-labelledby="draggable-dialog-title"
      >
        {propiedad.length === 0 ? null : (
          <MuiImageSlider
            images={propiedad.map((item) => item.url)}
            fitToImageHeight={true}
          />
        )}
      </Dialog>
      <TableCell
        size="small"
        align="center"
        className={claseCelda ? claseCelda : classes.celda}
      >
        {texto ? (
          <Typography variant="button" color="ButtonText" fontWeight={"bold"}>
            {texto}
          </Typography>
        ) : null}
        {propiedad.length === 0 ? (
          "No hay imagenes"
        ) : (
          <IconButton
            aria-label=""
            size="small"
            onClick={() => setopenImagen(true)}
          >
            <Image size="small" color="secondary" />
          </IconButton>
        )}
      </TableCell>
    </>
  ) : (
    <>
      <Dialog
        open={openImagen}
        onClose={() => setopenImagen(false)}
        aria-labelledby="draggable-dialog-title"
      >
        <img width="100%" src={propiedad} alt="imagen" />
      </Dialog>
      <TableCell
        size="small"
        align="center"
        className={claseCelda ? claseCelda : classes.celda}
      >
        {texto ? (
          <Typography variant="button" color="ButtonText" fontWeight={"bold"}>
            {texto}
          </Typography>
        ) : null}
        {propiedad === "" ? (
          "No hay imagen"
        ) : (
          <IconButton
            aria-label=""
            size="small"
            onClick={() => setopenImagen(true)}
          >
            <Image size="small" color="secondary" />
          </IconButton>
        )}
      </TableCell>
    </>
  );
};

export default ImagenCelda;
