import React, { useEffect, useState } from "react";
import { DropzoneArea } from "react-mui-dropzone";
import Typography from "@mui/material/Typography";
import clienteAxios from "../../config/axios";
import Cargando from "./Cargando/Cargando";
import {
  GridList,
  GridListTile,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
} from "@mui/material";
import { Delete, OpenInBrowser, Visibility } from "@mui/icons-material";

const Dropzone = (props) => {
  const {
    imagenes,
    setimagenes,
    fotosItem = [],
    textoDropzone = "Arrastra una imagen o da click aqui.",
  } = props;
  const [archivosIniciales, setarchivosIniciales] = useState(null);
  useEffect(() => {
    const getFotos = async () => {
      const resFotos = await Promise.all(
        fotosItem.map(async (fotoItem) => {
          try {
            const url = `imagen/0?imagen=${fotoItem.url}`;
            const resImagen = await clienteAxios.get(url, {
              responseType: "arraybuffer",
            });
            const nombre = url.split("/")[url.split("/").length - 1];

            const archivo = new File([resImagen.data], nombre, {
              type: "image/jpeg", // Asegúrate de usar el tipo MIME correcto aquí
            });

            setimagenes([archivo]);
            return archivo;
          } catch (error) {
            return "";
          }
        })
      );
      //   const resFotos = ;
      setarchivosIniciales(eliminarDuplicados([...resFotos, ...imagenes]));
    };
    getFotos();
    // eslint-disable-next-line
  }, []);

  if (archivosIniciales === null) {
    return <Cargando titulo="Cargando Imagenes" />;
  }
  function eliminarDuplicados(arr) {
    let unicos = {};
    arr.forEach((obj) => {
      unicos[obj.name] = obj;
    });
    return Object.values(unicos);
  }
  return (
    <>
      <DropzoneArea
        disabled
        previewText="Imagenes:"
        showPreviews={false}
        filesLimit={4}
        showPreviewsInDropzone={false}
        initialFiles={archivosIniciales}
        onDrop={(files) => {
          console.log("Archivo seleccionado:", files[0]);
        }}
        acceptedFiles={[
          "image/jpeg",
          "image/png",
          "image/bmp",
          "image/jpg",
          "image/jfif",
        ]}
        dropzoneText={
          <>
            <Typography variant="h4" color="primary">
              {textoDropzone}
            </Typography>
          </>
        }
        onChange={(e) => {
          setimagenes(e);
        }}
      />{" "}
      <ImageList sx={{ width: "100%" }} cols={2} rowHeight={250}>
        {imagenes.map((imagen, index) => (
          <ImageListItem key={index}>
            <img
              src={URL.createObjectURL(imagen)}
              alt={imagen.name}
              onClick={() => console.log("Imagen seleccionada:", imagen)}
              loading="lazy"
            />
            <ImageListItemBar
              title={imagen.name}
              actionIcon={
                <>
                  <IconButton
                    aria-label="Eliminar"
                    size="small"
                    onClick={() => {
                      setimagenes(
                        imagenes.filter((item) => item.name !== imagen.name)
                      );
                      // setimagenes([]);
                    }}
                  >
                    <Delete color="error" fontSize="small" />
                  </IconButton>{" "}
                  <IconButton
                    aria-label="Ver"
                    size="small"
                    onClick={() => window.open(URL.createObjectURL(imagen))}
                  >
                    <Visibility color="success" fontSize="small" />
                  </IconButton>
                </>
              }
            />
          </ImageListItem>
        ))}
      </ImageList>
    </>
  );
};

export default Dropzone;
