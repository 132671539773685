import React, { useState, useContext, useEffect, memo } from "react";
import { makeStyles } from "@mui/styles";
import {
  Modal,
  Box,
  Button,
  Divider,
  Typography,
  TextField,
  FormControl,
  Autocomplete,
  MenuItem,
} from "@mui/material";
import Draggable from "react-draggable";
import { Cancel } from "@mui/icons-material";
import { IconButton } from "@mui/material";
// eslint-disable-next-line
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import AuthContext from "../../../../Context/Auth/AuthContext";
import { trimPropsItem } from "../../../../config/const";
import clienteAxios from "../../../../config/axios";
import { usePermiso } from "../../../../hooks/usePermiso";
function getModalStyle() {
  return {
    borderRadius: "15px",
    position: "absolute",
    overflow: "scroll",
    maxHeight: "95vh",
  };
}
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "55%",
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "15px",
    marginTop: "5vh",
    [theme.breakpoints.down("sm")]: { width: "90%", marginTop: "2vh" },
  },
  margin: {
    margin: theme.spacing(1),
    width: "45%",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  ocultar: { display: "none" },
  inputPadding: {
    padding: theme.spacing(0.5),
    margin: theme.spacing(0),
    fontSize: "1rem",
  },
  formD: {
    flexGrow: 1,
    width: "45%",
    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  formAllW: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "95%",
    [theme.breakpoints.down("md")]: { padding: "0", margin: "2px" },
    [theme.breakpoints.down("sm")]: { padding: "0", margin: "2px" },
  },

  form30PC: {
    flexGrow: 1,
    width: "30%",
    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "30%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },

  large: {
    width: theme.spacing(13),
    height: theme.spacing(13),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    "&:hover": { opacity: ".7" },
  },
  center: { justifyContent: "center", alignItems: "center" },
  root: { width: "100%", marginTop: theme.spacing(1) },
  correo: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "45%",
    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  error: { color: "red", fontWeight: "bold" },
}));
const ModalData = (props) => {
  const { tienePermiso, alertaPermiso } = usePermiso("CarnetVacunas");
  const { mostrarAlerta } = useContext(AlertaContext);
  const { usuario } = useContext(AuthContext); //props
  const {
    open,
    setopen,
    editar,
    seteditar,
    ObjectoActivo,
    changeObjetoActivoState,
    socket,
  } = props;
  // eslint-disable-next-line
  const [item, setitem] = useState(ObjectoActivo);
  const {
    // carnMascId,
    carnMascNombre,
    // carnPerIdVet,
    carnPerNombreVet,
    carnPeso,
    carnProdTipo,
    carnProdNombre,
    carnProdFecCaducidad,
    carnFecVacuColocacion,
    carnFecVacuRecolocacion,
    carnDiasVacuRecolocacion,
    carnObservacion,
    // carnCorreo,
    // carnMensaje,
    // carnUser,
    // carnEmpresa,
    // carnFecReg,
    // Todos,
  } = item;
  const [productosData, setproductosData] = useState([]);
  const [personasData, setpersonasData] = useState([]);
  const [mascotasData, setmascotasData] = useState([]);
  const unDia = 1000 * 60 * 60 * 24;
  const timeDifference = new Date().getTimezoneOffset() * 60 * 1000;
  const handleChange = (e) => {
    setitem({ ...item, [e.target.name]: e.target.value });
  };
  const [cargando, setcargando] = useState(false);
  const [seccionActive, setseccionActive] = useState({
    vacunasSeccion: true,
    datosSeccion: false,
  });
  const { vacunasSeccion, datosSeccion } = seccionActive;
  const [error, seterror] = useState({});
  const [tiposProdcutoData, settiposProdcutoData] = useState(null);
  // GET => /api/sexo/filtro/0
  useEffect(() => {
    const gettiposProdcutoData = async () => {
      try {
        const restiposProdcutoData = await clienteAxios.get(
          `/categorias/filtrobytipo/0`
        );

        settiposProdcutoData(restiposProdcutoData.data.data);
      } catch (error) {
        settiposProdcutoData([]);
      }
    };

    if (!tiposProdcutoData) {
      gettiposProdcutoData();
    }

    setitem(ObjectoActivo); // eslint-disable-next-line
  }, [open]);
  const defaultActive = () => {
    changeObjetoActivoState({
      carnMascId: "",
      carnMascNombre: "",
      carnPerId: "",
      carnPerNombre: "",
      carnPerDocumento: "",
      carnPerIdVet: "",
      carnPerNombreVet: "",
      carnPeso: "",
      carnProdTipo: "", // pendiente (se tomara de un endpoint de productos)
      carnProdNombre: "", // pendiente (se tomara de un endpoint de productos)
      carnProdFecCaducidad: "", // pendiente (se tomara de un endpoint de productos)
      carnFecVacuColocacion: new Date(Date.now() - timeDifference)
        .toISOString()
        .substring(0, 10),
      carnFecVacuRecolocacion: new Date(Date.now() - timeDifference + 1 * unDia)
        .toISOString()
        .substring(0, 10),
      carnDiasVacuRecolocacion: "1",
      carnObservacion: "",
      carnCorreo: "PENDIENTE", // defecto
      carnMensaje: "PENDIENTE", // defecto
      carnUser: "",
      carnEmpresa: "",
      carnFecReg: "",
      Todos: "",
    });
    setopen(false);
    seteditar(false);
    seterror({});
    setproductosData([]);
    setcargando(false);
  };

  //hook de estilos
  const clases = useStyles();
  //definiendo los estilos del modal
  const [modalStyle] = useState(getModalStyle);

  const validacion = () => {
    if (
      carnMascNombre.trim() === "" ||
      carnPeso === "" ||
      carnProdTipo.trim() === "" ||
      carnProdNombre.trim() === "" ||
      carnPerNombreVet.trim() === "" ||
      Number(carnDiasVacuRecolocacion) <= 0 ||
      carnDiasVacuRecolocacion === ""
    ) {
      seterror({
        carnMascNombre: carnMascNombre.trim() === "" ? true : false,
        carnPeso: carnPeso === "" ? true : false,
        carnProdTipo: carnProdTipo.trim() === "" ? true : false,
        carnProdNombre: carnProdNombre.trim() === "" ? true : false,
        carnPerNombreVet: carnPerNombreVet.trim() === "" ? true : false,
        carnDiasVacuRecolocacion:
          Number(carnDiasVacuRecolocacion) <= 0 ||
          carnDiasVacuRecolocacion === ""
            ? true
            : false,
      });
      return true;
    } else {
      return false;
    }
  };
  const handleCrear = async () => {
    if (validacion()) {
      return mostrarAlerta("Faltan campos obligatorios", "error");
    }
    try {
      setcargando(true);
      let resFoto = null;
      // if (archivo !== "") {
      //   const formDataLogos = new FormData();
      //   formDataLogos.append("tipo", "mascotas");
      //   formDataLogos.append("rucempresa", usuario.rucempresa);
      //   formDataLogos.append("archivoAnterior", item.hcliFoto);
      //   formDataLogos.append("archivo", archivo);
      //   resFoto = await clienteAxios.post(
      //     "/upload_delete_multiple_files/upload",
      //     formDataLogos
      //   );
      // }
      console.log(
        trimPropsItem({
          ...item,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          tabla: "carnetvacuna",
          carnUser: usuario.usuario,
          carnEmpresa: usuario.rucempresa,
          carnFoto: resFoto ? resFoto.data.nombre : item.hcliFoto,
        })
      );
      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...item,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          tabla: "carnetvacuna",
          carnUser: usuario.usuario,
          carnEmpresa: usuario.rucempresa,
          carnFoto: resFoto ? resFoto.data.nombre : item.hcliFoto,
        })
      );
      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);
      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  const handleEditar = async () => {
    if (validacion()) {
      return mostrarAlerta("Faltan campos obligatorios", "error");
    }
    try {
      setcargando(true);
      let resFoto = null;
      // if (archivo !== "") {
      //   const formDataLogos = new FormData();
      //   formDataLogos.append("tipo", "mascotas");
      //   formDataLogos.append("rucempresa", usuario.rucempresa);
      //   formDataLogos.append("archivoAnterior", item.hcliFoto);
      //   formDataLogos.append("archivo", archivo);
      //   resFoto = await clienteAxios.post(
      //     "/upload_delete_multiple_files/upload",
      //     formDataLogos
      //   );
      // }
      socket.current.emit(
        "client:actualizarData",
        trimPropsItem({
          ...item,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          tabla: "carnetvacuna",
          carnUser: `${item.carnUser.split(" ** ")[0]} ** ${usuario.usuario}`,
          carnEmpresa: usuario.rucempresa,
          carnFoto: resFoto ? resFoto.data.nombre : item.hcliFoto,
        })
      );
      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);
      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  //filas por pagina

  // 1 + 2 + 3 + 4

  if (!open) {
    return null;
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Draggable handle="#handle">
          <Box display="flex" justifyContent="center">
            <div style={modalStyle} className={clases.paper}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography
                  style={{ cursor: "-webkit-grab" }}
                  id="handle"
                  variant="button"
                  color="initial"
                  align="center"
                >
                  {editar ? "Editar" : "Agregar"}
                  {" Carnet Vacuna"}
                </Typography>
                <Box display="flex" alignItems="center">
                  <IconButton
                    aria-label=""
                    style={{ margin: "0px" }}
                    onClick={() => {
                      defaultActive();
                    }}
                  >
                    <Cancel color="secondary" />
                  </IconButton>
                </Box>
              </Box>
              <Divider />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems=" stretch"
                width="100%"
              >
                <Box>
                  <Button
                    variant={
                      vacunasSeccion
                        ? "contained"
                        : error.carnMascNombre ||
                          error.carnPeso ||
                          error.carnProdTipo ||
                          error.carnProdNombre ||
                          error.carnProdFecCaducidad
                        ? "outlined"
                        : null
                    }
                    disableElevation
                    style={{ padding: "8px" }}
                    color={
                      error.carnMascNombre ||
                      error.carnPeso ||
                      error.carnProdTipo ||
                      error.carnProdNombre ||
                      error.carnProdFecCaducidad
                        ? "error"
                        : "secondary"
                    }
                    onClick={() => setseccionActive({ vacunasSeccion: true })}
                  >
                    Vacunas
                  </Button>

                  <Button
                    variant={
                      datosSeccion
                        ? "contained"
                        : error.carnPerNombreVet ||
                          error.carnFecVacuColocacion ||
                          error.carnDiasVacuRecolocacion ||
                          error.carnFecVacuRecolocacion ||
                          error.carnObservacion
                        ? "outlined"
                        : null
                    }
                    disableElevation
                    style={{ padding: "8px" }}
                    color={
                      error.carnPerNombreVet ||
                      error.carnFecVacuColocacion ||
                      error.carnDiasVacuRecolocacion ||
                      error.carnFecVacuRecolocacion ||
                      error.carnObservacion
                        ? "error"
                        : "secondary"
                    }
                    onClick={() => setseccionActive({ datosSeccion: true })}
                  >
                    Datos
                  </Button>
                </Box>
                {/* <FormControlLabel
                control={
                  <Switch
                    checked={perEstado === "ACTIVO" ? true : false}
                    onChange={(e) => {
                      setitem({
                        ...item,
                        perEstado: e.target.checked ? "ACTIVO" : "INACTIVO",
                      });
                    }}
                    name="antoine"
                  />
                }
                label="Estado"
              /> */}
              </Box>
              <Divider />

              <Box
                display="flex"
                flexWrap="wrap"
                alignItems="flex-end"
                borderRadius="0px 50px 50px 0px"
                mt={1}
              >
                {/* Mascota {carnMascNombre} */}
                {/* Peso kg. {carnPeso} */}
                {/* Tipo Producto {carnProdTipo} */}
                {/* Producto {carnProdNombre} */}
                {/* Fec. Caducidad {carnProdFecCaducidad} */}

                {vacunasSeccion ? (
                  <>
                    <FormControl fullWidth className={clases.formD}>
                      <Autocomplete
                        filterOptions={(x) => x}
                        options={mascotasData.map((item) => {
                          return (
                            item.mascNombre +
                            "/+/" +
                            item.mascPerNombre +
                            "/+/" +
                            item.mascId
                          );
                        })}
                        getOptionLabel={(option) => {
                          return option.replace("/+/", " - ").split("/+/")[0];
                        }}
                        id="controlled-demo"
                        value={carnMascNombre}
                        paperprops
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            const options = newValue.split("/+/");
                            const itemF = mascotasData.find((itemPersona) => {
                              return itemPersona.mascId === Number(options[2]);
                            });

                            setitem({
                              ...item,
                              carnMascId: itemF.mascId,
                              carnMascNombre: itemF.mascNombre,
                              carnPerId: itemF.mascPerId,
                              carnPerNombre: itemF.mascPerNombre,
                              carnPerDocumento: itemF.mascPerDocNumero,
                            });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            error={error.carnMascNombre}
                            label="Mascota"
                            fullWidth
                            onChange={async (e) => {
                              if (e.target.value === "") {
                                return;
                              }
                              try {
                                const res = await clienteAxios.get(
                                  `/mascotas/filtro/0?search=${e.target.value}`
                                );
                                if (!res.data.data) {
                                  return mostrarAlerta(res.data.msg, "error");
                                }

                                setmascotasData(res.data.data);
                              } catch (error) {}
                            }}
                            // InputProps={{
                            //   ...params.InputProps,
                            //   endAdornment: (
                            //     <React.Fragment>
                            //       {!editar ? (
                            //         <IconButton
                            //           size="small"
                            //           aria-label=""
                            //           onClick={async () => {
                            //             seteditarProveedor(false);
                            //             setopenProveedor(true);
                            //           }}
                            //         >
                            //           <AddCircleOutlineTwoTone color="primary" />
                            //         </IconButton>
                            //       ) : null}
                            //       {params.InputProps.endAdornment}
                            //     </React.Fragment>
                            //   ),
                            // }}
                          />
                        )}
                      />
                    </FormControl>
                    <TextField
                      type="number"
                      className={clases.formD}
                      label="Peso kg."
                      value={carnPeso}
                      name="carnPeso"
                      error={error.carnPeso}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      className={clases.formD}
                      label="Tipo Producto"
                      value={carnProdTipo}
                      name="carnProdTipo"
                      error={error.carnProdTipo}
                      onChange={async (e) => {
                        handleChange(e);
                        try {
                          const productos = await clienteAxios(
                            `/inventario/categoria/0?tipo=${e.target.value}`
                          );

                          setproductosData(productos.data.data);
                        } catch (error) {
                          mostrarAlerta("Hubo un error", "error");
                        }
                      }}
                      select
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                    >
                      <MenuItem value="">Seleccione.</MenuItem>
                      {tiposProdcutoData.map((item) => {
                        return (
                          <MenuItem key={item.catId} value={item.catNombre}>
                            {item.catNombre}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                    {/* <TextField
                      className={clases.formD}
                      label="Producto"
                      value={carnProdNombre}
                      name="carnProdNombre"
                      error={error.carnProdNombre}
                      onChange={(e) => handleChange(e)}
                    /> */}
                    <FormControl fullWidth className={clases.formD}>
                      <Autocomplete
                        options={productosData.map((item) => {
                          return item.invNombre + "/+/" + item.invId;
                        })}
                        getOptionLabel={(option) => {
                          return option.split("/+/")[0];
                        }}
                        id="controlled-demo"
                        value={carnProdNombre}
                        paperprops
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            const options = newValue.split("/+/");
                            const itemF = productosData.find((itemPersona) => {
                              return itemPersona.invId === Number(options[1]);
                            });

                            setitem({
                              ...item,
                              //  carnMascId: itemF.mascId,
                              carnProdNombre: itemF.invNombre,
                            });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            error={error.carnProdNombre}
                            label={
                              carnProdTipo === ""
                                ? "Producto (Es necesario un tipo de producto)"
                                : "Producto"
                            }
                            fullWidth
                            // InputProps={{
                            //   ...params.InputProps,
                            //   endAdornment: (
                            //     <React.Fragment>
                            //       {!editar ? (
                            //         <IconButton
                            //           size="small"
                            //           aria-label=""
                            //           onClick={async () => {
                            //             seteditarProveedor(false);
                            //             setopenProveedor(true);
                            //           }}
                            //         >
                            //           <AddCircleOutlineTwoTone color="primary" />
                            //         </IconButton>
                            //       ) : null}
                            //       {params.InputProps.endAdornment}
                            //     </React.Fragment>
                            //   ),
                            // }}
                          />
                        )}
                      />
                    </FormControl>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      type={"date"}
                      className={clases.formD}
                      label="Fec. Caducidad"
                      value={carnProdFecCaducidad}
                      name="carnProdFecCaducidad"
                      error={error.carnProdFecCaducidad}
                      onChange={(e) => handleChange(e)}
                    />
                  </>
                ) : null}

                {datosSeccion ? (
                  <>
                    <FormControl fullWidth className={clases.formD}>
                      <Autocomplete
                        filterOptions={(x) => x}
                        options={personasData.map((item) => {
                          return item.perNombre + "/+/" + item.perId;
                        })}
                        getOptionLabel={(option) => {
                          return option.split("/+/")[0];
                        }}
                        id="controlled-demo"
                        value={carnPerNombreVet}
                        paperprops
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            const options = newValue.split("/+/");
                            const itemF = personasData.find((itemPersona) => {
                              return itemPersona.perId === Number(options[1]);
                            });

                            setitem({
                              ...item,
                              carnPerNombreVet: itemF.perNombre,
                              carnPerIdVet: itemF.perId,
                            });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            error={error.carnPerNombreVet}
                            label="Veterinario"
                            fullWidth
                            onChange={async (e) => {
                              if (e.target.value === "") {
                                return;
                              }
                              try {
                                const res = await clienteAxios.get(
                                  `/proveedores/filtro/0?search=${e.target.value}&estado=VETERINARIO`
                                );
                                if (!res.data.data) {
                                  return mostrarAlerta(res.data.msg, "error");
                                }
                                setpersonasData(res.data.data);
                              } catch (error) {}
                            }}
                            // InputProps={{
                            //   ...params.InputProps,
                            //   endAdornment: (
                            //     <React.Fragment>
                            //       {!editar ? (
                            //         <IconButton
                            //           size="small"
                            //           aria-label=""
                            //           onClick={async () => {
                            //             seteditarProveedor(false);
                            //             setopenProveedor(true);
                            //           }}
                            //         >
                            //           <AddCircleOutlineTwoTone color="primary" />
                            //         </IconButton>
                            //       ) : null}
                            //       {params.InputProps.endAdornment}
                            //     </React.Fragment>
                            //   ),
                            // }}
                          />
                        )}
                      />
                    </FormControl>
                    <TextField
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      className={clases.formD}
                      label="Fec. Col."
                      value={carnFecVacuColocacion}
                      name="carnFecVacuColocacion"
                      error={error.carnFecVacuColocacion}
                      onChange={(e) => {
                        if (e.target.value === "") {
                          return setitem({
                            ...item,
                            carnFecVacuColocacion: new Date(
                              Date.now() - timeDifference
                            )
                              .toISOString()
                              .substring(0, 10),
                          });
                        }
                        setitem({
                          ...item,
                          [e.target.name]: e.target.value,
                          carnFecVacuRecolocacion: new Date(
                            Date.parse(e.target.value) +
                              carnDiasVacuRecolocacion * unDia
                          )
                            .toISOString()
                            .substring(0, 10),
                        });
                      }}
                    />
                    <TextField
                      type="number"
                      className={clases.formD}
                      label="#Dias R."
                      value={carnDiasVacuRecolocacion}
                      name="carnDiasVacuRecolocacion"
                      error={error.carnDiasVacuRecolocacion}
                      onChange={(e) => {
                        if (e.target.valueAsNumber) {
                          setitem({
                            ...item,
                            carnFecVacuRecolocacion: new Date(
                              Date.parse(carnFecVacuColocacion) +
                                e.target.valueAsNumber * 1000 * 60 * 60 * 24
                            )
                              .toISOString()
                              .substring(0, 16),
                            [e.target.name]: e.target.value,
                          });
                        } else {
                          setitem({
                            ...item,
                            carnFecVacuRecolocacion: new Date(
                              Date.parse(carnFecVacuColocacion)
                            )
                              .toISOString()
                              .substring(0, 10),
                            [e.target.name]: e.target.value,
                          });
                        }
                      }}
                    />
                    <TextField
                      type="datetime-local"
                      InputLabelProps={{ shrink: true }}
                      className={clases.formD}
                      label="Fec. Rec."
                      value={carnFecVacuRecolocacion}
                      name="carnFecVacuRecolocacion"
                      error={error.carnFecVacuRecolocacion}
                      onChange={(e) => {
                        if (e.target.value === "") {
                        }
                        setitem({
                          ...item,
                          [e.target.name]: e.target.value,
                        });
                      }}
                    />
                    <TextField
                      multiline
                      rows={3}
                      className={clases.formD}
                      label="Observación"
                      value={carnObservacion}
                      name="carnObservacion"
                      error={error.carnObservacion}
                      onChange={(e) => handleChange(e)}
                    />
                  </>
                ) : null}
              </Box>
              {/* <Divider /> */}
              <Box
                mt={2}
                display="flex"
                width="100%"
                justifyContent="space-between"
                alignItems="center"
                flexWrap={"wrap"}
              >
                <Box
                  className={clases.formD}
                  alignItems="center"
                  display={"flex"}
                  justifyContent="space-around"
                >
                  <Typography
                    className={clases.formD}
                    color="secondary"
                    fontSize={".9rem"}
                    fontWeight="bold"
                  >
                    Crea:
                    <Typography
                      className={clases.formD}
                      color="primary"
                      fontSize={".9rem"}
                      component="span"
                    >
                      {item.carnUser.split(" ** ")[0]}
                    </Typography>
                  </Typography>
                  <Typography
                    className={clases.formD}
                    color="secondary"
                    fontSize={".9rem"}
                    fontWeight="bold"
                  >
                    Act:
                    <Typography
                      className={clases.formD}
                      color="primary"
                      fontSize={".9rem"}
                      component="span"
                    >
                      {item.carnUser.split(" ** ")[1]}
                    </Typography>
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  mt={1}
                  className={clases.formD}
                >
                  <Button
                    className={clases.formD}
                    variant="contained"
                    color={item.restaurado ? "success" : "primary"}
                    disabled={cargando}
                    onClick={() => {
                      if (editar) {
                        if (!tienePermiso("editar")) {
                          return alertaPermiso("editar");
                        }
                        handleEditar();
                      } else {
                        if (!tienePermiso("agregar")) {
                          return alertaPermiso("agregar");
                        }
                        handleCrear();
                      }
                    }}
                  >
                    {item.restaurado ? "Restaurar" : "Guardar"}
                  </Button>
                </Box>
              </Box>
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default memo(ModalData);
