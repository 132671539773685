import React, { memo, useContext } from "react";

import {
  Autocomplete,
  FormControl,
  Popper,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";

import { useState } from "react";
import { withRouter } from "react-router";
import ButtonsAction from "../../../components/Tablas/ButtonsAction";
import AuthContext from "../../../../Context/Auth/AuthContext";
import { Cancel, Check } from "@mui/icons-material";

import { trimPropsItem } from "../../../../config/const";
import {
  useRowStyles,
  butonIconTransition,
} from "../../../../styles/stylesRows";
import { objectDefault } from "../data";
import clienteAxios from "../../../../config/axios";

const Row = ({ setopen, socket, chatsData }) => {
  // CLASES
  const classes = useRowStyles();

  // CONTEXT
  const { usuario } = useContext(AuthContext);
  // COPIA DEL REGISTRO PARA EDITAR
  const [item, setitem] = useState(objectDefault);

  const [personasData, setPersonasData] = useState([]);
  // DESHABILITAR BOTON
  const botonDisabled = () => {
    if (item.chat_name.trim() === "") {
      return true;
    }
  };
  // ELIMINAR EL REGISTRO
  const funcSubmit = () => {
    try {
      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...item,
          tabla: "chat_participants",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          chat_user: usuario.usuario,
          ciuEmpresa: usuario.rucempresa,
        })
      );
      setitem(objectDefault);
      setopen(false);
    } catch (error) {}
  };

  const PopperMy = function(props) {
    return (
      <Popper {...props} style={useRowStyles.popper} placement="bottom-start" />
    );
  };
  return (
    <React.Fragment>
      <TableRow className={classes.root} component={"form"}>
        {/* boton */}

        <ButtonsAction
          botones={[
            {
              tooltip: "Cancelar",
              texto: "",
              funcion: () => {
                setopen(false);
                setitem(objectDefault);
                // setItemEdit(itemHijo);
              },
              disabled: false,
              Icon: Cancel,
              color: "error",
              id: 1,
              ocultar: false,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Guardar",
              texto: "",
              funcion: () => {
                funcSubmit();
              },
              disabled: botonDisabled(),
              Icon: Check,
              color: "success",
              id: 2,
              ocultar: false,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
              submit: true,
            },
          ]}
        />
        <>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            <FormControl fullWidth>
              <Autocomplete
                PopperComponent={PopperMy}
                disableClearable={true}
                options={chatsData.map((item) => {
                  return item.chat_name + "/+/" + item.chat_id;
                })}
                getOptionLabel={(option) => {
                  return option.split("/+/")[0];
                }}
                id="controlled-demo"
                value={item.chat_name}
                onChange={async (event, newValue) => {
                  if (newValue) {
                    const values = newValue.split("/+/");
                    setitem({
                      ...item,
                      chat_name: values[0],
                      chat_id: values[1],
                    });
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    required
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            <FormControl fullWidth>
              <Autocomplete
                PopperComponent={PopperMy}
                disableClearable={true}
                options={personasData.map((item) => {
                  return (
                    item.num_documento +
                    "/+/" +
                    item.nombres +
                    "/+/" +
                    item.perId
                  );
                })}
                getOptionLabel={(option) => {
                  return option.replace("/+/", " ").split("/+/")[0];
                }}
                id="controlled-demo"
                value={item.num_documento}
                onChange={async (event, newValue) => {
                  if (newValue) {
                    const values = newValue.split("/+/");
                    setitem({
                      ...item,
                      num_documento: values[0],
                      nombres: values[1],
                      person_id: values[2],
                    });
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    required
                    fullWidth
                    onChange={async (e) => {
                      const res = await clienteAxios(
                        `/proveedores/toChat/0?search=${e.target.value}`
                      );
                      setPersonasData(res.data.data);
                    }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
          </TableCell>{" "}
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            {item.nombres}
          </TableCell>
        </>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
