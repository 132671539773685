import { FechaExacta } from "../../config/const";

export const columns = [
   {
    id: "noti_dirigido",
    label: "dirigido",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "noti_dirigido",
    align: "center",
    ocultar: true,
  },
   {
    id: "noti_tipo_servicio",
    label: "tipo_servicio",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "noti_tipo_servicio",
    align: "center",
    ocultar: true,
  },
   {
    id: "noti_ciudad",
    label: "ciudad",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "noti_ciudad",
    align: "center",
    ocultar: true,
  },
   {
    id: "noti_sector",
    label: "sector",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "noti_sector",
    align: "center",
    ocultar: true,
  },
 {
    id: "noti_titulo",
    label: "titulo",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "noti_titulo",
    align: "center",
    ocultar: true,
  },
 {
    id: "noti_fecha_inicio",
    label: "fecha_inicio",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "noti_fecha_inicio",
    align: "center",
    ocultar: true,
  },
   {
    id: "noti_fecha_fin",
    label: "fecha_fin",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "noti_fecha_fin",
    align: "center",
    ocultar: true,
  },
   {
    id: "noti_user",
    label: "user",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "noti_user",
    align: "center",
    ocultar: true,
  },
  {
    id: "noti_fecreg",
    label: "Fec Reg",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "noti_fecreg",
    align: "center",
    ocultar: true,
  },
];
export const validacion = (item, setitem, seterror) => {
  const {} = item;

  if (
    // prodPerDocumentoCliente.trim() === "" ||
    // prodColaboradores.length === 0 ||
    // prodFormaPago.trim() === "" ||
    // prodAbono === "" ||
    // prodDetalle.trim() === "" ||
    // prodEstado.trim() === "" ||
    // prodProceso.trim() === "" ||
    // prodFechaEntrega.trim() === "
    false
  ) {
    seterror({
      // PRIMER TAB
      // prodPerDocumentoCliente: prodPerDocumentoCliente.trim() === "",
      // prodColaboradores: prodColaboradores.length === 0,
      // prodFormaPago: prodFormaPago.trim() === "",
      // prodAbono: prodAbono === "",
      // prodDetalle: prodDetalle.trim() === "",
      // prodEstado: prodEstado.trim() === "",
      // prodProceso: prodProceso.trim() === "",
      // prodFechaEntrega: prodFechaEntrega.trim() === "",
      // SEGUNDO TAB
    });
    return true;
  }
};
export const objectDefault = {
  // "noti_id": 1,
  noti_dirigido: "CLIENTE", //NO CLIENTE
  noti_tipo_servicio: "HOGAR", //HOGAR - TRANSPORTE,
  noti_ciudad: "", //CIUDADES
  noti_sector: "", //CIUDADES
  noti_titulo: "",
  noti_descripcion: "", //TEXT AREA
  noti_foto: "", //DOCUMENTO
  noti_mostar_descripcion: "SI",
  noti_url: "", //INPUT,
  noti_fecha_inicio: FechaExacta,
  noti_fecha_fin: FechaExacta,
  noti_user: "",
  noti_empresa: "",
  noti_fecreg: "",
  noti_fecupd: "",
};

// DESHABILITAR BOTON
export const validacionHijo = (itemHijo) => {
  return (
    itemHijo.detalle.trim() === "" ||
    itemHijo.cantidad.trim() === "" ||
    itemHijo.valor.trim() === ""
  );
};
export const objectDefaultProducto = {
  order: 1,
  id: "",
  equipo: [],
  detalle: "",
  cantidad: "1",
  valor: "0",
  total: "0",
};
