import React, { useState, useContext, memo } from "react";

import { makeStyles } from "@mui/styles";
import {
  Modal,
  Box,
  Button,
  Divider,
  Typography,
  IconButton,
  TextField,
  FormControl,
  InputAdornment,
} from "@mui/material";
import Draggable from "react-draggable";
import { Cancel, AddCircle, Delete } from "@mui/icons-material";
import AlertaContext from "../../Context/Alerta/AlertaContext";
import clienteAxios from "../../config/axios";
import AuthContext from "../../Context/Auth/AuthContext";
import Dropzone from "../Extra/Dropzone";
function getModalStyle() {
  return {
    borderRadius: "15px",
    position: "absolute",
    overflow: "scroll",
    maxHeight: "95vh",
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",

    width: "50%",
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "15px",
    marginTop: "5vh",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      marginTop: "2vh",
    },
  },
  margin: {
    margin: theme.spacing(1),
    width: "45%",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  ocultar: {
    display: "none",
  },
  inputPadding: {
    padding: theme.spacing(0.25),
    margin: theme.spacing(0),
    fontSize: ".9rem",
  },
  formD: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "45%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  formAllW: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "95%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
    },
  },
  large: {
    width: theme.spacing(13),
    height: theme.spacing(13),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    "&:hover": {
      opacity: ".7",
    },
  },

  center: {
    justifyContent: "center",
    alignItems: "center",
  },
  root: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  correo: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "45%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  error: {
    color: "red",
    fontWeight: "bold",
  },
}));

const Soporte = (props) => {
  // eslint-disable-next-line
  const { mostrarAlerta } = useContext(AlertaContext);
  // eslint-disable-next-line
  const { usuario } = useContext(AuthContext);
  //props
  const { open, setopen } = props;
  const [item, setitem] = useState({
    nombre: "", // esto es del login
    asunto: "",
    descripcion: "",
    fotos: [],
    correos: [],
  });
  const { asunto, descripcion, fotos, correos } = item;
  const [imagenes, setimagenes] = useState([]);
  const [cargando, setcargando] = useState(false);

  // eslint-disable-next-line

  // eslint-disable-next-line

  const [error, seterror] = useState({});

  const defaultActive = () => {
    setitem({
      nombre: "", // esto es del login
      asunto: "",
      descripcion: "",
      fotos: [],
      correos: [],
    });
    setopen(false);

    setcargando(false);
  };
  // eslint-disable-next-line
  const handleChange = (e) => {
    setitem({
      ...item,
      [e.target.name]: e.target.value,
    });
  };

  //hook de estilos
  const clases = useStyles();
  //definiendo los estilos del modal

  const [modalStyle] = useState(getModalStyle);

  const validacion = () => {
    if (asunto.trim() === "" || descripcion.trim() === "") {
      seterror({
        asunto: asunto.trim() === "" ? true : false,
        descripcion: descripcion.trim() === "" ? true : false,
      });

      return true;
    } else {
      return false;
    }
  };
  const handleCrear = async () => {
    if (validacion()) {
      return mostrarAlerta("Faltan datos obligatorios por llenar", "error");
    }
    try {
      setcargando(true);
      const formDataImagenes = new FormData();
      // formDataImagenes.append(`eliminar`, JSON.stringify(fotos));
      let resDocumentos = {
        data: {
          urlspathUrls: fotos,
        },
      };

      await imagenes.map(async (item, index) => {
        return await formDataImagenes.append(`imagen${index + 1}`, item);
      });

      // resDocumentos = await clienteAxios.post("/multimedias", formDataImagenes);

      const res = await clienteAxios.post("/soporte", {
        ...item,
        nombre: usuario.nombre,
        fotos: resDocumentos.data.urlspathUrls,
      });

      // conFotosCliente: resDocumentos.data.urlspathUrls,
      mostrarAlerta(res.data.msg, "success");
      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);
      mostrarAlerta("Hubo un error", "error");
    }
  };
  //filas por pagina

  // eslint-disable-next-line

  // 1 + 2 + 3 + 4
  if (!open) {
    return null;
  }
  if (!item) {
    return null;
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Draggable handle="#handle">
          <Box display="flex" justifyContent="center">
            <div style={modalStyle} className={clases.paper}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography
                  style={{ cursor: "-webkit-grab" }}
                  id="handle"
                  variant="button"
                  color="initial"
                  align="center"
                >
                  Soporte.
                </Typography>
                <Box display="flex" alignItems="center">
                  <IconButton
                    aria-label=""
                    style={{ margin: "0px" }}
                    onClick={() => {
                      defaultActive();
                    }}
                  >
                    <Cancel color="secondary" />
                  </IconButton>
                </Box>
              </Box>
              <Divider />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems=" stretch"
                width="100%"
              ></Box>
              <Divider />
              <Box
                display="flex"
                flexWrap="wrap"
                alignItems="flex-end"
                borderRadius="0px 50px 50px 0px"
                p={1}
              >
                <TextField
                  inputProps={{ className: clases.inputPadding }}
                  className={clases.formD}
                  size="small"
                  id="asunto"
                  label="Asunto"
                  value={asunto}
                  name="asunto"
                  error={error.asunto}
                  onChange={(e) => handleChange(e)}
                />{" "}
                <TextField
                  multiline
                  rows={3}
                  variant="outlined"
                  inputProps={{ className: clases.inputPadding }}
                  className={clases.formAllW}
                  size="small"
                  id="descripcion"
                  label="Descripción"
                  value={descripcion}
                  name="descripcion"
                  error={error.descripcion}
                  onChange={(e) => handleChange(e)}
                />{" "}
                <FormControl className={clases.formAllW}>
                  <Box display="flex" alignItems="center">
                    <Typography
                      variant="subtitle1"
                      color={error.correos ? "error" : "primary"}
                    >
                      Enviar copia a:
                    </Typography>
                    <IconButton
                      aria-label=""
                      size="small"
                      onClick={async () => {
                        setitem({
                          ...item,
                          correos: [...item.correos, ""],
                        });
                      }}
                    >
                      <AddCircle fontSize="small" color="secondary" />
                    </IconButton>
                  </Box>
                </FormControl>
                {correos.map((precio, index) => {
                  return (
                    <TextField
                      key={index}
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      id=""
                      value={precio}
                      onChange={(e) => {
                        setitem({
                          ...item,
                          correos: correos.map((item, indexItem) => {
                            if (indexItem === index) {
                              return e.target.value;
                            } else {
                              return item;
                            }
                          }),
                        });
                      }}
                      label={`Correo #${index + 1}`}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment>
                            <IconButton
                              aria-label=""
                              onClick={() => {
                                setitem({
                                  ...item,
                                  correos: correos.filter((item, indexItem) => {
                                    return indexItem !== index;
                                  }),
                                });
                              }}
                            >
                              <Delete color="error" />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  );
                })}
                <Dropzone setimagenes={setimagenes} fotosItem={fotos} />
              </Box>
              {/* <Divider /> */}
              <Box
                display="flex"
                width="100%"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="flex-end"
                  mt={1}
                >
                  <Button
                    style={{ width: "30%" }}
                    variant="contained"
                    color="primary"
                    disabled={cargando}
                    onClick={() => {
                      handleCrear();
                    }}
                  >
                    Guardar
                  </Button>
                </Box>
              </Box>
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default memo(Soporte);
