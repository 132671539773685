import React, { memo, useContext, useEffect } from "react";

import { useState } from "react";

// import ConfirmacionDialog from "../../Dialog/ConfirmacionDialog";

import ConfirmacionDialog from "../../../../components/Extra/ConfirmacionDialog";

import { withRouter } from "react-router";
import ButtonsAction from "./ButtonsAction";
import AuthContext from "../../../../Context/Auth/AuthContext";

import ModalEmail from "./ModalEmail";
import CuentasPorPagar from "../../CuantasPorCobrar/CuentasPorCobrar";
// MATERIAL
import { makeStyles } from "@mui/styles";
import {
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  Collapse,
  Box,
  Button,
  Divider,
} from "@mui/material";
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  hover: {
    "&:hover": {
      opacity: ".7",
      cursor: "pointer",
    },
  },
  celda: {
    fontSize: ".7rem !important",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));

const Row = (props) => {
  const {
    seteditar,
    arrayExport,
    setarrayExport,
    row,
    index,
    setopen,
    campoExport,
    changeObjetoActivoState,
    socket,
  } = props;
  const { mostrarAlerta } = useContext(AlertaContext);
  const { usuario } = useContext(AuthContext);

  const [openModalEmail, setopenModalEmail] = useState(false);
  const classes = useRowStyles();

  const handleEliminar = async (_id) => {
    try {
      socket.current.emit("client:eliminarData", {
        tabla: "compra",
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        user: usuario.usuario,
        rucEmpresa: usuario.rucempresa,
        compId: row.compId,
      });
    } catch (error) {}
  };
  const funcionEdit = () => {
    changeObjetoActivoState({
      ...row,
    });
    setopen(true);
    seteditar(true);
  };

  useEffect(() => {}, []);
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  const [expandir, setexpandir] = useState(false);
  const [seccion, setseccion] = useState({
    CuentasPorPagarSeccion: true,
  });
  const desdeOtroLugarCompra = true;
  const { CuentasPorPagarSeccion } = seccion;
  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        titulo={`¿Esta seguro de eliminar este registro: ${row.retencion}?`}
        contenido={`La compra ${row.retencion} será eliminada, no podra ser recuperadá`}
        _id={row._id}
        funcion={handleEliminar}
      />
      <ModalEmail open={openModalEmail} setopen={setopenModalEmail} row={row} />
      <TableRow className={classes.root} selected={index % 2 === 0}>
        {/* boton */}
        <ButtonsAction
          socket={socket}
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          row={row}
          setopenConfirmDialog={setopenConfirmDialog}
          funcionEdit={funcionEdit}
          ocultarEditar={false}
          ocultarDelete={false}
          mostrarEstado={false}
          campoExport={campoExport}
          campoEstado=""
          setopenModalEmail={setopenModalEmail}
          expandir={expandir}
          setexpandir={setexpandir}
        />
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {row.retencion}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={
            row.estado === "SIN AUTORIZAR"
              ? `${classes.celda} ${classes.hover}`
              : `${classes.celda} `
          }
          onClick={() => {
            if (row.estado === "SIN AUTORIZAR") {
              // console.log(row);
              const fechaFactura = new Date(row.fechaFactura);
              const hoy = new Date();

              // Calcula la diferencia en milisegundos
              const diferencia = hoy - fechaFactura;

              // Convierte la diferencia a días
              const diasPasados = diferencia / (1000 * 60 * 60 * 24);

              console.log(diasPasados);
              // Verifica si han pasado más de 30 días
              if (diasPasados > 30) {
                return mostrarAlerta(
                  "Han pasado más de 30 días desde la fecha de la factura.",
                  "error"
                );
              } else {
                // console.log(
                //   "No han pasado 30 días desde la fecha de la factura."
                // );
                socket.current.emit("client:actualizarData", {
                  ...row,

                  user: usuario.usuario,
                  rucempresa: usuario.rucempresa,
                  tabla: "compra",
                });
              }
            }
          }}
        >
          <Tooltip
            title={row.estado === "SIN AUTORIZAR" ? "AUTORIZAR" : ""}
            arrow
          >
            <Typography
              variant="inherit"
              color="secondary"
              component={"span"}
              style={{
                color:
                  row.estado === "AUTORIZADO" ||
                  row.estado === "PROCESADO" ||
                  row.estado === "GASTOS PERSONALES"
                    ? "green"
                    : row.estado === "IMPORTADO"
                    ? "#0288d1"
                    : row.estado === "ANULADA"
                    ? "#f44336"
                    : //
                    row.estado === "GENERAR XML"
                    ? "#ffa726"
                    : "#ffa726",
                fontWeight: "bold",
              }}
            >
              {row.estado}
            </Typography>
          </Tooltip>
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={`${classes.celda} ${classes.hover}`}
        >
          {row.fechaAutorizacion.substr(0, 10)}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          ${row.valTotalRetencion}
        </TableCell>
        <TableCell size="small" align="left" className={classes.celda}>
          {row.rucProveedor} {row.nomProveedor}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={`${classes.celda}`}
        >
          <>{row.factura}</>
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {row.fechaFactura.substr(0, 10)}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          ${row.valorFactura}
        </TableCell>{" "}
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
          style={{
            color: row.envio === "ENVIADO" ? "green" : "#f44336",
            fontWeight: "bold",
          }}
        >
          {row.envio}
        </TableCell>
      </TableRow>{" "}
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={21}>
          <Collapse in={expandir} timeout="auto" unmountOnExit>
            <Box
              mt={1}
              display="flex"
              justifyContent="space-between"
              alignItems=" stretch"
              width="100%"
            >
              <Box>
                <Button
                  size="small"
                  variant={CuentasPorPagarSeccion ? "contained" : null}
                  onClick={async () => {
                    setseccion({ CuentasPorPagarSeccion: true });
                  }}
                  disableElevation
                  style={{
                    padding: ".5rem",
                    borderRadius: "15px 15px  0px 0px",
                  }}
                  color="secondary"
                >
                  Cuentas Por Pagar
                </Button>
              </Box>
            </Box>{" "}
            <Divider style={{ display: "block", width: "100%" }} />
            <Box p={2}>
              {CuentasPorPagarSeccion ? (
                <CuentasPorPagar
                  documento={row.rucProveedor + "&idCompra=" + row.compId}
                  settab={() => {}}
                  setarrayExportHijos={() => {}}
                  desdeOtroLugarCompra={desdeOtroLugarCompra}
                  itemRelacionadoPadre={row}
                />
              ) : null}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
