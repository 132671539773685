import React, { useReducer, memo } from "react";
import clienteAxios from "../../config/axios";
import { OBTENER, EDITAR, CHANGE_ACTIVO, CLEAR_ROWS } from "../../types";

//context
import MenuContext from "./MenuContext";
//reducer
import MenuReducer from "./MenuReducer";
const MenuState = (props) => {
  const initialState = {
    rows: null,
    columns: [
      {
        id: "nombre",
        label: `nombre`,
        minWidth: 150,
      },
      {
        id: "permisos",
        label: "permisos",
        minWidth: 150,
      },
      {
        id: "iconoIzquierda",
        label: "icono Izquierda",
        minWidth: 150,
      },
      {
        id: "iconoDerecha",
        label: "icono Derecha",
        minWidth: 150,
      },
      {
        id: "orden",
        label: "orden",
        minWidth: 50,
      },
    ],
    categoriaActiva: {
      sidId: 0,
      sidInfo: {
        orden: 1,
        path: "",
        name: "",
        icon: "",
        component: "",
        layout: "",
        tipo: "",
        ruta: false,
        expandible: true,
        permisos: ["USUARIO"],
        agregar: ["USUARIO"],
        editar: ["USUARIO"],
        ver: ["USUARIO"],
        eliminar: ["USUARIO"],
        impExp: ["USUARIO"],
        categoriaEmpresa: [],
        tutoriales: [],
      },
    },
  };

  const [state, dispatch] = useReducer(MenuReducer, initialState);

  const obtenerCategorias = async () => {
    const res = await clienteAxios.get("/sidebar");

    try {
      dispatch({
        type: OBTENER,
        payload: res.data.data,
      });
    } catch (error) {}
  };

  const editarCategoria = async (categoria) => {
    try {
      await clienteAxios.put(`/sidebar/${categoria.sidId}`, {
        info: categoria.sidInfo,
      });

      dispatch({
        type: EDITAR,
        payload: categoria,
      });
    } catch (error) {}
  };

  const changeCategoriaActiva = (asignatura) => {
    dispatch({
      type: CHANGE_ACTIVO,
      payload: asignatura,
    });
  };
  const clear = () => {
    dispatch({
      type: CLEAR_ROWS,
    });
  };
  return (
    <MenuContext.Provider
      value={{
        //state
        rows: state.rows,
        columns: state.columns,
        categoriaActiva: state.categoriaActiva,
        // funciones
        obtenerCategorias,
        editarCategoria,
        changeCategoriaActiva,
        clear,
      }}
    >
      {props.children}
    </MenuContext.Provider>
  );
};

export default memo(MenuState);
