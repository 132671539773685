import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import clienteAxios from "../../../config/axios";
import { useEffect, useState } from "react";
import { useSimpleTable } from "../../../hooks/useSimpleTable";
import TablaCabecera from "../../components/Tablas/TablaCabecera";
import ToolBarTable from "../../components/Tablas/ToolBarTable";
import { useRowStyles } from "../../../styles/stylesRows";
import { RowHistorial } from "./RowHistorial";

export const DialogHistorial = ({ open, setopen, row }) => {
  const [historial, sethistorial] = useState([]);
  const getHistorial = async () => {
    const res = await clienteAxios(
      `historial_acta_entrega_recepcion/listarByIdActa/${row.actaId}`
    );
    sethistorial(res.data.data);
  };
  useEffect(() => {
    getHistorial();
  }, []);
  const {
    // agregandoHistorial,
    // setagregandoHistorial,
    pageHistorial,
    // setPageHistorial,
    rowsPerPageHistorial,
    // setRowsPerPageHistorial,
    handleChangePageHistorial,
    handleChangeRowsPerPageHistorial,
  } = useSimpleTable("Historial");
  return (
    <Dialog
      open={open}
      onClose={() => setopen(false)}
      scroll={"paper"}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullWidth
      maxWidth={"md"}
    >
      <Box>
        <DialogTitle
          id="scroll-dialog-title"
          sx={{ padding: ".5rem" }}
          align="center"
        >
          Historial
        </DialogTitle>
        <DialogContent dividers sx={{}}>
          <Paper sx={{ width: "100%" }}>
            <ToolBarTable
              count={historial.length}
              rowsPerPage={rowsPerPageHistorial}
              page={pageHistorial}
              handleChangePage={handleChangePageHistorial}
              handleChangeRowsPerPage={handleChangeRowsPerPageHistorial}
              ocultarPaginacion={false}
              simplePagination={true}
              botonesIzquierdos={[]}
              botonesDerechos={[]}
            />

            <TableContainer>
              <Table stickyHeader aria-label="sticky table" size="small">
                <TableHead>
                  <TablaCabecera
                    columns={[
                      {
                        id: "usuario",
                        label: "usuario",
                        minWidth: 150,
                        tipo: "string",
                        propiedad: "usuario",
                        align: "center",
                      },
                      {
                        id: "estado",
                        label: "estado",
                        minWidth: 150,
                        tipo: "string",
                        propiedad: "estado",
                        align: "center",
                      },
                      {
                        id: "Tecnicos",
                        label: "Tecnicos",
                        minWidth: 150,
                        tipo: "string",
                        propiedad: "Tecnicos",
                        align: "center",
                      },
                      {
                        id: "fecha",
                        label: "fecha",
                        minWidth: 150,
                        tipo: "string",
                        propiedad: "fecha",
                        align: "center",
                      },
                    ]}
                    habilitarOrdenar={false}
                    ocultarPrimeraColumna={true}
                  />
                </TableHead>

                <TableBody component={"div"}>
                  {historial.map((item, index) => {
                    return <RowHistorial item={item} index={index} />;
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setopen(false)}
            fullWidth
            color="primary"
            variant="contained"
          >
            OK
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
