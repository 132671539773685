import React, { memo, useContext } from "react";
import IconBreadcrumbs from "../MigasDePan/MigasDePan";

import { withRouter } from "react-router";
import { makeStyles } from "@mui/styles";
import { Box, IconButton, TextField } from "@mui/material";
import { Backspace, FindInPage } from "@mui/icons-material";
import Botones from "../Botones";
import AlertaContext from "../../../Context/Alerta/AlertaContext";
const useStyles = makeStyles((theme) => ({
  button: {
    [theme.breakpoints.down("md")]: {
      padding: "5px",
      margin: "2px",
      width: "100%",
    },
    fontWeight: "bold",
    margin: theme.spacing(1),
  },
  margin: {
    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "100%",
    },
    margin: theme.spacing(1),
    width: "60%",
  },
}));
const BarraTablas = (props) => {
  const classes = useStyles();

  const {
    search,
    setsearch,
    funcion,
    buscando,
    LabelBuscador,
    funcionDefault,
    ocultarMigasDePan,
    botones,
  } = props;
  // const { usuario } = useContext(AuthContext);

  const { mostrarAlerta } = useContext(AlertaContext);
  const handleSubmit = (e) => {
    e.preventDefault();

    if (search.trim() === "") {
      return mostrarAlerta("ingrese una busqueda", "error");
    }

    funcion();
  };

  return (
    <div>
      {ocultarMigasDePan ? null : <IconBreadcrumbs />}
      <Box
        display="flex"
        justifyContent="space-between"
        width="100%"
        alignItems="center"
        flexWrap="wrap"
        flexGrow="1"
      >
        <form onSubmit={handleSubmit} className={classes.margin}>
          <TextField
            size="small"
            label={LabelBuscador ? LabelBuscador : "Buscar"}
            variant="outlined"
            fullWidth
            color="primary"
            value={search}
            onChange={(e) => setsearch(e.target.value)}
            InputProps={{
              endAdornment: (
                <Box display="flex" justifyContent="space-between">
                  {buscando ? (
                    <IconButton
                      aria-label=""
                      color="secondary"
                      onClick={async () => {
                        await funcionDefault();
                      }}
                    >
                      <Backspace color="error" />
                    </IconButton>
                  ) : null}
                  <IconButton aria-label="" color="secondary" type="submit">
                    <FindInPage />
                  </IconButton>
                </Box>
              ),
              labelWidth: "600",
            }}
          />
        </form>

        <Box
          display="flex"
          justifyContent="flex-end"
          flexWrap="wrap"
          flexGrow="1"
        >
          <Botones botones={botones} />
        </Box>
      </Box>
    </div>
  );
};

export default withRouter(memo(BarraTablas));
