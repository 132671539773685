import React, { memo, useState } from "react";

// MATERIAL
import { makeStyles } from "@mui/styles";
import { Box, IconButton, TableCell, TableRow, TextField } from "@mui/material";
import { Cancel, Check } from "@mui/icons-material";
import { v4 } from "uuid";

const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  margin: {
    margin: "0px",
    padding: "2px",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));
const RowAgregarArma = (props) => {
  const { setagregar, setitem, item } = props;
  const [itemRow, setItemRow] = useState({
    order: "",
    alimento: "",
    dosis: "",
    cantidad: "1",
    frecuencia: "1",
    inicio: "0",
  });
  const handleChange = (e) => {
    setItemRow({ ...itemRow, [e.target.name]: e.target.value });
  };
  const botonDisabled = () => {
    if (itemRow.nombre === "" || itemRow.cantidad === "") {
      return true;
    } else {
      return false;
    }
  };

  const classes = useRowStyles();
  //gql eliminar user

  const handleAgregar = async () => {
    try {
      const order = v4();
      const hospAlimentos = {
        alimentosCabecera: [
          {
            ...itemRow,
            order,
          },
          ...item.hospAlimentos.alimentosCabecera,
        ],
        alimentosHorario: [
          {
            fecha: new Date(
              Date.now() - new Date().getTimezoneOffset() * 60 * 1000
            )
              .toISOString()
              .substr(0, 10),
            nameAlimentosHorarios: itemRow.alimento,
            h0: "",
            h1: "",
            h2: "",
            h3: "",
            h4: "",
            h5: "",
            h6: "",
            h7: "",
            h8: "",
            h9: "",
            h10: "",
            h11: "",
            h12: "",
            h13: "",
            h14: "",
            h15: "",
            h16: "",
            h17: "",
            h18: "",
            h19: "",
            h20: "",
            h21: "",
            h22: "",
            h23: "",
            order,
          },
          ...item.hospAlimentos.alimentosHorario,
        ],
      };

      setitem({
        ...item,
        hospAlimentos,
      });
    } catch (error) {}
  };

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            alignItems="center"
          >
            <IconButton
              className={classes.margin}
              onClick={() => {
                setagregar(false);
                setItemRow({
                  order: "",
                  alimento: "",
                  dosis: "",
                  cantidad: "1",
                  frecuencia: "1",
                  inicio: "0",
                });
              }}
            >
              <Cancel fontSize="small" color="secondary" />
            </IconButton>
            <IconButton
              className={classes.margin}
              disabled={botonDisabled()}
              color={botonDisabled() ? "secondary" : "inherit"}
              onClick={() => {
                handleAgregar();

                setagregar(false);
                setItemRow({
                  order: "",
                  alimento: "",
                  dosis: "",
                  cantidad: "1",
                  frecuencia: "1",
                  inicio: "0",
                });
              }}
            >
              <Check fontSize="small" />
            </IconButton>{" "}
          </Box>
        </TableCell>
        {/* las demas columnas */}

        <>
          <TableCell
            checkbox
            size="small"
            align="center"
            className={classes.celda}
          >
            <TextField
              name="alimento"
              value={itemRow.alimento}
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
            />
          </TableCell>
          <TableCell
            checkbox
            size="small"
            align="center"
            className={classes.celda}
          >
            <TextField
              name="dosis"
              value={itemRow.dosis}
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
            />
          </TableCell>

          <TableCell
            checkbox
            size="small"
            align="center"
            className={classes.celda}
          >
            <TextField
              type={"number"}
              name="cantidad"
              value={itemRow.cantidad}
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
            />
          </TableCell>

          <TableCell
            checkbox
            size="small"
            align="center"
            className={classes.celda}
          >
            <TextField
              type={"number"}
              name="inicio"
              value={itemRow.inicio}
              onChange={(e) => {
                setItemRow({
                  ...itemRow,
                  inicio:
                    e.target.valueAsNumber > 23
                      ? 23
                      : e.target.valueAsNumber < 0
                      ? 0
                      : e.target.valueAsNumber,
                });
              }}
              size="small"
              fullWidth
            />
          </TableCell>
          <TableCell
            checkbox
            size="small"
            align="center"
            className={classes.celda}
          >
            <TextField
              type={"number"}
              name="frecuencia"
              value={itemRow.frecuencia}
              onChange={(e) => {
                setItemRow({
                  ...itemRow,
                  frecuencia:
                    e.target.valueAsNumber < 1
                      ? 1
                      : e.target.valueAsNumber > 23
                      ? 23
                      : e.target.valueAsNumber,
                });
              }}
              size="small"
              fullWidth
            />
          </TableCell>
        </>
      </TableRow>
    </React.Fragment>
  );
};

export default memo(RowAgregarArma);
