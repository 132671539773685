import React, { memo, useContext, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { MenuItem, TableCell, TableRow, TextField } from "@mui/material";

import { useState } from "react";

// import ConfirmacionDialog from "../../Dialog/ConfirmacionDialog";

import ConfirmacionDialog from "../../../../components/Extra/ConfirmacionDialog";

import { withRouter } from "react-router";
import ButtonsAction from "../../../../components/Extra/ButtonsAction";
import AuthContext from "../../../../Context/Auth/AuthContext";
import { tienePermisoRuta } from "../../../../config/validaciones";
import MenuContext from "../../../../Context/Menu/MenuContext";
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import {
  permisoDenagado,
  replaceCaracteres,
  trimPropsItem,
} from "../../../../config/const";

const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  celda: {
    fontSize: ".7rem !important",
    "&:hover": {
      opacity: ".7",
      cursor: "pointer",
    },
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));

const Row = (props) => {
  const { arrayExport, setarrayExport, row, index, campoExport, socket } =
    props;
  const { rows: rowsMenu } = useContext(MenuContext);
  const { mostrarAlerta } = useContext(AlertaContext);
  const { usuario } = useContext(AuthContext);
  const classes = useRowStyles();
  const [item, setitem] = useState({ ...row });
  const [editar, seteditar] = useState(false);
  const handleEliminar = async (_id) => {
    if (
      !tienePermisoRuta(
        rowsMenu,
        props.location.pathname,
        usuario.rol,
        "eliminar"
      )
    ) {
      return mostrarAlerta(permisoDenagado, "error");
    }
    try {
      socket.current.emit("client:eliminarData", {
        tabla: "porcentajeRetencion",
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        porUser: usuario.usuario,
        porEmpresa: usuario.rucempresa,
        porId: row.porId,
      });
    } catch (error) {}
  };
  const funcionEdit = () => {
    if (
      !tienePermisoRuta(
        rowsMenu,
        props.location.pathname,
        usuario.rol,
        "editar"
      )
    ) {
      return mostrarAlerta(permisoDenagado, "error");
    }
    seteditar(true);
  };

  const funcCancel = () => {
    seteditar(false);
  };
  const funcSubmit = () => {
    try {
      socket.current.emit(
        "client:actualizarData",
        trimPropsItem({
          ...item,
          tabla: "porcentajeRetencion",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          porUser: usuario.usuario,
          porEmpresa: usuario.rucempresa,
        })
      );
      seteditar(false);
    } catch (error) {}
  };
  const botonDisabled = () => {
    if (
      item.porImpuesto === "" ||
      item.porCodigo === "" ||
      item.porPorcentaje === "" ||
      item.porConcepto === ""
    ) {
      return true;
    } else {
      return false;
    }
  };
  useEffect(() => {}, []);
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        titulo={`¿Esta seguro de eliminar este registro: ${row.porCodigo}?`}
        contenido={`El Código ${row.porCodigo} será eliminado, no podrá ser recuperado.`}
        _id={row._id}
        funcion={handleEliminar}
      />

      <TableRow className={classes.root} selected={index % 2 === 0}>
        {/* boton */}
        <ButtonsAction
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          row={row}
          setopenConfirmDialog={setopenConfirmDialog}
          funcionEdit={funcionEdit}
          ocultarEditar={false}
          ocultarDelete={false}
          mostrarEstado={false}
          campoExport={campoExport}
          campoEstado="empEstado"
          editar={editar}
          funcCancel={funcCancel}
          funcSubmit={funcSubmit}
          botonDisabled={botonDisabled}
        />{" "}
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {editar ? (
            <TextField
              id=""
              value={item.porImpuesto}
              onChange={(e) => {
                const res = replaceCaracteres(e.target.value);
                setitem({
                  ...item,
                  porImpuesto: res,
                });
              }}
              size="small"
              helperText="Editando"
              fullWidth
              select
              InputLabelProps={{ shrink: true }}
              SelectProps={{ displayEmpty: true }}
            >
              <MenuItem value="">Seleccione</MenuItem>
              {["RENTA", "IVA", "ISD"].map((itemRow) => {
                return <MenuItem value={itemRow}>{itemRow} </MenuItem>;
              })}
            </TextField>
          ) : (
            `${item.porImpuesto}`
          )}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {editar ? (
            <TextField
              id=""
              value={item.porCodigo}
              onChange={(e) => {
                const res = replaceCaracteres(e.target.value);
                setitem({
                  ...item,
                  porCodigo: res,
                });
              }}
              size="small"
              fullWidth
              helperText="Editando"
            />
          ) : (
            `${item.porCodigo}`
          )}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {editar ? (
            <TextField
              id=""
              value={item.porPorcentaje}
              onChange={(e) => {
                const res = replaceCaracteres(e.target.value);
                setitem({
                  ...item,
                  porPorcentaje: res,
                });
              }}
              size="small"
              fullWidth
              helperText="Editando"
            />
          ) : (
            `${item.porPorcentaje}`
          )}
        </TableCell>
        <TableCell size="small" align="left" className={classes.celda}>
          {editar ? (
            <TextField
              id=""
              value={item.porConcepto}
              onChange={(e) => {
                const res = replaceCaracteres(e.target.value);
                setitem({
                  ...item,
                  porConcepto: res,
                });
              }}
              size="small"
              fullWidth
              helperText="Editando"
            />
          ) : (
            `${item.porConcepto}`
          )}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
