import React, { memo, useContext, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
  TableCell,
  TableRow,
  TextField,
  FormControl,
  MenuItem,
  Autocomplete,
} from "@mui/material";

import { useState } from "react";

import { withRouter } from "react-router";

import ConfirmacionDialog from "../../../../../../components/Extra/ConfirmacionDialog";
import ButtonsAction from "./ButtonsAction";

import AuthContext from "../../../../../../Context/Auth/AuthContext";
import AlertaContext from "../../../../../../Context/Alerta/AlertaContext";
import clienteAxios from "../../../../../../config/axios";

const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  celda: {
    fontSize: ".7rem !important",
  },
  hover: {
    "&:hover": {
      opacity: ".7",
      cursor: "pointer",
    },
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));

const RowArma = (props) => {
  const {
    row,
    index,
    campoExport,
    item,
    setitem,

    setPagoAdicionalsData,
    PagoAdicionalsData,
  } = props;

  const [itemHijoEdit, setitemHijoEdit] = useState(row);
  const { mostrarAlerta } = useContext(AlertaContext);
  const [open, setOpen] = useState(false);
  const [imagenes, setimagenes] = useState([]);
  const [cargando, setcargando] = useState(false);
  // TABLA

  const { usuario } = useContext(AuthContext);
  const handleChange = (e) => {
    setitemHijoEdit({
      ...itemHijoEdit,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    setitemHijoEdit(row);
    // eslint-disable-next-line
  }, [item]);
  const deshabilitarbotones = () => {
    if (row.padiEstado === "ACTIVA") {
      return false;
    } else {
      return true;
    }
  };
  const classes = useRowStyles();
  const handleEliminar = async (_id) => {
    await clienteAxios.delete(`/pagosadicionales/${itemHijoEdit.padiId}`);
    const res = PagoAdicionalsData.filter((itemMantenimientoData) => {
      return itemMantenimientoData.padiId !== itemHijoEdit.padiId;
    });

    setPagoAdicionalsData(res);
  };
  const funcionEdit = () => {
    seteditar(true);
  };
  const [editar, seteditar] = useState(false);
  const funcCancel = () => {
    setitemHijoEdit(row);
    seteditar(false);
  };
  const botonDisabled = () => {
    if (
      itemHijoEdit.padiContrato === "" ||
      itemHijoEdit.padiIva === "" ||
      itemHijoEdit.padiValor === "" ||
      itemHijoEdit.padiDetalle === "" ||
      itemHijoEdit.padiFecCobro === ""
    ) {
      return true;
    } else {
      return false;
    }
  };
  const funcSubmit = async () => {
    setcargando(true);
    try {
      const resItem = await clienteAxios.post("/pagosadicionales", {
        ...itemHijoEdit,
        padiUser: usuario.usuario,
        padiEmpresa: usuario.rucempresa,
      });

      const mantenimientosDataR = [
        ...PagoAdicionalsData.map((itemMantenimientoData) => {
          if (itemMantenimientoData.padiId === itemHijoEdit.padiId) {
            return resItem.data.data;
          } else {
            return itemMantenimientoData;
          }
        }),
      ];
      mostrarAlerta("Pago Adicional Actualizado Correctamente", "success");
      setPagoAdicionalsData(mantenimientosDataR);
      setimagenes([]);
      setcargando(false);
      seteditar(false);
    } catch (error) {
      mostrarAlerta("Hubo un error", "error");
      setcargando(false);
      seteditar(false);
    }
  };
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);

  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        titulo={`¿Esta seguro que quiere eliminar: ${row.padiContrato}?`}
        _id={itemHijoEdit._id}
        funcion={handleEliminar}
      />
      <TableRow className={classes.root} selected={index % 2 === 0}>
        {/* boton */}
        <ButtonsAction
          setitem={setitem}
          index={index}
          setitemHijoEdit={setitemHijoEdit}
          itemHijoEdit={itemHijoEdit}
          row={row}
          item={item}
          setopenConfirmDialog={setopenConfirmDialog}
          funcionEdit={funcionEdit}
          ocultarEditar={false}
          ocultarDelete={false}
          mostrarEstado={false}
          campoExport={campoExport}
          campoEstado="estado"
          funcCancel={funcCancel}
          funcSubmit={funcSubmit}
          editar={editar}
          deshabilitarbotones={deshabilitarbotones}
          botonDisabled={botonDisabled}
          open={open}
          setOpen={setOpen}
          imagenes={imagenes}
          setimagenes={setimagenes}
          cargando={cargando}
        />

        {editar ? (
          <>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              <FormControl fullWidth>
                <Autocomplete
                  autoComplete="false"
                  options={item.perISPContratos.map((itemContrato) => {
                    return itemContrato.perNombreContrato;
                  })}
                  getOptionLabel={(option) => {
                    return option.replace("/+/", " ** ").replace("/+/", " ** ");
                  }}
                  value={itemHijoEdit.padiContrato}
                  paperprops
                  onChange={async (event, newValue) => {
                    if (newValue) {
                      setitemHijoEdit({
                        ...itemHijoEdit,
                        padiContrato: newValue,
                      });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      required
                      autoComplete={false}
                      fullWidth
                      onChange={async (e) => {}}
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  )}
                />
              </FormControl>
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              <TextField
                size="small"
                fullWidth
                value={itemHijoEdit.padiDetalle}
                name="padiDetalle"
                onChange={(e) => handleChange(e)}
              />
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              <TextField
                type="number"
                size="small"
                fullWidth
                value={itemHijoEdit.padiValor}
                name="padiValor"
                onChange={(e) => handleChange(e)}
              />
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              <TextField
                size="small"
                fullWidth
                value={itemHijoEdit.padiIva}
                name="padiIva"
                onChange={(e) => handleChange(e)}
                select
                InputLabelProps={{ shrink: true }}
                SelectProps={{ displayEmpty: true }}
              >
                <MenuItem value="">Seleccione.</MenuItem>
                {["SI", "NO"].map((item) => {
                  return <MenuItem value={item}>{item} </MenuItem>;
                })}
              </TextField>
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              <TextField
                type="date"
                size="small"
                fullWidth
                value={itemHijoEdit.padiFecCobro}
                name="padiFecCobro"
                onChange={(e) => handleChange(e)}
              />
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              {usuario.usuario}
            </TableCell>
          </>
        ) : (
          <>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              {row.padiContrato}
            </TableCell>

            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              {row.padiDetalle}
            </TableCell>

            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              ${row.padiValor}
            </TableCell>

            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              {row.padiIva}
            </TableCell>

            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              {row.padiFecCobro}
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              {row.padiUser}
            </TableCell>
          </>
        )}
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(RowArma));
