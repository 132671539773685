import React, { memo } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  TextField,
  MenuItem,
} from "@mui/material";

import { Cancel, Check } from "@mui/icons-material";

import { useState } from "react";

const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  margin: {
    margin: "0px",
    padding: "2px",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));
const RowAgregarBodega = (props) => {
  const { setagregar, setitem, item, bodegaData } = props;
  const [itemRow, setItemRow] = useState({
    estado: "INACTIVA",
    nomBodega: "",
    stock: "",
    maximo: "0",
    minimo: "0",
  });
  const botonDisabled = () => {
    if (
      itemRow.nomBodega === "" ||
      itemRow.stock === "" ||
      itemRow.maximo === "" ||
      itemRow.minimo === ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  const classes = useRowStyles();
  //gql eliminar user

  const handleAgregar = async () => {
    try {
      const invBodegas = [
        {
          ...itemRow,
          estado: item.invBodegas.find((itemBodega) => {
            return itemBodega.estado === "ACTIVA";
          })
            ? "INACTIVA"
            : "ACTIVA",
        },
        ...item.invBodegas,
      ];

      setitem({
        ...item,
        invBodegas,
      });
    } catch (error) {}
  };
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            alignItems="center"
          >
            <IconButton
              className={classes.margin}
              onClick={() => {
                setagregar(false);
                setItemRow({
                  estado: "INACTIVA",
                  nomBodega: "",
                  stock: "",
                  maximo: "0",
                  minimo: "0",
                });
              }}
            >
              <Cancel fontSize="small" color="secondary" />
            </IconButton>
            <IconButton
              className={classes.margin}
              disabled={botonDisabled()}
              color={botonDisabled() ? "secondary" : "inherit"}
              onClick={() => {
                handleAgregar();

                setagregar(false);
                setItemRow({
                  estado: "INACTIVA",
                  nomBodega: "",
                  stock: "",
                  maximo: "0",
                  minimo: "0",
                });
              }}
            >
              <Check fontSize="small" />
            </IconButton>{" "}
            {/* <FormControlLabel
              className={classes.margin}
              onChange={(e) => {
                setitem({
                  ...item,
                  ["ëstado"]: e.target.checked ? "ACTIVA" : "INACTIVA",
                });
              }}
              control={
                <Checkbox
                  value="ACTIVA"
                  className={classes.margin}
                  checked={
                    item["ëstado"] === "ACTIVA" || item["ëstado"] === "ACTIVA"
                      ? true
                      : false
                  }
                  color="secondary"
                />
              }
            /> */}
          </Box>
        </TableCell>
        {/* las demas columnas */}
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          <TextField
            id="bodId"
            value={itemRow.bodId}
            name="bodId"
            onChange={(e) => {
              if (e.target.value === "") {
                return setItemRow({
                  ...itemRow,
                  bodId: "",
                  nomBodega: "",
                });
              }
              const itemR = bodegaData.find(
                (itemBodega) => itemBodega.bodId === e.target.value
              );

              setItemRow({
                ...itemRow,
                bodId: e.target.value,
                nomBodega: itemR.bodNombre,
              });
            }}
            size="small"
            fullWidth
            helperText="Agregando"
            select
            InputLabelProps={{ shrink: true }}
            SelectProps={{ displayEmpty: true }}
          >
            <MenuItem value="">Seleccione</MenuItem>
            {bodegaData.map((itemRow) => {
              return (
                <MenuItem value={itemRow.bodId}>{itemRow.bodNombre} </MenuItem>
              );
            })}
          </TextField>
        </TableCell>

        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          <TextField
            id=""
            type="number"
            value={itemRow.stock}
            onChange={(e) =>
              setItemRow({
                ...itemRow,
                stock: e.target.value,
              })
            }
            size="small"
            fullWidth
            helperText="Agregando"
          />
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          <TextField
            id=""
            type="number"
            value={itemRow.maximo}
            onChange={(e) =>
              setItemRow({
                ...itemRow,
                maximo: e.target.value,
              })
            }
            size="small"
            fullWidth
            helperText="Agregando"
          />
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          <TextField
            id=""
            type="number"
            value={itemRow.minimo}
            onChange={(e) =>
              setItemRow({
                ...itemRow,
                minimo: e.target.value,
              })
            }
            size="small"
            fullWidth
            helperText="Agregando"
          />
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default memo(RowAgregarBodega);
