import React, { memo, useContext } from "react";

import { useState } from "react";
import AuthContext from "../../../../../Context/Auth/AuthContext";
// MATERIAL
import { makeStyles } from "@mui/styles";
import { Box, IconButton, TableCell, TableRow, TextField } from "@mui/material";
import { Cancel, Check } from "@mui/icons-material";
const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  margin: {
    margin: "0px",
    padding: "2px",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));

const RowAgregarItem = (props) => {
  // eslint-disable-next-line

  const { itemPadre, setitemPadre, setagregarItemLista, socket } = props;
  const { usuario } = useContext(AuthContext);
  const [itemNuevo, setItemNuevo] = useState({
    nombre: "",
  });

  const classes = useRowStyles();
  //gql eliminar user

  const handleChange = (e) => {
    setItemNuevo({
      ...itemNuevo,
      [e.target.name]: e.target.value,
    });
  };

  const botonDisabled = () => {
    if (itemNuevo.nombre === "") {
      return true;
    } else {
      return false;
    }
  };

  //gql eliminar user

  const handleAgregar = async () => {
    try {
      const espRazas = [
        {
          ...itemNuevo,
        },
        ...itemPadre.espRazas,
      ];

      socket.current.emit("client:actualizarData", {
        ...itemPadre,
        tabla: "especie",
        espRazas,
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        espUser: `${itemPadre.espUser.split(" ** ")[0]} ** ${usuario.usuario}`,
        espEmpresa: usuario.rucempresa,
      });
      setitemPadre({
        ...itemPadre,
        espRazas,
      });
    } catch (error) {}
  };

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            alignItems="center"
          >
            <IconButton
              className={classes.margin}
              onClick={() => {
                setagregarItemLista(false);
                setItemNuevo({
                  nombre: "",
                });
              }}
            >
              <Cancel fontSize="small" color="secondary" />
            </IconButton>
            <IconButton
              color={
                botonDisabled
                  ? botonDisabled()
                    ? "inherit"
                    : "secondary"
                  : "secondary"
              }
              disabled={botonDisabled()}
              className={classes.margin}
              onClick={() => {
                handleAgregar();
                setagregarItemLista(false);
                setItemNuevo({
                  nombre: "",
                });
              }}
            >
              <Check fontSize="small" />
            </IconButton>{" "}
          </Box>
        </TableCell>
        {/* las demas columnas */}{" "}
        <TableCell
          fontSize="small"
          padding="none"
          align="center"
          className={classes.celda}
        >
          <TextField
            autoFocus
            id="nombre"
            value={itemNuevo.nombre}
            name="nombre"
            onChange={(e) => handleChange(e)}
            helperText="Agregando"
            size="small"
            fullWidth
          />
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default memo(RowAgregarItem);
