export const columns = [
  {
    id: "tablaNombre",
    label: "Nombre",
    minWidth: 125,
    tipo: "string",
    orden: 1,
    propiedad: "tablaNombre",
    align: "center",
    ocultar: true,
  },
];
export const objectDefault = {
  // campId: 1,
  campTabla: "",
  campEmpresa: "",
  campUser: "",
  campFecReg: "",
  campFecUpd: "",
  campDatos: [
    // {
    //   nombreTab: "Check 1",
    //   campDatos: [
    //     {
    //       name: "lastName",
    //       label: "Last Name",
    //       tipo: "",
    //       required: true,
    //       opciones: [],
    //       order: 1,
    //     },
    //   ],
    // },
  ],
};
export const columnasHijo = [

    {
    id: "label",
    label: "Etiqueta",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "label",
    align: "center",
    ocultar: true,
  },
  {
    id: "tipo",
    label: "tipo",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "tipo",
    align: "center",
    ocultar: true,
  },
  {
    id: "required",
    label: "Obligatorio",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "required",
    align: "center",
    ocultar: true,
  },
  {
    id: "opciones",
    label: "opciones",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "opciones",
    align: "center",
    ocultar: true,
  },
];
