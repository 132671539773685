import React, { memo, useEffect } from "react";
import { useState } from "react";
import { withRouter } from "react-router";
import ConfirmacionDialog from "../../../../../../components/Extra/ConfirmacionDialog";

// MATERIAL
import { makeStyles } from "@mui/styles";
import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import { Cancel, Check, Create, Delete } from "@mui/icons-material";

const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  celda: {
    fontSize: ".7rem !important",
    "&:hover": {
      opacity: ".7",
      cursor: "pointer",
    },
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "50px",
  },
}));

const RowArma = (props) => {
  const { row, index, item, setitem, deshabilitarbotones } = props;

  const [itemRow, setItemRow] = useState(row);
  useEffect(() => {
    setItemRow(row);
    // eslint-disable-next-line
  }, [item]);
  const classes = useRowStyles();
  const handleChange = (e) => {
    setItemRow({ ...itemRow, [e.target.name]: e.target.value });
  };
  const handleEliminar = async (_id) => {
    setitem({
      ...item,
      hospAlimentos: {
        alimentosCabecera: item.hospAlimentos.alimentosCabecera.filter(
          (itemFilter, itemIndex) => {
            return row.order !== itemFilter.order;
          }
        ),
        alimentosHorario: item.hospAlimentos.alimentosHorario.filter(
          (itemFilter, itemIndex) => {
            return row.order !== itemFilter.order;
          }
        ),
      },
    });
  };
  const funcionEdit = () => {
    seteditar(true);
  };
  const [editar, seteditar] = useState(false);
  const funcCancel = () => {
    setItemRow(row);
    seteditar(false);
  };
  const botonDisabled = () => {
    if (itemRow.nombre === "" || itemRow.valor === "") {
      return true;
    } else {
      return false;
    }
  };
  const funcSubmit = () => {
    setitem({
      ...item,
      hospAlimentos: {
        alimentosCabecera: item.hospAlimentos.alimentosCabecera.map(
          (itemFiltermedicamentosCabecera, itemIndex) => {
            if (itemRow.order === itemFiltermedicamentosCabecera.order) {
              return itemRow;
            } else {
              return itemFiltermedicamentosCabecera;
            }
          }
        ),
        alimentosHorario: item.hospAlimentos.alimentosHorario.map(
          (itemFiltermedicamentosHorario, itemIndex) => {
            if (itemRow.order === itemFiltermedicamentosHorario.order) {
              return {
                ...itemFiltermedicamentosHorario,
                nameAlimentosHorarios: itemRow.alimento,
              };
            } else {
              return itemFiltermedicamentosHorario;
            }
          }
        ),
      },
    });
    seteditar(false);
  };
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        titulo="¿Esta seguro que quiere eliminar este Registro?"
        _id={itemRow._id}
        funcion={handleEliminar}
      />

      <TableRow className={classes.root} selected={index % 2 === 0}>
        {/* boton */}

        <TableCell
          size="small"
          padding="checkbox"
          style={{
            borderRight: "1px solid #ccc",
          }}
        >
          <Box display="flex" alignItems="center" justifyContent="center">
            {editar ? (
              <>
                <Tooltip title="Cancelar">
                  <IconButton
                    size="small"
                    onClick={() => {
                      funcCancel();
                    }}
                  >
                    <Cancel fontSize="small" color="secondary" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Confirmar">
                  <IconButton
                    size="small"
                    disabled={botonDisabled ? botonDisabled() : false}
                    onClick={() => {
                      funcSubmit();
                    }}
                  >
                    <Check
                      fontSize="small"
                      color={
                        botonDisabled
                          ? botonDisabled()
                            ? "inherit"
                            : "secondary"
                          : "secondary"
                      }
                    />
                  </IconButton>
                </Tooltip>
              </>
            ) : (
              <>
                {" "}
                <Tooltip title="Eliminar">
                  <IconButton
                    size="small"
                    disabled={deshabilitarbotones}
                    onClick={() => {
                      setopenConfirmDialog(true);
                    }}
                  >
                    <Delete
                      fontSize="small"
                      color={deshabilitarbotones ? "inherit" : "error"}
                    />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Editar">
                  <IconButton
                    size="small"
                    color={deshabilitarbotones ? "inherit" : "secondary"}
                    disabled={deshabilitarbotones}
                    onClick={() => {
                      funcionEdit();
                    }}
                  >
                    <Create />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </Box>
        </TableCell>

        {editar ? (
          <>
            <TableCell
              checkbox
              size="small"
              align="center"
              className={classes.celda}
            >
              <TextField
                name="alimento"
                value={itemRow.alimento}
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
              />
            </TableCell>
            <TableCell
              checkbox
              size="small"
              align="center"
              className={classes.celda}
            >
              <TextField
                name="dosis"
                value={itemRow.dosis}
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
              />
            </TableCell>

            <TableCell
              checkbox
              size="small"
              align="center"
              className={classes.celda}
            >
              <TextField
                type={"number"}
                name="cantidad"
                value={itemRow.cantidad}
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
              />
            </TableCell>

            <TableCell
              checkbox
              size="small"
              align="center"
              className={classes.celda}
            >
              <TextField
                type={"number"}
                name="inicio"
                value={itemRow.inicio}
                onChange={(e) => {
                  setItemRow({
                    ...itemRow,
                    inicio:
                      e.target.valueAsNumber > 23
                        ? 23
                        : e.target.valueAsNumber < 0
                        ? 0
                        : e.target.valueAsNumber,
                  });
                }}
                size="small"
                fullWidth
              />
            </TableCell>
            <TableCell
              checkbox
              size="small"
              align="center"
              className={classes.celda}
            >
              <TextField
                type={"number"}
                name="frecuencia"
                value={itemRow.frecuencia}
                onChange={(e) => {
                  setItemRow({
                    ...itemRow,
                    frecuencia:
                      e.target.valueAsNumber < 1
                        ? 1
                        : e.target.valueAsNumber > 23
                        ? 23
                        : e.target.valueAsNumber,
                  });
                }}
                size="small"
                fullWidth
              />
            </TableCell>
          </>
        ) : (
          <>
            <TableCell
              checkbox
              size="small"
              align="center"
              className={classes.celda}
            >
              {itemRow.alimento}
            </TableCell>
            <TableCell
              checkbox
              size="small"
              align="center"
              className={classes.celda}
            >
              {itemRow.dosis}
            </TableCell>
            <TableCell
              checkbox
              size="small"
              align="center"
              className={classes.celda}
            >
              {itemRow.cantidad}
            </TableCell>
            <TableCell
              checkbox
              size="small"
              align="center"
              className={classes.celda}
            >
              {itemRow.inicio}
            </TableCell>
            <TableCell
              checkbox
              size="small"
              align="center"
              className={classes.celda}
            >
              {itemRow.frecuencia}
            </TableCell>
          </>
        )}
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(RowArma));
