import React, { memo, useContext, useEffect } from "react";
import { useState } from "react";
import { withRouter } from "react-router";
import ConfirmacionDialog from "../../../../../components/Extra/ConfirmacionDialog";
import AuthContext from "../../../../../Context/Auth/AuthContext";
import AlertaContext from "../../../../../Context/Alerta/AlertaContext";
// MATERIAL
import { makeStyles } from "@mui/styles";
import {
  TableCell,
  TableRow,
  TextField,
  Box,
  IconButton,
  FormControlLabel,
  Checkbox,
  FormControl,
  Autocomplete,
} from "@mui/material";
import { Cancel, Check, Create, Delete } from "@mui/icons-material";
const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  celda: {
    fontSize: ".7rem !important",
  },
  hover: {
    "&:hover": {
      opacity: ".7",
      cursor: "pointer",
    },
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));

const RowArma = (props) => {
  const {
    observacionContabilidad,
    // agregar,
    // setagregar,
    setcontabilidadData,
    contabilidadData,
    // setitem,
    item,
    row,
    index,
    deshabilitarbotones,
    planDeCuentas,
    contaEliminados,
    setcontaEliminados,
  } = props;

  const [itemHijoEdit, setitemHijoEdit] = useState(row);
  const { mostrarAlerta } = useContext(AlertaContext);
  const [cargando, setcargando] = useState(false);
  // TABLA

  const { usuario } = useContext(AuthContext);
  const handleChange = (e) => {
    setitemHijoEdit({
      ...itemHijoEdit,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    setitemHijoEdit(row);
    // eslint-disable-next-line
  }, [item]);

  const classes = useRowStyles();
  const handleEliminar = async (_id) => {
    try {
      // await clienteAxios.delete(`/contabilidad/${itemHijoEdit.contaId}`);
      const res = contabilidadData.filter((itemMantenimientoData) => {
        return itemMantenimientoData.contaId !== itemHijoEdit.contaId;
      });
      setcontaEliminados([itemHijoEdit, ...contaEliminados]);
      setcontabilidadData(res);
    } catch (error) {
      mostrarAlerta("Contabilidad: Hubo un error");
    }
  };
  const funcionEdit = () => {
    seteditar(true);
  };
  const [editar, seteditar] = useState(false);
  const funcCancel = () => {
    setitemHijoEdit(row);
    seteditar(false);
  };

  const botonDisabled = () => {
    if (
      itemHijoEdit.contaCuenta === "" ||
      itemHijoEdit.contaCuentaNombre === "" ||
      itemHijoEdit.contaDebe === "" ||
      itemHijoEdit.contaHaber === ""
    ) {
      return true;
    } else {
      return false;
    }
  };
  const funcSubmit = async () => {
    setcargando(true);
    try {
      const resItem = {
        ...itemHijoEdit,
        contaRuc: item.rucProveedor,
        contaNombre: item.nomProveedor,
        contaDocumento: item.factura,
        contaFechaDoc: item.fechaFactura,
        contaObservacion: observacionContabilidad,
        contaUser: usuario.usuario,
        contaEmpresa: usuario.rucempresa,
      };

      const mantenimientosDataR = [
        ...contabilidadData.map((itemMantenimientoData) => {
          if (itemMantenimientoData.contaId === itemHijoEdit.contaId) {
            return resItem;
          } else {
            return itemMantenimientoData;
          }
        }),
      ];

      setcontabilidadData(mantenimientosDataR);
      setcargando(false);
      seteditar(false);
    } catch (error) {
      mostrarAlerta("Hubo un error", "error");
      setcargando(false);
      seteditar(false);
    }
  };
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);

  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        titulo={`¿Esta seguro que quiere eliminar este registro?`}
        _id={itemHijoEdit._id}
        funcion={handleEliminar}
      />
      <TableRow className={classes.root} selected={index % 2 === 0}>
        <TableCell padding="none" style={{ borderRight: "1px solid #ccc" }}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center" minWidth={"4rem"}>
              {editar ? (
                <>
                  <IconButton
                    className={classes.margin}
                    disabled={botonDisabled() || cargando}
                    size="small"
                    onClick={async () => {
                      await funcCancel();
                    }}
                  >
                    <Cancel fontSize="small" color="secondary" />
                  </IconButton>
                  <IconButton
                    className={classes.margin}
                    disabled={botonDisabled() || cargando}
                    size="small"
                    onClick={async () => {
                      await funcSubmit();
                    }}
                  >
                    <Check fontSize="small" color="primary" />
                  </IconButton>
                </>
              ) : (
                <>
                  <IconButton
                    className={classes.margin}
                    disabled={
                      botonDisabled() || cargando || deshabilitarbotones
                    }
                    size="small"
                    onClick={async () => {
                      await setopenConfirmDialog(true);
                    }}
                  >
                    <Delete
                      fontSize="small"
                      color={deshabilitarbotones ? "inherit" : "error"}
                    />
                  </IconButton>
                  <IconButton
                    className={classes.margin}
                    disabled={
                      botonDisabled() || cargando || deshabilitarbotones
                    }
                    size="small"
                    onClick={async () => {
                      await funcionEdit();
                    }}
                  >
                    <Create
                      fontSize="small"
                      color={deshabilitarbotones ? "inherit" : "secondary"}
                    />
                  </IconButton>
                </>
              )}
            </Box>
            <FormControlLabel
              className={classes.margin}
              onChange={(e) => {
                setitemHijoEdit({
                  ...itemHijoEdit,
                  contaEstado: e.target.checked ? "ACTIVO" : "INACTIVA",
                });
              }}
              control={
                <Checkbox
                  disabled={!editar}
                  value="ACTIVO"
                  className={classes.margin}
                  checked={
                    itemHijoEdit["contaEstado"] === "ACTIVO" ? true : false
                  }
                  color="primary"
                />
              }
            />
          </Box>
        </TableCell>
        {editar ? (
          <>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              <FormControl fullWidth>
                <Autocomplete
                  options={planDeCuentas.map((item) => {
                    return item.plancCodigo + "/+/" + item.plancNombre;
                  })}
                  getOptionLabel={(option) => {
                    return option.replace("/+/", " ");
                  }}
                  id="controlled-demo"
                  value={itemHijoEdit.contaCuenta}
                  paperprops
                  onChange={async (event, newValue) => {
                    if (newValue) {
                      const options = newValue.split("/+/");
                      setitemHijoEdit({
                        ...itemHijoEdit,
                        contaCuenta: options[0],
                        contaCuentaNombre: options[1],
                      });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      fullWidth
                      onChange={async (e) => {
                        if (e.target.value === "") {
                          return mostrarAlerta("Ingrese una cuenta", "error");
                        }
                        try {
                          // setproveedoresData(res.data.data);
                        } catch (error) {}
                      }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              <TextField
                size="small"
                fullWidth
                value={itemHijoEdit.contaCuentaNombre}
                name="contaCuentaNombre"
                onChange={(e) => handleChange(e)}
              />
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              <TextField
                type="number"
                size="small"
                fullWidth
                value={itemHijoEdit.contaDebe}
                name="contaDebe"
                onChange={(e) => handleChange(e)}
              />
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              <TextField
                type="number"
                size="small"
                fullWidth
                value={itemHijoEdit.contaHaber}
                name="contaHaber"
                onChange={(e) => handleChange(e)}
              />
            </TableCell>
            {/* "contaObservacion": "La paz => actualizado este registro", */}
          </>
        ) : (
          <>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              {itemHijoEdit.contaCuentaNombre}
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              {itemHijoEdit.contaCuenta}
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              ${itemHijoEdit.contaDebe}
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              ${itemHijoEdit.contaHaber}
            </TableCell>
          </>
        )}
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(RowArma));
