import React, { memo, useContext } from "react";
import { makeStyles } from "@mui/styles";
import { Collapse, TableCell, TableRow } from "@mui/material";

import { useState } from "react";
import ConfirmacionDialog from "../../../../components/Extra/ConfirmacionDialog";
import { withRouter } from "react-router";
import ButtonsAction from "./ButtonsAction";
import AuthContext from "../../../../Context/Auth/AuthContext";
import ModalEmail from "../../../../components/Extra/ModalEmail";
import CallNombrePersona from "../../../components/Tablas/CallNombrePersona";
const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  hover: {
    "&:hover": {
      opacity: ".7",
      cursor: "pointer",
    },
  },
  celda: {
    fontSize: ".7rem !important",
    padding: ".2rem",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));

const Row = (props) => {
  const {
    arrayExport,
    setarrayExport,
    row,
    index,
    campoExport,
    puedeExportar,
    desdeOtroLugar,
    seteditar,
    setopen,
    changeObjetoActivoState,
    socket,
    tienePermiso,
    alertaPermiso,
    eliminadoEstado,
  } = props;
  const { usuario } = useContext(AuthContext);
  const classes = useRowStyles();
  const [celComprimida, setcelComprimida] = useState({});
  const handleEliminar = async (_id) => {
    if (!tienePermiso("eliminar")) {
      return alertaPermiso("eliminar");
    }
    try {
      socket.current.emit("client:eliminarData", {
        nombre: usuario.nombre,
        tabla: "hospitalizacion",
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        hospUser: usuario.usuario,
        hospEmpresa: usuario.rucempresa,
        hospId: row.hospId,
      });
    } catch (error) {}
  };
  const funcionEdit = () => {
    changeObjetoActivoState({ ...row, restaurado: eliminadoEstado });
    setopen(true);
    seteditar(true);
  };
  const funcionCopiar = () => {
    changeObjetoActivoState(row);
    setopen(true);
  };

  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  const [openModalEmail, setopenModalEmail] = useState(false);
  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        titulo={`¿Esta seguro de eliminar este registro?`}
        _id={row._id}
        funcion={handleEliminar}
      />{" "}
      <ModalEmail
        open={openModalEmail}
        setopen={setopenModalEmail}
        id={row.hospId}
        documento={"Hospitalizacion"}
        emailDefecto={row.hospPerEmailPropietario}
        campos={[
          {
            label: "Documento",
            propiedad: row.hospPerDocNumeroPropietario,
          },
          {
            label: "Nombre",
            propiedad: row.hospPerNombrePropietario,
          },
        ]}
      />
      <TableRow className={classes.root} selected={index % 2 === 0}>
        {/* boton */}
        <ButtonsAction
          eliminadoEstado={eliminadoEstado}
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          row={row}
          setopenConfirmDialog={setopenConfirmDialog}
          funcionEdit={funcionEdit}
          ocultarEditar={false}
          ocultarDelete={false}
          mostrarEstado={false}
          campoExport={campoExport}
          campoEstado=""
          puedeExportar={puedeExportar}
          desdeOtroLugar={desdeOtroLugar}
          funcionCopiar={funcionCopiar}
          setopenModalEmail={setopenModalEmail}
          socket={socket}
        />
        <>
          <TableCell
            checkbox
            size="small"
            align="center"
            className={classes.celda}
          >
            {row.hospId}
          </TableCell>
          <TableCell
            checkbox
            size="small"
            align="center"
            className={classes.celda}
          >
            {row.hospMascNombre}
          </TableCell>
          <TableCell
            checkbox
            size="small"
            align="center"
            className={classes.celda}
          >
            {row.hospPerDocNumeroPropietario +
              " " +
              row.hospPerNombrePropietario}
          </TableCell>
          <TableCell
            checkbox
            size="small"
            align="center"
            className={classes.celda}
          >
            {row.hospPerNombreDoc}
          </TableCell>
          <TableCell
            checkbox
            size="small"
            align="center"
            className={classes.celda}
          >
            {row.hospMascPeso}
          </TableCell>
          <TableCell
            checkbox
            size="small"
            align="center"
            className={classes.celda}
          >
            {row.hospEstado}
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={`${classes.celda} ${classes.hover}`}
            onClick={() =>
              setcelComprimida({
                ...celComprimida,
                hospDiagnostico:
                  row.hospDiagnostico.length > 30
                    ? !celComprimida["hospDiagnostico"]
                    : celComprimida["hospDiagnostico"],
              })
            }
          >
            {!celComprimida["hospDiagnostico"] ? (
              <>
                {row.hospDiagnostico.length > 30
                  ? `${row.hospDiagnostico.substr(0, 30)}...`
                  : `${row.hospDiagnostico.substr(0, 30)}`}
              </>
            ) : null}
            <Collapse
              in={celComprimida["hospDiagnostico"]}
              timeout="auto"
              unmountOnExit
            >
              {row.hospDiagnostico}
            </Collapse>
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={`${classes.celda} ${classes.hover}`}
            onClick={() =>
              setcelComprimida({
                ...celComprimida,
                hospObservacion:
                  row.hospObservacion.length > 30
                    ? !celComprimida["hospObservacion"]
                    : celComprimida["hospObservacion"],
              })
            }
          >
            {!celComprimida["hospObservacion"] ? (
              <>
                {row.hospObservacion.length > 30
                  ? `${row.hospObservacion.substr(0, 30)}...`
                  : `${row.hospObservacion.substr(0, 30)}`}
              </>
            ) : null}
            <Collapse
              in={celComprimida["hospObservacion"]}
              timeout="auto"
              unmountOnExit
            >
              {row.hospObservacion}
            </Collapse>
          </TableCell>
          <CallNombrePersona user={row.hospUser} />
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            {new Date(row.hospFecUpd).toLocaleString("es-ES", {
              weekday: "short",
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour: "2-digit",
            })}
          </TableCell>
        </>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
