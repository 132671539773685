export const validarEmail = (email) => {
  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
    return true;
  } else {
    return false;
  }
};
function soloNumeros(cadena) {
  return /^\d+$/.test(cadena);
}
export const validarDocumento = (tipoDocumento, documento, mostrarAlerta) => {
  if (
    tipoDocumento === "RUC" &&
    (documento.length !== 13 || !soloNumeros(documento))
  ) {
    mostrarAlerta("El documento RUC, debe tener 13 digitos", "error");
    return true;
  } else if (
    tipoDocumento === "CEDULA" &&
    (documento.length !== 10 || !soloNumeros(documento))
  ) {
    mostrarAlerta("El documento CEDULA, debe tener 10 digitos", "error");
    return true;
  } else if (tipoDocumento === "PASAPORTE" && documento.length < 4) {
    mostrarAlerta(
      "El documento PASAPORTE, debe tener al menos 4 caracteres",
      "error"
    );
    return true;
  }
  return false;
};
export const isNum = (val) => {
  return !isNaN(val);
};
export const calcularEdad = (fecha) => {
  var hoy = new Date();
  var cumpleanos = new Date(fecha);
  var edad = hoy.getFullYear() - cumpleanos.getFullYear();
  var m = hoy.getMonth() - cumpleanos.getMonth();

  if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
    edad--;
  }

  return edad;
};

export const tienePermiso = (rol, permisos) => {
  return permisos.some((permiso) => permiso === rol);
};
export const tienePermisoComponente = (
  objetcPermisos,
  tipoPermiso,
  usuario
) => {
  const tienePermiso = objetcPermisos[tipoPermiso].some((itemPermiso) =>
    usuario.rol.some((itemRolUsuario) => itemRolUsuario === itemPermiso)
  );

  return tienePermiso;
};

export const tienePermisoRuta = (rows, ruta, usuarioRol, tipo) => {
   
  const categorias = rows.filter((item) => {
    return item.sidInfo.tipo === "categoria";
  });
  const subcategorias = rows.filter((item) => {
    return item.sidInfo.tipo === "subCategoria";
  });

  const split = ruta.split("/");

  const alcance = split.length;

  if (alcance === 5) {
    const tienePermiso = true;
    return tienePermiso;
  } else if (alcance === 4) {
    const rutaSC = split[3];
    const SC = subcategorias.find((subcategoria) => {
      return subcategoria.sidInfo.path === "/" + rutaSC;
    });

    const tienePermiso = SC.sidInfo[tipo].some((rol) =>
      usuarioRol.some((rolDelUsuario) => {
        return rolDelUsuario === rol;
      })
    );
    return tienePermiso;
  } else if (alcance === 3) {
    const rutaC = split[2];
    const C = categorias.find((subcategoria) => {
      return subcategoria.sidInfo.path === "/" + rutaC;
    });

    const tienePermiso = C.sidInfo[tipo].some((rol) => rol === "ADMIN");
    return tienePermiso;
  }
};
