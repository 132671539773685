import React, { useState, useContext, useEffect, memo } from "react";
import { makeStyles } from "@mui/styles";
import {
  Modal,
  Box,
  Button,
  Divider,
  Typography,
  FormControl,
  InputLabel,
  Select,
  Input,
  Chip,
  MenuItem,
  useTheme,
  TextField,
  Paper,
  TableContainer,
  Table,
  TablePagination,
  TableBody,
  TableHead,
  Autocomplete,
} from "@mui/material";
import RowAgregarMaterial from "./RowsMateriales/RowAgregarMaterial";
import RowMaterial from "./RowsMateriales/RowMaterial";
import Draggable from "react-draggable";
import { Cancel } from "@mui/icons-material";
import { IconButton } from "@mui/material";
// eslint-disable-next-line
import AlertaContext from "../../../Context/Alerta/AlertaContext";
import AuthContext from "../../../Context/Auth/AuthContext";
import { replaceCaracteres, trimPropsItem } from "../../../config/const";

import clienteAxios from "../../../config/axios";
import ToolBarTable from "./ToolBarTable";
import TablaCabecera from "./TablaCabecera";

function getModalStyle() {
  return {
    borderRadius: "15px",
    position: "absolute",
    overflow: "scroll",
    maxHeight: "95vh",
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "70%",
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "15px",
    marginTop: "5vh",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      marginTop: "2vh",
    },
  },
  margin: {
    margin: theme.spacing(1),
    width: "45%",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  ocultar: {
    display: "none",
  },
  inputPadding: {
    padding: theme.spacing(0.5),
    margin: theme.spacing(0),
    fontSize: "1rem",
  },
  formD: {
    flexGrow: 1,

    width: "45%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  formAllW: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "95%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
    },
  },
  large: {
    width: theme.spacing(13),
    height: theme.spacing(13),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    "&:hover": {
      opacity: ".7",
    },
  },

  center: {
    justifyContent: "center",
    alignItems: "center",
  },
  root: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  correo: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "45%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  error: {
    color: "red",
    fontWeight: "bold",
  },
}));

const ModalAsistencia = (props) => {
  const [clientesData, setclientesData] = useState([]);
  const [contratos, setcontratos] = useState([]);

  const { mostrarAlerta } = useContext(AlertaContext);

  const { usuario } = useContext(AuthContext);
  //props
  const {
    open,
    setopen,
    editar,
    seteditar,
    ObjectoActivo,
    changeObjetoActivoState,
    socket,
  } = props;
  const [item, setitem] = useState(ObjectoActivo);
  const [cargando, setcargando] = useState(false);

  const {
    // asitecId: 1,
    // asitecFecha,
    asitecTipoServicio,
    asitecClienteTipo,
    asitecClienteDocumento,
    asitecClienteNombres,
    // asitecClienteDireccion,
    // asitecClienteCelular,
    asitecClienteContrato,
    asitecTecnicoDocumento,
    asitecTecnicoNombres,
    // asitecTecnicoDireccion,
    // asitecTecnicoCelular,
    asitecRedWifi,
    asitecPoseeRvoltaje,
    asitecEquiposInstalados,
    // asitecCargosAdicionales,
    // asitecEmpresa,
    // asitecUser,
    // asitecFecReg,
    // Todos,
  } = item;

  const [error, seterror] = useState({
    perDocTipo: false,
    perDocNumero: false,
    perNombre: false,
    perDireccion: false,
    perTelefono: false,
    perPais: false,
    perProvincia: false,
    perCanton: false,
    perTipoProveedor: false,
    perObligado: false,
    perTiempoCredito: false,
    perCredito: false,
    perEmail: false,
  });

  useEffect(() => {
    setitem({
      ...ObjectoActivo,
    });
    // eslint-disable-next-line
  }, [open]);

  const defaultActive = () => {
    changeObjetoActivoState({
      // asitecId: 1,
      asitecFecha: "",
      asitecTipoServicio: [],
      asitecClienteTipo: "",
      asitecClienteDocumento: "",
      asitecClienteNombres: "",
      asitecClienteDireccion: "",
      asitecClienteCelular: [],
      asitecClienteContrato: "",
      asitecTecnicoDocumento: "",
      asitecTecnicoNombres: "",
      asitecTecnicoDireccion: "",
      asitecTecnicoCelular: [],

      asitecRedWifi: "",
      asitecPoseeRvoltaje: "",
      asitecEquiposInstalados: [],
      asitecCargosAdicionales: [],
      asitecEmpresa: "",
      asitecUser: "",
      asitecFecReg: "",
      Todos: "",
    });
    setopen(false);
    seteditar(false);
    seterror({
      perDocTipo: false,
      perDocNumero: false,
      perNombre: false,
      perDireccion: false,
      perTelefono: false,
      perPais: false,
      perProvincia: false,
      perCanton: false,
      perTipoProveedor: false,
      perObligado: false,
      perTiempoCredito: false,
      perCredito: false,
      perEmail: false,
    });
    setseccionActive({
      DatosPrincipales: true,
    });
    setcargando(false);
  };

  const handleChange = (e) => {
    const res = replaceCaracteres(e.target.value);
    setitem({
      ...item,
      [e.target.name]: res,
    });
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        height: "auto",
      },
    },
  };
  function getStylesMultiSelect(name, permisos, theme) {
    return {
      fontWeight:
        permisos.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
       backgroundColor: permisos.indexOf(name) === -1 ? "white" : theme.palette.primary.main,
    };
  }

  const theme = useTheme();

  //hook de estilos
  const clases = useStyles();
  //definiendo los estilos del modal
  const [modalStyle] = useState(getModalStyle);
  const submit = () => {
    if (true) {
      return false;
    } else {
      return true;
    }
  };
  const [seccionActive, setseccionActive] = useState({
    DatosPrincipales: true,
  });
  const { DatosPrincipales } = seccionActive;

  // TABLA

  // const [materialesData, setmaterialesData] = useState([]);
  const [agregandoMaterial, setagregandoMaterial] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleCrear = async () => {
    try {
      setcargando(true);

      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...item,
          tabla: "asistenciatecnica",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          asitecUser: usuario.usuario,
          asitecEmpresa: usuario.rucempresa,
        })
      );
      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);
      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  const handleEditar = async () => {
    try {
      setcargando(true);
      socket.current.emit(
        "client:actualizarData",
        trimPropsItem({
          ...item,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          asitecUser: usuario.usuario,
          asitecEmpresa: usuario.rucempresa,
          tabla: "asistenciatecnica",
        })
      );
      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);
      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  //filas por pagina

  // 1 + 2 + 3 + 4

  if (!open) {
    return null;
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Draggable handle="#handle">
          <Box display="flex" justifyContent="center">
            <div style={modalStyle} className={clases.paper}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography
                  style={{ cursor: "-webkit-grab" }}
                  id="handle"
                  variant="button"
                  color="initial"
                  align="center"
                >
                  {editar ? "Editar" : "Agregar"}
                  {" Asistencia"}
                </Typography>
                <Box display="flex" alignItems="center">
                  <IconButton
                    aria-label=""
                    style={{ margin: "0px" }}
                    onClick={() => {
                      defaultActive();
                    }}
                  >
                    <Cancel color="secondary" />
                  </IconButton>
                </Box>
              </Box>
              <Divider />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems=" stretch"
                width="100%"
              >
                <Box>
                  <Button
                    variant={DatosPrincipales ? "contained" : null}
                    disableElevation
                    style={{ padding: "8px" }}
                    color="secondary"
                    onClick={() => setseccionActive({ DatosPrincipales: true })}
                  >
                    Datos Principales
                  </Button>
                </Box>

                {/* <FormControlLabel
                control={
                  <Switch
                    checked={perEstado === "ACTIVO" ? true : false}
                    onChange={(e) => {
                      setitem({
                        ...item,
                        perEstado: e.target.checked ? "ACTIVO" : "INACTIVO",
                      });
                    }}
                    name="antoine"
                  />
                }
                label="Estado"
              /> */}
              </Box>
              <Divider />
              <Box
                display="flex"
                flexWrap="wrap"
                alignItems="flex-end"
                borderRadius="0px 50px 50px 0px"
              >
                {DatosPrincipales ? (
                  <>
                    <FormControl
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                    >
                      <InputLabel id="demo-mutiple-chip-label">
                        Tipo Servicio
                      </InputLabel>
                      <Select
                        inputProps={{ className: clases.inputPadding }}
                        error={error.asitecTipoServicio}
                        labelId="demo-mutiple-chip-label"
                        id="demo-mutiple-chip"
                        multiple
                        name="asitecTipoServicio"
                        value={asitecTipoServicio}
                        onChange={(e) => {
                          setitem({
                            ...item,
                            [e.target.name]: e.target.value,
                          });
                        }}
                        input={<Input id="select-multiple-chip" />}
                        MenuProps={MenuProps}
                        renderValue={(selected) => (
                          <div className={clases.chips}>
                            {selected.map((value, index) => {
                              return (
                                <Chip
                                  key={index}
                                  label={value}
                                  style={{ margin: "0rem", padding: "0px" }}
                                />
                              );
                            })}
                          </div>
                        )}
                      >
                        {[
                          "INSTALACIÓN NUEVA FIBRA OPTICA",
                          "VISITA TÉCNICA FIBRA ÓPTICA",
                          "SERVICIOS POR VISITA",
                          "CAMBIO DE TECNOLOGIA",
                          "RETIRO DE EQUIPOS",
                          "TRASLADOS",
                        ].map((row, index) => (
                          <MenuItem
                            key={index}
                            value={row}
                            style={getStylesMultiSelect(
                              row,
                              asitecTipoServicio,
                              theme
                            )}
                          >
                            {row}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <TextField
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      id="asitecClienteTipo"
                      label="Tipo Cliente"
                      value={asitecClienteTipo}
                      name="asitecClienteTipo"
                      error={error.asitecClienteTipo}
                      onChange={(e) => handleChange(e)}
                      select
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                    >
                      <MenuItem value="">
                        Seleccione un Tipo de Cliente
                      </MenuItem>
                      {["EMPRESARIAL", "CORPORATIVO", "HOME"].map((item) => {
                        return <MenuItem value={item}>{item} </MenuItem>;
                      })}
                    </TextField>
                    <FormControl fullWidth className={clases.formD}>
                      <Autocomplete
                        options={clientesData.map((item) => {
                          return (
                            item.perDocNumero +
                            "/+/" +
                            item.perNombre +
                            "/+/" +
                            item.perId
                          );
                        })}
                        getOptionLabel={(option) => {
                          return option.replace("/+/", " ").split("/+/")[0];
                        }}
                        id="controlled-demo"
                        value={
                          (
                            asitecClienteDocumento +
                            " " +
                            asitecClienteNombres
                          ).trim() === ""
                            ? ""
                            : asitecClienteDocumento +
                              " " +
                              asitecClienteNombres
                        }
                        paperprops
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            const options = newValue.split("/+/");
                            const itemF = clientesData.find(
                              (itemCliente) =>
                                itemCliente.perId === Number(options[2])
                            );

                            setcontratos(Object.values(itemF.perISPContratos));
                            setitem({
                              ...item,
                              asitecClienteDocumento: itemF.perDocNumero,
                              asitecClienteNombres: itemF.perNombre,
                              asitecClienteDireccion: itemF.perDireccion,
                              asitecClienteCelular: itemF.perCelular,
                              asitecClienteContrato: "",
                            });
                          } else {
                            setitem({
                              ...item,
                              asitecClienteDocumento: "",
                              asitecClienteNombres: "",
                              asitecClienteDireccion: "",
                              asitecClienteCelular: [],
                              asitecClienteContrato: "",
                            });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            required
                            label="Cliente"
                            fullWidth
                            error={error.venRucCliente}
                            onChange={async (e) => {
                              if (e.target.value === "") {
                                return mostrarAlerta(
                                  "Ingrese un producto",
                                  "error"
                                );
                              }
                              try {
                                if (e.target.value.length < 3) {
                                  return;
                                }
                                const res = await clienteAxios.get(
                                  `/proveedores/filtro/0?empresa=${
                                    usuario.rucempresa
                                  }&search=${
                                    e.target.value
                                  }&estado=${"CLIENTE"}&tabla=ventas`
                                );
                                if (!res.data.data) {
                                  return mostrarAlerta(res.data.msg, "error");
                                }
                                setclientesData(res.data.data);
                              } catch (error) {}
                            }}
                            InputProps={{
                              ...params.InputProps,
                              // endAdornment: (
                              //   <React.Fragment>
                              //     <IconButton
                              //       size="small"

                              //       aria-label=""
                              //       onClick={async () => {
                              //         // seteditarProveedor(false);
                              //         setOpenProveedor(true);
                              //       }}
                              //     >
                              //       <AddCircleOutlineTwoTone color="primary" />
                              //     </IconButton>
                              //     {params.InputProps.endAdornment}
                              //   </React.Fragment>
                              // ),
                            }}
                          />
                        )}
                      />
                    </FormControl>
                    <FormControl fullWidth className={clases.formD}>
                      <Autocomplete
                        options={clientesData.map((item) => {
                          return (
                            item.perDocNumero +
                            "/+/" +
                            item.perNombre +
                            "/+/" +
                            item.perId
                          );
                        })}
                        getOptionLabel={(option) => {
                          return option.replace("/+/", " ").split("/+/")[0];
                        }}
                        id="controlled-demo"
                        value={
                          (
                            asitecTecnicoDocumento +
                            " " +
                            asitecTecnicoNombres
                          ).trim() === ""
                            ? ""
                            : asitecTecnicoDocumento +
                              " " +
                              asitecTecnicoNombres
                        }
                        paperprops
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            const options = newValue.split("/+/");
                            const itemF = clientesData.find(
                              (itemCliente) =>
                                itemCliente.perId === Number(options[2])
                            );

                            setitem({
                              ...item,
                              asitecTecnicoDocumento: itemF.perDocNumero,
                              asitecTecnicoNombres: itemF.perNombre,
                              asitecTecnicoDireccion: itemF.perDireccion,
                              asitecTecnicoCelular: itemF.perCelular,
                            });
                          } else {
                            setitem({
                              ...item,
                              asitecTecnicoDocumento: "",
                              asitecTecnicoNombres: "",
                              asitecTecnicoDireccion: "",
                              asitecTecnicoCelular: [],
                            });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            required
                            label="Tecnico"
                            fullWidth
                            error={error.venRucCliente}
                            onChange={async (e) => {
                              if (e.target.value === "") {
                                return mostrarAlerta(
                                  "Ingrese un producto",
                                  "error"
                                );
                              }
                              try {
                                if (e.target.value.length < 3) {
                                  return;
                                }
                                const res = await clienteAxios.get(
                                  `/proveedores/filtro/0?empresa=${
                                    usuario.rucempresa
                                  }&search=${
                                    e.target.value
                                  }&estado=${"TECNICO"}&tabla=ventas`
                                );
                                if (!res.data.data) {
                                  return mostrarAlerta(res.data.msg, "error");
                                }
                                setclientesData(res.data.data);
                              } catch (error) {}
                            }}
                            InputProps={{
                              ...params.InputProps,
                              // endAdornment: (
                              //   <React.Fragment>
                              //     <IconButton
                              //       size="small"

                              //       aria-label=""
                              //       onClick={async () => {
                              //         // seteditarProveedor(false);
                              //         setOpenProveedor(true);
                              //       }}
                              //     >
                              //       <AddCircleOutlineTwoTone color="primary" />
                              //     </IconButton>
                              //     {params.InputProps.endAdornment}
                              //   </React.Fragment>
                              // ),
                            }}
                          />
                        )}
                      />
                    </FormControl>
                    <FormControl fullWidth className={clases.formD}>
                      <Autocomplete
                        options={contratos.map((item) => {
                          return item.perNombreContrato;
                        })}
                        getOptionLabel={(option) => {
                          return option;
                        }}
                        id="controlled-demo"
                        value={asitecClienteContrato}
                        paperprops
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            setitem({
                              ...item,
                              asitecClienteContrato: newValue,
                            });
                          } else {
                            setitem({
                              ...item,
                            });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            required
                            label="Nombre Contrato"
                            fullWidth
                            error={error.venRucCliente}
                            onChange={async (e) => {
                              if (e.target.value === "") {
                                return mostrarAlerta(
                                  "Ingrese un producto",
                                  "error"
                                );
                              }
                              try {
                                if (e.target.value.length < 3) {
                                  return;
                                }
                                const res = await clienteAxios.get(
                                  `/proveedores/filtro/0?empresa=${
                                    usuario.rucempresa
                                  }&search=${
                                    e.target.value
                                  }&estado=${"CLIENTE"}&tabla=ventas`
                                );
                                if (!res.data.data) {
                                  return mostrarAlerta(res.data.msg, "error");
                                }
                                setclientesData(res.data.data);
                              } catch (error) {}
                            }}
                            InputProps={{
                              ...params.InputProps,
                              // endAdornment: (
                              //   <React.Fragment>
                              //     <IconButton
                              //       size="small"

                              //       aria-label=""
                              //       onClick={async () => {
                              //         // seteditarProveedor(false);
                              //         setOpenProveedor(true);
                              //       }}
                              //     >
                              //       <AddCircleOutlineTwoTone color="primary" />
                              //     </IconButton>
                              //     {params.InputProps.endAdornment}
                              //   </React.Fragment>
                              // ),
                            }}
                          />
                        )}
                      />
                    </FormControl>{" "}
                    <TextField
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      id="asitecRedWifi"
                      label="Red Wifi"
                      value={asitecRedWifi}
                      name="asitecRedWifi"
                      error={error.asitecRedWifi}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      id="asitecPoseeRvoltaje"
                      label="El cliente posee regulador de voltaje"
                      value={asitecPoseeRvoltaje}
                      name="asitecPoseeRvoltaje"
                      error={error.asitecPoseeRvoltaje}
                      onChange={(e) => handleChange(e)}
                      select
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                    >
                      <MenuItem value="">Seleccione</MenuItem>
                      {["SI", "NO"].map((item) => {
                        return <MenuItem value={item}>{item} </MenuItem>;
                      })}
                    </TextField>
                    <Paper className={clases.root}>
                      <ToolBarTable
                        titulo={"Equipos Instalados"}
                        soloTitulo={true}
                        botonAdd={true}
                        deshabilitarbotonAdd={false}
                        funcionAdd={() => {
                          setagregandoMaterial(true);

                          // const retenciones = [
                          //   {
                          //     detTipo: "",
                          //     detCodigo: "",
                          //     detBasImponible: "",
                          //     detImpuesto: "",
                          //     detPorcentaje: "",
                          //     detValor: "",
                          //   },
                          //   ...item.retenciones,
                          // ];

                          // setitem({
                          //   ...item,
                          //   retenciones,
                          // });
                        }}
                      />
                      <TableContainer className={clases.container}>
                        <Table
                          stickyHeader
                          aria-label="sticky table"
                          id="tabla"
                          size="small"
                        >
                          <caption
                            style={{
                              padding: "0px",
                              paddingRight: "1rem",
                              paddingLeft: "1rem",
                            }}
                          >
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <TablePagination
                                labelRowsPerPage="Filas por pagina"
                                rowsPerPageOptions={[
                                  10,
                                  20,
                                  50,
                                  100,
                                  500,
                                  1000,
                                ]}
                                component="div"
                                count={asitecEquiposInstalados.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                              />
                            </Box>
                          </caption>
                          {/* <ModalCarnet setopen={setopen} onChangeCarnetActivo={onChangeCarnetActivo}/> */}

                          <TableHead>
                            {" "}
                            <TablaCabecera
                              columns={[
                                {
                                  id: "ITEM",
                                  label: "ITEM",
                                  minWidth: 100,
                                  tipo: "string",

                                  propiedad: "ITEM",
                                },
                                {
                                  id: "COSTO UNITARIO",
                                  label: "COSTO UNITARIO",
                                  minWidth: 100,
                                  tipo: "string",

                                  propiedad: "COSTO UNITARIO",
                                },
                                {
                                  id: "CANTIDAD",
                                  label: "CANTIDAD",
                                  minWidth: 100,
                                  tipo: "string",

                                  propiedad: "CANTIDAD",
                                },
                                {
                                  id: "VALOR",
                                  label: "VALOR",
                                  minWidth: 100,
                                  tipo: "string",

                                  propiedad: "VALOR",
                                },
                                {
                                  id: "DESCRIPCIÓN",
                                  label: "DESCRIPCIÓN",
                                  minWidth: 100,
                                  tipo: "string",

                                  propiedad: "DESCRIPCIÓN",
                                },
                                {
                                  id: "MARCA",
                                  label: "MARCA",
                                  minWidth: 100,
                                  tipo: "string",

                                  propiedad: "MARCA",
                                },
                                {
                                  id: "OBSERVACIONES",
                                  label: "OBSERVACIONES",
                                  minWidth: 100,
                                  tipo: "string",

                                  propiedad: "OBSERVACIONES",
                                },
                              ]}
                              habilitarOrdenar={false}
                            />
                          </TableHead>

                          <TableBody>
                            {agregandoMaterial ? (
                              <RowAgregarMaterial
                                setagregar={setagregandoMaterial}
                                setitem={setitem}
                                item={item}
                              />
                            ) : null}
                            {asitecEquiposInstalados.map((row, index) => {
                              return (
                                <RowMaterial
                                  deshabilitarbotones={false}
                                  item={item}
                                  setitem={setitem}
                                  row={row}
                                  index={index}
                                  key={index}
                                />
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                  </>
                ) : null}
              </Box>
              {/* <Divider /> */}
              <Box
                display="flex"
                width="100%"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="flex-end"
                  mt={1}
                >
                  <Button
                    style={{ width: "30%" }}
                    variant="contained"
                    color="primary"
                    disabled={submit() || cargando}
                    onClick={() => {
                      if (editar) {
                        handleEditar();
                      } else {
                        handleCrear();
                      }
                    }}
                  >
                    Guardar
                  </Button>
                </Box>
              </Box>
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default memo(ModalAsistencia);
