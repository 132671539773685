import { Delete, PersonPin } from "@mui/icons-material";
import { IconButton, Tooltip, Typography } from "@mui/material";
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import { useContext } from "react";
import clienteAxios from "../../../../config/axios";
export const CalendarEvent = ({
  event: { event },
  changeObjetoActivoStatePropietario,
  setopenPropietarioModal,
  setopenConfirmDialog,
}) => {
  const { mostrarAlerta } = useContext(AlertaContext);

  const funcionEditPropietario = async () => {
    try {
      if (!event.resPerId || event.resPerId === "") {
        return mostrarAlerta("No hay propietario", "error");
      }
      const res = await clienteAxios(`/proveedores/${event.resPerId}`);
      if (res.data.data.length === 0) {
        return mostrarAlerta(
          "Hubo un error en la consulta del propietario",
          "error"
        );
      }
      changeObjetoActivoStatePropietario(res.data.data[0]);
      setopenPropietarioModal(true);
      // setopen(true);
      // seteditar(true);
      // changeObjetoActivoState(row);
    } catch (error) {
      mostrarAlerta("Hubo un error", "error");
    }
  };
  return (
    <>
      <Tooltip
        title={
          <Typography>
            <Typography
              variant="caption"
              display={"block"}
              fontSize={".7rem"}
              fontWeight={"bold"}
            >
              {new Date(event.resFecha).toLocaleString("es-ES", {
                month: "2-digit",
                day: "2-digit",
                minute: "2-digit",
                hour: "2-digit",
              })}
              {" >> "}
              {new Date(event.resFechaFinal).toLocaleString("es-ES", {
                weekday: "short",
                month: "2-digit",
                day: "2-digit",
                minute: "2-digit",
                hour: "2-digit",
              })}
            </Typography>
            <Typography
              variant="caption"
              display={"block"}
              fontSize={".7rem"}
              fontWeight={"bold"}
            >
              {event.resTipoReserva}
            </Typography>
            <Typography
              variant="caption"
              display={"block"}
              fontSize={".7rem"}
              fontWeight={"bold"}
            >
              {event.resPerDocumento} {event.resPerNombre}
            </Typography>
            <Typography
              variant="caption"
              display={"block"}
              fontSize={".7rem"}
              fontWeight={"bold"}
            >
              {event.resMascNombre}
            </Typography>
            <Tooltip title="Eliminar" arrow>
              <IconButton
                onClick={() => {
                  setopenConfirmDialog(event);
                }}
              >
                <Delete color="error" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Ver propietario" arrow>
              <IconButton
                onClick={() => {
                  funcionEditPropietario();
                }}
              >
                <PersonPin color="primary" />
              </IconButton>
            </Tooltip>
          </Typography>
        }
        arrow
      >
        <Typography>
          <Typography
            variant="caption"
            display={"block"}
            fontSize={".7rem"}
            fontWeight={"bold"}
          >
            {event.resTipoReserva + ">"}
            {new Date(event.resFecha).toLocaleString("es-ES", {
              minute: "2-digit",
              hour: "2-digit",
            })}
            {" - "}
            {new Date(event.resFechaFinal).toLocaleString("es-ES", {
              minute: "2-digit",
              hour: "2-digit",
            })}
            {">" + event.resPerDocumento + " "}
            {event.resPerNombre + " "}
            {event.resMascNombre}
          </Typography>
        </Typography>
      </Tooltip>
    </>
  );
};
