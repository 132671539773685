import React from "react";
import { Collapse, TableCell } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  hover: {
    "&:hover": {
      opacity: ".7",
      cursor: "pointer",
    },
  },
  celda: {
    fontSize: ".7rem !important",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));

const CollapseCell = (props) => {
  const { celComprimida, setcelComprimida, row, propiedad, claseCelda } = props;
  const classes = useRowStyles();

  //   function splitHtmlTags(str) {
  //     return str.split(/<[^>]*>/g);
  //   }
  function HTMLString(props) {
    const { htmlString } = props; 
    return <div dangerouslySetInnerHTML={{ __html: htmlString }}></div>;
  }
  const regex = /<[^>]*>/g;

  if (!regex.test(row[propiedad])) {
    return (
      <TableCell
        padding="none"
        size="small"
        align="center"
        className={`${claseCelda ? claseCelda : classes.celda} ${
          classes.hover
        }`}
        onClick={() =>
          setcelComprimida({
            ...celComprimida,
            [propiedad]:
              row[propiedad].length > 50
                ? !celComprimida[propiedad]
                : celComprimida[propiedad],
          })
        }
      >
        {!celComprimida[propiedad] ? (
          <>
            {row[propiedad].length > 50
              ? `${row[propiedad].substr(0, 50)}...`
              : `${row[propiedad]}`}
          </>
        ) : null}
        <Collapse in={celComprimida[propiedad]} timeout="auto" unmountOnExit>
          {row[propiedad]}
        </Collapse>
      </TableCell>
    );
  }
  return (
    <TableCell
      padding="none"
      size="small"
      align="center"
      className={`${claseCelda ? claseCelda : classes.celda} ${classes.hover}`}
      sx={{
        maxHeight: "2rem",
      }}
      onClick={() =>
        setcelComprimida({
          ...celComprimida,
          [propiedad]:
            row[propiedad].length > 50
              ? !celComprimida[propiedad]
              : celComprimida[propiedad],
        })
      }
    >
      {!celComprimida[propiedad] ? (
        <>
          {row[propiedad].length > 50 ? (
            <>
              {HTMLString({ htmlString: row[propiedad].substr(0, 50) + "..." })}
            </>
          ) : (
            HTMLString({ htmlString: row[propiedad] })
          )}
        </>
      ) : null}
      <Collapse
        in={celComprimida[propiedad]}
        timeout="auto"
        unmountOnExit
        sx={
          row[propiedad].length > 50
            ? { maxHeight: "10rem", overflow: "scroll" }
            : {}
        }
      >
        {HTMLString({ htmlString: row[propiedad] })}
      </Collapse>
    </TableCell>
  );
};

export default CollapseCell;
