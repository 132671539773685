import React, { useState } from "react";

import {
  Box,
  Typography,
  Paper,
  TableContainer,
  Table,
  TablePagination,
  TableBody,
  TableHead,
} from "@mui/material";

import ToolBarTable from "../ToolBarTable";
import TablaCabecera from "../TablaCabecera";
// MEDICAMENTOS
import RowAgregarFluido from "./FluidosCabecera/RowAgregarFluido";
import RowFluido from "./FluidosCabecera/RowFluido";
// import RowAlimentoHorario from "./ParametroHorario/RowParametroHorario";
const TablaMedicamentos = (props) => {
  // const objetcD = {
  //   fluidosCabecera: [
  //     {
  //       order: "",
  //       alimento: "",
  //       dosis: "",
  //       cantidad: "1",
  //       frecuencia: "1",
  //       inicio: "0",
  //     },
  //   ],
  //   parametrosHorario: [
  //     {
  //       nameParametrosHorarios: "NUTRAPRO",
  //     },
  //   ],
  // };

  const { hospFluidos, setitem, item, error } = props;

  // TABLA MEDICAMENTOS
  const [agregandoMedicamento, setagregandoMedicamento] = useState(false);
  const [pageMedicamento, setPageMedicamento] = React.useState(0);
  const [rowsPerPageMedicamento, setRowsPerPageMedicamento] =
    React.useState(10);
  const handleChangePageMedicamento = async (event, newPage) => {
    setPageMedicamento(newPage);
  };
  const handleChangeRowsPerPageMedicamento = (event) => {
    setRowsPerPageMedicamento(+event.target.value);
    setPageMedicamento(0);
  };

  return (
    <>
      <Paper sx={{ width: "100%" }}>
        {error.hospFluidos ? (
          <Typography variant="caption" color="error">
            Ingrese al menos un parametro
          </Typography>
        ) : null}
        <ToolBarTable
          titulo={"Fluido"}
          soloTitulo={true}
          botonAdd={true}
          funcionAdd={() => {
            setagregandoMedicamento(true);
          }}
        />
        <TableContainer>
          <Table stickyHeader aria-label="sticky table" id="tabla" size="small">
            <caption
              style={{
                padding: "0px",
                paddingRight: "1rem",
                paddingLeft: "1rem",
              }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <TablePagination
                  labelRowsPerPage="Filas por pagina"
                  rowsPerPageOptions={[10, 20, 50, 100, 500, 1000,  ]}
                  component="div"
                  count={hospFluidos.fluidosCabecera.length}
                  rowsPerPage={rowsPerPageMedicamento}
                   
                  page={pageMedicamento}
                  onPageChange={handleChangePageMedicamento}
                  onRowsPerPageChange={handleChangeRowsPerPageMedicamento}
                />
              </Box>
            </caption>
            {/* <ModalCarnet setopen={setopen} onChangeCarnetActivo={onChangeCarnetActivo}/> */}

            <TableHead>
              <TablaCabecera
                columns={[
                  { id: "fluido", label: "fluido", minWidth: 100 },
                  { id: "dosis", label: "dosis", minWidth: 100 },
                  { id: "cantidad", label: "cantidad", minWidth: 100 },
                  { id: "inicio", label: "inicio", minWidth: 100 },
                ]}
                habilitarOrdenar={false}
              />
            </TableHead>

            <TableBody>
              {agregandoMedicamento ? (
                <RowAgregarFluido
                  setagregar={setagregandoMedicamento}
                  setitem={setitem}
                  item={item}
                />
              ) : null}
              {hospFluidos.fluidosCabecera.map((row, index) => {
                return (
                  <RowFluido
                    deshabilitarbotones={false}
                    item={item}
                    setitem={setitem}
                    row={row}
                    index={index}
                    key={row.order}
                  />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export default TablaMedicamentos;
